/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const HarveyTeam = {
    ASSISTANT_V2: 'assistant-v2',
    INFRA: 'infra',
    PLATFORM: 'platform',
    TAX: 'tax',
    VAULT: 'vault'
} as const;
export type HarveyTeam = typeof HarveyTeam[keyof typeof HarveyTeam];


export function instanceOfHarveyTeam(value: any): boolean {
    for (const key in HarveyTeam) {
        if (Object.prototype.hasOwnProperty.call(HarveyTeam, key)) {
            if (HarveyTeam[key as keyof typeof HarveyTeam] === value) {
                return true;
            }
        }
    }
    return false;
}

export function HarveyTeamFromJSON(json: any): HarveyTeam {
    return HarveyTeamFromJSONTyped(json, false);
}

export function HarveyTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): HarveyTeam {
    return json as HarveyTeam;
}

export function HarveyTeamToJSON(value?: HarveyTeam | null): any {
    return value as any;
}

export function HarveyTeamToJSONTyped(value: any, ignoreDiscriminator: boolean): HarveyTeam {
    return value as HarveyTeam;
}

