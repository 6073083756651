import React, { useCallback, useEffect } from 'react'

import _ from 'lodash'

import { getRolePerms, WorkspaceRole } from 'models/roles'
import { Workspace } from 'models/workspace'
import { RestrictedRole } from 'openapi/models/RestrictedRole'

import { useAuthUser } from 'components/common/auth-context'
import { Card, CardContent, CardTitle } from 'components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

import RoleBulkAddPerms from './role-bulk-add-perms'
import RolePermsTable, { RolePerm } from './role-perms-table'

interface RolesPermsCardProps {
  workspaceRole: WorkspaceRole
  setRolePermCount: (count: number) => void
  roleUserCount: number
  workspace: Workspace
}

enum RolePermsTab {
  rolePerms = 'rolePerms',
  addPerms = 'addPerms',
}

const RolesPermsCard = ({
  workspaceRole,
  setRolePermCount,
  roleUserCount,
  workspace,
}: RolesPermsCardProps) => {
  const userInfo = useAuthUser()
  const [rolePerms, setRolePerms] = React.useState<RolePerm[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const restrictedRoles = new Set(
    Object.values(RestrictedRole).map((key) => `${workspace.slug}-${key}`)
  )
  const rolePermManagementDisabled =
    restrictedRoles.has(workspaceRole.roleId) || workspace.deletedAt

  const fetchRolePerms = useCallback(async () => {
    setIsLoading(true)
    const rolePerms = await getRolePerms(workspaceRole.rolePk)
    setRolePerms(rolePerms)
    setIsLoading(false)
    setRolePermCount(rolePerms.length)
  }, [workspaceRole.rolePk, setRolePermCount])

  useEffect(() => {
    fetchRolePerms()
  }, [fetchRolePerms])

  if (_.isNil(userInfo) || !userInfo.IsInternalAdminReader) return <></>

  const RolesPermsTable = (
    <RolePermsTable
      isLoading={isLoading}
      rolePerms={_.sortBy(rolePerms, 'name')}
      fetchRolePermsCallback={fetchRolePerms}
      workspaceRole={workspaceRole}
      workspace={workspace}
    />
  )

  return (
    <div className="mb-6 w-full">
      <Card className="p-4">
        <CardTitle className="mb-3 ml-1">Permissions</CardTitle>
        <CardContent className="mt-2 px-1">
          {userInfo.IsInternalAdminWriter && !rolePermManagementDisabled ? (
            <Tabs defaultValue={RolePermsTab.rolePerms}>
              <TabsList>
                <TabsTrigger value={RolePermsTab.rolePerms}>
                  Role permissions
                </TabsTrigger>
                <TabsTrigger value={RolePermsTab.addPerms}>
                  Add permissions
                </TabsTrigger>
              </TabsList>
              <TabsContent value={RolePermsTab.rolePerms}>
                {RolesPermsTable}
              </TabsContent>
              <TabsContent value={RolePermsTab.addPerms}>
                <RoleBulkAddPerms
                  currentPerms={rolePerms}
                  role={workspaceRole}
                  fetchRolePermsCallback={fetchRolePerms}
                  roleUserCount={roleUserCount}
                />
              </TabsContent>
            </Tabs>
          ) : (
            <div className="mt-3">{RolesPermsTable}</div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default RolesPermsCard
