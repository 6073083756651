import { ProductLabel } from 'openapi/models/ProductLabel'
import { TimeInterval } from 'openapi/models/TimeInterval'
import Services from 'services'
import { TaskType } from 'types/task'

export interface UsageDataTimeseriesEntryRaw {
  timestamp: string
  queryCountByType: {
    [key in ProductLabel]: number
  }
  userCountByType: {
    [key in ProductLabel]: number
  }
  queryCountTotal: number
  userCountTotal: number
}

export interface UsageDataRaw {
  distinctTypes: TaskType[]
  aggregate: {
    userCountTotal: number // active users
    queryCountTotal: number
    userCountByType: {
      [key in ProductLabel]: number
    }
    queryCountByType: {
      [key in ProductLabel]: number
    }
  }
  // should be returned in ascending order from BE
  timeseries: {
    [key in TimeInterval]: UsageDataTimeseriesEntryRaw[]
  }
  userStats: {
    // user email as key
    [key: string]: {
      // 0 value means user has access to product type but ran zero queries
      // no entry for product type means user doesn't have access to it
      [key in ProductLabel]: number
    }
  }
}

// interface for post-processed FE data
export interface UsageDataTimeseriesEntry {
  timestamp: string
  parsedDateShortened: string // e.g. Jan 1
  parsedDateString: string // e.g. January 1, 2024
  queryCountByType: {
    [key: string]: number
  }
  userCountByType: {
    [key: string]: number
  }
  queryCountTotal: number
  userCountTotal: number
}

// interface for post-processed FE data
export interface UsageData {
  distinctTypes: string[] // using task definitions labels
  aggregate: {
    // for area chart
    userCountTotal: number
    queryCountTotal: number
    // for pie chart & area chart
    userCountByType: {
      [key: string]: number
    }
    queryCountByType: {
      [key: string]: number
    }
  }
  // for area chart
  timeseriesMap: {
    [key in TimeInterval]: UsageDataTimeseriesEntry[]
  }
  // for user stats table
  userStats: {
    // dictionary is now flattened with userEmail and product lines as keys
    userEmail: string
    // 0 value means user has access but ran zero queries
    // no entry for product type means user doesn't have access to it
    [key: string]: number | string
  }[]
}

export interface FetchUsageDataProps {
  workspaceSlug: string
  createdAfterOrEqual: Date
  createdBeforeOrEqual: Date
  userTz?: string
  selectedProductTypes?: string[] // ProductLabel enum values
}

export async function FetchUsageDataRaw(
  props: FetchUsageDataProps
): Promise<UsageDataRaw> {
  const result = await Services.Backend.Post<UsageDataRaw>(
    `dashboard/usage`,
    {
      workspaceSlug: props.workspaceSlug,
      createdAfterOrEqual: props.createdAfterOrEqual.toISOString(),
      createdBeforeOrEqual: props.createdBeforeOrEqual.toISOString(),
      userTz: props.userTz ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      productTypes: props.selectedProductTypes,
      includeMonthData: true,
    },
    {
      throwOnError: true,
    }
  )
  return result
}

interface UsageMetadata {
  distinctTypes: string[] // ProductLabel enum values
}

export async function FetchUsageMetadata(
  workspaceSlug: string
): Promise<UsageMetadata> {
  const params = new URLSearchParams()
  params.append('workspace_slug', workspaceSlug)

  const result = await Services.Backend.Get<UsageMetadata>(
    `dashboard/usage/metadata?${params.toString()}`,
    {
      throwOnError: true,
    }
  )
  return result
}
