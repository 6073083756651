import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import { DatabaseSource } from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { useAuthUser } from 'components/common/auth-context'

export const useIsPwcTax = () => {
  const userInfo = useAuthUser()
  const knowledgeSource = useAssistantStore((s) => s.knowledgeSource)

  const isTax = !!(knowledgeSource?.type === DatabaseSource.TAX)
  const isInternalOrPwcUser = userInfo.IsInternalUser || userInfo.IsPwcUser

  return isTax && isInternalOrPwcUser
}
