import React, { useRef, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useUnmount } from 'react-use'

import { isEmpty } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import HistoricQueries from './components/file-previewer/historic-queries'
import { BaseAppPath } from 'components/base-app-path'
import { getIsLoadingUrl } from 'components/common/pdf-viewer/helpers'
import { PdfViewerPushSheet } from 'components/common/pdf-viewer/pdf-viewer-push-sheet'
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
  ImperativeResizablePanelGroupHandle,
} from 'components/ui/resizable'
import { Spinner } from 'components/ui/spinner'
import { EXPIRATION_URL_KEY } from 'components/vault/utils/vault'
import { FetchVaultFile } from 'components/vault/utils/vault-fetcher'
import { isUrlExpired } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const VaultFilePreviewer = () => {
  const { fileId } = useParams()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const fileIdRef = useRef<string | null>(null)

  const fileIdToVaultFile = useVaultStore(
    useShallow((s) => s.fileIdToVaultFile)
  )
  const activeDocument = useVaultStore(useShallow((s) => s.activeDocument))
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)
  const setError = useVaultStore((s) => s.setError)

  const setIsPdfLoading = usePDFViewerStore((s) => s.setIsPdfLoading)
  const isAnnotating = usePDFViewerStore(useShallow((s) => s.isAnnotating))

  const isLoadingUrl = getIsLoadingUrl(activeDocument)

  const fetchFile = useCallback(async () => {
    if (!fileId) return

    if (fileIdRef.current === fileId) return

    // let's check if we have the file in our store
    // if we don't have it then we should terminate early because the file does not exist (ie it was deleted or not a valid fileId in the params)
    const existingFile = fileIdToVaultFile[fileId]
    if (isEmpty(existingFile) || existingFile.deletedAt) {
      setError({
        message: 'This file does not exist',
        cta: {
          redirectUri: BaseAppPath.Vault,
          message: 'Back to Vault',
        },
      })
      return
    }
    fileIdRef.current = fileId
    setIsPdfLoading(true)
    const existingFileUrl = existingFile.url || existingFile.docAsPdfUrl || ''
    const isUrlEmpty = isEmpty(existingFileUrl)
    const isExistingFileUrlValid =
      isUrlEmpty && !isUrlExpired(existingFileUrl, EXPIRATION_URL_KEY)
    if (isExistingFileUrlValid) {
      setActiveDocument(existingFile)
      return
    }

    // setting activeDocument to empty values so that drawer will render
    // specifically setting the 'x' path value so that the drawer will show a loading bar
    setActiveDocument({ name: '', url: '', path: 'x' } as UploadedFile)
    const file = await FetchVaultFile(fileId)

    if (!isEmpty(file)) {
      upsertVaultFiles([file])
      setActiveDocument(file as UploadedFile)
    } else {
      setError({
        message: 'There was an error fetching the file',
        cta: {
          redirectUri: BaseAppPath.Vault,
          message: 'Back to Vault',
        },
      })
    }
  }, [
    fileId,
    fileIdToVaultFile,
    setActiveDocument,
    setError,
    upsertVaultFiles,
    setIsPdfLoading,
  ])

  useEffect(() => {
    void fetchFile()
  }, [fetchFile])

  useUnmount(() => {
    setActiveDocument(null)
    fileIdRef.current = null
  })

  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)
  const defaultResizablePanelSizes = [60, 40]
  const defaultResizablePanelMinSize = 20
  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
  }

  return (
    <ResizablePanelGroup
      direction="horizontal"
      ref={resizablePanelGroupRef}
      className="flex w-full"
    >
      <ResizablePanel
        defaultSize={defaultResizablePanelSizes[0]}
        minSize={defaultResizablePanelMinSize}
      >
        <div className="relative h-full w-full">
          {isAnnotating && (
            <div
              className="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-primary bg-opacity-75"
              style={{ pointerEvents: 'none' }}
            >
              <Spinner />
            </div>
          )}
          <PdfViewerPushSheet
            document={activeDocument}
            isLoadingUrl={isLoadingUrl}
            containerRef={containerRef}
          />
        </div>
      </ResizablePanel>
      <ResizableHandle withHandle onDoubleClick={resetLayout} />
      <ResizablePanel
        defaultSize={defaultResizablePanelSizes[1]}
        minSize={defaultResizablePanelMinSize}
      >
        <HistoricQueries />
      </ResizablePanel>
    </ResizablePanelGroup>
  )
}

export default VaultFilePreviewer
