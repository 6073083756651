import React from 'react'

import { UploadCloud } from 'lucide-react'

import { cn } from 'utils/utils'

import Icon from 'components/ui/icon/icon'
import { Spinner } from 'components/ui/spinner'

interface Props {
  isLoading: boolean
  dropzone: {
    getRootProps: () => any
    getInputProps: () => any
  }
  description: React.ReactNode
  descriptionClassName?: string
  processingDescription?: string
  className?: string
  applySpacing?: boolean
}

export const Dropzone = (props: Props): JSX.Element => {
  const applySpacing = props.applySpacing ?? true
  return (
    <div
      {...props.dropzone.getRootProps()}
      className={cn(
        'relative flex h-full cursor-pointer items-center justify-center rounded-lg border border-dashed px-2 py-8 transition hover:border-ring hover:bg-secondary-hover focus:outline-none focus:ring-offset-0 focus-visible:ring focus-visible:ring-ring',
        props.className
      )}
      data-testid="dropzone"
    >
      <input {...props.dropzone.getInputProps()} />
      {props.isLoading ? (
        <div className="flex flex-col items-center justify-center text-center">
          <Spinner />
          <p className="mt-4 text-muted">
            {props.processingDescription || 'Uploading'}
          </p>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center text-center">
          <Icon icon={UploadCloud} size="large" />
          <p
            className={cn('', {
              'mt-4': applySpacing,
            })}
          >
            Drag and drop files here
          </p>
          <p
            className={cn('text-xs text-muted', props.descriptionClassName, {
              'mt-2': applySpacing,
            })}
          >
            {props.description}
          </p>
        </div>
      )}
    </div>
  )
}
