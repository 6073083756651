import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  ColumnDef,
  Row,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import _ from 'lodash'
import pluralize from 'pluralize'

import { UserPermInfo, fetchPermUsers } from 'models/perms'
import { Permission } from 'models/user-info'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { useAuthUser } from 'components/common/auth-context'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import { Badge } from 'components/ui/badge'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'
import SearchInput from 'components/ui/search-input'
import Tag from 'components/ui/tag'

const SettingPermissionUsers = () => {
  const userInfo = useAuthUser()

  const location = useLocation()
  const navigate = useNavigateWithQueryParams()
  const searchPermId: string = location.state?.permId
  const permName: string = location.state?.permName
  if (!searchPermId) {
    navigate('/settings/permissions')
  }

  const [userPermInfos, setUserPermInfos] = useState<UserPermInfo[]>([])
  const [loading, setLoading] = useState(false)

  const fetchPermissionUsers = useCallback(async () => {
    // fetch permission users
    setLoading(true)
    const users = await fetchPermUsers(searchPermId as Permission)
    setUserPermInfos(users)
    setLoading(false)
  }, [searchPermId])

  const handleRowClick = (row: Row<UserPermInfo>) => {
    navigate(`/settings/internal_admin/user-inspector`, {
      state: { email: row.original.email },
    })
  }

  useEffect(() => {
    void fetchPermissionUsers()
  }, [searchPermId, fetchPermissionUsers])

  const columns: ColumnDef<UserPermInfo>[] = [
    {
      accessorKey: 'email',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Email" />
      },
      cell: ({ getValue }) => <div className="ml-3">{String(getValue())}</div>,
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'workspace',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Workspace" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3">{_.capitalize(String(getValue()))}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'sources',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Source" />
      },
      cell: ({ getValue }) => {
        const sources = getValue() as string[]
        return (
          <div className="flex flex-wrap">
            {sources.map((source) => (
              <div key={source} className="ml-3">
                <Tag text={_.capitalize(source)} />
              </div>
            ))}
          </div>
        )
      },
      enableGlobalFilter: true,
    },
  ]

  const [tablePaginationState, setTablePaginationState] = useState({
    pageIndex: 0,
    pageSize: 20,
  })
  const [sorting, setSorting] = useState<SortingState>([])
  const [filter, setFilter] = useState('')

  const table = useReactTable({
    data: userPermInfos,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
    },
    onPaginationChange: setTablePaginationState,
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = table.getFilteredRowModel().rows.length
    ? `${table.getFilteredRowModel().rows.length} ${pluralize(
        'user',
        table.getFilteredRowModel().rows.length
      )}`
    : loading
    ? 'Loading…'
    : 'No users'

  if (!userInfo.IsInternalAdminReader) return null

  return (
    <>
      <SettingsAppHeader
        actions={<SearchInput value={filter} setValue={setFilter} withIcon />}
        isInternalAdmin
      />
      <SettingsLayout>
        <div className="mb-2 flex items-baseline space-x-2">
          <div className="text-lg">{_.startCase(permName)}</div>
          <Badge variant="secondary">{searchPermId}</Badge>
          <Badge variant="secondary">{rowCountCopy}</Badge>
        </div>
        <DataTable
          className="w-full"
          table={table}
          onRowClick={handleRowClick}
          isLoading={loading}
          emptyStateText="No users found"
        />
        <div className="mb-4">
          <DataTableFooter table={table} isLoading={loading} />
        </div>
      </SettingsLayout>
    </>
  )
}

export default SettingPermissionUsers
