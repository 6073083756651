import React, { useMemo } from 'react'
import { useMount } from 'react-use'

import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { getLibraryTeamLabel } from 'models/helpers/library-helper'
import { LibraryVisbilityScope } from 'openapi/models/LibraryVisbilityScope'
import Services from 'services'

import { displaySuccessMessage } from 'utils/toast'

import { usePromptsFilterComponents } from './prompts-filter-components'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import SelectVisibleFilter, {
  TabConfig,
} from 'components/filter/instances/select-visible-filter'
import { Dialog } from 'components/ui/dialog'

import { LibraryFilterRow } from './library-filter-row'
import {
  FilterFunction,
  LibraryFilterKey,
  usePromptFilterStore,
} from './library-filter-store'
import { PromptsStatus } from './library-status'
import { LibraryTable } from './library-table'
import { LibraryItem, LibraryItemKind, Prompt } from './library-types'
import { eventKindToQueryLimit } from './library.helpers'
import { getPromptsTableColumns } from './prompts-table-columns'
import {
  LibrarySavePromptDialogContent,
  SavePromptParams,
} from './save-prompt-button'
import { useLibraryData } from './use-library-data'
import { useVisibilityScope } from './use-visibility-scope'

const TAB_FILTER_CONFIG: Record<
  LibraryVisbilityScope,
  Pick<TabConfig, 'label' | 'tooltip'>
> = {
  [LibraryVisbilityScope.PRIVATE]: {
    label: 'Private',
    tooltip: 'Prompts created by you for your own personal use',
  },
  [LibraryVisbilityScope.WORKSPACE]: {
    label: getLibraryTeamLabel,
    tooltip: 'Prompts created by your admin for everyone',
  },
  [LibraryVisbilityScope.HARVEY]: {
    label: 'Harvey',
    tooltip: 'Prompts created by Harvey',
  },
}

const Prompts: React.FC = () => {
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()

  useMount(() => {
    Services.HoneyComb.Record({
      metric: 'ui.prompts_page_visited',
      workspace_id: userInfo.workspace.id,
      user_id: userInfo.id,
    })
  })

  const { items, isLoading, refetchItems } = useLibraryData(
    LibraryItemKind.PROMPT
  )

  const [registerFilter, filterValues, setFilterValue, setFilterValues] =
    usePromptFilterStore(
      useShallow((s) => [
        s.registerFilter,
        s.filterValues,
        s.setFilterValue,
        s.setFilterValues,
      ])
    )

  const visibleScopes = [
    ...(userInfo.IsLibraryPrivatePromptUser
      ? [LibraryVisbilityScope.PRIVATE]
      : []),
    LibraryVisbilityScope.WORKSPACE,
    LibraryVisbilityScope.HARVEY,
  ]

  const initialTab = visibleScopes[0]
  const [currentTab, setCurrentTab] = useVisibilityScope({
    initialScope: initialTab,
    items,
    queryKey: LibraryFilterKey.VISIBILITY_SCOPE,
    filterValues,
    setFilterValue,
  })

  const [editItem, setEditItem] = React.useState<Prompt | null>(null)

  const filterFunctions = usePromptFilterStore((s) => s.filterFunctions)
  const filterComponents = usePromptsFilterComponents({
    items: items as Record<string, Prompt>, // TODO: Cast type in useLibraryData
  })

  const filterKeys = useMemo(
    () => [
      ...filterComponents.map(({ filterKey }) => filterKey),
      LibraryFilterKey.VISIBILITY_SCOPE,
    ],
    [filterComponents]
  )

  const filteredItems = useMemo(
    () =>
      Object.values(items).filter(
        (item) => item.visibilityScope === currentTab
      ),
    [items, currentTab]
  )

  if (_.isNil(userInfo) || !userInfo.IsLibraryUser) {
    return null
  }

  // TODO: Move this to an api service
  const handleSaveUpdate = async (params: SavePromptParams) => {
    const { name, categories, practiceAreas, query } = params

    if (!editItem) return false
    let result = false
    try {
      const prompt = await Services.Backend.Patch<Prompt>(
        `library/prompt/${editItem.id}`,
        {
          name: name.trim(),
          query: query.trim(),
          categories: categories || undefined,
          practiceAreas: practiceAreas || undefined,
          sourceEventId: editItem.eventId,
        }
      )

      if (!_.isEmpty(prompt)) {
        Services.HoneyComb.Record({
          metric: `ui.library_prompt_edited`,
          item_id: editItem.id,
          task_type: editItem.eventKind,
          starred: editItem.starred,
          workspace_id: editItem.workspaceId,
          user_id: editItem.userId,
          visibility_scope: editItem.visibilityScope,
          categories: editItem.categories,
          practice_areas: editItem.practiceAreas,
          document_types: editItem.documentTypes,
        })
        trackEvent('Library Item Edited', {
          item_id: editItem.id,
          event_id: editItem.eventId,
          kind: LibraryItemKind.PROMPT,
          task_type: editItem.eventKind,
          categories: editItem.categories,
          practice_areas: editItem.practiceAreas,
          visibility_scope: editItem.visibilityScope,
          starred: editItem.starred,
          entry_point: 'prompts table',
        })
        displaySuccessMessage('Saved prompt successfully', 1)
        setEditItem(null)
        result = true
        void refetchItems()
      }
    } catch (error) {
      console.error('Error saving prompt update', error)
    }

    return result
  }

  return (
    <AppMain
      className="container mx-auto flex h-full flex-col space-y-4 py-4"
      data-testid="prompts-container"
    >
      <AppHeader
        breadcrumbs={<RouterBreadcrumbs removeParams={filterKeys} />}
        title="Prompts"
        subtitle="Browse prompts to easily perform common tasks"
        actions={
          <div className="ml-auto pl-2 lg:hidden">
            <PromptsStatus fetchUpdate={refetchItems} />
          </div>
        }
      />
      <Dialog
        open={!!editItem}
        onOpenChange={(open) => {
          if (!open) {
            setEditItem(null)
          }
        }}
      >
        {editItem && (
          <LibrarySavePromptDialogContent
            handleSavePrompt={handleSaveUpdate}
            query={editItem.query}
            queryLimit={eventKindToQueryLimit(editItem?.eventKind)}
            eventKind={editItem.eventKind}
            editPrompt={{
              id: editItem.id,
              name: editItem.name,
              categories: editItem.categories,
              practiceAreas: editItem.practiceAreas,
              visibilityScope: editItem.visibilityScope,
            }}
            trackEvent={trackEvent}
          />
        )}
      </Dialog>
      <div className="flex shrink-0 space-x-2 lg:justify-between">
        <div className="flex grow space-x-2">
          <div className="shrink-0">
            <SelectVisibleFilter
              id="visible-prompts-filter"
              filterKey={LibraryFilterKey.VISIBILITY_SCOPE}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabs={visibleScopes.map((scope) => ({
                value: scope,
                ...TAB_FILTER_CONFIG[scope],
              }))}
              trackEvent={trackEvent}
            />
          </div>
          <div className="hidden lg:block">
            <PromptsStatus fetchUpdate={refetchItems} />
          </div>
        </div>
        <LibraryFilterRow
          type={LibraryItemKind.PROMPT}
          workspace={userInfo.workspace}
          // TODO: Fix this type
          filterComponents={filterComponents as any}
          filterKeys={filterKeys}
          filterStore={{
            registerFilter,
            filterValues,
            setFilterValue,
            setFilterValues,
          }}
        />
      </div>
      <LibraryTable
        getColumns={(onDeleteItem, onHideItem) =>
          getPromptsTableColumns({
            currentTab,
            userInfo,
            onDeleteItem,
            onEditItem: setEditItem as (item: LibraryItem) => void,
            onHideItem,
          })
        }
        isLoading={isLoading}
        items={filteredItems}
        filterFunctions={
          Object.values(filterFunctions) as FilterFunction<LibraryItem>[]
        }
        kind={LibraryItemKind.PROMPT}
        visibilityScope={currentTab}
      />
    </AppMain>
  )
}

export default Prompts
