import { useCallback, useEffect } from 'react'

import { useShallow } from 'zustand/react/shallow'

import {
  CreateEventShares,
  FetchEventSharingStatus,
  FetchSharingWorkspaceUsers,
  UpdateEventSharingStatus,
} from 'models/fetchers/sharing-fetcher'
import { EventAccessPermission } from 'openapi/models/EventAccessPermission'
import { EventShareCreateRequest } from 'openapi/models/EventShareCreateRequest'
import { EventShareStatusApiResponse } from 'openapi/models/EventShareStatusApiResponse'
import { useSharingStore } from 'stores/sharing-store'
import {
  PermissionLevel,
  SHARE_ERROR_MESSAGE,
  SHARE_SUCCESS_MESSAGE,
} from 'types/sharing'

import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import { UpdatedWorkspaceAndUserSharingInfo } from 'components/vault/utils/vault-fetcher'

export const useEventSharing = (eventId: string | null) => {
  const { workspace } = useAuthUser()
  const [
    setSharingUsersForWorkspace,
    setSharingStatus,
    sharingStatus,
    reset,
    setDidFetchSharingUsersForWorkspaceFail,
    setDidFetchSharingStatusFail,
  ] = useSharingStore(
    useShallow((s) => [
      s.setSharingUsersForWorkspace,
      s.setSharingStatus,
      s.sharingStatus,
      s.reset,
      s.setDidFetchSharingUsersForWorkspaceFail,
      s.setDidFetchSharingStatusFail,
    ])
  )

  const refreshSharingStatus = useCallback(
    async (eventId: string, updatedStatus?: EventShareStatusApiResponse) => {
      try {
        const { shareStatus } =
          updatedStatus ?? (await FetchEventSharingStatus(eventId))

        setSharingStatus({
          permissionsByWorkspace: shareStatus.permissionsByWorkspace || [],
          permissionsByUser: shareStatus.permissionsByUser || [],
        })
        setDidFetchSharingStatusFail(false)
      } catch (e) {
        setDidFetchSharingStatusFail(true)
      }
    },
    [setSharingStatus, setDidFetchSharingStatusFail]
  )

  const populateSharingStore = useCallback(
    async (eventId: string) => {
      const updatePromises = [
        FetchSharingWorkspaceUsers(workspace.id, 'assist')
          .then(({ users }) => setSharingUsersForWorkspace(users))
          .catch(() => setDidFetchSharingUsersForWorkspaceFail(true)),
        refreshSharingStatus(eventId),
      ]

      await Promise.all(updatePromises)
    },
    [
      refreshSharingStatus,
      workspace.id,
      setSharingUsersForWorkspace,
      setDidFetchSharingUsersForWorkspaceFail,
    ]
  )

  const grantUserPermissions = useCallback(
    async (newUserIds: string[], permissionLevel: PermissionLevel) => {
      if (!eventId) return

      const createPayload: EventShareCreateRequest = {
        shareWithUsers: newUserIds.map((userId) => ({
          userId,
          permission: permissionLevel as EventAccessPermission,
        })),
      }

      await CreateEventShares(eventId, createPayload)
        .then(async (updatedStatus) => {
          await refreshSharingStatus(eventId, updatedStatus)
          displaySuccessMessage(SHARE_SUCCESS_MESSAGE)
        })
        .catch(() => {
          displayErrorMessage(SHARE_ERROR_MESSAGE)
        })
    },
    [refreshSharingStatus, eventId]
  )

  const updateSharePermissions = useCallback(
    async (params: UpdatedWorkspaceAndUserSharingInfo) => {
      if (!eventId) return

      const updateToRequestFormat = (
        params: UpdatedWorkspaceAndUserSharingInfo
      ) => {
        return {
          removeShareWithUsers: params.removeShareWithUsers,
          updateShareWithUsers: params.updateShareWithUsers?.map((user) => ({
            userId: user.userId,
            permission: user.permissionLevel as EventAccessPermission,
          })),
          removeShareWithWorkspaces: params.removeShareWithWorkspaces,
          updateShareWithWorkspaces: params.updateShareWithWorkspaces?.map(
            (workspace) => ({
              workspaceId: workspace.workspaceId,
              permission: workspace.permissionLevel as EventAccessPermission,
            })
          ),
        }
      }

      await UpdateEventSharingStatus(eventId, updateToRequestFormat(params))
        .then(async (updatedStatus) => {
          displaySuccessMessage(SHARE_SUCCESS_MESSAGE)
          await refreshSharingStatus(eventId, updatedStatus)
        })
        .catch(() => {
          displayErrorMessage(SHARE_ERROR_MESSAGE)
        })
    },
    [eventId, refreshSharingStatus]
  )

  const changeSharedWithWorkspace = useCallback(
    async (sharedWithWorkspace: boolean) => {
      if (!eventId) return

      if (sharedWithWorkspace) {
        await updateSharePermissions({
          updateShareWithWorkspaces: [
            {
              workspaceId: workspace.id,
              permissionLevel: PermissionLevel.VIEW,
            },
          ],
        })
      } else {
        await updateSharePermissions({
          removeShareWithWorkspaces: [workspace.id],
        })
      }
    },
    [eventId, workspace.id, updateSharePermissions]
  )

  useEffect(() => {
    if (eventId === null) {
      reset()
      return
    }

    void populateSharingStore(eventId)
  }, [eventId, populateSharingStore, reset])

  return {
    populateSharingStore,
    grantUserPermissions,
    changeSharedWithWorkspace,
    updateSharePermissions,
    sharingStatus,
  }
}
