import React, { useState, useMemo, useRef } from 'react'
import { useClickAway } from 'react-use'

import { useQueryClient } from '@tanstack/react-query'
import { SortDirection, GridApi } from 'ag-grid-community'
import { CustomHeaderProps } from 'ag-grid-react'
import { capitalize } from 'lodash'
import {
  Calendar,
  Clipboard,
  File,
  FileText,
  MapPin,
  User,
  Type,
  ArrowDownAZ,
  ArrowUpAZ,
  Trash2,
  Check,
  Hash,
  GripVertical,
  Settings2,
  Pencil,
} from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useGeneralStore } from 'stores/general-store'

import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Popover, PopoverAnchor, PopoverContent } from 'components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Textarea } from 'components/ui/text-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import { QuestionColumnDef } from 'components/vault/query-detail/data-grid-helpers'
import {
  EXCLUDED_COLIDS_FROM_COLUMN_CLICK,
  EXCLUDED_HEADER_NAMES_FROM_DELETE_COLUMN,
} from 'components/vault/query-detail/vault-query-detail'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  ColumnDataType,
  COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { DeleteReviewColumn } from 'components/vault/utils/vault-fetcher'
import { getNumSelectedRows } from 'components/vault/utils/vault-helpers'

const iconLookup: Record<string, React.ElementType> = {
  document: FileText,
  date: Calendar,
  location: MapPin,
  person: User,
  notes: Clipboard,
  file: File,
  text: Type,
  number: Hash,
}

interface HeaderDropdownMenuProps {
  gridApi: GridApi
  colDef: QuestionColumnDef
  colId: string
  isUpdatableColumn: boolean
  isPendingColumn: boolean
  isSortable: boolean
  sortColumnId: string
  setIsIconHovered: (isIconHovered: boolean) => void
  setSort: (sort: SortDirection, multiSort?: boolean | undefined) => void
  onColumnHeaderClicked: (e?: React.MouseEvent<HTMLDivElement>) => void
}

const ColumnEditor = ({
  colDef,
  colId,
  gridApi,
  isPendingColumn,
}: {
  colDef: QuestionColumnDef
  colId: string
  gridApi: GridApi
  isPendingColumn: boolean
}) => {
  const [setCurrentPendingColumnId, updatePendingQueryQuestion] =
    useVaultQueryDetailStore(
      useShallow((state) => [
        state.setCurrentPendingColumnId,
        state.updatePendingQueryQuestion,
      ])
    )

  const ref = useRef<HTMLDivElement>(null)
  const selectContentRef = useRef<HTMLDivElement>(null)

  const [columnDataTypeOpen, setColumnDataTypeOpen] = useState<boolean>(false)
  const [headerName, setHeaderName] = useState<string>(colDef.headerName ?? '')
  const [columnDataType, setColumnDataType] = useState<ColumnDataType>(
    colDef.columnDataType
  )
  const [question, setQuestion] = useState<string>(colDef.originalQuestion)

  const isSubmitDisabled = !isPendingColumn
    ? !headerName
    : !columnDataType || !question || !headerName
  const isDefaultColumnDef =
    colDef.headerName === 'Untitled' &&
    colDef.originalQuestion === '' &&
    colDef.columnDataType === ColumnDataType.date
  const isDirty =
    headerName !== colDef.headerName ||
    question !== colDef.originalQuestion ||
    columnDataType !== colDef.columnDataType

  useClickAway(ref, (event) => {
    if (
      selectContentRef.current &&
      selectContentRef.current.contains(event.target as Node)
    ) {
      return
    }
    setCurrentPendingColumnId(null)
  })

  const cancelHandler = () => {
    setCurrentPendingColumnId(null)
  }

  const onHeaderNameChangeHandler = (value: string) => {
    setHeaderName(value)
    updatePendingQueryQuestion(colId, 'header', value)
  }

  const onColumnDataTypeChangeHandler = (value: ColumnDataType) => {
    setColumnDataType(value)
    updatePendingQueryQuestion(colId, 'columnDataType', value)
  }

  const onQuestionChangeHandler = (value: string) => {
    setQuestion(value)
    updatePendingQueryQuestion(colId, 'text', value)
  }

  const onSubmitHandler = () => {
    const columnDefs = gridApi.getColumnDefs() ?? []
    columnDefs.forEach((columnDef) => {
      const questionColumnDef = columnDef as QuestionColumnDef
      const columnId =
        'colId' in questionColumnDef ? questionColumnDef.colId : null
      if (columnId === colId) {
        questionColumnDef.headerName = headerName
        questionColumnDef.originalQuestion = question
        questionColumnDef.columnDataType = columnDataType
      }
    })
    gridApi.setGridOption('columnDefs', columnDefs)
    setCurrentPendingColumnId(null)

    // TODO: update the column in the backend
  }

  return (
    <div ref={ref} className="flex-gol flex min-h-72 flex-col gap-4">
      <div>
        <Label>Header</Label>
        <Input
          required
          className="h-8"
          placeholder="Signing Date"
          value={headerName}
          onChange={(e) => {
            e.stopPropagation()
            onHeaderNameChangeHandler(e.target.value)
          }}
        />
      </div>
      <div>
        <Label>Type</Label>
        <Select
          disabled={!isPendingColumn}
          open={columnDataTypeOpen}
          onOpenChange={setColumnDataTypeOpen}
          value={columnDataType}
          onValueChange={(value) => {
            onColumnDataTypeChangeHandler(value as ColumnDataType)
          }}
        >
          <SelectTrigger className="h-8 w-full text-nowrap">
            <SelectValue placeholder="Date" />
          </SelectTrigger>
          <SelectContent ref={selectContentRef}>
            {Object.values(ColumnDataType)
              .filter(
                (value) =>
                  !COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION.includes(value)
              )
              .map((value) => (
                <SelectItem
                  key={value}
                  value={value}
                  onClick={(e) => {
                    e.stopPropagation()
                    setColumnDataTypeOpen(false)
                  }}
                >
                  {capitalize(value.replace(/_/g, ' '))}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label>Question</Label>
        <Textarea
          required
          className="resize-none"
          placeholder="What is the signing date of this agreement?"
          disabled={!isPendingColumn}
          value={question}
          onChange={(e) => {
            e.stopPropagation()
            onQuestionChangeHandler(e.target.value)
          }}
        />
      </div>
      <div className="flex w-full justify-end gap-2">
        <Button variant="outline" onClick={cancelHandler}>
          Cancel
        </Button>
        <Button
          onClick={onSubmitHandler}
          disabled={isSubmitDisabled || !isDirty}
        >
          {isDefaultColumnDef ? 'Add column' : 'Update column'}
        </Button>
      </div>
    </div>
  )
}

const HeaderDropdownMenu = ({
  gridApi,
  colDef,
  colId,
  isPendingColumn,
  isUpdatableColumn,
  isSortable,
  sortColumnId,
  setIsIconHovered,
  setSort,
  onColumnHeaderClicked,
}: HeaderDropdownMenuProps) => {
  const queryClient = useQueryClient()
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(
    'asc'
  )

  const [addRequestToPendingRequestIds, removeRequestFromPendingRequestIds] =
    useGeneralStore(
      useShallow((s) => [
        s.addRequestToPendingRequestIds,
        s.removeRequestFromPendingRequestIds,
      ])
    )

  const [
    isQueryLoading,
    removeFromPendingQueryQuestions,
    updateColumnInHistoryItem,
    setCurrentPendingColumnId,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.isQueryLoading,
      state.removeFromPendingQueryQuestions,
      state.updateColumnInHistoryItem,
      state.setCurrentPendingColumnId,
    ])
  )

  const currentSortColumnId = useVaultDataGridFilterStore(
    (state) => state.currentSortColumnId
  )
  const setCurrentSortColumnId = useVaultDataGridFilterStore(
    (state) => state.setCurrentSortColumnId
  )

  const isCurrentSortColumn = sortColumnId === currentSortColumnId
  const isCurrentSortAsc = isCurrentSortColumn && sortDirection === 'asc'
  const isCurrentSortDesc = isCurrentSortColumn && sortDirection === 'desc'

  const sortHandler = (newSortDirection: 'asc' | 'desc') => {
    // using props.setSort instead of props.progressSort because progressSort was not working
    // it required the user to click on the icon twice to sort
    if (isCurrentSortColumn && newSortDirection === sortDirection) return
    setCurrentSortColumnId(sortColumnId)
    setSortDirection(newSortDirection)
    setSort(newSortDirection)
  }

  const onDeleteColumnHandler = async () => {
    const allColumnDefs = gridApi.getColumnDefs()
    const filteredColumns = allColumnDefs?.filter((column) =>
      'colId' in column ? column.colId !== colId : true
    )
    if (isPendingColumn) {
      removeFromPendingQueryQuestions(colId)
      gridApi.setGridOption('columnDefs', filteredColumns)
      // update colDef id & field within the grid
      let newDisplayIndex = 0
      const updatedColumnDefs = filteredColumns?.map((filteredColumnDef) => {
        // skip if its a group column
        const isColIdInFilteredColumnDef = 'colId' in filteredColumnDef
        if (!isColIdInFilteredColumnDef) return filteredColumnDef

        // skip if its a column that is excluded from column click (row, name, gutter)
        const filteredColId = filteredColumnDef.colId
        if (EXCLUDED_HEADER_NAMES_FROM_DELETE_COLUMN.includes(filteredColId!))
          return filteredColumnDef

        newDisplayIndex++
        return {
          ...filteredColumnDef,
          colId: newDisplayIndex.toString(),
          field: newDisplayIndex.toString(),
          questionId: newDisplayIndex.toString(),
        }
      })
      gridApi.setGridOption('columnDefs', updatedColumnDefs)
    } else {
      const requestId = uuidv4()
      try {
        gridApi.setGridOption('columnDefs', filteredColumns)
        if (!colDef.eventId || !colDef.columnId) {
          throw new Error('Missing eventId or columnId')
        }
        addRequestToPendingRequestIds(requestId)
        const updatedColumn = await DeleteReviewColumn(
          colDef.eventId,
          colDef.columnId
        )
        updateColumnInHistoryItem(updatedColumn)
        // We need to invalidate the query so that the new query is fetched
        // This will update historyItem to reflect the right state in the document cells
        queryClient.invalidateQueries({
          queryKey: [
            HarvQueryKeyPrefix.VaultHistoryItemQuery,
            colDef.eventId,
            false,
          ],
        })
      } catch (error) {
        displayErrorMessage('Failed to delete column')
        gridApi.setGridOption('columnDefs', allColumnDefs)
      }
      removeRequestFromPendingRequestIds(requestId)
    }
    setCurrentPendingColumnId(null)
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="xsIcon" variant="ghost">
          <Settings2 className="h-3 w-3" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="space-y-1">
        {isSortable && (
          <DropdownMenuItem
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation()
              sortHandler('asc')
              setIsIconHovered(false)
            }}
            className={cn('flex w-full min-w-48 items-center justify-between', {
              'bg-button-secondary': isCurrentSortAsc,
            })}
          >
            <div className="flex items-center gap-1">
              <ArrowDownAZ className="h-3 w-3" />
              <p className="text-xs">Sort ascending</p>
            </div>
            {isCurrentSortAsc && <Check className="h-3 w-3" />}
          </DropdownMenuItem>
        )}
        {isSortable && (
          <DropdownMenuItem
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation()
              sortHandler('desc')
              setIsIconHovered(false)
            }}
            className={cn('flex w-full min-w-48 items-center justify-between', {
              'bg-button-secondary': isCurrentSortDesc,
            })}
          >
            <div className="flex items-center gap-1">
              <ArrowUpAZ className="h-3 w-3" />
              <p className="text-xs">Sort descending</p>
            </div>
            {isCurrentSortDesc && <Check className="h-3 w-3" />}
          </DropdownMenuItem>
        )}
        {isUpdatableColumn && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              disabled={isQueryLoading}
              onClick={onColumnHeaderClicked}
            >
              <div className="flex items-center gap-1">
                <Pencil className="h-3 w-3" />
                <p className="text-xs">Edit column</p>
              </div>
            </DropdownMenuItem>
            <DropdownMenuItem
              disabled={isQueryLoading}
              onClick={onDeleteColumnHandler}
            >
              <div className="flex items-center gap-1">
                <Trash2 className="h-3 w-3" />
                <p className="text-xs">Delete column</p>
              </div>
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const RowNumberColumnHeader = ({ gridApi }: { gridApi: GridApi }) => {
  const selectedRows = useVaultDataGridFilterStore(
    useShallow((state) => state.selectedRows)
  )
  const bulkAddSelectedRows = useVaultDataGridFilterStore(
    (state) => state.bulkAddSelectedRows
  )
  const bulkRemoveSelectedRows = useVaultDataGridFilterStore(
    (state) => state.bulkRemoveSelectedRows
  )

  const numRows = useMemo(() => {
    const { numRows } = getNumSelectedRows(gridApi)
    return numRows
  }, [gridApi])

  const isHeaderChecked = selectedRows.length > 0

  const onCheckedChange = () => {
    const nextChecked = !isHeaderChecked
    const rowsToUpdate: string[] = []
    gridApi.forEachNode((node) => {
      if (node.group) return
      // if the next state is checked and the node is not displayed, we don't want to select it
      // if the next state is unchecked we want to unselect it regardless of whether it is displayed
      if (nextChecked && !node.displayed) return
      node.setSelected(nextChecked)
      rowsToUpdate.push(node.data.id)
    })
    if (nextChecked) {
      bulkAddSelectedRows(rowsToUpdate)
    } else {
      bulkRemoveSelectedRows(rowsToUpdate)
    }
  }
  return (
    <div className="flex h-full w-full cursor-default items-center justify-center border-r border-primary px-4">
      <Checkbox
        checked={isHeaderChecked}
        onCheckedChange={onCheckedChange}
        isIndeterminate={selectedRows.length < numRows}
      />
    </div>
  )
}

const VaultHeaderCell = (props: CustomHeaderProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const [
    pendingQueryQuestions,
    currentPendingColumnId,
    setCurrentPendingColumnId,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.pendingQueryQuestions,
      state.currentPendingColumnId,
      state.setCurrentPendingColumnId,
    ])
  )

  const gridApi = props.api
  const colDef = props.column.getColDef() as QuestionColumnDef
  const colId = props.column.getColId()
  const isSortable = props.column.isSortable()

  const columnType = colDef.type
  const isRowNumberColumn = colId === 'row'
  const isPendingColumn = pendingQueryQuestions
    ? pendingQueryQuestions.some((question) => question.id === colId)
    : false
  const columnOriginalQuestion = colDef.originalQuestion
  const isUpdatableColumn = !EXCLUDED_COLIDS_FROM_COLUMN_CLICK.includes(colId)
  const columnHeaderName = colDef.headerName
  const columnIcon = iconLookup[columnType as string]
  const iconComponent = React.createElement(columnIcon, {
    className: 'mr-1 size-3 shrink-0',
  })
  const isQueryLoading = colDef.headerComponentParams.isQueryLoading
  const isExampleProject = colDef.headerComponentParams.isExampleProject
  const doesCurrentUserHaveEditPermission =
    colDef.headerComponentParams.doesCurrentUserHaveEditPermission
  const showGripIcon =
    isHovered &&
    columnType !== 'document' &&
    !isExampleProject &&
    doesCurrentUserHaveEditPermission

  const onColumnHeaderClicked = () => {
    setCurrentPendingColumnId(colId)
  }

  if (isRowNumberColumn) {
    return <RowNumberColumnHeader gridApi={gridApi} />
  }

  return (
    <Popover open={currentPendingColumnId === colId}>
      <PopoverAnchor asChild>
        <div
          className="flex w-full items-center justify-between px-4"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Tooltip delayDuration={200}>
            <TooltipTrigger
              className="w-full truncate"
              disabled={!columnOriginalQuestion}
            >
              <div className="flex items-center text-xs">
                {showGripIcon ? (
                  <Icon icon={GripVertical} size="small" className="mr-1" />
                ) : (
                  iconComponent
                )}
                {columnHeaderName}
              </div>
            </TooltipTrigger>
            {columnOriginalQuestion && (
              <TooltipContent
                className="max-w-72 whitespace-normal"
                align="start"
              >
                {columnOriginalQuestion}
              </TooltipContent>
            )}
            {!isQueryLoading && (
              <HeaderDropdownMenu
                gridApi={gridApi}
                colDef={colDef}
                colId={colId}
                isUpdatableColumn={isUpdatableColumn}
                isSortable={isSortable}
                isPendingColumn={isPendingColumn}
                sortColumnId={colDef.colId ?? ''}
                setIsIconHovered={setIsHovered}
                setSort={props.setSort}
                onColumnHeaderClicked={onColumnHeaderClicked}
              />
            )}
          </Tooltip>
        </div>
      </PopoverAnchor>
      <PopoverContent align="start" sideOffset={-16} className="ml-32 min-w-72">
        <ColumnEditor
          gridApi={gridApi}
          colDef={colDef}
          colId={colId}
          isPendingColumn={isPendingColumn}
        />
      </PopoverContent>
    </Popover>
  )
}

export default VaultHeaderCell
