import React from 'react'

import AssistantSharedTable from 'components/assistant-v2/components/assistant-shared-table'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'

export const AssistantSharedIndex = () => {
  return (
    <AppMain hasContainer containerClassName="flex h-full flex-col space-y-4">
      <AppHeader
        title="Shared with you"
        subtitle="Browse queries shared with you"
      />

      <AssistantSharedTable />
    </AppMain>
  )
}
