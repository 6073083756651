import React, { useMemo } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { CirclePlay } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { EventKind } from 'openapi/models/EventKind'
import { TagScope } from 'openapi/models/TagScope'
import { VaultFile } from 'openapi/models/VaultFile'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import { ToBackendKeys } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING } from 'components/vault/utils/use-document-classification-store'
import {
  REMOVE_PARAMS,
  projectsPath,
  queriesPath,
  DocumentClassificationAnalyticsData,
  ReviewCellStatus,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { CreateVaultReviewQuery } from 'components/vault/utils/vault-fetcher'
import {
  getSortedFilesBasedOnReviewQueryOrder,
  getQuestionsLimit,
  columnToQueryQuestion,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

const VaultRunButton = ({
  isExampleProject,
  doesCurrentUserHaveEditPermission,
}: {
  isExampleProject: boolean | null
  doesCurrentUserHaveEditPermission: boolean
}) => {
  const userInfo = useAuthUser()
  const { recordQuerySubmitted } = useQueryAnalytics(EventKind.VAULT_REVIEW)
  const navigate = useNavigateWithQueryParams()
  const queryClient = useQueryClient()

  const [reviewQuestionsPerQueryLimit, reviewFilesPerQueryLimit] =
    useVaultUsageStore(
      useShallow((s) => [
        s.reviewQuestionsPerQueryLimit,
        s.reviewFilesPerQueryLimit,
      ])
    )

  const selectedClientMatter = useClientMattersStore(
    (s) => s.selectedClientMatter
  )

  const [currentProject, currentProjectMetadata, folderIdToVaultFolder] =
    useVaultStore(
      useShallow((state) => [
        state.currentProject,
        state.currentProjectMetadata,
        state.folderIdToVaultFolder,
      ])
    )

  const [selectedRows, clearSelectedRows] = useVaultDataGridFilterStore(
    useShallow((state) => [state.selectedRows, state.clearSelectedRows])
  )

  const [
    workflow,
    gridApi,
    queryId,
    isRunButtonLoading,
    isQueryLoading,
    historyItem,
    pendingQueryFileIds,
    pendingQueryQuestions,
    setPendingQueryQuestions,
    setPendingQueryFileIds,
    setWorkflow,
    setQueryId,
    setIsRunButtonLoading,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.workflow,
      state.gridApi,
      state.queryId,
      state.isRunButtonLoading,
      state.isQueryLoading,
      state.historyItem,
      state.pendingQueryFileIds,
      state.pendingQueryQuestions,
      state.setPendingQueryQuestions,
      state.setPendingQueryFileIds,
      state.setWorkflow,
      state.setQueryId,
      state.setIsRunButtonLoading,
    ])
  )

  const isNewQuery = queryId === 'new'
  const hasSelectedFiles = selectedRows.length > 0
  const reviewEvent = historyItem as ReviewHistoryItem
  const filesLimit = reviewEvent?.filesLimit ?? reviewFilesPerQueryLimit
  const queryQuestionsLimit =
    reviewEvent?.questionsLimit ?? reviewQuestionsPerQueryLimit
  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission
  const hasToProcessFiles =
    (pendingQueryFileIds && pendingQueryFileIds.length > 0) ?? false
  const hasToProcessQuestions =
    (pendingQueryQuestions && pendingQueryQuestions.length > 0) ?? false
  const hasEmptyCells = useMemo(() => {
    if (!reviewEvent) return false
    const visibleColumnIds = new Set(
      reviewEvent.columns
        .filter((column) => !column.isHidden)
        .map((column) => column.id)
    )
    const visibleRowIds = new Set(
      reviewEvent.rows.filter((row) => !row.isHidden).map((row) => row.id)
    )
    const processedCells = reviewEvent.cells?.filter((cell) => {
      return (
        cell.status !== ReviewCellStatus.EMPTY &&
        visibleColumnIds.has(cell.reviewColumnId) &&
        visibleRowIds.has(cell.reviewRowId)
      )
    })
    return (
      (processedCells?.length ?? 0) < visibleColumnIds.size * visibleRowIds.size
    )
  }, [reviewEvent])
  const queryQuestions = [
    ...(reviewEvent?.columns
      .filter((column) => !column.isHidden)
      .map(columnToQueryQuestion) ?? []),
    ...(pendingQueryQuestions ?? []),
  ]
  const questionsLimit = getQuestionsLimit(
    queryQuestionsLimit,
    reviewQuestionsPerQueryLimit
  )

  const clientMatterId: string | null | undefined =
    userInfo.IsVaultProjectClientMatterUser
      ? currentProjectMetadata.clientMatterId
      : userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null

  const shouldCheckIsQueryLoadingForRunButton =
    !userInfo.IsVaultInternalOnlyUser

  const isRunButtonDisabled =
    !gridApi ||
    (shouldCheckIsQueryLoadingForRunButton && isQueryLoading) ||
    isRunButtonLoading ||
    !canCurrentUserEditProject ||
    (hasToProcessFiles && queryQuestions.length === 0) ||
    (!hasToProcessQuestions &&
      !hasToProcessFiles &&
      !hasSelectedFiles &&
      !hasEmptyCells) ||
    // If we have selected files, we need to check that the selected files are exactly the same as the pending files.
    (!hasToProcessQuestions &&
      hasToProcessFiles &&
      hasSelectedFiles &&
      (selectedRows.some((id) => !pendingQueryFileIds?.includes(id)) ||
        pendingQueryFileIds?.some((id) => !selectedRows.includes(id)))) ||
    // If we have pending questions, we need to make sure there are no selected files.
    (hasToProcessQuestions && hasSelectedFiles)

  const computeDocumentClassificationAnalyticsData = (
    fileIdsToProcess: string[]
  ): DocumentClassificationAnalyticsData[] => {
    const documentClassificationTags = fileIdsToProcess.flatMap((fileId) => {
      const file = currentProjectMetadata.descendantFiles?.find(
        (file) => file.id === fileId
      )
      return (
        file?.tags.filter(
          (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
        ) ?? []
      )
    })
    const documentClassificationData = documentClassificationTags.reduce(
      (acc, tag) => {
        const existingEntry = acc.find(
          (entry) => entry.typeDocumentClassification === tag.name
        )
        const tagKey =
          tag.name as keyof typeof DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING
        const groupingDocumentClassification =
          DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING[tagKey]
        if (existingEntry) {
          existingEntry.numDocuments++
        } else {
          acc.push({
            numDocuments: 1,
            typeDocumentClassification: tag.name,
            groupingDocumentClassification: groupingDocumentClassification,
          })
        }
        return acc
      },
      [] as DocumentClassificationAnalyticsData[]
    )
    return documentClassificationData
  }

  const handleRun = async () => {
    setIsRunButtonLoading(true)
    const projectId = currentProject?.id ?? ''
    const currentQueryFileIds: string[] = []
    gridApi?.forEachNode((node) => {
      if (node.group) return
      currentQueryFileIds.push(node.data.id)
    })
    const readyToQueryFiles: VaultFile[] =
      currentProjectMetadata.descendantFiles?.filter((file) => {
        return (
          file.readyToQuery &&
          currentQueryFileIds.includes(file.id) &&
          // If we have selected files, we need to filter down
          (hasSelectedFiles ? selectedRows.includes(file.id) : true) &&
          // Otherwise, if we have pendingQueryFileIds, we need to filter down
          (!hasSelectedFiles && pendingQueryFileIds
            ? pendingQueryFileIds.includes(file.id)
            : true)
        )
      }) ?? []

    const sortedReadyToQueryFileIds = getSortedFilesBasedOnReviewQueryOrder(
      readyToQueryFiles,
      folderIdToVaultFolder
    ).map((file) => file.id)

    const existingQuestions = queryQuestions.filter(
      (question) =>
        !pendingQueryQuestions?.map((q) => q.id).includes(question.id)
    )
    const existingFileIds = currentQueryFileIds.filter(
      (fileId) => !pendingQueryFileIds?.includes(fileId)
    )

    // TODO: handle case where we have files but no questions (all of the questions will not be in pendingColumnIds)
    const requestTypeGetter = () => {
      if (isNewQuery) {
        return 'new'
      }
      if (hasToProcessFiles && hasToProcessQuestions) {
        return 'extra_files_and_columns'
      }
      if (hasToProcessQuestions) {
        return 'extra_columns'
      }
      if (hasToProcessFiles) {
        return 'extra_files'
      }
      if (hasSelectedFiles) {
        return 'retry'
      }
      return 'retry_empty'
    }
    const requestType = requestTypeGetter()

    const documentClassificationAnalyticsData =
      computeDocumentClassificationAnalyticsData(sortedReadyToQueryFileIds)
    recordQuerySubmitted({
      event_kind: EventKind.VAULT_REVIEW,
      event_id: queryId,
      query_length: 0,
      num_files: sortedReadyToQueryFileIds.length,
      document_classification: ToBackendKeys(
        documentClassificationAnalyticsData
      ),
    })

    if (sortedReadyToQueryFileIds.length === 0) {
      displayErrorMessage('No files to review or files are not processed yet')
      setIsRunButtonLoading(false)
      return
    }

    try {
      const workflowId = isNewQuery && workflow?.id ? String(workflow.id) : null
      const questionColumnIds = queryQuestions
        .map((question) => question.columnId)
        .filter(Boolean)
      const selectedWorkflowColumnIds =
        isNewQuery && workflow?.columns
          ? workflow?.columns
              ?.filter((column) => questionColumnIds.includes(column.id))
              .map((column) => column.id)
          : null

      const fileIds = sortedReadyToQueryFileIds
      const questions = pendingQueryQuestions
        ? queryQuestions.filter((question) =>
            pendingQueryQuestions.map((q) => q.id).includes(question.id)
          )
        : queryQuestions
      let pendingQueryUsage = fileIds.length * questions.length
      let pendingFileUsage = fileIds.length
      if (requestType === 'retry') {
        pendingQueryUsage = 0
      }
      if (requestType === 'extra_columns' || requestType === 'retry') {
        // Adding extra columns doesn't affect the number of files used
        pendingFileUsage = 0
      }
      if (requestType === 'extra_files_and_columns') {
        // Recalculate pending query usage to include the usage of existing questions and files
        pendingQueryUsage =
          fileIds.length * questions.length +
          fileIds.length * (existingQuestions?.length ?? 0) +
          questions.length * (existingFileIds?.length ?? 0)
      }
      const response = await CreateVaultReviewQuery({
        workflowId: workflowId,
        selectedWorkflowColumnIds: selectedWorkflowColumnIds,
        eventId: !isNewQuery ? Number(queryId) : null,
        query: `Review on ${currentProjectMetadata.name}`,
        clientMatterId: clientMatterId,
        taskType: TaskType.VAULT_REVIEW,
        vaultFolderId: projectId,
        fileIds: fileIds,
        questions: questions,
        questionsLimit: questionsLimit,
        filesLimit: filesLimit,
        requestType: requestType,
        pendingQueryUsage: pendingQueryUsage,
        pendingFileUsage: pendingFileUsage,
        dryRun: false,
      })
      const reviewQueryJobEventId = response.reviewQueryJobEventId
      setQueryId(reviewQueryJobEventId)
      const columnDefs = gridApi?.getColumnDefs()
      const updatedColumnDefs = columnDefs?.map((column) => {
        return {
          ...column,
          eventId: Number(reviewQueryJobEventId),
        }
      })
      gridApi?.setGridOption('columnDefs', updatedColumnDefs)
      setPendingQueryFileIds(null)
      setPendingQueryQuestions(null)
      setWorkflow(null)
      gridApi?.deselectAll()
      clearSelectedRows()

      // We need to invalidate the query so that the new query is fetched
      // This will update historyItem to reflect the right state in the document cells
      await queryClient.invalidateQueries({
        queryKey: [
          HarvQueryKeyPrefix.VaultHistoryItemQuery,
          reviewQueryJobEventId,
          false,
        ],
      })
      const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}${reviewQueryJobEventId}`
      navigate(newPath, { replace: true }, REMOVE_PARAMS)
    } catch (error) {
      console.error(error)
      displayErrorMessage('Failed to run review query. Please try again later.')
    } finally {
      setIsRunButtonLoading(false)
    }
  }

  if (!userInfo.IsVaultV2User) {
    return null
  }

  return (
    <Button
      className="flex items-center gap-1"
      onClick={handleRun}
      isLoading={
        (shouldCheckIsQueryLoadingForRunButton && isQueryLoading) ||
        isRunButtonLoading
      }
      disabled={isRunButtonDisabled}
    >
      <Icon icon={CirclePlay} />
      Run
    </Button>
  )
}

export default VaultRunButton
