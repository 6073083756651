import React, { useEffect, useMemo, useState } from 'react'
import { useMount } from 'react-use'

import { useShallow } from 'zustand/react/shallow'

import { useResearchTaxonomyQuery } from 'models/queries/use-research-taxonomy-query'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  DATABASE_SOURCE_TO_RESEARCH_AREA,
  DatabaseSource,
  isResearchKnowledgeSource,
} from 'components/assistant-v2/utils/assistant-knowledge-sources'
import Explorer from 'components/research/explorer'
import { getAllChildrenDeep } from 'components/research/research-helpers'

type Props = {
  databaseSource: DatabaseSource
  onClose: () => void
  setAskHarveyDisabled: (disabled: boolean) => void
}

export const AssistantResearchKnowledgeSource = ({
  databaseSource,
  setAskHarveyDisabled,
}: Props) => {
  const researchArea = DATABASE_SOURCE_TO_RESEARCH_AREA[databaseSource]
  const { taxonomy, isLoading: isTaxonomyLoading } = useResearchTaxonomyQuery(
    researchArea || null
  )

  const allFiltersFlattened = useMemo(() => {
    const researchFilters = taxonomy
    return researchFilters.concat(
      researchFilters.flatMap((researchFilter) =>
        getAllChildrenDeep(researchFilter)
      )
    )
  }, [taxonomy])

  const [selectedFilters, setSelectedFilters] = useState<ResearchFilter[]>([])
  const [knowledgeSource, setKnowledgeSource] = useAssistantStore(
    useShallow((s) => [s.knowledgeSource, s.setKnowledgeSource])
  )

  useMount(() => {
    if (isResearchKnowledgeSource(knowledgeSource)) {
      const filterSet = new Set(knowledgeSource.filterIds)
      const preselectedFilters = allFiltersFlattened.filter((filter) =>
        filterSet.has(filter.id)
      )
      if (preselectedFilters.length > 0) {
        setSelectedFilters(preselectedFilters)
      }
    }
  })

  useEffect(() => {
    setAskHarveyDisabled(!selectedFilters.length)
  }, [selectedFilters, setAskHarveyDisabled])

  const onSetSelectedFilters = (filters: ResearchFilter[]) => {
    setSelectedFilters(filters)

    const filterIds = filters.flatMap((filter) => filter.id)

    if (filters.length > 0) {
      setKnowledgeSource({
        ...knowledgeSource,
        type: databaseSource,
        filterIds,
      })
    }
  }

  if (!researchArea) return null

  return (
    <Explorer
      isOpen={true}
      taxonomy={taxonomy as ResearchFilter[]}
      selectedFilters={selectedFilters}
      setSelectedFilters={onSetSelectedFilters}
      isLoading={isTaxonomyLoading}
      researchArea={researchArea}
    />
  )
}
