import React, { useState } from 'react'

import { isEmpty } from 'lodash'

import Services from 'services'

import { displaySuccessMessage, displayErrorMessage } from 'utils/toast'

import { useAuthUser } from 'components/common/auth-context'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion'
import { Alert, AlertTitle } from 'components/ui/alert'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from 'components/ui/dialog'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

interface CopyContentResponse {
  message: string
  AuditLogType: {
    INTERNAL_MGMT_COPY_LIBRARY_EVENT_CONTENT: string
  }
  sourceEventIds: string[]
  targetEventIds: string[]
  fieldsCopied: string[]
  copiedEvents: number[]
}

const LibraryEventsContentCopier: React.FC = () => {
  const userInfo = useAuthUser()
  const [sourceEventIds, setSourceEventIds] = useState('')
  const [targetEventIds, setTargetEventIds] = useState('')
  const [fieldsToCopy, setFieldsToCopy] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [copyContentAcknowledge, setCopyContentAcknowledge] = useState(false)

  if (!userInfo.IsLibraryCreateHarveyItemsUser) {
    return null
  }

  const handleInitialSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setIsConfirmDialogOpen(true)
  }

  const handleConfirmedSubmit = async () => {
    if (!copyContentAcknowledge) {
      displayErrorMessage('Please acknowledge the action before proceeding.')
      return
    }
    setIsSubmitting(true)
    try {
      const response = await Services.Backend.Post<CopyContentResponse>(
        'internal_admin/library_events_content/copy',
        {
          sourceEventIds: sourceEventIds.split(',').map((id) => id.trim()),
          targetEventIds: targetEventIds.split(',').map((id) => id.trim()),
          fieldsToCopy: fieldsToCopy.split(',').map((field) => field.trim()),
        }
      )
      if (!isEmpty(response) && !isEmpty(response.sourceEventIds)) {
        displaySuccessMessage(
          `Content copied successfully from ${response.sourceEventIds.length} events.`
        ),
          10
      } else {
        displaySuccessMessage('Content copied successfully.')
      }
      setIsConfirmDialogOpen(false)
    } catch (error) {
      console.error('Error copying content:', error)
      displayErrorMessage(
        `Error copying content: ${
          error instanceof Error ? error.message : String(error)
        }`
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Accordion type="single" collapsible className="rounded-md border">
      <AccordionItem value="copy-library-events-content">
        <AccordionTrigger className="p-4">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold">
              Copy Library Events Content
            </h2>
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <form onSubmit={handleInitialSubmit}>
            <div className="ml-4 space-y-4">
              <div>
                <Label className="mb-4">Source Event IDs</Label>
                <div className="mb-2 mt-2 flex w-96 flex-row items-center">
                  <Input
                    value={sourceEventIds}
                    onChange={(e) => setSourceEventIds(e.target.value)}
                    placeholder="Comma-separated IDs"
                    required
                  />
                </div>
              </div>
              <div>
                <Label className="mb-4">Target Event IDs</Label>
                <div className="mb-2 mt-2 flex w-96 flex-row items-center">
                  <Input
                    value={targetEventIds}
                    onChange={(e) => setTargetEventIds(e.target.value)}
                    placeholder="Comma-separated IDs"
                    required
                  />
                </div>
              </div>
              <div>
                <Label className="mb-4">Fields to Copy</Label>
                <div className="mb-2 mt-2 flex w-96 flex-row items-center">
                  <Input
                    value={fieldsToCopy}
                    onChange={(e) => setFieldsToCopy(e.target.value)}
                    placeholder="Comma-separated fields"
                    required
                  />
                </div>
              </div>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Copying...' : 'Copy Contents'}
              </Button>
            </div>
          </form>
        </AccordionContent>
      </AccordionItem>
      <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Content Copy</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <p>
              <strong>From:</strong> {sourceEventIds}
            </p>
            <p>
              <strong>To:</strong> {targetEventIds}
            </p>
            <p>
              <strong>Fields:</strong> {fieldsToCopy}
            </p>
            <Alert className="h-17 mt-4" variant="destructive">
              <AlertTitle className="mr-2 flex justify-between">
                Please acknowledge you want to copy these contents. This action
                cannot be undone.
                <Checkbox
                  checked={copyContentAcknowledge}
                  onCheckedChange={() =>
                    setCopyContentAcknowledge(!copyContentAcknowledge)
                  }
                  checkboxClassName="border border-destructive"
                />
              </AlertTitle>
            </Alert>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsConfirmDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmedSubmit}
              disabled={!copyContentAcknowledge || isSubmitting}
            >
              {isSubmitting ? 'Copying...' : 'Confirm Copy'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Accordion>
  )
}

export default LibraryEventsContentCopier
