import React from 'react'

import _ from 'lodash'
import { ChevronsUpDown, PlusCircle } from 'lucide-react'

import { cn } from 'utils/utils'

import { Button } from './button'
import { Checkbox } from './checkbox'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from './command'
import { Popover, PopoverContent, PopoverTrigger } from './popover'
import { ScrollArea } from './scroll-area'

export type MultiSelectEntry = {
  text: string
  value: string
  values?: string[]
}

interface MultiSelectProps {
  placeholder: string
  sortedEntries: MultiSelectEntry[]
  sortedGroups?: { label: string; entries: MultiSelectEntry[] }[]
  ungroupedTitle?: string
  selectedValues: string[]
  setSelectedValues: (selectedValues: string[]) => void
  disabled?: boolean
  className?: string
  popoverContentClassName?: string
  align?: 'center' | 'start' | 'end'
  createNew?: {
    onCreateNew: (value: string) => void
    createNewLabel: string
  }
  containerRef?: React.RefObject<HTMLDivElement>
  maxHeight?: string
  toggleAll?: {
    // this entry name should be different from the entries in the list
    toggleAllEntry: MultiSelectEntry
    onToggleAll: () => void
  }
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  placeholder,
  sortedEntries,
  sortedGroups,
  ungroupedTitle,
  selectedValues: selectedValuesProp,
  setSelectedValues,
  disabled,
  className,
  popoverContentClassName,
  align = 'center',
  createNew,
  toggleAll,
  containerRef,
  maxHeight,
}) => {
  const [open, setOpen] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const selectedValues = React.useMemo(
    () => new Set(selectedValuesProp),
    [selectedValuesProp]
  )

  const getIsSelected = React.useCallback(
    (entry: MultiSelectEntry) => {
      return !!(
        selectedValues.has(entry.value) ||
        entry.values?.some((value) => selectedValues.has(value))
      )
    },
    [selectedValues]
  )

  const allSelected = React.useMemo(() => {
    if (_.isNil(toggleAll)) {
      return false
    }

    return (
      sortedEntries.every((entry) => getIsSelected(entry)) &&
      sortedGroups?.every((group) =>
        group.entries.every((entry) => getIsSelected(entry))
      )
    )
  }, [toggleAll, sortedEntries, sortedGroups, getIsSelected])

  const handleSelect = React.useCallback(
    (entry: MultiSelectEntry, shouldClosePopover: boolean) => {
      const value = entry.value
      const isSelected = getIsSelected(entry)
      if (isSelected) {
        selectedValues.delete(value)
        entry.values?.forEach((val) => {
          selectedValues.delete(val)
        })
      } else {
        selectedValues.add(value)
      }
      setSelectedValues(Array.from(selectedValues))
      if (shouldClosePopover) {
        setOpen(false)
      }
    },
    [getIsSelected, selectedValues, setSelectedValues, setOpen]
  )

  const groupEntries = (sortedGroups || []).reduce(
    (entries: MultiSelectEntry[], group) => {
      entries = entries.concat(group.entries)
      return entries
    },
    []
  )

  const renderButtonText = React.useMemo(() => {
    if (selectedValues.size === 0) {
      return placeholder
    }

    const firstSelectedItem = [...groupEntries, ...sortedEntries].find(
      (entry) => selectedValues.has(entry.value)
    )
    if (selectedValues.size > 1) {
      return `${selectedValues.size} selected`
    }

    return firstSelectedItem?.text
  }, [placeholder, selectedValues, groupEntries, sortedEntries])

  const handleCreateNew = React.useCallback(() => {
    if (createNew?.onCreateNew && search.trim()) {
      createNew.onCreateNew(search.trim())
      setSearch('')
    }
  }, [createNew, search])

  const showCreateNew = !!createNew && !!search.trim()

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          disabled={disabled}
          className={cn(
            'justify-between disabled:cursor-not-allowed',
            className
          )}
          data-testid="multiselect-open-popover-button"
        >
          <span className="line-clamp-1 text-left text-sm">
            {renderButtonText}
          </span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'w-auto min-w-[180px] max-w-96 p-0',
          popoverContentClassName
        )}
        align={align}
        container={containerRef?.current}
      >
        <Command>
          <CommandInput
            placeholder="Search"
            value={search}
            onValueChange={setSearch}
            data-testid="multiselect-search-input"
          />
          <CommandList>
            <ScrollArea maxHeight={maxHeight}>
              <CommandEmpty>No results found.</CommandEmpty>
              {toggleAll && (
                <>
                  <CommandGroup>
                    <CommandItem
                      key={toggleAll.toggleAllEntry.value}
                      onSelect={() => {
                        toggleAll.onToggleAll()
                      }}
                    >
                      <Checkbox
                        checked={selectedValues.size > 0}
                        isIndeterminate={
                          !allSelected && selectedValues.size > 0
                        }
                        checkboxClassName="mr-2"
                        label={toggleAll.toggleAllEntry.text}
                      />
                    </CommandItem>
                  </CommandGroup>
                  <CommandSeparator />
                </>
              )}
              {sortedGroups?.map((group) => {
                return (
                  <React.Fragment key={group.label}>
                    <CommandGroup heading={group.label}>
                      {group.entries.map((entry) => (
                        <CommandItem
                          key={entry.value}
                          onSelect={() => handleSelect(entry, false)}
                        >
                          <Checkbox
                            checked={getIsSelected(entry)}
                            checkboxClassName="mr-2"
                            label={entry.text}
                          />
                        </CommandItem>
                      ))}
                    </CommandGroup>
                    <CommandSeparator />
                  </React.Fragment>
                )
              })}
              <CommandGroup heading={ungroupedTitle}>
                {sortedEntries.map((entry) => (
                  <CommandItem
                    key={entry.value}
                    onSelect={() => handleSelect(entry, false)}
                  >
                    <Checkbox
                      checked={getIsSelected(entry)}
                      checkboxClassName="mr-2"
                      label={entry.text}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
              {showCreateNew && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={handleCreateNew}
                      keywords={[search.trim()]}
                    >
                      <PlusCircle className="mr-2 h-4 w-4" />
                      {createNew.createNewLabel}: “{search.trim()}”
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
              {_.isNil(toggleAll) && selectedValues.size > 0 && (
                <>
                  <CommandSeparator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => setSelectedValues([])}
                      className="justify-center text-center"
                    >
                      Clear all
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export { MultiSelect }
