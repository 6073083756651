import React from 'react'

import _ from 'lodash'
import { X } from 'lucide-react'
import { Instance } from 'pspdfkit'

import { Maybe } from 'types'

import {
  IS_LOADING_HELP_TEXT,
  QUERY_MUST_HAVE_X_LEN_HELP_TEXT,
  UPLOAD_DOCUMENT_HELP_TEXT,
} from 'utils/tooltip-texts'
import { cn } from 'utils/utils'

import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import PDFViewer from 'components/common/pdf-viewer/pdf-viewer'
import {
  ResizablePanelGroup,
  ResizablePanel,
  ResizableHandle,
  ImperativeResizablePanelGroupHandle,
} from 'components/ui/resizable'

import { AssistantDropzone } from './assistant-dropzone'
import AssistantInput from './assistant-input'
import { useAssistantStore } from './assistant-store'
import {
  DOC_QA_QUERY_LIMIT,
  MIN_QUERY_LENGTH,
  OPEN_ENDED_QUERY_LIMIT,
  isQueryValid,
} from './assistant-utils'

interface AssistantInputPanelProps {
  onAskHarvey: (prompt?: string) => void
  pspdfkitInstanceRef: React.MutableRefObject<Maybe<Instance>>
  applyAnnotations: () => Promise<void>
  historyLoading?: boolean
}

const AssistantInputPanel = ({
  onAskHarvey,
  pspdfkitInstanceRef,
  applyAnnotations,
  historyLoading,
}: AssistantInputPanelProps): JSX.Element => {
  const userInfo = useAuthUser()

  const {
    activeDocument,
    isLoading,
    query,
    internetBrowsing,
    uploadedFiles,
    setActiveDocument,
  } = useAssistantStore()

  const isDocumentUser = userInfo.isDocumentUser

  const queryLimit =
    !_.isEmpty(uploadedFiles) || internetBrowsing
      ? DOC_QA_QUERY_LIMIT
      : OPEN_ENDED_QUERY_LIMIT

  // Some workspaces should not have access to open ended. Meaning they
  // have to always upload a document before asking harvey
  const isRequiredDocumentMissing: boolean =
    (!userInfo.IsBaseUser || false) && uploadedFiles?.length === 0

  const askHarveyDisabled =
    !isQueryValid(query, queryLimit) || isLoading || isRequiredDocumentMissing

  const getDisabledText = () => {
    if (query.trim().length < MIN_QUERY_LENGTH) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`at least ${MIN_QUERY_LENGTH}`)
    } else if (query.trim().length > queryLimit) {
      return QUERY_MUST_HAVE_X_LEN_HELP_TEXT(`fewer than ${queryLimit}`)
    } else if (isRequiredDocumentMissing) {
      return UPLOAD_DOCUMENT_HELP_TEXT
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else {
      return ''
    }
  }

  const defaultResizablePanelSizes = [50, 50]
  const defaultResizablePanelMinSize = 25
  const resizablePanelGroupRef =
    React.useRef<ImperativeResizablePanelGroupHandle>(null)
  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
  }

  const inputTextareaRef = React.useRef<HTMLTextAreaElement | null>(null)

  return (
    <div className="flex h-full flex-col">
      {_.isNil(activeDocument) ? (
        <ResizablePanelGroup
          direction="vertical"
          autoSaveId="assistant-input-panel"
          ref={resizablePanelGroupRef}
        >
          <ResizablePanel
            id="assistant-text-input"
            className="focus:outline-none focus:ring-0"
            defaultSize={defaultResizablePanelSizes[0]}
            minSize={defaultResizablePanelMinSize}
          >
            <AssistantInput
              queryLimit={queryLimit}
              inputTextareaRef={inputTextareaRef}
            />
          </ResizablePanel>
          {isDocumentUser && (
            <ResizableHandle
              onDoubleClick={resetLayout}
              className={cn({ hidden: historyLoading })}
            />
          )}
          {isDocumentUser && (
            <ResizablePanel
              id="assistant-document-input"
              defaultSize={defaultResizablePanelSizes[1]}
              minSize={defaultResizablePanelMinSize}
            >
              <AssistantDropzone />
            </ResizablePanel>
          )}
          <div className="border-t p-3">
            <div className="flex w-full" id="assistant-submit">
              <AskHarveyButton
                handleSubmit={() => onAskHarvey()}
                disabled={askHarveyDisabled}
                tooltip={getDisabledText()}
                className="w-full"
                size="lg"
                isLoading={isLoading}
                inputRef={inputTextareaRef}
              />
            </div>
          </div>
        </ResizablePanelGroup>
      ) : (
        <div className="relative h-full p-3">
          <PDFViewer
            document={activeDocument}
            onClear={() => setActiveDocument(null)}
            pspdfInstanceRef={pspdfkitInstanceRef}
            canClear
            applyAnnotations={applyAnnotations}
            closeIcon={X}
            isMaximized
          />
        </div>
      )}
    </div>
  )
}

export default AssistantInputPanel
