import React from 'react'

import { ScrollArea } from 'components/ui/scroll-area'

type Props = {
  input: React.ReactElement
  output: React.ReactElement
}

export const WorkflowsDualPaneLayout: React.FC<Props> = ({ input, output }) => {
  return (
    <div className="flex h-full space-x-2">
      <div className="w-1/2">{input}</div>
      <div className="w-1/2">
        <ScrollArea className="h-full rounded-lg border" hasHorizontalScroll>
          {React.cloneElement(output, { className: 'border-none' })}
        </ScrollArea>
      </div>
    </div>
  )
}
