import * as React from 'react'

import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from 'utils/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 shrink-0',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-interactive text-primary-inverse hover:bg-interactive-hover',
        secondary:
          'border-transparent bg-button-secondary text-primary hover:bg-button-secondary-hover',
        destructive:
          'border-transparent bg-destructive text-destructive hover:bg-destructive/80',
        outline: 'text-primary bg-primary',
        ghost: 'text-primary bg-primary border-transparent',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  isPill?: boolean
}

function Badge({
  className,
  variant,
  isPill,
  ...props
}: BadgeProps): JSX.Element {
  return (
    <div
      className={cn(
        badgeVariants({ variant }),
        { 'rounded-full': isPill },
        className
      )}
      {...props}
    />
  )
}

export { Badge, badgeVariants }
