import React, { useState } from 'react'

import _ from 'lodash'
import { ChevronRight } from 'lucide-react'

import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchFilter } from 'openapi/models/ResearchFilter'

import { cn } from 'utils/utils'

import {
  computeIsDisabled,
  getAllChildrenDeep,
  getNumSelectedChildren,
  hasAllSiblingsSelected,
  hasSelectedChild,
} from 'components/research/research-helpers'
import { Checkbox } from 'components/ui/checkbox'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

interface ExplorerItemProps {
  taxonomy: ResearchFilter[]
  filter: ResearchFilter
  depth: number
  openFilters: ResearchFilter[]
  setOpenFilters: (filters: ResearchFilter[]) => void
  selectedFilters: ResearchFilter[]
  setSelectedFilters: (filters: ResearchFilter[]) => void
  researchArea: ResearchArea
}

const ExplorerItem: React.FC<ExplorerItemProps> = ({
  filter,
  depth,
  taxonomy,
  openFilters,
  setOpenFilters,
  selectedFilters,
  setSelectedFilters,
  researchArea,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  // Constants
  const children = getAllChildrenDeep(filter)
  const isOpen = !_.isNil(openFilters) && filter.id === openFilters[depth]?.id
  const isChecked = selectedFilters.some((n) => n.id === filter.id)
  const parentFilter = openFilters[depth - 1]
  const isIndeterminate =
    hasSelectedChild(filter, selectedFilters) && !isChecked
  const isDisabled = computeIsDisabled({
    filter,
    selectedFilters,
    openFilters,
    taxonomy,
  })

  const isAllSiblingsChecked = hasAllSiblingsSelected(
    filter,
    selectedFilters,
    parentFilter
  )

  const numChildrenSelected = getNumSelectedChildren(filter, selectedFilters)

  // Handlers
  const handleClick = () => {
    const newSelectedFilters = [...openFilters]
    newSelectedFilters[depth] = filter
    newSelectedFilters.splice(depth + 1)
    setOpenFilters(newSelectedFilters)
  }

  const handleCheckedChange = (checked: boolean) => {
    if (checked) {
      handleChecked()
    } else {
      handleUnchecked()
    }
  }

  const handleChecked = () => {
    const selectedFiltersToSet = [...selectedFilters, filter, ...children]
    if (isAllSiblingsChecked && parentFilter) {
      selectedFiltersToSet.push(parentFilter)
    }
    setSelectedFilters(selectedFiltersToSet)
  }

  const handleUnchecked = () => {
    const selectedFiltersToSet = selectedFilters.filter((n) => {
      const isFilterOrChild =
        n.id === filter.id ||
        children.some((c) => c.id === n.id) ||
        (parentFilter && parentFilter.id === n.id)
      return !isFilterOrChild
    })
    setSelectedFilters(selectedFiltersToSet)
  }

  const maybeRenderTooltip = (text: string) => {
    if (text.length > 15) {
      return (
        <Tooltip delayDuration={300}>
          <TooltipTrigger asChild>
            <p className="line-clamp-1 w-fit text-sm">{text}</p>
          </TooltipTrigger>
          <TooltipContent>{text}</TooltipContent>
        </Tooltip>
      )
    } else {
      return <p className="line-clamp-1 text-sm">{text}</p>
    }
  }

  const checkboxChecked = isChecked || isIndeterminate

  // Render
  return (
    <Tooltip>
      <TooltipTrigger className="block" asChild>
        <div
          role="button"
          tabIndex={isDisabled ? -1 : 0} // Only allow tab focus when not disabled
          onKeyPress={handleClick}
          className={cn(
            'text relative mx-4 flex items-center justify-between space-x-2 rounded p-1.5 pl-2 text-left ring-inset focus:outline-none',
            /* eslint-disable-next-line prefer-smart-quotes/prefer */
            "before:absolute before:inset-x-0 before:-bottom-px before:top-0 before:content-['']",
            {
              'focus:ring focus:ring-ring': !isDisabled && children.length > 0, // Apply focus ring only if not disabled and children are present
              'hover:bg-secondary': !isOpen,
              'bg-secondary-hover': isOpen,
              'opacity-50': isDisabled,
            }
          )}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={(e) => {
            if (isDisabled) {
              e.stopPropagation()
              return
            }
            handleClick()
          }}
          data-testid={`research-filter-${filter.id}`}
        >
          <div className="flex items-center space-x-2">
            <Checkbox
              checkboxClassName={cn({
                'hover:bg-button-secondary-hover border-ring':
                  !checkboxChecked && isOpen,
              })}
              checked={checkboxChecked}
              isIndeterminate={isIndeterminate}
              onCheckedChange={handleCheckedChange}
              disabled={isDisabled}
            />

            {maybeRenderTooltip(filter.name)}
          </div>
          <div className="flex shrink-0 items-center">
            {numChildrenSelected > 0 && (
              <span className="shrink-0 text-nowrap text-xs text-muted">
                {numChildrenSelected} Selected
              </span>
            )}
            {researchArea === ResearchArea.MEMOS &&
              depth === 0 &&
              numChildrenSelected === 0 && (
                <span className="text-xs text-muted">Select at least 1</span>
              )}
            {filter.children.length > 0 && (
              <ChevronRight
                className="ml-1 inline-block shrink-0"
                data-testid="research-filter-expand-btn"
                size={16}
              />
            )}
          </div>
        </div>
      </TooltipTrigger>
      {isHovered && isDisabled && (
        <TooltipContent className="w-full">
          This filter cannot be selected
        </TooltipContent>
      )}
    </Tooltip>
  )
}

export default ExplorerItem
