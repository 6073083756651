import { useEffect, useMemo } from 'react'

import { useHistoryItemQuery } from 'models/queries/use-history-item-query'
import { EventKind } from 'openapi/models/EventKind'
import { Maybe } from 'types'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { TaskStatus, TaskType } from 'utils/task'
import {
  AssistantV1TaskTypes,
  ResearchV1TaskTypesMovedToV2,
} from 'utils/task-definitions'
import { displayErrorMessage } from 'utils/toast'

import {
  assistantV1ToV2,
  researchV1ToV2,
} from 'components/assistant-v2/backward-compatibility'
import { AssistantMode } from 'components/assistant-v2/components/assistant-mode-select'
import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  CHAT_EXAMPLE,
  DRAFT_EXAMPLE,
} from 'components/assistant-v2/utils/assistant-example-data'
import { EXAMPLE_MESSAGE_ID } from 'components/assistant-v2/utils/assistant-helpers'
import { useAuthUser } from 'components/common/auth-context'

const ASSISTANT_HISTORY_KINDS: EventKind[] = [
  EventKind.ASSISTANT_CHAT,
  EventKind.ASSISTANT_DRAFT,
]

const ASSISTANT_MODE_MAP: { [key: string]: AssistantMode } = {
  [EventKind.ASSISTANT_CHAT]: AssistantMode.ASSIST,
  [EventKind.ASSISTANT_DRAFT]: AssistantMode.DRAFT,
}

export const useRestoreAssistantHistoryItem = (
  eventId: Maybe<string>,
  mode: AssistantMode
) => {
  const userInfo = useAuthUser()
  const restoreHistoryItem = useAssistantStore((s) => s.restoreHistoryItem)
  const existingEventId = useAssistantStore((s) => s.eventId)
  const messages = useAssistantStore((s) => s.messages)
  const streamingMessage = useAssistantStore((s) => s.streamingMessage)
  const createdAt = useAssistantStore((s) => s.createdAt) // means it was generated
  const navigate = useNavigateWithQueryParams()

  const isDraft = mode === AssistantMode.DRAFT
  const isStaticExample = eventId === EXAMPLE_MESSAGE_ID

  // Only poll history if the current message is not streaming
  const shouldPollHistory =
    !isStaticExample &&
    !streamingMessage &&
    (userInfo.IsHistoryUser || userInfo.IsLibraryUser) &&
    // Don't poll after message completion if user is not a history user
    // (handles 0 day retention)
    !(messages.length && !userInfo.IsHistoryUser)

  const {
    historyItem,
    error: reactQueryError,
    isFetching,
  } = useHistoryItemQuery({
    id: eventId,
    throwOnError: true,
    isEnabled: shouldPollHistory,
    refetchInterval: (query) => {
      // Poll for history item updates every 3 seconds if it is in progress
      if (
        query.state.status === 'success' &&
        query.state.data &&
        query.state.data.status === TaskStatus.IN_PROGRESS
      ) {
        return 3_000
      }
      return false
    },
  })

  useEffect(() => {
    if (isStaticExample) {
      restoreHistoryItem(isDraft ? DRAFT_EXAMPLE : CHAT_EXAMPLE, userInfo)
      return
    }

    if (isFetching || !historyItem || streamingMessage) return

    // Navigate away if no messages and task is cancelled or errored
    if (
      [TaskStatus.CANCELLED, TaskStatus.ERRORED].includes(historyItem.status) &&
      !messages.length
    ) {
      navigate('/assistant', { state: { skipReset: true, mode } })
      return
    }

    // `?? 1` handles v1 events, which have no messages until transformed, then will have 1
    const historyItemHasNewMessages =
      messages.length < (historyItem.messages?.length ?? 1)
    const isDifferentEvent = existingEventId !== eventId

    if (historyItemHasNewMessages || isDifferentEvent) {
      if ((AssistantV1TaskTypes as string[]).includes(historyItem.kind)) {
        restoreHistoryItem(assistantV1ToV2(historyItem), userInfo)
      } else if (
        ResearchV1TaskTypesMovedToV2.includes(historyItem.kind as TaskType)
      ) {
        restoreHistoryItem(researchV1ToV2(historyItem), userInfo, true)
      } else if (
        (ASSISTANT_HISTORY_KINDS as string[]).includes(historyItem.kind)
      ) {
        restoreHistoryItem(historyItem, userInfo)
      } else {
        console.error('Unsupported task type', historyItem.kind)
        displayErrorMessage('Task type not supported by Assistant')
      }
    }
  }, [
    isDraft,
    isStaticExample,
    isFetching,
    historyItem,
    restoreHistoryItem,
    eventId,
    existingEventId,
    messages.length,
    streamingMessage,
    navigate,
    mode,
    userInfo,
  ])

  const error = useMemo(() => {
    if (reactQueryError) return reactQueryError

    if (
      !createdAt &&
      !userInfo.IsHistoryUser &&
      !userInfo.IsLibraryUser &&
      !streamingMessage
    ) {
      return new Error('User has no history perms')
    }

    const historyItemKind = historyItem?.kind as EventKind
    if (
      historyItem &&
      ASSISTANT_HISTORY_KINDS.includes(historyItemKind) &&
      ASSISTANT_MODE_MAP[historyItemKind] !== mode
    ) {
      return new Error('History item mode does not match current mode')
    }

    return null
  }, [
    historyItem,
    mode,
    reactQueryError,
    userInfo,
    createdAt,
    streamingMessage,
  ])

  return {
    historyItem,
    historyItemInProgress: historyItem?.status === TaskStatus.IN_PROGRESS,
    error,
    isFetching,
  }
}
