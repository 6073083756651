import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'
import { ScrollArea } from 'components/ui/scroll-area'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/ui/table'
import { getReadyToQueryFileIds } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

import VaultWorkflowAddFiles from './vault-workflow-add-files'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from './vault-workflow-store'

const VaultWorkflowBuilder = () => {
  const { projectId } = useParams()
  const [currentProjectMetadata, folderIdToVaultFolder] = useVaultStore(
    useShallow((state) => [
      state.currentProjectMetadata,
      state.folderIdToVaultFolder,
    ])
  )
  const [workflowModalState, setWorkflowModalState, setSelectedWorkflow] =
    useVaultWorkflowStore(
      useShallow((state) => [
        state.workflowModalState,
        state.setWorkflowModalState,
        state.setSelectedWorkflow,
      ])
    )

  const [preselectFileIds, setPreselectFileIds] = useState<Set<string>>(
    new Set()
  )

  const handleOpenAddFiles = () => {
    const readyToQueryFileIds = getReadyToQueryFileIds(
      currentProjectMetadata,
      null,
      folderIdToVaultFolder
    )
    const fileIds = new Set(readyToQueryFileIds)
    setPreselectFileIds(fileIds)
    setWorkflowModalState(WorkflowModalState.AddFilesWorkflow)
  }

  if (!projectId) {
    return null
  }

  return (
    <Dialog
      open={
        workflowModalState === WorkflowModalState.Builder ||
        workflowModalState === WorkflowModalState.AddFilesWorkflow
      }
      onOpenChange={(open) => {
        setWorkflowModalState(
          open ? WorkflowModalState.Builder : WorkflowModalState.None
        )
        if (!open) {
          setSelectedWorkflow(null)
        }
      }}
    >
      <DialogContent
        className="sm:max-w-[800px]"
        innerClassName="flex flex-col"
        hasContainer={false}
      >
        {workflowModalState === WorkflowModalState.Builder && (
          <VaultWorkflowBuilderDialogContents
            handleOpenAddFiles={handleOpenAddFiles}
          />
        )}
        {workflowModalState === WorkflowModalState.AddFilesWorkflow && (
          <VaultWorkflowAddFiles
            projectId={projectId}
            onBackButtonClicked={() =>
              setWorkflowModalState(WorkflowModalState.Builder)
            }
            buttonLabel="Create table"
            preselectFileIds={preselectFileIds}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

const VaultWorkflowBuilderDialogContents = ({
  handleOpenAddFiles,
}: {
  handleOpenAddFiles: () => void
}) => {
  const [selectedWorkflow] = useVaultWorkflowStore(
    useShallow((state) => [state.selectedWorkflow])
  )
  const [setWorkflowModalState] = useVaultWorkflowStore(
    useShallow((state) => [state.setWorkflowModalState])
  )
  return (
    <>
      <DialogHeader className="gap-4">
        <DialogTitle className="w-full text-center">
          Create a {selectedWorkflow?.name} table
        </DialogTitle>
        <div className="-mx-6 border-b" />
        <DialogDescription className="text-left text-primary">
          {selectedWorkflow?.description}
        </DialogDescription>
      </DialogHeader>
      <div className="flex max-h-[40vh] rounded border">
        <ScrollArea className="flex max-h-[40vh] w-full flex-col">
          <Table className="w-full rounded">
            <TableHeader className="bg-accent hover:bg-accent [&_tr]:hover:bg-accent">
              <TableRow className="hover:bg-accent">
                <TableHead className="h-8 border-r px-2 text-xs font-normal text-primary">
                  Title
                </TableHead>
                <TableHead className="h-8 border-r px-2 text-xs font-normal text-primary">
                  Query
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedWorkflow?.columns.map((column) => (
                <TableRow key={column.id} className="hover:bg-primary">
                  <TableCell className="border-r px-2 py-2 text-xs">
                    {column.header}
                  </TableCell>
                  <TableCell className="border-r px-2 py-2 text-xs">
                    {column.fullText}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-xs text-muted">
          You can remove, rename, or add columns after the table is created.
        </p>
        <div className="flex justify-end gap-2">
          <Button
            variant="outline"
            onClick={() => setWorkflowModalState(WorkflowModalState.None)}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault()
              handleOpenAddFiles()
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  )
}

export default VaultWorkflowBuilder
