import React from 'react'

import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { FetchHistoryMetadata } from 'models/fetchers/history-fetcher'
import { DeleteWorkspaceHistoryBulk } from 'models/workspace'
import Services from 'services'
import { RequestError } from 'services/backend/backend'
import { Maybe } from 'types'
import { HistoryItemFolder } from 'types/history'

import { addQueryParamsToRequestPath } from 'utils/routing'
import { SafeRecord } from 'utils/safe-types'
import { TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'

import { CLIENT_MATTER_URL_PARAM } from 'components/client-matters/client-matter-utils'
import { ClientMatter } from 'components/client-matters/client-matters-store'
import { ClientMatterRecord } from 'components/filter/types/client-matter-record'
import { FavoriteRecord } from 'components/filter/types/favorite-record'
import { RecordBase } from 'components/filter/types/record-base'

interface HistoryMetadataState {
  historyTaskTypes: TaskType[]
  historyItemFolders: HistoryItemFolder[]
  historyClientMatters: ClientMatter[]
  systemClientMatters: ClientMatter[]
  optimisticEventClientMatterId: SafeRecord<number, string | null>
  optimisticEventClientMatterIdTimestamp: SafeRecord<number, Date>
  clientMatterIdToName: SafeRecord<string, string>
  optimisticEventFavorite: SafeRecord<number, boolean>
  optimisticEventFavoriteTimestamp: SafeRecord<number, Date>
  optimisticEventDeleted: SafeRecord<number, boolean>
  optimisticEventDeletedTimestamp: SafeRecord<number, Date>
  favoriteFolder?: HistoryItemFolder
}

interface HistoryMetadataAction {
  getFavoriteStatus: (record: FavoriteRecord) => Maybe<boolean>
  getClientMatterName: (record: ClientMatterRecord) => Maybe<string>
  getRouteWithClientMatter: (record: ClientMatterRecord, path: string) => string
  getEventDeleted: (record: RecordBase & { id: number }) => boolean
  /**
   * Updates the favorite status of an event optimistically and syncs with the backend.
   * If the backend call fails, the optimistic update is reverted.
   * @param {number} eventId - The ID of the event to update.
   * @param {boolean} favorite - The new favorite status of the event.
   */
  updateEventFavorite: (eventId: number, favorite: boolean) => Promise<void>
  /**
   * Updates the client matter of an event optimistically and syncs with the backend.
   * If the backend call fails, the optimistic update is reverted.
   * @param {number} eventId - The ID of the event to update.
   * @param {string} clientMatterId - The new client matter ID of the event.
   */
  updateEventClientMatter: (
    eventId: number,
    clientMatterId: string | null
  ) => Promise<void>
  deleteEvent: (
    eventId: number,
    isWorkspaceDelete: boolean,
    isVaultDelete: boolean
  ) => Promise<void>
  bulkDeleteEvents: (eventIds: number[]) => Promise<void>
  /**
   * Fetches history metadata from the backend and updates the store.
   * This includes event task types, item folders, and client matters.
   */
  fetchData: () => Promise<void>
  /**
   * Sets the client matters for the current workspace.
   */
  setCurrentWorkspaceClientMatters: (clientMatters: ClientMatter[]) => void
}

export const useHistoryMetadataStore = create<
  HistoryMetadataState & HistoryMetadataAction
>()(
  devtools(
    immer((set, get) => ({
      historyTaskTypes: [],
      historyItemFolders: [],
      historyClientMatters: [],
      systemClientMatters: [],
      optimisticEventClientMatterId: {},
      clientMatterIdToName: {},
      optimisticEventFavorite: {},
      optimisticEventFavoriteTimestamp: {},
      optimisticEventClientMatterIdTimestamp: {},
      optimisticEventDeleted: {},
      optimisticEventDeletedTimestamp: {},
      favoriteFolder: undefined,
      getFavoriteStatus: (record: FavoriteRecord) => {
        const optimistic = getOptimisticValue<boolean>({
          eventId: record.id,
          optimisticKey: 'optimisticEventFavorite',
          timestampKey: 'optimisticEventFavoriteTimestamp',
          updatedAt: new Date(record.updatedAt),
          get,
        })
        if (!_.isUndefined(optimistic)) {
          return optimistic
        }

        const { favoriteFolder } = get()
        if (!favoriteFolder) {
          return undefined
        }
        return record.folderId === favoriteFolder.id
      },
      getClientMatterName: (record: ClientMatterRecord) => {
        const optimisticClientMatterId = getOptimisticValue<string | null>({
          eventId: record.id,
          optimisticKey: 'optimisticEventClientMatterId',
          timestampKey: 'optimisticEventClientMatterIdTimestamp',
          updatedAt: new Date(record.updatedAt),
          get,
        })
        const clientMatterId = optimisticClientMatterId || record.clientMatterId

        const { clientMatterIdToName } = get()
        return clientMatterId ? clientMatterIdToName[clientMatterId] : undefined
      },
      getRouteWithClientMatter: (record: ClientMatterRecord, path: string) => {
        const optimisticClientMatterId = getOptimisticValue<string | null>({
          eventId: record.id,
          optimisticKey: 'optimisticEventClientMatterId',
          timestampKey: 'optimisticEventClientMatterIdTimestamp',
          updatedAt: new Date(record.updatedAt),
          get,
        })
        const clientMatterId = optimisticClientMatterId || record.clientMatterId

        const systemClientMatter = get().systemClientMatters.find(
          (cm) => cm.id === clientMatterId
        )
        if (!systemClientMatter) {
          // We don't want to append the client matter to the path if it doesn't
          // exist in the system client matters list (it's possible the client
          // matter was deleted).
          return path
        }

        const searchParams = new URLSearchParams({
          [CLIENT_MATTER_URL_PARAM]: systemClientMatter.name,
        })
        return addQueryParamsToRequestPath(path, searchParams)
      },
      getEventDeleted: (record: RecordBase & { id: number }) => {
        const optimisticDeleted = getOptimisticValue<boolean>({
          eventId: record.id,
          optimisticKey: 'optimisticEventDeleted',
          timestampKey: 'optimisticEventDeletedTimestamp',
          updatedAt: new Date(record.updatedAt),
          get,
        })
        if (!_.isUndefined(optimisticDeleted)) {
          return optimisticDeleted
        }

        return !_.isNil(record.deletedAt)
      },
      updateEventFavorite: async (eventId: number, favorite: boolean) => {
        const favoriteFolder = get().favoriteFolder
        const updateData = {
          id: eventId,
          folderId: favorite ? favoriteFolder?.id : null,
        }

        await optimisticUpdate<boolean>({
          set,
          get,
          eventIds: [eventId],
          updateValue: favorite,
          optimisticKey: 'optimisticEventFavorite',
          timestampKey: 'optimisticEventFavoriteTimestamp',
          updateFunction: updateEvent(eventId, updateData),
        })
      },
      updateEventClientMatter: async (
        eventId: number,
        clientMatterId: string | null
      ) => {
        const updateData = {
          id: eventId,
          clientMatterId,
        }

        await optimisticUpdate<string | null>({
          set,
          get,
          eventIds: [eventId],
          updateValue: clientMatterId,
          optimisticKey: 'optimisticEventClientMatterId',
          timestampKey: 'optimisticEventClientMatterIdTimestamp',
          updateFunction: updateEvent(eventId, updateData),
          optimisticUpdateSideEffect: (state) => {
            if (
              clientMatterId &&
              !state.historyClientMatters.some(
                (cm) => cm.id === clientMatterId
              ) &&
              state.systemClientMatters.some((cm) => cm.id === clientMatterId)
            ) {
              // Add the new client matter to the list of history client matters
              const newClientMatter = state.systemClientMatters.find(
                (cm) => cm.id === clientMatterId
              )
              if (newClientMatter) {
                state.historyClientMatters.push(newClientMatter)
              }
            }
          },
        })
      },
      deleteEvent: async (
        eventId: number,
        isWorkspaceDelete: boolean,
        isVaultDelete: boolean
      ) => {
        await optimisticUpdate<boolean>({
          set,
          get,
          eventIds: [eventId],
          updateValue: true,
          optimisticKey: 'optimisticEventDeleted',
          timestampKey: 'optimisticEventDeletedTimestamp',
          updateFunction: async () => {
            const route = isVaultDelete
              ? isWorkspaceDelete
                ? `vault/history/workspace/${eventId}`
                : `vault/history/user/${eventId}`
              : isWorkspaceDelete
              ? `workspace/history/${eventId}`
              : `user/history/${eventId}`

            return Services.Backend.Delete(
              route,
              {},
              {
                throwOnError: true,
              }
            )
          },
        })
      },
      bulkDeleteEvents: async (eventIds: number[]) => {
        await optimisticUpdate<boolean>({
          set,
          get,
          eventIds,
          updateValue: true,
          optimisticKey: 'optimisticEventDeleted',
          timestampKey: 'optimisticEventDeletedTimestamp',
          updateFunction: async () =>
            await DeleteWorkspaceHistoryBulk(eventIds),
        })
      },
      fetchData: async () => {
        const historyMetadata = (await FetchHistoryMetadata()) || {
          eventTaskTypes: [],
          eventFolders: [],
          eventClientMatters: [],
        }
        set((state) => {
          state.historyTaskTypes = historyMetadata.eventTaskTypes
          state.historyItemFolders = historyMetadata.eventFolders
          state.favoriteFolder = historyMetadata.eventFolders.find(
            (f) => f.name === 'Favorite'
          )
          state.historyClientMatters = historyMetadata.eventClientMatters
          state.clientMatterIdToName = {
            ...state.clientMatterIdToName,
            ...historyMetadata.eventClientMatters.reduce(
              (acc, cm) => {
                if (cm.id) {
                  acc[cm.id] = cm.name
                }
                return acc
              },
              {} as SafeRecord<string, string>
            ),
          }
        })
      },
      setCurrentWorkspaceClientMatters: (clientMatters: ClientMatter[]) => {
        set((state) => {
          state.systemClientMatters = clientMatters
          state.clientMatterIdToName = {
            ...state.clientMatterIdToName,
            ...clientMatters.reduce(
              (acc, cm) => {
                if (cm.id) {
                  acc[cm.id] = cm.name
                }
                return acc
              },
              {} as SafeRecord<string, string>
            ),
          }
        })
      },
    }))
  )
)

const updateEvent =
  (
    eventId: number,
    data: FormData | Record<string, Maybe<string> | Maybe<number>>
  ) =>
  async () =>
    await Services.Backend.Patch<boolean>(`user/history/${eventId}`, data, {
      throwOnError: true,
    })

type OptimisticUpdateKeys = {
  [K in keyof HistoryMetadataState]: HistoryMetadataState[K] extends SafeRecord<
    number,
    boolean | Date | string | null
  >
    ? K
    : never
}[keyof HistoryMetadataState] &
  string

interface OptimisticUpdateParams<T> {
  set: (fn: (state: HistoryMetadataState) => void) => void
  get: () => HistoryMetadataState & HistoryMetadataAction
  eventIds: number[]
  updateValue: T
  optimisticKey: OptimisticUpdateKeys
  timestampKey: OptimisticUpdateKeys
  updateFunction: () => Promise<boolean | RequestError>
  optimisticUpdateSideEffect?: (state: HistoryMetadataState) => void
}

async function optimisticUpdate<T>({
  set,
  get,
  eventIds,
  updateValue,
  optimisticKey,
  timestampKey,
  updateFunction,
  optimisticUpdateSideEffect,
}: OptimisticUpdateParams<T>) {
  const eventIdToPrev: Record<
    number,
    { previousValue: T; previousTimestamp: Date }
  > = eventIds.reduce(
    (acc, eventId) => {
      const optimisticValue = (get()[optimisticKey] as SafeRecord<number, T>)[
        eventId
      ]
      const optimisticTimestamp = (
        get()[timestampKey] as SafeRecord<number, Date>
      )[eventId]
      if (
        !_.isUndefined(optimisticValue) &&
        !_.isUndefined(optimisticTimestamp)
      ) {
        acc[eventId] = {
          previousValue: optimisticValue,
          previousTimestamp: optimisticTimestamp,
        }
      }
      return acc
    },
    {} as { [eventId: number]: { previousValue: T; previousTimestamp: Date } }
  )

  eventIds.forEach((eventId) => {
    // Optimistically update state and timestamp
    set((state) => {
      const optimisticState = state[optimisticKey] as SafeRecord<number, T>
      optimisticState[eventId] = updateValue
      const timestampState = state[timestampKey] as SafeRecord<number, Date>
      timestampState[eventId] = new Date()
      optimisticUpdateSideEffect?.(state)
    })
  })

  try {
    const result = await updateFunction()
    if (result instanceof RequestError || result === false) {
      throw new Error(`Update ${optimisticKey} failed for event ${eventIds}`)
    }
  } catch (error) {
    // Revert to previous state and timestamp in case of an error
    set((state) => {
      eventIds.forEach((eventId) => {
        const optimisticState = state[optimisticKey] as SafeRecord<number, T>
        optimisticState[eventId] = eventIdToPrev[eventId].previousValue
        const timestampState = state[timestampKey] as SafeRecord<number, Date>
        timestampState[eventId] = eventIdToPrev[eventId].previousTimestamp
      })
    })
    displayErrorMessage('An error occurred. Please try again.')
    console.error(error)
  }
}

function getOptimisticValue<T>({
  eventId,
  optimisticKey,
  timestampKey,
  updatedAt,
  get,
}: {
  eventId: number
  optimisticKey: keyof HistoryMetadataState
  timestampKey: keyof HistoryMetadataState
  updatedAt: Date
  get: () => HistoryMetadataState & HistoryMetadataAction
}): T | undefined {
  const optimisticValue = (get()[optimisticKey] as SafeRecord<number, T>)[
    eventId
  ]
  const optimisticTimestamp = (get()[timestampKey] as SafeRecord<number, Date>)[
    eventId
  ]

  if (
    !_.isUndefined(optimisticValue) &&
    !_.isUndefined(optimisticTimestamp) &&
    new Date(optimisticTimestamp) > updatedAt
  ) {
    return optimisticValue
  }

  return undefined
}

export const useGetEventDeleted = () => {
  const { getEventDeleted, optimisticEventDeleted } = useHistoryMetadataStore(
    (state) => ({
      getEventDeleted: state.getEventDeleted,
      optimisticEventDeleted: state.optimisticEventDeleted,
    })
  )

  return React.useCallback(
    (record: RecordBase & { id: number }) => getEventDeleted(record),
    // Include optimisticEventDeleted in the dependency array to ensure reactivity.
    // This does not use optimisticEventDeleted directly, but its presence triggers re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getEventDeleted, optimisticEventDeleted]
  )
}
