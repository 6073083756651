import queryString from 'query-string'

export const footnoteParam = 'footnote'
export const queryIdParam = 'queryId'

export const REMOVE_PARAMS = [footnoteParam, queryIdParam]

export const addFootnoteToUrl = ({
  documentUrl,
  footnote,
}: {
  documentUrl: string
  footnote: number
}) => {
  const search = queryString.stringify({ footnote: footnote })
  return `${documentUrl}&${search}`
}
