import slugify from 'slugify'

export const getSlug = (val: string): string => {
  return slugify(val, {
    lower: true, // Convert to lower case
    strict: true, // Remove special characters
    remove: /[*+~.()'"!:@]/g, // Regex to remove any unwanted characters
  })
}

const joinWithAnd = (array: string[]): string => {
  if (array.length === 0) {
    return ''
  }
  if (array.length === 1) {
    return array[0]
  }
  const last = array.pop()
  return `${array.join(', ')} and ${last}`
}

const normalizationMap: { [key: string]: string } = {
  // eslint-disable-next-line prefer-smart-quotes/prefer
  '’': "'", // Curly apostrophe to straight apostrophe
  // eslint-disable-next-line prefer-smart-quotes/prefer
  '‘': "'", // Curly single quote to straight single quote
  // eslint-disable-next-line prefer-smart-quotes/prefer
  '“': '"', // Curly double quote to straight double quote
  // eslint-disable-next-line prefer-smart-quotes/prefer
  '”': '"', // Curly double quote to straight double quote
  '–': '-', // En dash to hyphen
  '—': '-', // Em dash to hyphen
  '…': '...', // Ellipsis to three dots
  // Add more mappings as needed
}

export function normalizeString(input: string) {
  // remap all special characters to their normalized form
  return input
    .split('')
    .map((char: string) => normalizationMap[char] || char)
    .join('')
}

const replaceSpacesWithDashes = (str: string) => {
  return str.replace(/ +/g, '-')
}

const caseInsensitiveMatch = (str1: string, str2: string): boolean => {
  return str1.toLowerCase() === str2.toLowerCase()
}

function findSubstrings(word1: string, word2: string, haystack: string) {
  if (!word1.length || !word2.length || !haystack.length) {
    return []
  }

  const indices = []
  let word1Index = 0

  while ((word1Index = haystack.indexOf(word1, word1Index)) !== -1) {
    let word2StartIndex = word1Index + word1.length
    let word2Index

    while ((word2Index = haystack.indexOf(word2, word2StartIndex)) !== -1) {
      indices.push([word1Index, word2Index + word2.length])
      word2StartIndex = word2Index + 1
    }

    word1Index += word1.length || 1 // Ensure we always move forward
  }

  return indices.map(([start, end]) => haystack.substring(start, end))
}

const findClosestMatchRegex = ({
  haystack,
  firstWord,
  lastWord,
  length,
  threshold,
}: {
  haystack: string
  firstWord: string
  lastWord: string
  length: number
  threshold?: number
}) => {
  const matches = findSubstrings(firstWord, lastWord, haystack)

  if (matches.length === 0) {
    return { index: -1, match: '' }
  }

  const closestMatch = matches.reduce((closest, current) => {
    const currentDiff = Math.abs(current.length - length)
    const closestDiff = Math.abs(closest.length - length)
    return currentDiff < closestDiff ? current : closest
  })

  const matchDiff = Math.abs(closestMatch.length - length)

  if (matchDiff > (threshold || 0.1) * length) {
    return { index: -1, match: '' }
  }

  return {
    index: haystack.indexOf(closestMatch),
    match: closestMatch,
  }
}

function findOriginalSubstring(key: string, haystack: string) {
  // Function to strip whitespace and create a mapping
  function stripAndMap(str: string) {
    return str.split('').reduce(
      (acc, char, index) => {
        if (char.trim() !== '') {
          acc.stripped += char
          acc.mapping.push(index)
        }
        return acc
      },
      { stripped: '', mapping: [] as number[] }
    )
  }

  // Strip whitespace from key and haystack
  const strippedKey = key.replace(/\s/g, '')
  const { stripped: strippedHaystack, mapping } = stripAndMap(haystack)

  // Find the index of the stripped key in the stripped haystack
  const strippedStartIndex = strippedHaystack.indexOf(strippedKey)

  // If the key is not found, return null
  if (strippedStartIndex === -1) {
    return null
  }

  // Calculate the end index in the stripped haystack
  const strippedEndIndex = strippedStartIndex + strippedKey.length

  // Remap the indices to the original haystack
  const originalStartIndex = mapping[strippedStartIndex]
  const originalEndIndex = mapping[strippedEndIndex - 1] + 1 // +1 to include the last character

  // Return the substring from the original haystack
  return haystack.substring(originalStartIndex, originalEndIndex)
}

const removeWhitespace = (str: string) => {
  return str.replace(/\s/g, '')
}

const removeControlCharacters = (str: string) => {
  // eslint-disable-next-line no-control-regex
  return str.replace(/[\x00-\x1F\x7F-\x9F]/g, '')
}

// exports
export {
  joinWithAnd,
  findOriginalSubstring,
  replaceSpacesWithDashes,
  caseInsensitiveMatch,
  findClosestMatchRegex,
  removeWhitespace,
  findSubstrings,
  removeControlCharacters,
}
