import React from 'react'

import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog'

interface ClientMatterDisallowedDialogProps {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  cmName: string
  contentText: string
}

const ClientMatterDisallowedDialog = ({
  isOpen,
  onOpenChange,
  cmName,
  contentText,
}: ClientMatterDisallowedDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Client matter {cmName} is disallowed</DialogTitle>
        </DialogHeader>
        <DialogDescription>{contentText}</DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button onClick={() => onOpenChange(false)}>Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ClientMatterDisallowedDialog
