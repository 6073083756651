import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'

import { AnnotationById, Source } from 'utils/task'
import { HarveySocketSetter, HarveySocketTask } from 'utils/use-harvey-socket'
import { HarveySocketCompletionStatus } from 'utils/use-harvey-socket-utils'

import { FeedbackResult } from 'components/common/feedback/feedback-with-comments'

import { AssistantDocument } from './assistant-utils'

export const ASSISTANT_RESPONSE_PANEL_PERCENT_DEFAULT = 50

interface AssistantState {
  headerText: string
  query: string
  queryPreview: string
  response: string
  isLoading: boolean
  queryId: Maybe<number>
  activeDocument: Maybe<UploadedFile>
  progress: number
  internetBrowsing: boolean
  uploadedFiles: Maybe<AssistantDocument[]>
  sources: Source[]
  annotations: AnnotationById
  selectedSource: Maybe<Source>
  feedback: Maybe<FeedbackResult>
  hasRedlineDoc: boolean
  assistantResponsePanelPercent: number
  completionStatus: HarveySocketCompletionStatus | null
}

interface AssistantAction {
  setQuery: (query: string) => void
  setQueryPreview: (query: string) => void
  setResponse: (response: string) => void
  setIsLoading: (isLoading: boolean) => void
  setQueryId: (queryId: Maybe<number>) => void
  setActiveDocument: (activeDocument: Maybe<UploadedFile>) => void
  setProgress: (progress: number) => void
  setInternetBrowsing: (isInternetBrowsing: boolean) => void
  setUploadedFiles: (files: AssistantDocument[]) => void
  setSources: (sources: Source[]) => void
  setAnnotations: (annotations: AnnotationById) => void
  setTask: HarveySocketSetter
  setSelectedSource: (source: Maybe<Source>) => void
  setFeedback: (feedback: Maybe<FeedbackResult>) => void
  setHeaderText(text: string): void
  setHasRedlineDoc(hasRedlineDoc: boolean): void
  setAssistantResponsePanelPercent(percent: number): void
  resetAssistantResponsePanelPercent(): void
}

export const useAssistantStore = create(
  devtools(
    immer<AssistantState & AssistantAction>((set) => ({
      headerText: '',
      query: '',
      queryPreview: '',
      response: '',
      isLoading: false,
      sources: [],
      annotations: {},
      queryId: null,
      activeDocument: null,
      progress: 0,
      internetBrowsing: false,
      uploadedFiles: [],
      selectedSource: null,
      feedback: null,
      hasRedlineDoc: false,
      assistantResponsePanelPercent: ASSISTANT_RESPONSE_PANEL_PERCENT_DEFAULT,
      completionStatus: null,
      setAnnotations: (annotations: AnnotationById) =>
        set((state) => {
          state.annotations = annotations
        }),
      setQuery: (query: string) =>
        set((state) => {
          state.query = query
          state.queryPreview = ''
        }),
      setQueryPreview: (queryPreview: string) =>
        set((state) => {
          state.queryPreview = queryPreview
        }),
      setResponse: (response: string) =>
        set((state) => {
          state.response = response
        }),
      setIsLoading: (isLoading: boolean) =>
        set((state) => {
          state.isLoading = isLoading
        }),
      setQueryId: (queryId: Maybe<number>) =>
        set((state) => {
          state.queryId = queryId
        }),
      setActiveDocument: (activeDocument: Maybe<UploadedFile>) =>
        set((state) => {
          state.activeDocument = activeDocument
        }),
      setProgress: (progress: number) =>
        set((state) => {
          state.progress = progress
        }),
      setInternetBrowsing: (isInternetBrowsing: boolean) =>
        set((state) => {
          state.internetBrowsing = isInternetBrowsing
        }),
      setUploadedFiles: (files: AssistantDocument[]) =>
        set((state) => {
          state.uploadedFiles = files
        }),
      setSources: (sources: Source[]) =>
        set((state) => {
          state.sources = sources
        }),
      setSelectedSource: (source: Maybe<Source>) =>
        set((state) => {
          state.selectedSource = source
        }),
      setFeedback: (feedback: Maybe<FeedbackResult>) => {
        set(() => ({
          feedback,
        }))
      },
      setTask: (socketState: Partial<HarveySocketTask>) => {
        set(() => ({
          ...socketState,
        }))
      },
      setHeaderText(text) {
        set((state) => {
          state.headerText = text
        })
      },
      setHasRedlineDoc: (hasRedlineDoc: boolean) =>
        set((state) => {
          state.hasRedlineDoc = hasRedlineDoc
        }),
      setAssistantResponsePanelPercent: _.debounce(
        (percent: number) =>
          set((state) => {
            state.assistantResponsePanelPercent = percent
          }),
        100
      ),
      resetAssistantResponsePanelPercent: () =>
        set((state) => {
          state.assistantResponsePanelPercent =
            ASSISTANT_RESPONSE_PANEL_PERCENT_DEFAULT
        }),
    }))
  )
)
