import React, { useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { Trash } from 'lucide-react'
import pluralize from 'pluralize'

import { removePermsFromRole, WorkspaceRole } from 'models/roles'
import { Workspace } from 'models/workspace'

import { getTableDateString } from 'utils/date-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { DataTable } from 'components/ui/data-table/data-table'
import DataTableFooter from 'components/ui/data-table/data-table-footer'
import DataTableHeader from 'components/ui/data-table/data-table-header'
import DataTableSortHeader from 'components/ui/data-table/data-table-sort-header'

interface RolePermsTableProps {
  rolePerms: RolePerm[]
  isLoading: boolean
  fetchRolePermsCallback: () => void
  workspaceRole: WorkspaceRole
  workspace: Workspace
}

export interface RolePerm {
  rolePk: string
  permId: string
  name: string
  desc: string
  createdAt: string
}

const RolePermsTable = ({
  rolePerms,
  isLoading,
  fetchRolePermsCallback,
  workspaceRole,
  workspace,
}: RolePermsTableProps) => {
  const userInfo = useAuthUser()

  const columns: ColumnDef<RolePerm>[] = [
    {
      accessorKey: 'rolePk',
    },
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Permission" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
    },
    {
      accessorKey: 'permId',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Perm ID" />
      },
      cell: ({ getValue }) => (
        <div className="ml-3 text-sm">{String(getValue())}</div>
      ),
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => {
        return <DataTableSortHeader column={column} header="Date granted" />
      },
      cell: ({ getValue }) => {
        const value = getValue()
        return (
          <div className="ml-3 text-sm">
            {value ? getTableDateString(value) : ''}
          </div>
        )
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'actions',
      header: '',
      size: 1,
      cell: ({ row }) => {
        if (workspace.deletedAt) {
          return <></>
        }
        return (
          <div className="float-right flex justify-end">
            <Button
              className="flex items-center justify-center rounded hover:bg-button-secondary-hover"
              variant="ghost"
              size="xsIcon"
              tooltip={`Remove ${row.getValue('name')}`}
              tooltipSide="left"
              onClick={() =>
                onDeleteRolePerm(row.getValue('rolePk'), row.getValue('permId'))
              }
            >
              <Trash className="h-4 w-4 stroke-destructive" />
            </Button>
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  const [filter, setFilter] = useState<string>('')

  const [tablePaginationState, setTablePaginationState] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: rolePerms,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      sorting,
      pagination: tablePaginationState,
      globalFilter: filter,
      columnVisibility: {
        rolePk: false,
        actions: userInfo.isUserManagement ?? false,
      },
    },
    onPaginationChange: setTablePaginationState,
    onGlobalFilterChange: setFilter,
    enableSorting: true,
    enableSortingRemoval: true,
  })

  const rowCountCopy = `${table.getFilteredRowModel().rows.length} ${pluralize(
    'permission',
    table.getFilteredRowModel().rows.length
  )}`

  const onDeleteRolePerm = async (rolePk: string, permId: string) => {
    const numRemoved = await removePermsFromRole(rolePk, [permId])
    if (numRemoved > 0) {
      const rolePerm = rolePerms.find((p) => p.permId === permId)
      displaySuccessMessage(
        `Removed ${rolePerm?.name} from role ${workspaceRole.roleId}`,
        10
      )
      fetchRolePermsCallback()
    } else {
      displayErrorMessage(`Failed to remove permission from role ${rolePk}`, 10)
    }
  }

  if (!userInfo || !userInfo.IsInternalAdminReader) return <></>

  return (
    <div className="-mt-10">
      <DataTableHeader
        searchable={{ searchQuery: filter, setSearchQuery: setFilter }}
      />
      <div className="mt-2">
        <DataTable
          table={table}
          className={cn({ 'h-[620px]': !isLoading })}
          useVirtual={rolePerms.length > 100}
          isLoading={isLoading}
          emptyStateText="No permissions found"
        />
      </div>
      <DataTableFooter table={table} isLoading={isLoading}>
        <p>{rowCountCopy}</p>
      </DataTableFooter>
    </div>
  )
}

export default RolePermsTable
