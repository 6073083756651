import React from 'react'

import { Instance } from 'pspdfkit'

import {
  PdfKitSourceFromJSON,
  instanceOfPdfKitSource,
} from 'openapi/models/PdfKitSource'
import { Maybe } from 'types'
import { AnnotationById, Source } from 'types/task'

import { SourceCard } from 'components/common/source-card/source-card'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Separator } from 'components/ui/separator'

interface Props {
  sources?: Source[]
  pspdfInstanceRef?: React.MutableRefObject<Maybe<Instance>>
}

export const onSourceClick = async (
  source: Source,
  pspdfInstanceRef?: React.MutableRefObject<Maybe<Instance>>
) => {
  if (!pspdfInstanceRef?.current) {
    return
  }

  const newViewState = pspdfInstanceRef.current.viewState.set(
    'currentPageIndex',
    source.page
  )
  pspdfInstanceRef.current.setViewState(newViewState)
}

export const onAnnotationClick = async (
  annotations: AnnotationById,
  annotationId: string,
  pspdfInstanceRef?: React.MutableRefObject<Maybe<Instance>>
) => {
  if (!(annotationId in annotations)) {
    return
  }
  // TODO: use strict types as part of PLT-542 refactor
  const source = annotations[annotationId]
  if (!instanceOfPdfKitSource(source)) {
    return
  }

  const { page } = PdfKitSourceFromJSON(source)

  if (!pspdfInstanceRef?.current) {
    return
  }
  const newViewState = pspdfInstanceRef.current.viewState.set(
    'currentPageIndex',
    page
  )
  pspdfInstanceRef.current.setViewState(newViewState)
}

const WorkflowResponseSources: React.FC<Props> = ({
  sources,
  pspdfInstanceRef,
}) => {
  if (!sources?.length) {
    return null
  }
  const documentName = sources[0].documentName

  return (
    <Card className="border-0">
      <CardHeader className="mb-2 space-y-2">
        <CardTitle>References</CardTitle>
        <Separator />
      </CardHeader>
      <CardContent>{documentName}</CardContent>
      <CardContent className="space-y-2">
        {sources.map((source, idx) => (
          <SourceCard
            onClick={() => onSourceClick(source, pspdfInstanceRef)}
            key={idx}
            title=""
            badges={[
              { text: `Page ${String(source.page + 1)}`, variant: 'ghost' },
            ]}
            text={`${idx + 1}. ${source.text}`}
          />
        ))}
      </CardContent>
    </Card>
  )
}

export default WorkflowResponseSources
