import React from 'react'

import { Row } from '@tanstack/react-table'
import _ from 'lodash'
import { Upload } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { DiligenceDocument } from 'openapi/models/DiligenceDocument'
import { DiligenceDocumentLabel } from 'openapi/models/DiligenceDocumentLabel'

import { mbToReadable } from 'utils/file-utils'

import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import { Button } from 'components/ui/button'
import { DiligenceDocumentsTable } from 'components/workflows/workflow/discovery/common/documents-table'
import { useDiligenceDropzone } from 'components/workflows/workflow/discovery/common/use-diligence-dropzone'
import * as config from 'components/workflows/workflow/discovery/config'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'

export const DiligenceDocumentsPageInner: React.FC<{
  handleDocumentsChanged: () => void
}> = ({ handleDocumentsChanged }) => {
  const documents = useDiligenceStore((state) => state.documents)
  const [isUploadingDocuments, setIsUploadingDocuments] = useDiligenceStore(
    useShallow((state) => [
      state.isUploadingDocuments,
      state.setIsUploadingDocuments,
    ])
  )
  const handleNewFiles = useDiligenceStore((state) => state.handleNewFiles)
  const removeDocument = useDiligenceStore((state) => state.removeDocument)
  const setDocumentTypes = useDiligenceStore((state) => state.setDocumentTypes)

  const handleNewFilesWrapper = async (files: File[]) => {
    await handleNewFiles(files)
    handleDocumentsChanged()
  }

  const { getRootProps, getInputProps, open } = useDiligenceDropzone(
    documents,
    handleNewFilesWrapper,
    setIsUploadingDocuments
  )

  const onMultiSelectChange = (
    row: Row<DiligenceDocument>,
    selectedValues: DiligenceDocumentLabel[]
  ) => {
    setDocumentTypes(row.original.file.name, selectedValues)
  }

  const onDeleteClick = (row: Row<DiligenceDocument>) => {
    removeDocument(row.original.file.name)
    handleDocumentsChanged()
  }

  const isAnyDocuments = !(_.isNil(documents) || _.isEmpty(documents))

  if (!isAnyDocuments) {
    return (
      <div className="flex h-full w-full justify-center">
        <Dropzone
          className="h-full w-full"
          isLoading={isUploadingDocuments}
          dropzone={{ getRootProps, getInputProps }}
          description={
            <DropzoneDescription
              fileTypes={config.FILE_TYPES}
              maxSize={mbToReadable(config.MAX_FILE_SIZE_MB)}
            />
          }
        />
      </div>
    )
  }

  return (
    <div className="relative flex h-full flex-col">
      <div className="min-h-0 grow">
        <DiligenceDocumentsTable
          documents={documents}
          onMultiSelectChange={onMultiSelectChange}
          onDeleteClick={onDeleteClick}
        />
      </div>
      <Button
        className="absolute right-2 top-2 z-20"
        variant="outline"
        onClick={open}
      >
        <Upload size={16} className="mr-1" />
        Upload
      </Button>
    </div>
  )
}
