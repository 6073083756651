import React from 'react'

import { cn } from 'utils/utils'

import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

interface Props {
  query: string
  queryLimit: number
}

const AssistantQueryLimitTooltip = ({ query, queryLimit }: Props) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <div
          className={cn(
            'mr-0.5 rounded-t bg-primary p-1 pr-0.5 text-xs leading-none text-muted opacity-0 transition-opacity',
            {
              'opacity-100': query.length >= queryLimit * 0.9,
              'text-destructive': query.length > queryLimit,
            }
          )}
        >
          {query.length} / {queryLimit}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        {query.length > queryLimit
          ? 'Maximum query length exceeded'
          : 'Maximum query length'}
      </TooltipContent>
    </Tooltip>
  )
}

export default AssistantQueryLimitTooltip
