import { LucideIcon, Table } from 'lucide-react'

import { SafeRecord } from 'utils/safe-types'

export enum FileType {
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  WORD_LEGACY = 'application/msword',
  EXCEL_LEGACY = 'application/vnd.ms-excel',
  C = 'text/x-c',
  CPP = 'text/x-c++',
  CSV = 'text/csv',
  HTML = 'text/html',
  JAVA = 'text/x-java',
  JSON = 'application/json',
  MARKDOWN = 'text/markdown',
  PHP = 'text/x-php',
  POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  POWERPOINT_LEGACY = 'application/vnd.ms-powerpoint',
  PYTHON = 'text/x-python',
  PYTHON_SCRIPT = 'text/x-script.python',
  RUBY = 'text/x-ruby',
  TEX = 'text/x-tex',
  TEXT = 'text/plain',
  CSS = 'text/css',
  JPEG = 'image/jpeg',
  JAVASCRIPT = 'text/javascript',
  GIF = 'image/gif',
  PNG = 'image/png',
  TAR = 'application/x-tar',
  TYPESCRIPT = 'application/typescript',
  XML = 'application/xml',
  XML_TEXT = 'text/xml',
  EMAIL = 'message/rfc822',
  OUTLOOK = 'application/vnd.ms-outlook',
  RTF = 'application/rtf',
}

export const FileTypeToExtension: Record<FileType, string[]> = {
  [FileType.PDF]: ['.pdf', '.PDF'],
  [FileType.WORD]: ['.docx', '.DOCX'],
  [FileType.WORD_LEGACY]: ['.doc', '.DOC'],
  [FileType.EXCEL_LEGACY]: ['.xls', '.XLS'],
  [FileType.EXCEL]: ['.xlsx', '.XLSX'],
  [FileType.ZIP]: ['.zip', '.ZIP'],
  [FileType.C]: ['.c'],
  [FileType.CPP]: ['.cpp'],
  [FileType.CSV]: ['.csv', '.CSV'],
  [FileType.HTML]: ['.html'],
  [FileType.JAVA]: ['.java'],
  [FileType.JSON]: ['.json'],
  [FileType.MARKDOWN]: ['.md'],
  [FileType.PHP]: ['.php'],
  [FileType.POWERPOINT]: ['.pptx'],
  [FileType.POWERPOINT_LEGACY]: ['.ppt', '.PPT'],
  [FileType.PYTHON]: ['.py'],
  [FileType.PYTHON_SCRIPT]: ['.py'],
  [FileType.RUBY]: ['.rb'],
  [FileType.TEX]: ['.tex'],
  [FileType.TEXT]: ['.txt'],
  [FileType.CSS]: ['.css'],
  [FileType.JPEG]: ['.jpeg', '.jpg'],
  [FileType.JAVASCRIPT]: ['.js'],
  [FileType.GIF]: ['.gif'],
  [FileType.PNG]: ['.png'],
  [FileType.TAR]: ['.tar'],
  [FileType.TYPESCRIPT]: ['.ts'],
  [FileType.XML]: ['.xml'],
  [FileType.XML_TEXT]: ['.xml'],
  [FileType.EMAIL]: ['.eml'],
  [FileType.OUTLOOK]: ['.msg', '.pst'],
  [FileType.RTF]: ['.rtf'],
}

export const FileExtenstionToIcon: SafeRecord<string, LucideIcon> = {
  xls: Table,
  xlsx: Table,
}

const LEGACY_SUFFIX = '(Legacy)'

export const FileTypeReadableName: Record<FileType, string> = {
  'application/pdf': 'PDF',
  'application/zip': 'Zip',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word',
  'application/msword': `Word ${LEGACY_SUFFIX}`,
  'application/vnd.ms-excel': `Excel ${LEGACY_SUFFIX}`,
  'text/x-c': 'C Source File',
  'text/x-c++': 'C++ Source File',
  'text/csv': 'CSV',
  'text/html': 'HTML',
  'text/x-java': 'Java Source File',
  'application/json': 'JSON',
  'text/markdown': 'Markdown',
  'text/x-php': 'PHP Source File',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'PowerPoint',
  'application/vnd.ms-powerpoint': `PowerPoint ${LEGACY_SUFFIX}`,
  'text/x-python': 'Python Source File',
  'text/x-script.python': 'Python Script',
  'text/x-ruby': 'Ruby Source File',
  'text/x-tex': 'TeX',
  'text/plain': 'Text',
  'text/css': 'CSS',
  'image/jpeg': 'JPEG Image',
  'text/javascript': 'JavaScript',
  'image/gif': 'GIF Image',
  'image/png': 'PNG Image',
  'application/x-tar': 'Tar Archive',
  'application/typescript': 'TypeScript',
  'application/xml': 'XML',
  'text/xml': 'XML (Text)',
  'message/rfc822': 'Email',
  'application/vnd.ms-outlook': 'Email (Outlook)',
  'application/rtf': 'RTF',
}

const SPECIFICATION_REGEX = / \([^)]*\)/

export const removeSubsetDuplicates = (readableNames: string[]) => {
  const readablePrefixes = new Set()

  // Filter out subsets if their non-subset counterpart exists
  return readableNames.filter((name) => {
    const prefix = name.replace(SPECIFICATION_REGEX, '').trim()
    if (!readablePrefixes.has(prefix)) {
      readablePrefixes.add(prefix)
      return true
    }
    return !SPECIFICATION_REGEX.test(name)
  })
}
