import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { Source } from 'utils/task'
import { EM_DASH } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from 'components/ui/accordion'
import Footnote from 'components/vault/components/vault-footnote'
import {
  REMOVE_PARAMS,
  projectsPath,
  filesPath,
  queryIdSearchParamKey,
  QueryQuestions,
  QueryAnswer,
  sourceIdSearchParamKey,
} from 'components/vault/utils/vault'
import { getDisplayAnswer } from 'components/vault/utils/vault-helpers'
import { VaultSocketTask } from 'components/vault/utils/vault-store'

const ReviewQueryResponse = ({
  selectedQuery,
  sources,
  fileId,
  projectId,
}: {
  selectedQuery: VaultSocketTask
  sources: Source[]
  fileId: string | undefined
  projectId: string | undefined
}) => {
  const [searchParams] = useSearchParams()
  const queryParamSourceId = searchParams.get(sourceIdSearchParamKey)

  const navigateWithQueryParams = useNavigateWithQueryParams()
  const columnHeaders: QueryQuestions[] = selectedQuery.columnHeaders
  const queryQuestions: QueryQuestions[] = selectedQuery.questions

  const queryAnswers = selectedQuery.answers
  const fileQueryAnswers: QueryAnswer[] = fileId
    ? queryAnswers[fileId] ?? []
    : []

  const [selectedQueryQuestionId, setSelectedQueryQuestionId] = useState<
    string | undefined
  >(queryQuestions[0]?.id)
  useEffect(() => {
    setSelectedQueryQuestionId(queryQuestions[0]?.id)
  }, [queryQuestions])

  const onDocumentSourceClick = (source: Source) => {
    const sourceDocumentId = source.documentId
    const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${filesPath}${sourceDocumentId}`
    const searchParams = new URLSearchParams()
    searchParams.set(queryIdSearchParamKey, selectedQuery.queryId)
    searchParams.set(sourceIdSearchParamKey, source.id)
    const searchParamString = `?${searchParams.toString()}`
    navigateWithQueryParams(newPath + searchParamString, {}, REMOVE_PARAMS)
  }

  if (
    columnHeaders.length !== queryQuestions.length ||
    queryQuestions.length === 0
  ) {
    return null
  }

  return (
    <Accordion
      type="single"
      value={selectedQueryQuestionId}
      onValueChange={setSelectedQueryQuestionId}
    >
      {queryQuestions.map((question) => {
        const questionId = question.id
        const questionText = question.text
        const questionColumnHeader = columnHeaders.find((columnHeader) => {
          return columnHeader.id === questionId
        })

        const shortAnswer = fileQueryAnswers.find((answer) => {
          return answer.columnId === questionId && !answer.long
        })

        const longAnswer = fileQueryAnswers.find((answer) => {
          return answer.columnId === questionId && answer.long
        })

        const displayLongAnswer =
          longAnswer?.text.toLocaleLowerCase() ===
          shortAnswer?.text.toLocaleLowerCase()
            ? EM_DASH
            : getDisplayAnswer(longAnswer)

        const questionSources = sources.filter(
          (source) => source.questionId === questionId
        )

        return (
          <AccordionItem value={question.id} key={question.id}>
            <AccordionTrigger className="group hover:no-underline">
              <div className="flex items-center">
                <p className="text-start group-hover:underline">
                  {questionColumnHeader?.text ?? ''}
                </p>
                <div className="ml-2 flex items-center space-x-1">
                  {questionSources.map((questionSource) => {
                    return (
                      <Footnote
                        isCurrentSource={
                          queryParamSourceId === questionSource.id
                        }
                        text={questionSource.footnote.toString()}
                        key={questionSource.id}
                        onClick={() => onDocumentSourceClick(questionSource)}
                      />
                    )
                  })}
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="space-y-2">
                <div className="flex flex-col justify-between  2xl:flex-row 2xl:space-x-4">
                  <p className="w-24 shrink-0 text-muted">Question</p>
                  <p className="w-full text-left">{questionText}</p>
                </div>
                <div className="flex flex-col justify-between  2xl:flex-row 2xl:space-x-4">
                  <p className="w-24 shrink-0 text-muted">Short answer</p>
                  <p
                    className="w-full text-left"
                    dangerouslySetInnerHTML={{
                      __html: getDisplayAnswer(shortAnswer).replace(
                        /\n/g,
                        '<br />'
                      ),
                    }}
                  />
                </div>
                <div className="flex flex-col justify-between  2xl:flex-row 2xl:space-x-4">
                  <p className="w-24 shrink-0 text-muted">Long answer</p>
                  <p
                    className="w-full text-left"
                    dangerouslySetInnerHTML={{
                      __html: displayLongAnswer.replace(/\n/g, '<br />'),
                    }}
                  />
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default ReviewQueryResponse
