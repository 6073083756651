import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DateRange } from 'react-day-picker'

import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  DatabaseSource,
  FileSource,
  getDateRange,
  isResearchKnowledgeSource,
  KnowledgeSource,
  KnowledgeSourceConfig,
  KnowledgeSourceItem,
} from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import VaultKnowledgeSourcePicker from 'components/common/vault-knowledge-source-picker'
import { ResearchDatePicker } from 'components/research/date-picker'
import { Button } from 'components/ui/button'
import { ScrollArea } from 'components/ui/scroll-area'
import { Spinner } from 'components/ui/spinner'

import AssistantFilesInput from './assistant-files-input'
import AssistantInputSourceHeader from './assistant-input-source-header'
import { AssistantMode } from './assistant-mode-select'
import { AssistantResearchKnowledgeSource } from './assistant-research-knowledge-source'

// Height of the 'Sources' header
const HEADER_HEIGHT = 28
// Height of the 'Files' and 'Databases' header under 'Sources'
const SOURCE_HEADER_HEIGHT = 24

// Height of a single database button
const DATABASE_HEIGHT = 46
const DATABASE_GAP = 8

// Default height of the dropzone
const BASE_HEIGHT = DATABASE_HEIGHT * 4 + DATABASE_GAP * 3
const BASE_VERTICAL_PADDING = 32

interface Props {
  knowledgeSources: Set<KnowledgeSource>
  isForkingFiles: boolean // When user chooses 'Reuse documents' from 'New thread' dropdown in a thread header
  mode: AssistantMode
  setFilesAskHarveyDisabled: (disabled: boolean) => void
  setResearchAskHarveyDisabled: (disabled: boolean) => void
  defaultKnowledgeSource?: KnowledgeSource
}
const AssistantKnowledgeSourceInput = ({
  knowledgeSources,
  isForkingFiles,
  mode,
  setFilesAskHarveyDisabled,
  setResearchAskHarveyDisabled,
  defaultKnowledgeSource,
}: Props) => {
  const [documents, documentsUploading, knowledgeSource, setKnowledgeSource] =
    useAssistantStore(
      useShallow((s) => [
        s.documents,
        s.documentsUploading,
        s.knowledgeSource,
        s.setKnowledgeSource,
      ])
    )

  const { trackEvent } = useAnalytics()

  const dateRange = getDateRange(knowledgeSource)
  const handleSetDateRange = (dateRange: DateRange | null) => {
    if (!isResearchKnowledgeSource(knowledgeSource)) return
    setKnowledgeSource({
      ...knowledgeSource,
      dateRange: dateRange ?? undefined,
    })
  }

  const isFileSource =
    isForkingFiles || documents.length > 0 || documentsUploading.length > 0

  const defaultSource =
    defaultKnowledgeSource ??
    (isFileSource
      ? FileSource.FILES
      : knowledgeSource
      ? knowledgeSource.type
      : undefined)

  const [activeSource, setActiveSource] = useState<KnowledgeSource | undefined>(
    defaultSource
  )
  const sourceConfig = activeSource
    ? KnowledgeSourceConfig[activeSource]
    : undefined

  const hasMode = useCallback(
    (source: KnowledgeSource) =>
      KnowledgeSourceConfig[source].modes.includes(mode),
    [mode]
  )

  const databaseSources = useMemo(
    () =>
      Object.values(DatabaseSource).filter((source) =>
        knowledgeSources.has(source)
      ),
    [knowledgeSources]
  )
  const availableDatabaseSources = useMemo(
    () => databaseSources.filter(hasMode),
    [databaseSources, hasMode]
  )
  const fileSources = useMemo(
    () =>
      Object.values(FileSource).filter((source) =>
        knowledgeSources.has(source)
      ),
    [knowledgeSources]
  )
  const availableFileSources = useMemo(
    () => fileSources.filter(hasMode),
    [fileSources, hasMode]
  )
  const internalFileSources = useMemo(
    () => fileSources.filter((source) => source !== FileSource.FILES),
    [fileSources]
  )

  // If the active source is not available, reset it
  useEffect(() => {
    const availableSources: Set<KnowledgeSource> = new Set([
      ...availableDatabaseSources,
      ...availableFileSources,
    ])
    setActiveSource(() => {
      const newSource = isFileSource
        ? FileSource.FILES
        : knowledgeSource
        ? knowledgeSource.type
        : undefined
      if (newSource && availableSources.has(newSource)) return newSource
      return undefined
    })
  }, [
    isFileSource,
    knowledgeSource,
    availableDatabaseSources,
    availableFileSources,
  ])

  const hasDatabases = availableDatabaseSources.length > 0
  const hasFiles = fileSources.length > 0

  const showDatabases = hasDatabases && !activeSource
  const showFiles =
    hasFiles && (!activeSource || activeSource === FileSource.FILES)

  const handleFileUpload = () => {
    setActiveSource(FileSource.FILES)
  }
  const handleSetSource = (
    source: Exclude<KnowledgeSource, FileSource.FILES>
  ) => {
    setActiveSource(source)
    setKnowledgeSource({ type: source } as KnowledgeSourceItem)
    trackEvent('Knowledge Source Selected', {
      source_type: source,
      assistant_mode: mode,
    })
  }
  const handleSourceCancel = () => {
    setActiveSource(undefined)
    setKnowledgeSource(null)
    trackEvent('Knowledge Source Selection Canceled', {
      assistant_mode: mode,
    })
  }

  const dropzoneSources = internalFileSources.map((source) => {
    const config = KnowledgeSourceConfig[source]
    if (!config) return null
    return (
      <Button
        key={source}
        className="text-primary"
        onClick={(e) => {
          e.stopPropagation()
          setActiveSource(source)
        }}
        size="sm"
        variant="outline"
      >
        {React.cloneElement(config.icon, {
          className: 'h-4 w-4 shrink-0 mr-2',
        })}
        {config.label}
      </Button>
    )
  })

  // We fix the height so when user moves on to files uploaded view,
  // or knowledge source view, the area doesn't jump.
  let containerHeight = BASE_HEIGHT
  if (databaseSources.length > 0) {
    const databasesHeight =
      databaseSources.length * DATABASE_HEIGHT +
      (databaseSources.length - 1) * DATABASE_GAP
    containerHeight = Math.max(containerHeight, databasesHeight)
  }
  if (hasFiles && availableFileSources.length > 0) {
    containerHeight += SOURCE_HEADER_HEIGHT
  }
  containerHeight += HEADER_HEIGHT + BASE_VERTICAL_PADDING

  return (
    <div
      className={cn(
        'box-border flex flex-col rounded-md rounded-t-none bg-secondary p-4 pt-2',
        {
          'border border-t-0 bg-primary p-0 shadow-sm': !!activeSource,
        }
      )}
      style={{ height: `${containerHeight}px` }}
    >
      {!activeSource ? (
        <p className="my-2 h-5 font-medium">Sources</p>
      ) : sourceConfig && (!sourceConfig.hideHeader || isForkingFiles) ? (
        <AssistantInputSourceHeader
          className="gap-x-4"
          actions={
            isForkingFiles ? null : (
              <Button size="sm" variant="outline" onClick={handleSourceCancel}>
                Cancel
              </Button>
            )
          }
        >
          {sourceConfig.name}
          {sourceConfig.hasDateRange && (
            <ResearchDatePicker
              dateRange={dateRange}
              handleSetDateRange={handleSetDateRange}
            />
          )}
        </AssistantInputSourceHeader>
      ) : null}
      <div className="flex min-h-0 grow gap-4">
        {showFiles && (
          <div className="grow">
            {isForkingFiles ? (
              <div className="px-6 py-4">
                <p className="flex items-center px-1 py-2 text-xs">
                  <Spinner className="mr-2 h-4 w-4" />
                  Duplicating documents
                </p>
              </div>
            ) : (
              <AssistantFilesInput
                dropzoneSources={dropzoneSources}
                hasFilesHeader={hasDatabases && !activeSource}
                mode={mode}
                onCancel={handleSourceCancel}
                onUpload={handleFileUpload}
                setAskHarveyDisabled={setFilesAskHarveyDisabled}
              />
            )}
          </div>
        )}
        {showDatabases && (
          <div className="flex w-64 flex-col">
            {hasFiles && (
              <p className="pb-2 text-xs font-medium text-secondary">
                Knowledge
              </p>
            )}
            <ScrollArea className="grow">
              <div className="space-y-2">
                {availableDatabaseSources.map((source) => {
                  const config = KnowledgeSourceConfig[source]
                  if (!config) return null
                  return (
                    <Button
                      key={source}
                      className="flex h-auto w-full items-center justify-start border px-4 py-3 hover:bg-secondary-hover"
                      onClick={() => handleSetSource(source)}
                      variant="unstyled"
                    >
                      {React.cloneElement(config.icon, {
                        className: 'h-4 w-4 shrink-0 mr-2',
                      })}
                      {config.label}
                    </Button>
                  )
                })}
              </div>
            </ScrollArea>
          </div>
        )}
        {/* TODO: Create a map for these */}
        {activeSource === FileSource.VAULT && (
          <VaultKnowledgeSourcePicker
            knowledgeSource={knowledgeSource}
            setKnowledgeSource={setKnowledgeSource}
            onClose={handleSourceCancel}
          />
        )}
        {Object.values(DatabaseSource).includes(
          activeSource as DatabaseSource
        ) && (
          <AssistantResearchKnowledgeSource
            onClose={handleSourceCancel}
            databaseSource={activeSource as DatabaseSource}
            setAskHarveyDisabled={setResearchAskHarveyDisabled}
          />
        )}
      </div>
    </div>
  )
}

export default AssistantKnowledgeSourceInput
