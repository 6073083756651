import React, { useState } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  FileSource,
  VAULT_SOURCE_SELECT_FILES_DESCRIPTION,
} from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { BaseAppPath } from 'components/base-app-path'
import { Button } from 'components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
  DialogDescription,
} from 'components/ui/dialog'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import {
  getReadyToQueryFileIds,
  getVaultFileIds,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

const DIALOG_TITLE = 'Query Vault files in Assistant'
const DIALOG_DESCRIPTION = `Vault Ask has moved to Assistant. To get a single answer over your files, go to Assistant and choose from Vault. You can still run Review queries from Vault.`

const VaultAssistantModal = () => {
  const [isFileExplorerOpen, setIsFileExplorerOpen] = useState(false)
  const navigate = useNavigateWithQueryParams()

  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const pendingQueryFileIds = useVaultStore(
    useShallow((s) => s.pendingQueryFileIds)
  )
  const folderIdToVaultFolder = useVaultStore(
    useShallow((s) => s.folderIdToVaultFolder)
  )
  const folderIdToVaultFileIds = useVaultStore(
    useShallow((s) => s.folderIdToVaultFileIds)
  )
  const parentIdToVaultFolderIds = useVaultStore(
    useShallow((s) => s.parentIdToVaultFolderIds)
  )

  const pendingQuery = useVaultStore(useShallow((s) => s.pendingQuery))
  const isVaultAssistantModalOpen = useVaultStore(
    useShallow((s) => s.isVaultAssistantModalOpen)
  )
  const setIsVaultAssistantModalOpen = useVaultStore(
    useShallow((s) => s.setIsVaultAssistantModalOpen)
  )
  const setKnowledgeSource = useAssistantStore(
    useShallow((s) => s.setKnowledgeSource)
  )
  const setQuery = useAssistantStore(useShallow((s) => s.setQuery))

  const closeHandler = () => {
    setIsFileExplorerOpen(false)
    setIsVaultAssistantModalOpen(false)
  }

  const allFileIds = getVaultFileIds({
    folderId: currentProjectMetadata.id,
    parentIdToVaultFolderIds,
    folderIdToVaultFileIds,
  })
  const [preselectFileIds, setPreselectFileIds] = useState<Set<string>>()

  const handleOpenFileExplorer = () => {
    const fileIds = new Set(pendingQueryFileIds ?? allFileIds)
    setPreselectFileIds(fileIds)
    setIsFileExplorerOpen(true)
  }

  const handleOpenProjectInAssistant = () => {
    setKnowledgeSource({
      type: FileSource.VAULT,
      folderId: currentProjectMetadata.id,
      // If user has pending files to be queried, we need to pass them to Assistant, otherwise we leave it empty
      fileIds: pendingQueryFileIds
        ? getReadyToQueryFileIds(
            currentProjectMetadata,
            pendingQueryFileIds,
            folderIdToVaultFolder
          )
        : [],
      query: pendingQuery,
    })
    setQuery(pendingQuery)
    setIsVaultAssistantModalOpen(false)
    navigate(BaseAppPath.Assistant, { state: { skipReset: true } })
  }

  const dialogDescription = isFileExplorerOpen
    ? VAULT_SOURCE_SELECT_FILES_DESCRIPTION
    : DIALOG_DESCRIPTION

  return (
    <Dialog
      open={isVaultAssistantModalOpen}
      onOpenChange={setIsVaultAssistantModalOpen}
    >
      <DialogContent
        hasContainer={isFileExplorerOpen}
        innerClassName="flex flex-col p-0"
      >
        <DialogHeader className="px-6 pt-6">
          <DialogTitle>{DIALOG_TITLE}</DialogTitle>
          <DialogDescription>{dialogDescription}</DialogDescription>
        </DialogHeader>
        {isFileExplorerOpen && (
          <VaultFileExplorer
            isAddingFilesToQuery
            preselectFileIds={preselectFileIds}
            projectId={currentProjectMetadata.id}
          />
        )}
        <DialogFooter className="px-6 pb-6">
          <DialogClose asChild>
            <Button variant="outline" onClick={closeHandler}>
              Cancel
            </Button>
          </DialogClose>
          {isFileExplorerOpen ? (
            <Button
              disabled={preselectFileIds?.size === 0}
              onClick={handleOpenProjectInAssistant}
            >
              Select
            </Button>
          ) : (
            <Button onClick={handleOpenFileExplorer}>
              Open project in Assistant
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default VaultAssistantModal
