import React from 'react'

import { site } from 'utils/server-data'
import { getSiteName } from 'utils/site'
import { replaceSpacesWithDashes } from 'utils/string'
import { cn } from 'utils/utils'

import { SettingsPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import { AllenOveryWorkspaceId } from 'components/special-ids'
import { Separator } from 'components/ui/separator'

import SettingsNavItem, { SettingsNavItemProps } from './settings-nav-item'
import { useSettingsState } from './settings-store'

const SettingsSidebar: React.FC = () => {
  const userInfo = useAuthUser()
  const settingsUser = useSettingsState((s) => s.settingsUser)

  const userSettingsNavItems: SettingsNavItemProps[] = [
    {
      label: 'Workspace',
      href: SettingsPath.Workspace,
      available: settingsUser?.IsWorkspaceInfoViewer ?? false,
    },
    {
      label: 'Client matters',
      href: SettingsPath.ClientMatters,
      available: settingsUser?.isClientMattersReadUser ?? false,
    },
    {
      label: 'Sharing',
      href: SettingsPath.Sharing,
      available: settingsUser?.IsSharingManagementUser ?? false,
    },
  ]
  const hasUserSettings = userSettingsNavItems.some(
    (item) => item.available === true
  )

  const adminSettingsNavItems: SettingsNavItemProps[] = [
    {
      label: 'Usage',
      href: SettingsPath.Usage,
      available:
        (settingsUser?.IsUsageDashboardViewer ||
          settingsUser?.IsUsageDashboardV2Viewer) ??
        false,
    },
    {
      label: 'Users',
      href: SettingsPath.Users,
      available:
        (settingsUser?.IsClientAdminViewUsers &&
          settingsUser.workspace?.id !== AllenOveryWorkspaceId) ??
        false,
    },
    {
      label: 'Workspace history',
      href: SettingsPath.WorkspaceHistory,
      available:
        (settingsUser?.IsWorkspaceHistoryReader &&
          settingsUser.IsHistoryUser) ??
        false,
    },
  ]
  const hasAdminSettings = adminSettingsNavItems.some(
    (item) => item.available === true
  )

  const internalAdminSettingsNavItems: SettingsNavItemProps[] = [
    {
      label: 'Workspaces',
      href: SettingsPath.InternalAdminWorkspaces,
      available: userInfo.IsInternalAdminReader,
    },
    {
      label: 'Permissions',
      href: SettingsPath.InternalAdminPermissions,
      available: userInfo.IsInternalAdminReader,
    },
    {
      label: 'User management',
      href: SettingsPath.InternalAdminUserManagement,
      available: userInfo.IsInternalAdminReader,
    },
    {
      label: 'Experiment management',
      href: SettingsPath.InternalAdminExperimentManagement,
      available: userInfo.IsResponseComparisonAdmin,
    },
    {
      label: 'PWC tax feedback export',
      href: SettingsPath.InternalAdminPwcTaxExport,
      available: userInfo.IsPWCTaxFeedbackExportUser,
    },
    {
      label: 'Library items manager',
      href: SettingsPath.InternalAdminLibraryEventsManager,
      available: userInfo.IsLibraryCreateHarveyItemsUser,
    },
    {
      label: 'Incident management',
      href: SettingsPath.InternalAdminIncidentManagement,
      available: userInfo.IsInternalAdminWriter,
    },
  ]

  return (
    <div className="flex w-48 flex-col">
      <div>
        {hasUserSettings && (
          <div>
            <p className="mb-1.5 text-sm font-semibold ">Settings</p>
            <div className="space-y-1">
              {userSettingsNavItems
                .filter((item) => item.available)
                .map((item) => (
                  <SettingsNavItem
                    key={item.label}
                    id={`user-settings-nav-item-${replaceSpacesWithDashes(
                      item.label
                    )}`}
                    label={item.label}
                    icon={item.icon}
                    href={item.href}
                    comingSoon={item.comingSoon}
                  />
                ))}
            </div>
          </div>
        )}
        {hasAdminSettings && (
          <div className={cn({ 'mt-6': hasUserSettings })}>
            <p className="mb-1.5 text-sm font-semibold ">Admin</p>
            <div className="space-y-1">
              {adminSettingsNavItems
                .filter((item) => item.available)
                .map((item) => (
                  <SettingsNavItem
                    key={item.label}
                    id={`admin-settings-nav-item-${replaceSpacesWithDashes(
                      item.label
                    )}`}
                    label={item.label}
                    icon={item.icon}
                    href={item.href}
                    search={item.search}
                    comingSoon={item.comingSoon}
                  />
                ))}
            </div>
          </div>
        )}
        {userInfo.IsInternalAdminReader && (
          <div
            className={cn('space-y-4', {
              'mt-4': hasAdminSettings || hasUserSettings,
            })}
          >
            {(hasAdminSettings || hasUserSettings) && (
              <Separator className="-ml-3" />
            )}
            <div>
              <p className="mb-1.5 items-center text-sm font-semibold">{`Internal admin (${getSiteName(
                site
              )})`}</p>
              <div className="space-y-1">
                {internalAdminSettingsNavItems
                  .filter((item) => item.available)
                  .map((item) => (
                    <SettingsNavItem
                      key={item.label}
                      label={item.label}
                      icon={item.icon}
                      href={item.href}
                      comingSoon={item.comingSoon}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SettingsSidebar
