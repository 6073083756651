import React from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import useChangeHistory from 'components/common/use-change-history'
import PromptButtonGroup from 'components/library/prompt-button-group'
import { Textarea } from 'components/ui/text-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import { useAssistantStore } from './assistant-store'

interface AssistantInputTextProps {
  queryLimit: number
  inputTextareaRef: React.MutableRefObject<HTMLTextAreaElement | null>
}

const AssistantInput = ({
  queryLimit,
  inputTextareaRef,
}: AssistantInputTextProps): JSX.Element => {
  const userInfo = useAuthUser()
  const [query, queryPreview, isLoading, setQuery, setQueryPreview] =
    useAssistantStore(
      useShallow((s) => [
        s.query,
        s.queryPreview,
        s.isLoading,
        s.setQuery,
        s.setQueryPreview,
      ])
    )
  const { handleKeyDown, setValue } = useChangeHistory(setQuery)

  const nonPreviewQuery = queryPreview ? '' : query

  return (
    <div
      className="flex h-full flex-col bg-primary p-1 ring-inset ring-ring ring-offset-primary has-[:focus]:ring-2"
      data-testid="assistant--input-panel"
    >
      <Textarea
        ref={inputTextareaRef}
        className={cn(
          'focus-visible:ring-none min-h-8 grow resize-none border-none px-3 pt-3 focus-visible:ring-transparent',
          { 'disabled:cursor-default': queryPreview }
        )}
        id="assistant-input"
        data-testid="assistant--input"
        value={nonPreviewQuery}
        placeholder={queryPreview || 'Enter your query here'}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
        maxLength={queryLimit}
        disabled={isLoading || !!queryPreview}
      />
      <div className="mb-2 flex h-8 flex-row items-center justify-between px-2 pt-1">
        {userInfo.IsLibraryUser && (
          <PromptButtonGroup
            query={query}
            setQuery={(q) => {
              setValue(q)
              setTimeout(() => inputTextareaRef.current?.focus())
            }}
            setQueryPreview={setQueryPreview}
            queryLimit={queryLimit}
            eventKind={EventKind.OPEN_ENDED}
            saveDisabled={isLoading}
            loadDisabled={isLoading}
          />
        )}
        <Tooltip>
          <TooltipTrigger>
            <p className="tracking-tight text-muted/70">
              {query.length} / {queryLimit}
            </p>
          </TooltipTrigger>
          <TooltipContent>
            {query.length > queryLimit
              ? 'Maximum query length exceeded'
              : 'Maximum query length'}
          </TooltipContent>
        </Tooltip>
      </div>
    </div>
  )
}

export default AssistantInput
