import { toLower } from 'lodash'

import {
  PaginatedResponse,
  PaginationOptions,
} from 'models/queries/lib/use-paginated-query'
import Services from 'services'

import { LibraryItem, LibraryItemKind } from './library-types'

export const FetchLibraryItems = async (
  type: LibraryItemKind,
  pagination?: PaginationOptions
) => {
  const { pageNumber, pageSize, createdBefore } = pagination || {}
  const searchParams = new URLSearchParams()
  if (pageNumber) searchParams.append('page_number', pageNumber.toString())
  if (pageSize) searchParams.append('page_size', pageSize.toString())
  if (createdBefore) {
    searchParams.append('created_before_exclusive', createdBefore)
  }

  const requestPath = `library/${toLower(type)}${
    searchParams.size > 0 ? '?' + searchParams.toString() : ''
  }`
  const response = await Services.Backend.Get<PaginatedResponse<LibraryItem>>(
    requestPath,
    { throwOnError: true }
  )
  return response
}
