import { GridApi } from 'ag-grid-community'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { VaultFile } from 'openapi/models/VaultFile'
import { HistoryItem } from 'types/history'

import {
  QueryQuestions,
  ReviewColumn,
  ReviewEvent,
} from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'
import {
  useVaultStore,
  VaultCurrentStreamingState,
  VaultReviewSocketState,
} from 'components/vault/utils/vault-store'

import {
  GridTransactionAction,
  computeIsQueryLoading,
  addFilesToGrid,
} from './data-grid-helpers'

export type ReviewHistoryItem = HistoryItem &
  ReviewEvent &
  VaultReviewSocketState &
  VaultCurrentStreamingState
export interface VaultQueryDetailState {
  workflow: ReviewWorkflow | null
  useV1QueryDetail: boolean
  shouldRenderTable: boolean
  queryId: string
  isRunButtonLoading: boolean
  isQueryLoading: boolean
  isFetchingQuery: boolean
  historyItem: HistoryItem | ReviewHistoryItem | null | undefined
}

interface VaultDataGridState {
  gridApi: GridApi | null
  hasOnGridReadyExecuted: boolean
  initialHistoryItem: HistoryItem | ReviewHistoryItem | null | undefined
  initialPendingQueryFileIds: string[] | null
  initialPendingQueryQuestions: QueryQuestions[] | null
  pendingQueryFileIds: string[] | null
  pendingQueryQuestions: QueryQuestions[] | null
  currentPendingColumnId: string | null
}

export interface VaultQueryDetailAction {
  setUseV1QueryDetail: (useV1QueryDetail: boolean) => void
  setQueryId: (queryId: string) => void
  setIsRunButtonLoading: (isRunButtonLoading: boolean) => void
  setHistoryItem: (
    historyItem: HistoryItem | ReviewHistoryItem | null | undefined
  ) => void
}
export interface VaultDataGridAction {
  setWorkflow: (workflow: ReviewWorkflow | null) => void
  setGridApi: (gridApi: GridApi | null) => void
  setHasOnGridReadyExecuted: (hasOnGridReadyExecuted: boolean) => void
  setPendingQueryFileIds: (fileIds: string[] | null) => void
  addToPendingQueryFileIds: (fileIds: string[]) => void
  removeFileIdsFromPendingQueryFileIds: (fileIds: string[]) => void
  setPendingQueryQuestions: (questions: QueryQuestions[] | null) => void
  addToPendingQueryQuestions: (question: QueryQuestions) => void
  removeFromPendingQueryQuestions: (columnId: string) => void
  updatePendingQueryQuestion: (
    columnId: string,
    key: string,
    value: string
  ) => void
  setCurrentPendingColumnId: (columnId: string | null) => void
  updateColumnInHistoryItem: (column: ReviewColumn) => void
}

const useVaultQueryDetailStore = create(
  immer(
    devtools(
      immer<
        VaultQueryDetailState &
          VaultQueryDetailAction &
          VaultDataGridState &
          VaultDataGridAction
      >((set) => ({
        useV1QueryDetail: false,
        workflow: null,
        shouldRenderTable: true,
        queryId: 'new',
        isRunButtonLoading: false,
        isQueryLoading: false,
        isFetchingQuery: true,
        historyItem: null,
        gridApi: null,
        hasOnGridReadyExecuted: false,
        initialHistoryItem: null,
        initialPendingQueryFileIds: null,
        initialPendingQueryQuestions: null,
        pendingQueryFileIds: null,
        pendingQueryQuestions: null,
        currentPendingColumnId: null,
        setUseV1QueryDetail: (useV1QueryDetail: boolean) => {
          set((state) => {
            state.useV1QueryDetail = useV1QueryDetail
          })
        },
        setQueryId: (queryId: string) => {
          set((state) => {
            state.queryId = queryId
            state.isQueryLoading = queryId !== 'new'
            // if we are setting the queryId then we are not fetching the query
            // this is required to prevent vault-query-detail from flashing the null table
            state.isFetchingQuery = queryId === 'new'
          })
        },
        setIsRunButtonLoading: (isRunButtonLoading: boolean) => {
          set((state) => {
            state.isRunButtonLoading = isRunButtonLoading
          })
        },
        setGridApi: (gridApi: GridApi | null) => {
          set((state) => {
            state.gridApi = gridApi
          })
        },
        setHasOnGridReadyExecuted: (hasOnGridReadyExecuted: boolean) => {
          set((state) => {
            state.hasOnGridReadyExecuted = hasOnGridReadyExecuted
          })
        },
        setHistoryItem: (
          historyItem: HistoryItem | ReviewHistoryItem | null | undefined
        ) => {
          set((state) => {
            const newQueryId = historyItem?.id ?? 'new'
            const newIsQueryLoading = computeIsQueryLoading(historyItem)

            state.historyItem = historyItem
            state.queryId = newQueryId
            state.isQueryLoading = newIsQueryLoading

            // if we are setting the historyItem to null then we should reset our state (when component unMounts)
            state.isFetchingQuery = newQueryId === 'new' ? true : !historyItem
            state.hasOnGridReadyExecuted = !historyItem
              ? false
              : state.hasOnGridReadyExecuted
            state.initialHistoryItem = !historyItem
              ? historyItem
              : !state.initialHistoryItem
              ? historyItem
              : state.initialHistoryItem

            const reviewEvent = historyItem as ReviewHistoryItem
            // when streaming we want to use the historyItem to update row data
            // only after the hasOnGridReadyExecuted is true, we know that the grid is ready
            if (state.gridApi && historyItem && state.hasOnGridReadyExecuted) {
              const setAgGridHeaderHeight =
                useVaultDataGridFilterStore.getState().setAgGridHeaderHeight
              const folderIdToVaultFolder =
                useVaultStore.getState().folderIdToVaultFolder
              const fileIdToVaultFile =
                useVaultStore.getState().fileIdToVaultFile

              // update columnDefs with columnId
              const currentColumnDefs = state.gridApi?.getColumnDefs()
              const updatedColumnDefs = currentColumnDefs?.map((colDef) => {
                const isIdInColDef = 'colId' in colDef
                if (!isIdInColDef) return colDef
                const colId = colDef.colId
                const historyItemColumn = reviewEvent.columns?.find(
                  (column) => column.displayId.toString() === colId
                )
                if (historyItemColumn) {
                  return {
                    ...colDef,
                    eventId: reviewEvent.id,
                    columnId: historyItemColumn.id.toString(),
                  }
                }
                return colDef
              })
              state.gridApi?.setGridOption('columnDefs', updatedColumnDefs)

              const historyFilesToAdd =
                (reviewEvent?.fileIds
                  ?.map((fileId: string) => fileIdToVaultFile[fileId])
                  .filter(Boolean) as VaultFile[]) ?? []

              addFilesToGrid({
                gridApi: state.gridApi as GridApi<any>,
                setAgGridHeaderHeight: setAgGridHeaderHeight,
                files: historyFilesToAdd,
                folderIdToVaultFolder: folderIdToVaultFolder,
                isLoading: newIsQueryLoading,
                gridAction: GridTransactionAction.UPDATE,
                isDryRun: reviewEvent.dryRun,
                fileIdToSources: reviewEvent.fileIdToSources,
                fileIdToAnswers: reviewEvent.answers,
                fileIdToErrors: reviewEvent.errors,
                questions: reviewEvent.questions,
                suppressedFileIdsSet: new Set(reviewEvent.suppressedFileIds),
              })
            }
          })
        },
        setWorkflow: (workflow: ReviewWorkflow | null) => {
          set((state) => {
            state.workflow = workflow
          })
        },
        setPendingQueryFileIds: (fileIds: string[] | null) => {
          set((state) => {
            state.initialPendingQueryFileIds = fileIds
            state.pendingQueryFileIds = fileIds
          })
        },
        removeFileIdsFromPendingQueryFileIds: (fileIds: string[]) => {
          set((state) => {
            state.pendingQueryFileIds =
              state.pendingQueryFileIds?.filter(
                (fileId) => !fileIds.includes(fileId)
              ) ?? null
          })
        },
        addToPendingQueryFileIds: (fileIds: string[]) => {
          set((state) => {
            state.pendingQueryFileIds = [
              ...(state.pendingQueryFileIds ?? []),
              ...fileIds,
            ]
          })
        },
        setPendingQueryQuestions: (questions: QueryQuestions[] | null) => {
          set((state) => {
            state.initialPendingQueryQuestions = questions
            state.pendingQueryQuestions = questions
          })
        },
        addToPendingQueryQuestions: (question: QueryQuestions) => {
          set((state) => {
            ;(state.currentPendingColumnId = question.id),
              (state.pendingQueryQuestions = [
                ...(state.pendingQueryQuestions ?? []),
                question,
              ])
          })
        },
        removeFromPendingQueryQuestions: (columnId: string) => {
          set((state) => {
            if (state.pendingQueryQuestions) {
              // Filter out the deleted question
              const filteredQuestions = state.pendingQueryQuestions.filter(
                (question) => question.id !== columnId
              )

              // Update the IDs of the remaining questions
              const updatedQuestions = filteredQuestions.reduce(
                (acc, question) => {
                  const currentId = parseInt(question.id)
                  const newId =
                    currentId > parseInt(columnId)
                      ? (currentId - 1).toString()
                      : question.id
                  acc.push({ ...question, id: newId })
                  return acc
                },
                [] as QueryQuestions[]
              )

              state.pendingQueryQuestions = updatedQuestions
            } else {
              state.pendingQueryQuestions = null
            }
          })
        },
        updatePendingQueryQuestion: (
          columnId: string,
          key: string,
          value: string
        ) => {
          set((state) => {
            state.pendingQueryQuestions = state.pendingQueryQuestions
              ? state.pendingQueryQuestions?.map((question) => {
                  if (question.id === columnId) {
                    return {
                      ...question,
                      [key]: value,
                    }
                  }
                  return question
                })
              : null
          })
        },
        setCurrentPendingColumnId: (columnId: string | null) => {
          set((state) => {
            state.currentPendingColumnId = columnId
          })
        },
        updateColumnInHistoryItem: (column: ReviewColumn) => {
          set((state) => {
            const reviewEvent = state.historyItem as ReviewHistoryItem
            reviewEvent.columns = reviewEvent.columns?.map((existingColumn) => {
              if (existingColumn.id === column.id) {
                return column
              }
              return existingColumn
            })
            state.historyItem = reviewEvent
          })
        },
      }))
    )
  )
)

export default useVaultQueryDetailStore
