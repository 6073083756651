import React, { useMemo, useState } from 'react'

import { Row, Table } from '@tanstack/react-table'
import _ from 'lodash'
import {
  MoreHorizontal,
  FolderClosed,
  FolderOpen,
  UploadCloud,
  CloudOff,
} from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import Services from 'services'

import { bytesToPreciseReadable, bytesToReadable } from 'utils/file-utils'
import { EM_DASH, backendToLocalFull, backendToReadable, cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import FolderShieldIcon from 'components/ui/icons/folder-shield-icon'
import { Spinner } from 'components/ui/spinner'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import VaultDocumentClassification from 'components/vault/components/vault-document-classification'
import useRetryHandler from 'components/vault/hooks/use-retry-handler'
import {
  VaultItem,
  VaultItemStatus,
  VaultItemType,
  VaultItemWithIndex,
} from 'components/vault/utils/vault'
import { HIGHLIGHT_COLOR } from 'components/vault/utils/vault'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getContentTypeDisplayString,
  getFileIcon,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizePages } from 'components/vault/utils/vault-text-utils'

import VaultLocationBreadcrumbs from './vault-location-breadcrumbs'

const NUBMER_KEY = 'numPages'

const isVaultItemLocalOnly = (item: VaultItem) =>
  item.status === VaultItemStatus.uploading ||
  // If the item is a file and it has no path (not uploaded yet), it is local only
  (item.type === VaultItemType.file && !item.data.path)

const isVaultItemDisabled = (item: VaultItem) =>
  item.status === VaultItemStatus.failedToUpload ||
  item.status === VaultItemStatus.recoverableFailure ||
  item.status === VaultItemStatus.unrecoverableFailure ||
  item.disabled

interface TableProps {
  table: Table<VaultItem>
}

interface CellProps {
  row: Row<VaultItem>
  setLastSelectedRow?: (row: VaultItemWithIndex) => void
  handleShiftSelection?: (row: Row<VaultItem>) => void
}

const BaseCell: React.FC<{
  text: string
  tooltipContent: string
  tooltipAlign: 'top' | 'bottom'
  wrapInBadge?: boolean
  'data-testid'?: string
  disabled?: boolean
  className?: string
  highlightText?: string
}> = ({
  text,
  tooltipContent,
  tooltipAlign,
  wrapInBadge,
  'data-testid': dataTestId,
  disabled,
  className,
  highlightText,
}) => {
  const highlightedText = useMemo(() => {
    if (_.isNil(highlightText)) {
      return <span>{text}</span>
    }

    const intervals = []
    let startIndex = 0
    while (startIndex < text.length) {
      const index = text
        .toLocaleLowerCase()
        .indexOf(highlightText.toLocaleLowerCase(), startIndex)
      if (index === -1) break
      intervals.push([index, index + highlightText.length])
      startIndex = index + 1
    }

    if (intervals.length === 0) {
      return <span>{text}</span>
    }

    const outputTextParts: React.ReactNode[] = []
    let currentIndex = 0

    intervals.forEach(([start, end]) => {
      // Push the text before the interval (if any)
      if (currentIndex < start) {
        outputTextParts.push(text.slice(currentIndex, start))
      }

      // Push the marked text
      outputTextParts.push(
        <mark
          key={start}
          className="rounded-sm py-1"
          style={{ backgroundColor: HIGHLIGHT_COLOR }}
        >
          {text.slice(start, end)}
        </mark>
      )

      // Update the current index
      currentIndex = end
    })

    if (currentIndex < text.length) {
      outputTextParts.push(text.slice(currentIndex))
    }

    return <span>{outputTextParts}</span>
  }, [highlightText, text])

  const tooltipTrigger = wrapInBadge ? (
    <Badge data-testid={dataTestId} variant="secondary">
      <p className={cn('line-clamp-1 break-all text-start text-sm', className)}>
        {highlightedText}
      </p>
    </Badge>
  ) : (
    <p
      data-testid={dataTestId}
      className={cn('line-clamp-1 break-all text-start text-sm', className, {
        'cursor-not-allowed text-muted': disabled,
      })}
    >
      {highlightedText}
    </p>
  )

  if (_.isEmpty(tooltipContent)) return tooltipTrigger

  return (
    <Tooltip>
      <TooltipTrigger asChild>{tooltipTrigger}</TooltipTrigger>
      <TooltipContent side={tooltipAlign} className="max-w-96">
        {tooltipContent}
      </TooltipContent>
    </Tooltip>
  )
}

const VaultSelectHeader: React.FC<TableProps> = ({ table }) => {
  const hasRowsSelected =
    table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()
  return (
    <div className="flex w-6 justify-start">
      <Button
        variant="ghost"
        size="smIcon"
        onClick={(e) => {
          e.stopPropagation()
          table.toggleAllRowsSelected(!hasRowsSelected)
        }}
      >
        <Checkbox
          checked={hasRowsSelected}
          isIndeterminate={table.getIsSomeRowsSelected()}
          onCheckedChange={() => table.toggleAllRowsSelected(!hasRowsSelected)}
          aria-label="Select all"
        />
      </Button>
    </div>
  )
}

const VaultSelectCell: React.FC<CellProps> = ({
  row,
  setLastSelectedRow,
  handleShiftSelection,
}) => {
  const isNewSelected =
    row.getIsSelected() ||
    row.getIsSomeSelected() ||
    row.getIsAllSubRowsSelected()
  const isSomeAlreadySelected =
    row.original.type === VaultItemType.folder
      ? row.original.isSomeAlreadySelected
      : false

  return (
    <div className="flex w-6 justify-start">
      <Button
        variant="ghost"
        size="smIcon"
        onClick={(e) => {
          e.stopPropagation()
          if (!row.getIsSelected() && setLastSelectedRow && !e.shiftKey) {
            // if row is being selected and the shift key is not pressed, set the last selected row to the current row
            setLastSelectedRow({
              ...row.original,
              index: String(row.index),
            })
            row.toggleSelected(!row.getIsSelected())
          } else if (!row.getIsSelected() && handleShiftSelection) {
            // if row is being selected and the shift key is pressed, use shift selection
            handleShiftSelection(row)
          } else {
            row.toggleSelected(!row.getIsSelected())
          }
        }}
      >
        <Checkbox
          checked={
            isNewSelected ||
            row.original.isAlreadySelected ||
            isSomeAlreadySelected
          }
          disabled={row.original.disabled}
          isIndeterminate={
            row.getIsSomeSelected() ||
            (isSomeAlreadySelected &&
              !row.original.isAlreadySelected &&
              !isNewSelected)
          }
          onCheckedChange={row.getToggleSelectedHandler()}
          aria-label="Select row"
        />
      </Button>
    </div>
  )
}

const VaultNameCell: React.FC<CellProps> = ({ row }) => {
  const searchValue = useVaultFileExplorerStore(
    useShallow((state) => state.searchValue)
  )

  const icon =
    row.original.type === VaultItemType.file ? (
      <Icon
        icon={getFileIcon(row.original.data)}
        className="mr-2 h-3.5 w-3.5 shrink-0"
      />
    ) : row.original.type === VaultItemType.folder ? (
      row.getIsExpanded() ? (
        <FolderOpen size={14} className="mr-2 shrink-0" />
      ) : (
        <FolderClosed size={14} className="mr-2 shrink-0" />
      )
    ) : (
      <FolderShieldIcon className="mr-2 h-3.5 w-3.5 shrink-0" />
    )
  return (
    <div
      className={cn('flex w-full items-center text-left', {
        'cursor-not-allowed text-muted': isVaultItemDisabled(row.original),
      })}
      style={{ paddingLeft: `${row.depth * 16 + 10}px` }}
    >
      {icon}
      <BaseCell
        text={row.original.name}
        tooltipContent={row.original.name}
        tooltipAlign="top"
        data-testid="vault-table--cell--name"
        disabled={isVaultItemDisabled(row.original)}
        highlightText={searchValue}
      />
      {/* Show the status icon only if the row is collapsed */}
      {!row.getIsExpanded() && (
        <div className="ml-1 mr-2 flex shrink-0 items-center">
          {statusIconForRow(row)}
        </div>
      )}
    </div>
  )
}

interface TimeCellProps extends CellProps {
  timeKey: 'createdAt' | 'updatedAt'
}

const VaultTimeCell: React.FC<TimeCellProps> = ({ row, timeKey }) => {
  const time = row.getValue(timeKey) as string
  return (
    <BaseCell
      text={
        isVaultItemLocalOnly(row.original) ? EM_DASH : backendToReadable(time)
      }
      tooltipContent={
        isVaultItemLocalOnly(row.original) ? '' : backendToLocalFull(time)
      }
      tooltipAlign="top"
      data-testid="vault-table--cell--created-at"
      disabled={isVaultItemDisabled(row.original)}
      className="px-2.5 text-muted"
    />
  )
}

const VaultContentTypeCell: React.FC<CellProps> = ({ row }) => {
  const text = getContentTypeDisplayString(row.original)
  return (
    <BaseCell
      text={text}
      tooltipContent={text}
      tooltipAlign="top"
      className="px-2.5 text-muted"
    />
  )
}

const VaultSizeCell: React.FC<CellProps> = ({ row }) => {
  const count = row.original[NUBMER_KEY]
  const countText = _.isNumber(count) ? pluralizePages(count).trim() : null

  const size = row.getValue('size') as number
  const sizeText = _.isNumber(size) ? bytesToReadable(size as number) : EM_DASH
  const tooltipContent = _.isNumber(size)
    ? bytesToPreciseReadable(size as number)
    : ''

  const displayText = countText ? `${sizeText} (${countText})` : sizeText
  const displayToolTip = countText
    ? `${tooltipContent} (${countText})`
    : tooltipContent

  return (
    <BaseCell
      text={displayText}
      tooltipContent={displayToolTip}
      tooltipAlign="top"
      data-testid="vault-table--cell--size"
      disabled={isVaultItemDisabled(row.original)}
      className="px-2.5 text-muted"
    />
  )
}

const statusIconForRow = (row: Row<VaultItem>) => {
  const status = row.original.status
  let statusContent = null
  switch (status) {
    case VaultItemStatus.uploading:
      statusContent = IconWithTooltip(
        'Uploading…',
        UploadCloud,
        'animate-pulse stroke-background-accent'
      )
      break
    case VaultItemStatus.processing:
      statusContent = IconWithTooltip('Processing…')
      break
    case VaultItemStatus.failedToUpload:
    case VaultItemStatus.recoverableFailure:
    case VaultItemStatus.unrecoverableFailure: {
      let failureReason = row.original.failureReason?.trim() || ''
      if (failureReason.endsWith('.')) {
        failureReason = failureReason.slice(0, -1)
      }
      failureReason = `${failureReason}. It will not be included in the query.`
      if (status === VaultItemStatus.failedToUpload) {
        statusContent = IconWithTooltip(
          failureReason,
          CloudOff,
          'text-destructive'
        )
      } else {
        statusContent = IconWithTooltip(failureReason, AlertIcon)
      }
      break
    }
    case VaultItemStatus.allFilesFailed:
      statusContent = IconWithTooltip(
        'All files in this folder failed to be processed. They will not be included in the query.',
        AlertIcon
      )
      break
    case VaultItemStatus.readyToQueryWithFailedFiles:
      statusContent = IconWithTooltip(
        'Ready to query (some files failed to be processed)',
        AlertIcon
      )
      break
    case VaultItemStatus.readyToQuery:
      statusContent = null
      break
    default:
      statusContent = null
  }
  return statusContent
}

// Helper function to create an icon with a tooltip.
// If no icon is provided, a spinner is shown.
const IconWithTooltip = (
  tooltip: string,
  Icon?: React.FC<{ className?: string }>,
  className?: string
) => {
  return (
    <Tooltip>
      <TooltipTrigger>
        {Icon ? (
          <Icon className={cn('h-4 w-4', className)} />
        ) : (
          <Spinner className={cn('mx-0 h-4 w-4', className)} />
        )}
      </TooltipTrigger>
      <TooltipContent side="right" className="max-w-96">
        {tooltip}
      </TooltipContent>
    </Tooltip>
  )
}

const MenuDropdown = ({
  row,
  onMenuDropdownChangeHandler,
  dropdownAlign = 'end',
}: {
  row: VaultItem
  onMenuDropdownChangeHandler?: (isOpen: boolean) => void
  dropdownAlign?: 'start' | 'end'
}) => {
  const { trackEvent } = useAnalytics()
  const fileIdToVaultFile = useVaultStore((s) => s.fileIdToVaultFile)
  const setCurrentUploadFilesFolderId = useVaultStore(
    (s) => s.setCurrentUploadFilesFolderId
  )
  const setIsUploadFilesDialogOpen = useVaultStore(
    (s) => s.setIsUploadFilesDialogOpen
  )
  const setCurrentCreateFolderFolderId = useVaultStore(
    (s) => s.setCurrentCreateFolderFolderId
  )
  const setIsCreateFolderDialogOpen = useVaultStore(
    (s) => s.setIsCreateFolderDialogOpen
  )
  const setDeleteRecords = useVaultStore((s) => s.setDeleteRecords)
  const setIsDeleteDialogOpen = useVaultStore((s) => s.setIsDeleteDialogOpen)
  const setIsRenameDialogOpen = useVaultStore((s) => s.setIsRenameDialogOpen)
  const setRenameRecord = useVaultStore((s) => s.setRenameRecord)
  const setIsMoveDialogOpen = useVaultStore((s) => s.setIsMoveDialogOpen)
  const setMoveRecords = useVaultStore((s) => s.setMoveRecords)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const onOpenChangeHandler = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen)
    onMenuDropdownChangeHandler && onMenuDropdownChangeHandler(isOpen)
  }

  const onMoveHandler = () => {
    setMoveRecords([row])
    setIsMoveDialogOpen(true)
  }

  const onDeleteHandler = () => {
    setDeleteRecords([row])
    setIsDeleteDialogOpen(true)
  }

  const onRenameHandler = () => {
    setRenameRecord(row)
    setIsRenameDialogOpen(true)
  }

  const { onRetryHandler } = useRetryHandler()
  const onRetry = async () => {
    await onRetryHandler([row.id])
  }

  const onUploadFilesHandler = () => {
    if (row.type !== VaultItemType.file) {
      setCurrentUploadFilesFolderId(row.data.id)
      setIsUploadFilesDialogOpen(true)
    }
  }
  const onCreateFolderHandler = () => {
    if (row.type !== VaultItemType.file) {
      setCurrentCreateFolderFolderId(row.data.id)
      setIsCreateFolderDialogOpen(true)
    }
  }

  const editEnabled = useMemo(() => {
    // if the row is a file then we have to ensure that the file is not being uploaded
    if (row.type === VaultItemType.file) {
      // Add a check that the path for the file exists, otherwise it is being uploaded (we cannot edit an uploading file)
      const vaultFile = fileIdToVaultFile[row.id]
      return vaultFile && vaultFile.path
    }
    // if the row is a folder then we can edit
    return true
  }, [row, fileIdToVaultFile])

  const shouldShowUploadFiles = useMemo(() => {
    return row.type === VaultItemType.folder
  }, [row])

  const shouldShowCreateFolder = useMemo(() => {
    return row.type === VaultItemType.folder
  }, [row])

  const shouldShowRetry = useMemo(() => {
    // Only show retry for recoverable failure files that have a path (uploaded successfully)
    return (
      row.status === VaultItemStatus.recoverableFailure &&
      row.type === VaultItemType.file
    )
  }, [row])

  const shouldShowMove = useMemo(() => {
    return row.type === VaultItemType.file || row.type === VaultItemType.folder
  }, [row])

  return (
    <DropdownMenu onOpenChange={onOpenChangeHandler}>
      <DropdownMenuTrigger asChild>
        <div className="flex flex-grow justify-end">
          <Button
            variant="ghost"
            size="smIcon"
            className={cn({
              'bg-button-secondary text-primary': isDropdownOpen,
            })}
            data-testid="vault-table--cell--menu"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <Icon icon={MoreHorizontal} />
          </Button>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align={dropdownAlign}>
        {shouldShowUploadFiles && (
          <DropdownMenuItem
            onClick={(event) => {
              const key = `${row.type}_id`
              Services.HoneyComb.Record({
                metric: `ui.vault_upload_files_${row.type}_event`,
                [key]: row.id,
              })
              trackEvent(`Vault ${_.upperFirst(row.type)} Uploaded Files`, {
                [key]: row.id,
              })
              event.stopPropagation()
              onUploadFilesHandler()
            }}
            data-testid="vault-table--cell--menu--upload"
          >
            Upload files
          </DropdownMenuItem>
        )}
        {shouldShowCreateFolder && (
          <DropdownMenuItem
            onClick={(event) => {
              const key = `${row.type}_id`
              Services.HoneyComb.Record({
                metric: `ui.vault_create_folder_${row.type}_event`,
                [key]: row.id,
              })
              trackEvent(`Vault ${_.upperFirst(row.type)} Created Folder`, {
                [key]: row.id,
              })
              event.stopPropagation()
              onCreateFolderHandler()
            }}
            data-testid="vault-table--cell--menu--create-folder"
          >
            Create new folder
          </DropdownMenuItem>
        )}
        {(shouldShowUploadFiles || shouldShowCreateFolder) && (
          <DropdownMenuSeparator />
        )}
        {shouldShowRetry && (
          <DropdownMenuItem
            onClick={async (event) => {
              Services.HoneyComb.Record({
                metric: 'ui.vault_retry_file_event',
                file_id: row.id,
              })
              trackEvent('Vault File Retried', {
                file_id: row.id,
              })
              event.stopPropagation()
              await onRetry()
            }}
          >
            Retry
          </DropdownMenuItem>
        )}
        {shouldShowMove && (
          <DropdownMenuItem
            disabled={!editEnabled}
            onClick={(event) => {
              const key = `${row.type}_id`
              Services.HoneyComb.Record({
                metric: `ui.vault_move_${row.type}_event`,
                [key]: row.id,
              })
              trackEvent(`Vault ${_.upperFirst(row.type)} Moved`, {
                [key]: row.id,
              })
              event.stopPropagation()
              onMoveHandler()
            }}
            data-testid="vault-table--cell--menu--move"
          >
            Move
          </DropdownMenuItem>
        )}
        <DropdownMenuItem
          disabled={!editEnabled}
          onClick={(event) => {
            const key = `${row.type}_id`
            Services.HoneyComb.Record({
              metric: `ui.vault_rename_${row.type}_event`,
              [key]: row.id,
            })
            trackEvent(`Vault ${_.upperFirst(row.type)} Renamed`, {
              [key]: row.id,
            })
            event.stopPropagation()
            onRenameHandler()
          }}
        >
          Rename {row.type}
        </DropdownMenuItem>
        {/* TODO: re-enable when we want to allow editing project client matter
        {row.type === VaultItemType.project &&
          setIsEditClientMatterDialogOpen && (
            <VaultProjectClientMatterMenuItem
              row={row}
              setIsEditClientMatterDialogOpen={setIsEditClientMatterDialogOpen}
            />
          )} */}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={(event) => {
            const key = `${row.type}_id`
            Services.HoneyComb.Record({
              metric: `ui.vault_delete_${row.type}_event`,
              [key]: row.id,
            })
            trackEvent(`Vault ${_.upperFirst(row.type)} Deleted`, {
              [key]: row.id,
            })
            event.stopPropagation()
            onDeleteHandler()
          }}
          className="text-destructive"
        >
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

// TODO: re-enable when we want to allow editing project client matter
// const VaultProjectClientMatterMenuItem = ({
//   row,
//   setIsEditClientMatterDialogOpen,
// }: {
//   row: VaultProjectItem
//   setIsEditClientMatterDialogOpen: (isOpen: boolean) => void
// }) => {
//   const { doesCurrentUserHaveFullAccessPermission } = useSharingPermissions({
//     projectId: row.data.id,
//   })

//   if (!doesCurrentUserHaveFullAccessPermission) {
//     return null
//   }

//   return (
//     <DropdownMenuItem
//       onClick={() => {
//         setIsEditClientMatterDialogOpen(true)
//       }}
//     >
//       Edit CM#
//     </DropdownMenuItem>
//   )
// }

const VaultMenuCell: React.FC<CellProps> = ({ row }) => {
  return <MenuDropdown row={row.original} />
}

const VaultLocationCell: React.FC<CellProps> = ({ row }) => {
  return <VaultLocationBreadcrumbs id={row.original.id} />
}

const VaultClassificationCell: React.FC<CellProps> = ({ row }) => {
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const isFilesProcessing =
    // If there are incomplete files, we want to poll for the update
    currentProjectMetadata.completedFiles !== currentProjectMetadata.totalFiles

  const fileId = row.original.id

  if (row.original.type !== VaultItemType.file) {
    return null
  }

  return (
    <VaultDocumentClassification
      fileId={fileId}
      isFilesProcessing={isFilesProcessing}
    />
  )
}

export {
  VaultItemStatus,
  VaultSelectHeader,
  VaultSelectCell,
  VaultNameCell,
  VaultTimeCell,
  VaultContentTypeCell,
  VaultSizeCell,
  VaultMenuCell,
  VaultLocationCell,
  VaultClassificationCell,
  MenuDropdown,
}
