import { useEffect, useMemo } from 'react'

import { isEmpty } from 'lodash'

import { Event } from 'models/event'
import { HarvQueryKeyPrefix } from 'models/queries/all-query-keys'
import { useWrappedQuery } from 'models/queries/lib/use-wrapped-query'
import { ReviewEventRunType } from 'openapi/models/ReviewEventRunType'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { ReviewEventRun } from 'components/vault/utils/vault'
import {
  FetchVaultHistoryByPage,
  FetchVaultHistoryByPageV2,
} from 'components/vault/utils/vault-fetcher'
import {
  mapReviewEventToEventV1Metadata,
  updateQueryStateForEvent,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

type UseRecentQueriesProps = {
  projectId: string
  maxQueries: number
  hasInProgressHistoryEvents: boolean
}

const useRecentQueries = ({
  projectId,
  maxQueries,
  hasInProgressHistoryEvents,
}: UseRecentQueriesProps) => {
  const userInfo = useAuthUser()
  const setTask = useVaultStore((s) => s.setTask)
  const setReviewTask = useVaultStore((s) => s.setReviewTask)
  const { trackEvent } = useAnalytics()

  const { data: historyData, isPending: isLoadingHistory } = useWrappedQuery({
    queryKey: [HarvQueryKeyPrefix.VaultHistoryQuery, projectId, maxQueries],
    queryFn: () =>
      FetchVaultHistoryByPage({
        currentPage: 1,
        // TODO: support more than 100 queries for a vault project
        // https://linear.app/harveyai/issue/PLT-1501/scale-vault-queries-page-to-support-100-queries-per-project
        pageSize: maxQueries + 1,
        vaultFolderId: projectId,
        threadOnly: userInfo.IsVaultV2User,
        shouldQueryV2: userInfo.IsVaultV2User || userInfo.IsVaultDualWriteUser,
        trackEvent,
      }),
    // Poll for history updates every 10 seconds if there are any in progress
    refetchInterval: hasInProgressHistoryEvents ? 10_000 : false,
    // Disable refetch on window focus to prevent unnecessary re-fetches
    refetchOnWindowFocus: false,
    enabled: !!projectId,
  })

  const { data: historyDataV2, isPending: isLoadingHistoryV2 } =
    useWrappedQuery({
      queryKey: [HarvQueryKeyPrefix.VaultHistoryQueryV2, projectId, maxQueries],
      queryFn: () =>
        FetchVaultHistoryByPageV2({
          currentPage: 1,
          pageSize: maxQueries + 1,
          vaultFolderId: projectId,
          trackEvent,
          shouldQueryV2:
            userInfo.IsVaultV2User || userInfo.IsVaultDualWriteUser,
        }),
      // Poll for history updates every 10 seconds if there are any in progress
      refetchInterval: hasInProgressHistoryEvents ? 10_000 : false,
      // Disable refetch on window focus to prevent unnecessary re-fetches
      refetchOnWindowFocus: false,
      enabled: !!projectId && userInfo.IsVaultV2User,
    })
  const historyDataV2ToV1 = useMemo(() => {
    if (!historyDataV2) {
      return { events: [], total: 0 }
    }
    return {
      events: historyDataV2.events.map((event) => {
        const metadata = mapReviewEventToEventV1Metadata(event)
        return {
          id: event.eventId,
          userId: event.eventCreatorEmail, // userId of the event v1 is the creator's email
          status: event.eventStatus,
          query:
            event.runs.find(
              (run: ReviewEventRun) => run.runType === ReviewEventRunType.NEW
            )?.query ?? '',
          response: '',
          kind: event.eventKind,
          created: event.eventCreatedAt,
          updatedAt: event.eventUpdatedAt,
          metadata: metadata,
          sources: [],
          ...metadata,
        } as Event
      }),
      total: historyDataV2.eventsCount,
    }
  }, [historyDataV2])

  useEffect(() => {
    if (historyData && !isEmpty(historyData.events)) {
      historyData.events.forEach((event: Event) => {
        updateQueryStateForEvent({ event, setTask, setReviewTask })
      })
    }
    if (!isEmpty(historyDataV2ToV1.events)) {
      historyDataV2ToV1.events.forEach((event: Event) => {
        updateQueryStateForEvent({
          event,
          setTask,
          setReviewTask,
          onlyInsertIfNotExists: true,
        })
      })
    }
  }, [historyData, historyDataV2ToV1, setTask, setReviewTask])

  return {
    historyData: userInfo.IsVaultV2User
      ? {
          events: [...historyDataV2ToV1.events, ...(historyData?.events ?? [])],
          total: historyDataV2ToV1.total + (historyData?.total ?? 0),
        }
      : historyData,
    isLoadingHistory: userInfo.IsVaultV2User
      ? isLoadingHistoryV2 || isLoadingHistory
      : isLoadingHistory,
  }
}

export default useRecentQueries
