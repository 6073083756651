import React, { useEffect, useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { useShallow } from 'zustand/react/shallow'

import { useGeneralStore } from 'stores/general-store'
import { PermissionLevel } from 'types/sharing'

import { doesUserHavePermission } from 'utils/sharing-helpers'
import { cn } from 'utils/utils'

import VaultFileExplorerToolbelt from './components/file-explorer/vault-file-explorer-toolbelt'
import VaultCustomWorkflow from './components/vault-query-box/vault-custom-workflow'
import VaultQueryBox from './components/vault-query-box/vault-query-box'
import VaultRecentQueries from './components/vault-recent-queries'
import VaultSectionBreadcrumb from './components/vault-section-breadcrumb'
import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import VaultFileExplorerActions from 'components/vault/components/file-explorer/vault-file-explorer-actions'
import VaultFileExplorerBreadcrumbs from 'components/vault/components/file-explorer/vault-file-explorer-breadcrumbs'
import VaultProgress from 'components/vault/components/vault-progress'
import {
  GenerateN1ResponseProps,
  GenerateNNResponseProps,
  GenerateQuestionsProps,
  projectDetailPageTabSearchParamKey,
} from 'components/vault/utils/vault'

import useSharingPermissions from './hooks/use-sharing-permissions'
import { QueryQuestionsResponseData } from './utils/vault'
import { useVaultSharingStore } from './utils/vault-sharing-store'
import { useVaultStore } from './utils/vault-store'
import VaultWorkflowList from './workflows/vault-workflow-list'

export enum VaultProjectDetailTabTypes {
  RECENT_QUERIES = 'queries',
  FILES = 'files',
}

const VaultProjectDetail = ({
  generateQuestions,
  generateNNResponse,
  generateN1Response,
}: {
  generateQuestions: (
    props: GenerateQuestionsProps
  ) => Promise<QueryQuestionsResponseData>
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
  generateN1Response: (props: GenerateN1ResponseProps) => Promise<void>
}) => {
  const { projectId } = useParams()
  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const [searchParams, setSearchParams] = useSearchParams()
  const currentProjectMetadata = useVaultStore(
    (state) => state.currentProjectMetadata
  )

  const isSidebarOpen = useGeneralStore(
    useShallow((state) => state.isSidebarOpen)
  )
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )
  const currentUserPermissionByProjectId = useVaultSharingStore(
    useShallow((s) => s.currentUserPermissionByProjectId)
  )
  const permissionsByProjectId = useVaultSharingStore(
    useShallow((s) => s.permissionsByProjectId)
  )
  const deleteVaultFolders = useVaultStore(
    useShallow((s) => s.deleteVaultFolders)
  )
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))

  const isExampleProject = useMemo(
    () => projectId && exampleProjectIds.has(projectId),
    [projectId, exampleProjectIds]
  )
  const setError = useVaultStore((state) => state.setError)

  useEffect(() => {
    const isCurrentUserNotProjectOwner =
      currentProjectMetadata.userId.trim() !== '' &&
      currentProjectMetadata.userId !== userInfo.dbId

    const isVaultSharingEnabled = userInfo.IsVaultViewSharesUser
    if (isVaultSharingEnabled) {
      // if sharing is enabled, only show error after fetching current user's project permission level
      if (
        isCurrentUserNotProjectOwner &&
        !isExampleProject &&
        projectId &&
        permissionsByProjectId[projectId] &&
        !doesUserHavePermission({
          currentPermissionLevel: currentUserPermissionByProjectId[projectId],
          requiredPermissionLevel: PermissionLevel.VIEW,
          isOwner: currentProjectMetadata.userId === userInfo.dbId,
        })
      ) {
        deleteVaultFolders([projectId])
        setError({
          message:
            'You are not authorized to access this vault project.\nContact support@harvey.ai if this issue persists.',
          cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
        })
      }
    } else {
      if (isCurrentUserNotProjectOwner && !isExampleProject) {
        deleteVaultFolders([projectId!])
        setError({
          message:
            'You are not authorized to access this vault project.\nContact support@harvey.ai if this issue persists.',
          cta: { redirectUri: BaseAppPath.Vault, message: 'Back to Vault' },
        })
      }
    }
  }, [
    currentProjectMetadata,
    userInfo,
    deleteVaultFolders,
    setError,
    isExampleProject,
    permissionsByProjectId,
    currentUserPermissionByProjectId,
    projectId,
  ])

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId,
  })
  const showCustomWorkflow = useMemo(() => {
    return (
      userInfo.IsVaultReviewUser &&
      userInfo.IsVaultWorkflowRepsWarrantiesUser &&
      !isExampleProject &&
      doesCurrentUserHaveEditPermission
    )
  }, [userInfo, isExampleProject, doesCurrentUserHaveEditPermission])

  const recentQueries = useMemo(() => {
    return Object.values(queryIdToState)
      .filter(Boolean)
      .filter((state) => state && state.vaultFolderId === projectId)
  }, [queryIdToState, projectId])

  const defaultTab =
    recentQueries.length > 0
      ? VaultProjectDetailTabTypes.RECENT_QUERIES
      : VaultProjectDetailTabTypes.FILES
  const tabParam = searchParams.get(
    projectDetailPageTabSearchParamKey
  ) as VaultProjectDetailTabTypes | null
  const selectedTab = tabParam ?? defaultTab
  const handleTabChange = (tab: string) => {
    setSearchParams(
      (prevParams) => {
        const newParams = new URLSearchParams(prevParams)
        newParams.set(projectDetailPageTabSearchParamKey, tab)
        return newParams
      },
      { replace: true }
    )
    trackEvent('Vault Project Detail Tab Selected', {
      selected_tab: tab,
    })
  }

  const shouldShowWorkflows =
    doesCurrentUserHaveEditPermission && !isExampleProject

  if (userInfo.IsVaultV2User) {
    return (
      <AppMain hasContainer>
        <VaultQueryBox
          generateQuestions={generateQuestions}
          generateNNResponse={generateNNResponse}
          generateN1Response={generateN1Response}
          shouldHideWhenUnfocused
          className={cn(
            'container fixed left-0 w-full translate-x-[35px] xl:max-w-none',
            {
              'pl-[158px]': isSidebarOpen,
            }
          )}
        />
        {shouldShowWorkflows && <VaultWorkflowList />}
        <div className={cn({ 'mt-8': shouldShowWorkflows })}>
          <Tabs
            className="mt-4"
            value={selectedTab}
            onValueChange={handleTabChange}
          >
            <TabsList variant="minimal" className="w-full border-b">
              <TabsTrigger
                id="vault-your-projects-tab"
                value={VaultProjectDetailTabTypes.RECENT_QUERIES}
                variant="minimal"
                className="border-b-0 font-medium"
              >
                <p className="text-sm first:font-medium">Recent queries</p>
              </TabsTrigger>
              <TabsTrigger
                id="vault-shared-projects-tab"
                value={VaultProjectDetailTabTypes.FILES}
                variant="minimal"
                className="font-medium"
              >
                <p className="text-sm first-line:font-normal">Project files</p>
              </TabsTrigger>
            </TabsList>
            <TabsContent value={VaultProjectDetailTabTypes.RECENT_QUERIES}>
              <VaultRecentQueries />
            </TabsContent>
            <TabsContent value={VaultProjectDetailTabTypes.FILES}>
              <div>
                <VaultSectionBreadcrumb
                  title={<VaultFileExplorerBreadcrumbs />}
                  trailingActions={[
                    <VaultFileExplorerActions key="file-explorer-actions" />,
                  ]}
                />
                <VaultProgress />
                <VaultFileExplorer projectId={projectId} />
              </div>
              <VaultFileExplorerToolbelt
                generateNNResponse={generateNNResponse}
                className={cn('fixed ml-24 max-w-[calc(100%-192px)]', {
                  'ml-8 max-w-[calc(100%-64px)]': !isSidebarOpen,
                })}
              />
            </TabsContent>
          </Tabs>
        </div>
      </AppMain>
    )
  }

  return (
    <AppMain hasContainer>
      {/* Mask to hide scroll text. Required because the query box is fixed position*/}
      <div
        className={cn('fixed left-48 right-4 z-10 -mt-4 h-4 bg-primary', {
          'left-16': !isSidebarOpen,
        })}
      />
      <VaultQueryBox
        generateQuestions={generateQuestions}
        generateNNResponse={generateNNResponse}
        generateN1Response={generateN1Response}
        className={cn('fixed left-48 right-0 z-50 w-auto', {
          'left-16': !isSidebarOpen,
        })}
      />
      <div className="translate-y-[78px] space-y-10 bg-primary">
        {showCustomWorkflow && <VaultCustomWorkflow />}
        <VaultRecentQueries />
        <div>
          <VaultSectionBreadcrumb
            title={<VaultFileExplorerBreadcrumbs />}
            trailingActions={[
              <VaultFileExplorerActions key="file-explorer-actions" />,
            ]}
          />
          <VaultProgress />
          <VaultFileExplorer projectId={projectId} />
        </div>
      </div>

      <VaultFileExplorerToolbelt
        generateNNResponse={generateNNResponse}
        className={cn('fixed ml-24 max-w-[calc(100%-192px)]', {
          'ml-8 max-w-[calc(100%-64px)]': !isSidebarOpen,
        })}
      />
    </AppMain>
  )
}

export default VaultProjectDetail
