import React from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import { Instance } from 'pspdfkit'

import { EventKind } from 'openapi/models/EventKind'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'
import { FileType } from 'types/file'

import { onDrop } from 'utils/dropzone'
import { feedbackClosedState } from 'utils/feedback'
import { createAcceptObject, mbToBytes, mbToReadable } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'
import { useDropzoneTrack } from 'utils/use-dropzone-track'
import { HarveySocketSetter } from 'utils/use-harvey-socket'

import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import { FeedbackResult } from 'components/common/feedback/feedback-with-comments'
import PdfViewer from 'components/common/pdf-viewer/pdf-viewer'

const MAX_FILES = 1
export const MAX_FILE_SIZE_MB = 20

interface WorkflowFileInputProps {
  store: () => {
    activeDocument: Maybe<UploadedFile | File>
    setActiveDocument: (document: null) => void
    setFeedback: (feedback: FeedbackResult | null) => void
    setTask: HarveySocketSetter
    isLoading: boolean
  }
  canMaximize?: boolean
  children: React.ReactNode
  eventKind: EventKind
  handleFile: (file: File) => void | Promise<void>
  pspdfInstanceRef: React.MutableRefObject<Maybe<Instance>>
  fileTypes?: FileType[]
  maxFileSizeMb?: number
  pdfViewerFooter?: React.ReactNode
  dropzoneDescription?: React.ReactNode
  onClear?: () => void
}

/* PDF Dropzone and PDF Viewer w/ varying input component */
const WorkflowFileInput: React.FC<WorkflowFileInputProps> = ({
  canMaximize = true,
  pspdfInstanceRef,
  store,
  children,
  eventKind,
  handleFile,
  fileTypes = [FileType.PDF, FileType.WORD],
  maxFileSizeMb = MAX_FILE_SIZE_MB,
  pdfViewerFooter,
  dropzoneDescription,
  onClear,
}) => {
  const [isMaximized, setIsMaximized] = React.useState(false)
  const { activeDocument, setActiveDocument, setFeedback, isLoading } = store()
  const [isFileLoading, setIsFileLoading] = React.useState(false)

  const onDocumentClear = () => {
    onClear?.()
    setActiveDocument(null)
    setFeedback(feedbackClosedState)
    setIsMaximized(false)
  }

  const recordFileDrop = useDropzoneTrack(eventKind)

  const onFileDrop = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) return
    setIsFileLoading(true)
    recordFileDrop(acceptedFiles)
    try {
      const file = acceptedFiles[0]
      await handleFile(file)
    } catch (e) {
      displayErrorMessage(
        'An error occurred while uploading your file. Please try again.'
      )
    } finally {
      setIsFileLoading(false)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: createAcceptObject(fileTypes),
    onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) =>
      onDrop({
        acceptedFiles,
        fileRejections,
        currentFileCount: 0,
        maxFiles: MAX_FILES,
        acceptedFileTypes: fileTypes,
        maxFileSize: mbToBytes(MAX_FILE_SIZE_MB),
        maxZipFileSize: 0,
        handleAcceptedFiles: onFileDrop,
      }),
    maxSize: mbToBytes(maxFileSizeMb),
    maxFiles: MAX_FILES,
  })

  const isFileSelected = !!activeDocument

  return (
    <div className="flex h-full flex-col space-y-2">
      {isFileSelected ? (
        <div className="flex h-full flex-col">
          <PdfViewer
            document={activeDocument ?? null}
            onClear={onDocumentClear}
            canClear={!isLoading}
            pspdfInstanceRef={pspdfInstanceRef}
            canMaximize={canMaximize}
            isMaximized={isMaximized}
            setIsMaximized={setIsMaximized}
            footerElement={pdfViewerFooter}
          />
        </div>
      ) : (
        <div className="h-full">
          <Dropzone
            dropzone={{ getRootProps, getInputProps }}
            isLoading={isFileLoading}
            description={
              dropzoneDescription ?? (
                <DropzoneDescription
                  fileTypes={fileTypes}
                  maxSize={mbToReadable(maxFileSizeMb)}
                />
              )
            }
          />
        </div>
      )}
      {isMaximized ? null : <div>{children}</div>}
    </div>
  )
}

export default WorkflowFileInput
