import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { isEmpty } from 'lodash'
import { X } from 'lucide-react'
import { isHash, isUUID } from 'validator'

import { Source as OpenApiSource } from 'openapi/models/Source'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { useAnnotationCaches } from 'hooks/use-annotation-caches'
import { Source } from 'utils/task'
import { cn, isAzureBlobPdf } from 'utils/utils'

import {
  FILE_ID_PARAM,
  incrementReset,
  MESSAGE_ID_PARAM,
  RESET_PARAM,
  SOURCE_ID_PARAM,
} from 'components/assistant-v2/utils/assistant-helpers'
import {
  applyAnnotations,
  applyFrontendAnnotations,
} from 'components/assistant/pspdfkit-helpers'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { getIsLoadingUrl } from 'components/common/pdf-viewer/helpers'
import PdfDocumentHeader from 'components/common/pdf-viewer/pdf-document-header'
import { PdfViewerPushSheet } from 'components/common/pdf-viewer/pdf-viewer-push-sheet'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Spinner } from 'components/ui/spinner'

export interface PushSheetMessage {
  messageId: string
  sources: OpenApiSource[]
}

interface Props {
  className?: string
  documents?: UploadedFile[]
  eventId?: string | null
  fileId?: string
  getDocument: (
    eventId: string | null,
    fileId: string
  ) => Promise<UploadedFile | undefined>
  messages?: PushSheetMessage[]
  sources: OpenApiSource[]
}

const PdfPushSheet: React.FC<Props> = ({
  className,
  documents,
  eventId,
  fileId,
  getDocument,
  messages,
  sources,
}) => {
  const { trackEvent } = useAnalytics()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [instance, isPdfLoading, setIsPdfLoading, isAnnotating] =
    usePDFViewerStore((s) => [
      s.instance,
      s.isPdfLoading,
      s.setIsPdfLoading,
      s.isAnnotating,
    ])
  const setIsAnnotating = usePDFViewerStore((s) => s.setIsAnnotating)
  const clearDocumentAnnotation = usePDFViewerStore(
    (s) => s.clearDocumentAnnotation
  )
  const {
    sourceAnnotationsRef,
    updateSourceAnnotationsRef,
    documentAnnotationsRef,
    updateDocumentAnnotationsRef,
  } = useAnnotationCaches()

  const [searchParams, setSearchParams] = useSearchParams()
  const [activeDocument, setActiveDocument] = useState<UploadedFile | null>(
    null
  )

  useEffect(() => {
    clearDocumentAnnotation()
  }, [activeDocument, clearDocumentAnnotation])

  const sourceId = searchParams.get(SOURCE_ID_PARAM)
  const messageId = searchParams.get(MESSAGE_ID_PARAM)
  const resetSource = searchParams.get(RESET_PARAM)

  const currentMessageSourceIds = useMemo(() => {
    if (messages === undefined) {
      return sources.map((source) => source.id)
    }

    return (
      messages.find((message) => message.messageId === messageId)?.sources ?? []
    ).map((source) => source.id)
  }, [messages, messageId, sources])

  const isLoadingUrl = getIsLoadingUrl(activeDocument)

  useEffect(() => {
    const handleAssistantV1Document = () => {
      if (documents === undefined) {
        return false
      }
      const doc = documents.find((doc) => doc.name === fileId)
      if (doc) {
        setIsPdfLoading(true)
        setActiveDocument(doc)
        return true
      }
      return false
    }
    const fetchDocument = async () => {
      if (fileId) {
        const document = await getDocument(eventId ?? null, fileId)
        if (document) {
          setIsPdfLoading(true)
          setActiveDocument(document)
        }
      } else {
        setActiveDocument(null)
      }
    }

    if (!handleAssistantV1Document()) void fetchDocument()
  }, [documents, fileId, eventId, getDocument, setIsPdfLoading])

  const handleResetFileId = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams)
      newParams.delete(FILE_ID_PARAM)
      newParams.delete(SOURCE_ID_PARAM)
      newParams.delete(MESSAGE_ID_PARAM)
      newParams.delete(RESET_PARAM)
      return newParams
    })
  }

  const handleSourceClick = (source: OpenApiSource) => {
    setSearchParams((prevParams) => {
      trackEvent('Citation Clicked', { location: 'pdf-viewer-panel' })
      const newParams = new URLSearchParams(prevParams)
      if (fileId) {
        newParams.set(FILE_ID_PARAM, fileId)
        newParams.set(SOURCE_ID_PARAM, source.id)
      } else {
        newParams.delete(FILE_ID_PARAM)
        newParams.delete(SOURCE_ID_PARAM)
      }
      if (sourceId === newParams.get(SOURCE_ID_PARAM)) {
        newParams.set(RESET_PARAM, incrementReset(resetSource))
      } else {
        newParams.delete(RESET_PARAM)
      }
      return newParams
    })
  }

  const activeSources = useMemo(
    () =>
      sources.filter(
        (source) =>
          source.documentName === activeDocument?.name &&
          currentMessageSourceIds.includes(source.id)
      ) as Source[],
    [sources, activeDocument, currentMessageSourceIds]
  )

  const selectedSource = useMemo(
    () => activeSources.find((source) => source.id === sourceId),
    [activeSources, sourceId]
  )

  const annotationsHandler = useCallback(async () => {
    const isFileId =
      fileId &&
      (isUUID(fileId) ||
        isAzureBlobPdf(fileId) ||
        // Cuatrecasas file id's are sha1 hashes
        isHash(fileId, 'sha1'))
    if (
      !activeDocument?.url ||
      isEmpty(activeSources) ||
      isPdfLoading ||
      (isFileId && activeDocument.id !== fileId) ||
      (!isFileId && activeDocument.name !== fileId) ||
      !instance
    )
      return

    const shouldUseBackendProvidedHighlights = selectedSource
      ? selectedSource.annotations?.length > 0
      : activeSources.some((source) => source.annotations?.length > 0)

    if (shouldUseBackendProvidedHighlights) {
      void applyAnnotations(
        activeDocument,
        activeSources,
        instance,
        selectedSource
      )
    } else {
      setIsAnnotating(true)
      await applyFrontendAnnotations({
        sources: activeSources,
        pdfkitInstance: instance,
        selectedSource,
        cachedSourceAnnotations: sourceAnnotationsRef.current,
        updateSourceAnnotationStore: updateSourceAnnotationsRef.current,
        cachedDocumentAnnotations: documentAnnotationsRef.current,
        updateDocumentAnnotationStore: updateDocumentAnnotationsRef.current,
      })
      setIsAnnotating(false)
    }
  }, [
    activeDocument,
    instance,
    activeSources,
    selectedSource,
    isPdfLoading,
    fileId,
    documentAnnotationsRef,
    sourceAnnotationsRef,
    updateDocumentAnnotationsRef,
    updateSourceAnnotationsRef,
    setIsAnnotating,
  ])

  useEffect(() => {
    void annotationsHandler()
  }, [annotationsHandler, resetSource])

  if (!fileId) return null

  return (
    <div className={cn('flex h-full flex-col', className)}>
      <div className="flex items-start justify-between border-b p-4">
        <PdfDocumentHeader
          name={activeDocument?.name}
          size={activeDocument?.size}
          sources={activeSources}
          onSourceClick={handleSourceClick}
          sourceIdParam={SOURCE_ID_PARAM}
        />
        <Button
          className="ml-auto shrink-0"
          onClick={handleResetFileId}
          size="xsIcon"
          variant="ghost"
        >
          <Icon icon={X} />
        </Button>
      </div>
      <div className="flex grow flex-col justify-center">
        <div className="relative h-full w-full">
          {isAnnotating && (
            <div
              className="absolute inset-0 z-10 flex h-full w-full items-center justify-center bg-primary bg-opacity-75"
              style={{ pointerEvents: 'none' }}
            >
              <Spinner />
            </div>
          )}
          {activeDocument && (
            <PdfViewerPushSheet
              document={activeDocument}
              containerRef={containerRef}
              isLoadingUrl={isLoadingUrl}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PdfPushSheet
