import type { QueryKey } from '@tanstack/react-query'

// Define an enum with your query keys
const enum HarvQueryKeyPrefix {
  ExamplesQuery = 'example_query_keyz_prefix',
  PromptQuery = 'prompts_query_keyz_prefix',
  HistoryQuery = 'history_query_keyz_prefix',
  HistoryItemQuery = 'history_item_query_keyz_prefix',
  VaultHistoryItemQuery = 'vault_history_item_query_keyz_prefix',
  HistoryPollingQuery = 'history_polling_query_keyz_prefix',
  UsaCaseLaw = 'usa_case_law_keyz_prefix',
  ResearchTaxonomiesQuery = 'research_taxonomies_query_keyz_prefix',
  SettingsClientMatters = 'settings_client_matters_query_keyz_prefix',
  ClientMatters = 'client_matters_query_keyz_prefix',
  DiligenceSections = 'diligence_sections_query_keyz_prefix',
  LibraryQuery = 'library_query_keyz_prefix',
  LibraryItemQuery = 'library_item_query_keyz_prefix',
  VaultHistoryQuery = 'vault_history_query_keyz_prefix',
  VaultHistoryQueryV2 = 'vault_history_query_v2_keyz_prefix',
  VaultHistoryStatsQuery = 'vault_history_stats_query_keyz_prefix',
  UserWorkspacesQuery = 'user_workspaces_query_keyz_prefix',
  JobQueueEtaQuery = 'job_queue_eta_query_keyz_prefix',
  QueryCapRulesQuery = 'query_cap_rules_query_keyz_prefix',
  VaultFileSearchQuery = 'vault_file_search_query_keyz_prefix',
  AssistantRecentQueries = 'assistant_recent_queries_query_keyz_prefix',
  AssistantSharedQueries = 'assistant_shared_queries_query_keyz_prefix',
  EventFeedbacks = 'event_feedbacks_query_keyz_prefix',
  SharingSettings = 'sharing_settings_query_keyz_prefix',
  VaultWorkflows = 'vault_workflows_query_keyz_prefix',
}

// queries we want saved to disk in indexedDB
const INCLUDE_QUERY_KEYS_IN_DISK_STORAGE: HarvQueryKeyPrefix[] = [
  HarvQueryKeyPrefix.ExamplesQuery,
  // add other queries to save offline here
]

// Function to check if a query should be stored on disk in indexedDB based on query key prefix
const isQueryStoredOnDisk = (queryKey: QueryKey): boolean => {
  return INCLUDE_QUERY_KEYS_IN_DISK_STORAGE.some((inclusionKey) =>
    queryKey.includes(inclusionKey)
  )
}

// exports
export { HarvQueryKeyPrefix, isQueryStoredOnDisk }
