import { UserInfo } from 'models/user-info'
import { ResearchArea } from 'openapi/models/ResearchArea'
import { ResearchDetail } from 'types/research'

export const ResearchAreaToDetail: Record<ResearchArea, ResearchDetail> = {
  [ResearchArea.EDGAR]: {
    name: 'Edgar',
    path: '/research/edgar',
    permCheck: (user: UserInfo) => user.IsEDGARQAUser,
  },
  [ResearchArea.TAX]: {
    name: 'Tax',
    path: '/research/tax',
    permCheck: (user: UserInfo) =>
      user.IsRegionalTaxQAUser || user.IsWWTSQAUser,
  },
  [ResearchArea.MEMOS]: {
    name: 'Memos',
    path: '/research/memos',
    permCheck: (user: UserInfo) => user.IsMemosUser,
  },
  [ResearchArea.EURLEX]: {
    name: 'EUR-Lex',
    path: '/research/eurlex',
    permCheck: (user: UserInfo) => user.IsEurLexUser,
  },
  [ResearchArea.USACASELAW]: {
    name: 'US Case Law',
    path: '/research/' + ResearchArea.USCASELAW,
    permCheck: (user: UserInfo) => user.IsUsaCaseLawUser,
  },
  [ResearchArea.USCASELAW]: {
    name: 'US Case Law',
    path: '/research/' + ResearchArea.USCASELAW,
    permCheck: (user: UserInfo) => user.IsUsaCaseLawUser,
  },
  [ResearchArea.FRANCECASELAW]: {
    name: 'French Case Law',
    path: '/research/' + ResearchArea.FRANCECASELAW,
    permCheck: (user: UserInfo) => user.IsFranceCaseLawUser,
  },
  [ResearchArea.AUSBREACHREPORTING]: {
    name: 'Australia Breach Reporting',
    path: '/research/' + ResearchArea.AUSBREACHREPORTING,
    permCheck: (user: UserInfo) => user.IsAusBreachReportingUser,
  },
  [ResearchArea.CUATRECASAS]: {
    name: 'Cuatrecasas',
    path: '/research/' + ResearchArea.CUATRECASAS,
    permCheck: (user: UserInfo) => user.IsCuatrecasasUser,
  },
  [ResearchArea.FROMCOUNSEL]: {
    name: 'From Counsel',
    path: '/research/' + ResearchArea.FROMCOUNSEL,
    permCheck: (user: UserInfo) => user.IsFromCounselUser,
  },
  [ResearchArea.SWEDENCASELAW]: {
    name: 'Sweden Case Law',
    path: '/research/' + ResearchArea.SWEDENCASELAW,
    permCheck: (user: UserInfo) => user.IsSwedenCaseLawUser,
  },
}
