import React, { useState } from 'react'

import { FileIcon, FolderArchive, Trash } from 'lucide-react'

import { UploadedFile } from 'openapi/models/UploadedFile'

import { bytesToReadable } from 'utils/file-utils'
import { cn } from 'utils/utils'

import { useAssistantFileStore } from 'components/assistant-v2/stores/assistant-file-store'
import {
  FileUploadingState,
  useAssistantStore,
} from 'components/assistant-v2/stores/assistant-store'
import { getIsLoadingUrl } from 'components/common/pdf-viewer/helpers'
import { PdfViewerSheet } from 'components/common/pdf-viewer/pdf-viewer-sheet'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { Spinner } from 'components/ui/spinner'

const AssistantFiles = ({
  className,
  files,
  handleRemoveFile,
  uploadingFiles,
  zipFiles,
  pstFiles,
}: {
  className?: string
  files: UploadedFile[]
  handleRemoveFile: (file: UploadedFile | FileUploadingState) => void
  uploadingFiles?: FileUploadingState[]
  zipFiles?: File[]
  pstFiles?: File[]
}) => {
  const getDocument = useAssistantFileStore((s) => s.getDocument)
  const eventId = useAssistantStore((s) => s.eventId)
  const [activeDocument, setActiveDocument] = useState<UploadedFile | null>(
    null
  )
  const isLoadingUrl = getIsLoadingUrl(activeDocument)

  const handleFileClick = async (file: UploadedFile) => {
    setActiveDocument(file)

    const document = await getDocument(eventId, file.id)
    if (document) {
      setActiveDocument(document)
    }
  }

  return (
    <>
      <ul className={cn('h-full divide-y px-6 py-2', className)}>
        {files.map((file, i) => (
          <FileItem
            key={`file-${i}`}
            file={file}
            handleFileClick={handleFileClick}
            handleRemoveFile={handleRemoveFile}
          />
        ))}
        {uploadingFiles?.map((file, i) => (
          <FileItem
            key={`uploading-${file.name}-${i}}`}
            file={file}
            isUploading
            handleRemoveFile={handleRemoveFile}
          />
        ))}
        {zipFiles?.map((zipFile, i) => (
          <FileItemWrapper key={`zip-${zipFile.name}-${i}`}>
            <Icon icon={FolderArchive} className="mr-2 h-3 w-3" />
            <FileItemColumns
              isUploading
              name={`Unzipping “${zipFile.name}”…`}
            />
          </FileItemWrapper>
        ))}
        {pstFiles?.map((pstFile, i) => (
          <FileItemWrapper key={`pst-${pstFile.name}-${i}`}>
            <Icon icon={FileIcon} className="mr-2 h-3 w-3" />
            <FileItemColumns isUploading name={`Parsing “${pstFile.name}”…`} />
          </FileItemWrapper>
        ))}
      </ul>
      <PdfViewerSheet
        document={activeDocument}
        showSheet={!!activeDocument}
        setShowSheet={() => setActiveDocument(null)}
        shouldAutoFocus={false}
        isLoadingUrl={isLoadingUrl}
      />
    </>
  )
}

const FileItem = ({
  file,
  isUploading,
  handleFileClick,
  handleRemoveFile,
}: {
  file: UploadedFile | FileUploadingState
  isUploading?: boolean
  handleFileClick?: (file: UploadedFile) => void
  handleRemoveFile?: (file: UploadedFile | FileUploadingState) => void
}) => {
  const DetailsComp = handleFileClick ? 'button' : 'span'
  return (
    <FileItemWrapper
      className={cn({
        'hover:bg-secondary': !!handleFileClick,
      })}
    >
      <DetailsComp
        className="flex grow items-center py-1 text-left"
        onClick={() => {
          handleFileClick?.(file as UploadedFile)
        }}
      >
        <Icon icon={FileIcon} className="mr-2 h-3 w-3" />
        <FileItemColumns
          isUploading={isUploading}
          name={file.name}
          size={bytesToReadable(file.size ?? 0)}
        />
      </DetailsComp>
      {handleRemoveFile && (
        <Button
          className="shrink-0 hover:bg-secondary-hover"
          onClick={() => {
            handleRemoveFile(file)
          }}
          size="xsIcon"
          variant="ghost"
        >
          <Icon icon={Trash} size="small" variant="secondary" />
        </Button>
      )}
    </FileItemWrapper>
  )
}

const FileItemWrapper = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <li className={cn('flex items-center px-1 py-1', className)}>{children}</li>
  )
}

const FileItemColumns = ({
  isUploading,
  name,
  size,
}: {
  isUploading?: boolean
  name: string
  size?: string
}) => {
  return (
    <>
      <span className="flex grow items-center">
        <span className="truncate text-xs">{name}</span>
        {isUploading && (
          <span className="ml-2 flex w-6 shrink-0 items-center justify-center">
            <Spinner className="mr-0 h-4 w-4" />
          </span>
        )}
      </span>
      <span className="ml-2 w-1/6 min-w-12 max-w-24 shrink-0 text-xs text-muted">
        {size}
      </span>
    </>
  )
}

export default AssistantFiles
