import { v4 as uuidv4 } from 'uuid'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { VaultFile } from 'openapi/models/VaultFile'

export enum TypeOperator {
  CONTAINS = 'contains',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEqual',
}

export enum JoinOperator {
  OR = 'OR',
  AND = 'AND',
}

export const operatorLabels: Record<TypeOperator, string> = {
  [TypeOperator.CONTAINS]: 'Contains',
  [TypeOperator.EQUALS]: 'Equals',
  [TypeOperator.NOT_EQUALS]: 'Does Not Equal',
}
export interface ColumnFilter {
  id: string
  operator: TypeOperator
  value: string[]
  headerName: string
  questionId?: string
}

export enum FilterType {
  TEXT = 'text',
  DATE = 'date',
}

export interface FilterModelValue {
  filterType: FilterType
  type: TypeOperator
  filter: string
}

export interface ConditionalFilterModelValue {
  filterType: FilterType
  operator: JoinOperator
  conditions: FilterModelValue[]
}

export interface FilterModel {
  [key: string]: FilterModelValue | ConditionalFilterModelValue
}

export const generateEmptyFilter = (
  isShowingLongResponses: boolean
): ColumnFilter => {
  return {
    id: uuidv4(),
    operator: isShowingLongResponses
      ? TypeOperator.CONTAINS
      : TypeOperator.EQUALS,
    headerName: '',
    questionId: undefined,
    value: [],
  }
}

export interface PopoverPosition {
  left: number
  top: number
  right: number
  width: number
}

export interface PopoverData {
  displayText: string
  queryId: string
  vaultFolderId: string
  file: VaultFile
  fileId: string
  isTextType: boolean
  error?: { columnId: string; text: string }
  questionId?: string
  sourceId?: string
}

export interface VaultDataGridFilterState {
  hasRowDataApplied: boolean
  isShowingLongResponses: boolean
  currentSortColumnId: string
  agGridHeaderHeight: number
  isFilterDropdownOpen: boolean
  filters: ColumnFilter[]
  currentAppliedFilters: ColumnFilter[]
  displayedRows: string[]
  selectedRows: string[]
  popoverData: PopoverData | null
  popoverPosition: PopoverPosition
}

export interface VaultDataGridFilterActions {
  setHasRowDataApplied: (hasRowDataApplied: boolean) => void
  setIsShowingLongResponses: (isShowingLongResponses: boolean) => void
  setCurrentSortColumnId: (currentSortColumnId: string) => void
  setIsFilterDropdownOpen: (isFilterDropdownOpen: boolean) => void
  setAgGridHeaderHeight: (agGridHeaderHeight: number) => void
  setFilters: (filters: ColumnFilter[]) => void
  setCurrentAppliedFilters: (currentAppliedFilters: ColumnFilter[]) => void
  addFilter: () => void
  resetFilterState: () => void
  updateFilter: (filterId: string, newFilter: Partial<ColumnFilter>) => void
  removeFilter: (filterId: string) => void
  setDisplayedRows: (displayedRows: string[]) => void
  addSelectedRow: (id: string) => void
  bulkAddSelectedRows: (ids: string[]) => void
  removeSelectedRow: (id: string) => void
  bulkRemoveSelectedRows: (ids: string[]) => void
  clearSelectedRows: () => void
  setPopoverData: (popoverData: PopoverData | null) => void
  setPopoverPosition: (popoverPosition: PopoverPosition) => void
}

export const useVaultDataGridFilterStore = create(
  devtools(
    immer<VaultDataGridFilterState & VaultDataGridFilterActions>((set) => ({
      hasRowDataApplied: false,
      isShowingLongResponses: false,
      currentSortColumnId: 'name',
      agGridHeaderHeight: 0,
      isFilterDropdownOpen: false,
      filters: [generateEmptyFilter(false)],
      currentAppliedFilters: [],
      displayedRows: [],
      selectedRows: [],
      setHasRowDataApplied: (hasRowDataApplied) => set({ hasRowDataApplied }),
      popoverData: null,
      popoverText: '',
      popoverPosition: {
        left: 0,
        top: 0,
        right: 0,
        width: 0,
      },
      setIsShowingLongResponses: (isShowingLongResponses) =>
        set({ isShowingLongResponses }),
      setAgGridHeaderHeight: (agGridHeaderHeight) =>
        set({ agGridHeaderHeight }),
      setIsFilterDropdownOpen: (isFilterDropdownOpen) =>
        set({ isFilterDropdownOpen }),
      setFilters: (filters) => set({ filters }),
      setCurrentAppliedFilters: (currentAppliedFilters) =>
        set({ currentAppliedFilters }),
      setCurrentSortColumnId: (currentSortColumnId) =>
        set({ currentSortColumnId }),
      addFilter: () =>
        set((state) => {
          const newFilter = {
            id: uuidv4(),
            operator: state.isShowingLongResponses ? 'contains' : 'equals',
            value: [],
            headerName: '',
            questionId: undefined,
          }
          return {
            filters: [...state.filters, newFilter],
          }
        }),
      resetFilterState: () => {
        set((state) => {
          return {
            filters: [generateEmptyFilter(state.isShowingLongResponses)],
            currentAppliedFilters: [],
          }
        })
      },
      updateFilter: (filterId, newFilter) => {
        set((state) => {
          const index = state.filters.findIndex((f) => f.id === filterId)
          const isNewOperatorContains =
            newFilter.operator === TypeOperator.CONTAINS
          state.filters[index] = {
            ...state.filters[index],
            ...newFilter,
            ...(isNewOperatorContains ? { value: [] } : {}),
          }
        })
      },
      removeFilter: (filterId) => {
        set((state) => {
          const nextFilters = state.filters.filter((f) => f.id !== filterId)
          if (nextFilters.length === 0) {
            return {
              filters: [generateEmptyFilter(state.isShowingLongResponses)],
            }
          }
          return { filters: nextFilters }
        })
      },
      setDisplayedRows: (displayedRows) => set({ displayedRows }),
      addSelectedRow: (id) => {
        set((state) => {
          if (!state.selectedRows.includes(id)) {
            return {
              selectedRows: [...state.selectedRows, id],
            }
          }
        })
      },
      bulkAddSelectedRows: (ids: string[]) => {
        set((state) => ({
          selectedRows: [
            ...Array.from(new Set([...state.selectedRows, ...ids])),
          ],
        }))
      },
      removeSelectedRow: (id) => {
        set((state) => ({
          selectedRows: state.selectedRows.filter((r) => r !== id),
        }))
      },
      bulkRemoveSelectedRows: (ids: string[]) => {
        set((state) => ({
          selectedRows: state.selectedRows.filter((r) => !ids.includes(r)),
        }))
      },
      clearSelectedRows: () => set({ selectedRows: [] }),
      setPopoverData: (popoverData) => set({ popoverData }),
      setPopoverPosition: (popoverPosition: PopoverPosition) =>
        set({ popoverPosition }),
    }))
  )
)
