import React, { useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useClickAway } from 'react-use'

import { CustomCellRendererProps } from 'ag-grid-react'
import { isNil } from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import { usePDFViewerStore } from 'stores/pdf-viewer-store'

import { unloadPDFDiv } from 'utils/pspdfkit'
import { Source } from 'utils/task'
import { EM_DASH, cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import Icon from 'components/ui/icon/icon'
import { QuestionColumnDef } from 'components/vault/query-detail/data-grid-helpers'
import ErrorPopover from 'components/vault/query-detail/data-grid/cells/error-popover'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  fileIdSearchParamKey,
  sourceIdSearchParamKey,
  questionIdSearchParamKey,
  GenerateNNResponseProps,
} from 'components/vault/utils/vault'
import {
  PopoverData,
  useVaultDataGridFilterStore,
} from 'components/vault/utils/vault-data-grid-filters-store'
import {
  getFileIcon,
  getRandomSkeletonSize,
} from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'

interface DocumentCellProps extends CustomCellRendererProps {
  projectId: string
  queryId: string
  generateNNResponse: (props: GenerateNNResponseProps) => Promise<void>
}

export const DocumentCell: React.FC<DocumentCellProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)
  const sourceId = searchParams.get(sourceIdSearchParamKey)
  const queryParamQuestionId = searchParams.get(questionIdSearchParamKey)

  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLButtonElement>(null)

  const { value, projectId, queryId } = props
  const { id, file, sources } = props.data

  const colDef = props.column?.getColDef() as QuestionColumnDef
  const { questionId, type } = colDef

  const pendingQueryQuestions = useVaultQueryDetailStore(
    useShallow((s) => s.pendingQueryQuestions)
  )
  const pendingColumnIds = pendingQueryQuestions?.map((q) => q.id) ?? []

  const isDocumentType = type === 'document'
  const isTextType = !isDocumentType
  const isCellLoading = String(value) === 'Processing…'
  const isCellEmptyDueToDryRun = String(value) === '<DRY_RUN_EMPTY_CELL>'
  const isPendingCell = pendingColumnIds.includes(questionId)

  const sourceToOpen = sources.find(
    (s: Source) => s.questionId === questionId && s.documentId === id
  )

  const errors: { columnId: string; text: string }[] = props.data.errors
  const error = errors.find((error) => error.columnId === questionId)

  const setActiveDocument = useVaultStore((s) => s.setActiveDocument)
  const isPdfLoading = usePDFViewerStore((s) => s.isPdfLoading)
  const setInstance = usePDFViewerStore((s) => s.setInstance)
  const popoverData = useVaultDataGridFilterStore((s) => s.popoverData)

  const setPopoverData = useVaultDataGridFilterStore((s) => s.setPopoverData)
  const setPopoverPosition = useVaultDataGridFilterStore(
    (s) => s.setPopoverPosition
  )

  const shouldHighlightRow =
    file.id === fileId ||
    (popoverData !== null && file.id === popoverData.fileId)

  const handleCellClick = () => {
    if (!isFocused) {
      return setIsFocused(true)
    }

    if (!ref.current) return
    // if (!projectId || !queryId) return
    if (error) return

    const boundingClientRect = ref.current.getBoundingClientRect()
    const isExceedingRightEdge =
      boundingClientRect.x + boundingClientRect.width > window.innerWidth

    // TODO instead, just scroll to end
    if (isExceedingRightEdge) {
      setPopoverPosition({
        left: window.innerWidth - 24 - boundingClientRect.width,
        top: boundingClientRect.y,
        right: window.innerWidth - 24,
        width: boundingClientRect.width,
      })
    } else {
      setPopoverPosition({
        left: boundingClientRect.x,
        top: boundingClientRect.y,
        right: boundingClientRect.x + boundingClientRect.width,
        width: boundingClientRect.width,
      })
    }

    const popoverData: PopoverData = {
      displayText: value,
      queryId: queryId,
      vaultFolderId: projectId,
      file: file,
      fileId: id,
      error: error,
      isTextType: isTextType,
      questionId: questionId,
      sourceId: sourceToOpen?.id,
    }
    setPopoverData(popoverData)
  }

  const openPanel = () => {
    if (error) return
    const isExistingFile = fileId === id
    const sourceToOpen = sources.find(
      (s: Source) => s.questionId === questionId && s.documentId === id
    )
    // if the file is already opened and the source is the same, then we do not need to do anything
    // if the fileId is already open and the source is different, we just need to set teh sourceId and the push sheet will take care of updating the annotations
    if (isExistingFile && !isNil(sourceToOpen) && sourceId === sourceToOpen.id)
      return
    if (fileId === id) {
      const shouldApplyQuestionQueryParam =
        isTextType && queryParamQuestionId !== questionId
      const shouldApplySourceQueryParam =
        isTextType && sourceToOpen && sourceId !== sourceToOpen.id
      if (shouldApplyQuestionQueryParam || shouldApplySourceQueryParam) {
        setSearchParams((prev) => {
          const newParams = new URLSearchParams(prev)
          if (shouldApplyQuestionQueryParam) {
            newParams.set(questionIdSearchParamKey, questionId)
          }
          if (shouldApplySourceQueryParam) {
            newParams.set(sourceIdSearchParamKey, sourceToOpen.id)
          }
          return newParams
        })
      }
      return
    }
    // before we set the activeDocument we are going to clear the instance and unload the existing pdf
    // in case there is an existing pdf open (in the push sheet) we need to setInstance to null so the annotations do not apply (useEffect in push sheet)
    // we also want to unload the pdf immediately so that we can display a loader, otherwise the old pdf will be displayed while the new pdf is loading
    setInstance(null)
    unloadPDFDiv()
    setActiveDocument(file)
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev)
      newParams.delete(sourceIdSearchParamKey)

      newParams.set(fileIdSearchParamKey, id)
      // if the type of the cell is text, then we are clicking on a specific question
      // let's set the source param so that the push sheet is open with the current source
      if (isTextType) {
        newParams.set(questionIdSearchParamKey, questionId)

        if (sourceToOpen) {
          newParams.set(sourceIdSearchParamKey, sourceToOpen.id)
        }
      }
      return newParams
    })
  }

  useClickAway(ref, () => {
    setIsFocused(false)
  })

  // Loading State
  if (isCellLoading && !error) {
    return (
      <div
        className={cn('flex h-full w-full items-center px-2.5 text-sm', {
          'block bg-secondary text-left': isDocumentType,
        })}
      >
        <div
          className="h-4 animate-pulse rounded bg-skeleton"
          style={{ width: getRandomSkeletonSize(id, questionId) }}
        />
      </div>
    )
  }

  const trailingIcon =
    isDocumentType && errors.length > 0 ? (
      <ErrorPopover fileId={file.id} />
    ) : null
  const text = error
    ? error.text
    : isCellEmptyDueToDryRun || isPendingCell
    ? ''
    : value
  const isTextEmdash = text === EM_DASH

  return (
    <Button
      variant="unstyled"
      onClick={isDocumentType ? openPanel : handleCellClick}
      disabled={isPendingCell || (isDocumentType && isPdfLoading)}
      className={cn(
        'flex h-full w-full items-center justify-start rounded-none px-4 text-left text-xs',
        {
          'text-muted': error || isTextEmdash,
          'ring-primary ring-[1px] ring-inset': isFocused,
          'bg-button-secondary': shouldHighlightRow || isCellEmptyDueToDryRun,
          'hover:bg-button-secondary': isDocumentType,
        }
      )}
      ref={ref}
    >
      {isDocumentType ? (
        <Badge
          variant="secondary"
          className="flex shrink items-center gap-1 px-1 font-normal"
        >
          <Icon icon={getFileIcon(file)} size="small" />
          <p className="truncate text-xs">{text}</p>
        </Badge>
      ) : (
        <p className="truncate text-xs">{text}</p>
      )}
      {trailingIcon}
    </Button>
  )
}

export default DocumentCell
