import React, { useCallback, useMemo } from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import _ from 'lodash'
import { AlertTriangle } from 'lucide-react'

import { EvaluationQuestion } from 'openapi/models/EvaluationQuestion'
import { EvaluationQuestionResponseOptionRating } from 'openapi/models/EvaluationQuestionResponseOptionRating'
import { EvaluationQuestionResponsePayloadPreference } from 'openapi/models/EvaluationQuestionResponsePayloadPreference'
import { EvaluationQuestionResponsePayloadRating } from 'openapi/models/EvaluationQuestionResponsePayloadRating'
import { EvaluationQuestionResponseType } from 'openapi/models/EvaluationQuestionResponseType'
import { Experiment } from 'openapi/models/Experiment'
import { ExperimentMetadata } from 'openapi/models/ExperimentMetadata'
import { ModelResponse } from 'openapi/models/ModelResponse'
import { User } from 'openapi/models/User'
import { UserEvaluation } from 'openapi/models/UserEvaluation'
import { UserEvaluationResponse } from 'openapi/models/UserEvaluationResponse'

import { readableFormat } from 'utils/date-utils'
import { displayErrorMessage, displaySuccessMessage } from 'utils/toast'
import {
  download,
  findMedianOfNumberArray,
  parseIsoString,
  roundDecimalToXPlaces,
} from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAuthUser } from 'components/common/auth-context'
import SettingsAppHeader from 'components/settings/settings-app-header'
import SettingsLayout from 'components/settings/settings-layout'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { NavLink } from 'components/ui/nav-link'
import { ScrollArea } from 'components/ui/scroll-area'
import SectionHeader from 'components/ui/section-header'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

import EvaluationQuestionCard from './evaluation-question/evaluation-question-card'
import ExperimentMultiUserManagement from './experiment-user-management'
import ExperimentUserOverviewTable, {
  ExperimentUserOverviewTableEntry,
} from './experiment-user-overview-table'
import {
  AddUsersToExperiment,
  FetchAllUserEvaluations,
  FetchEvaluationQuestions,
  FetchExperimentBySlug,
  FetchExperimentResultsExport,
  FetchModelResponsesByExperimentId,
  FetchUserEvaluationResponsesByExperimentId,
  FetchUsersInExperiment,
  RemoveUsersFromExperiment,
} from './utils/experiment-fetcher'
import {
  EvaluationQuestionMeta,
  USER_PREFERENCE_RESPONSE_VALUE_TIE,
} from './utils/experiment-utils'

interface RatingResponseStats {
  mean: number
  median: number
  ratingFrequencies: { [key: number]: number }
  totalRatingResponses: number
}

// NOTE: the paradigm here is to pull the individual data objects from BE
// and construct the view we want in FE; this may not be sustainable as complexity grows,
// we might need dedicated BE endpoints for each view
const ExperimentManagement = () => {
  const userInfo = useAuthUser()

  const { slug: experimentSlug = '' } = useParams<{ slug: string }>()
  const experimentLink = `${BaseAppPath.Evaluations}/experiment/${experimentSlug}`
  const [isLoading, setIsLoading] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)

  // TODO think about combining some of this logic with user-evaluation
  // use a combined hook -- see useWorkspaceDetail
  const [experiment, setExperiment] = useState<Experiment>()
  const [experimentEvaluationQuestions, setExperimentEvaluationQuestions] =
    useState<EvaluationQuestion[]>([])
  const [experimentModelResponses, setExperimentModelResponses] = useState<
    ModelResponse[]
  >([])
  const [
    evaluationQuestionToUserResponsesMap,
    setEvaluationQuestionToUserResponsesMap,
  ] = useState<{ [key: string]: UserEvaluationResponse[] }>({})
  //TODO now this will be used by both static display of stats and influenced by delete action in user table

  const [experimentUsers, setExperimentUsers] = useState<User[]>([])
  const [experimentUserEvaluations, setExperimentUserEvaluations] = useState<
    UserEvaluation[]
  >([])
  const [userEvaluationResponses, setUserEvaluationResponses] = useState<
    UserEvaluationResponse[]
  >([])

  const modelLabels = useMemo(() => {
    return Array.from(
      new Set(
        experimentModelResponses.map((modelResponse) => modelResponse.label)
      )
    )
  }, [experimentModelResponses])

  const userOverviewEntries: ExperimentUserOverviewTableEntry[] =
    useMemo(() => {
      return experimentUsers.map((user) => {
        const ue = experimentUserEvaluations.filter(
          (evaluation) => evaluation.userId === user.id
        )
        const earliestCreatedAtDate = ue
          .map((ue) => new Date(parseIsoString(ue.createdAt)))
          .reduce(
            (earliest, current) => (earliest < current ? earliest : current),
            new Date()
          )

        const userEvaluationResponsesForUser = userEvaluationResponses.filter(
          (uer) => {
            const eue = experimentUserEvaluations.find(
              (eue) => eue.id === uer.userEvaluationId && eue.userId === user.id
            )
            return eue !== undefined
          }
        )

        const latestUpdatedAtDate: Date | undefined =
          userEvaluationResponsesForUser.length > 0
            ? userEvaluationResponsesForUser
                .map((uer) => new Date(parseIsoString(uer.updatedAt)))
                .reduce(
                  (latest, current) => (latest > current ? latest : current),
                  new Date(0)
                )
            : undefined

        return {
          email: user.email,
          numEvaluationsCompleted: ue.filter((ue) => ue.isComplete).length,
          numEvaluationsTotal: ue.length,
          userAddedAt: earliestCreatedAtDate,
          lastUserActivityAt: latestUpdatedAtDate,
        }
      })
    }, [experimentUserEvaluations, experimentUsers, userEvaluationResponses])

  const fetchAndDownloadExperimentResultsCsv = async () => {
    if (!experiment) {
      displayErrorMessage('Experiment ID not set', 10)
      return
    }

    setIsDownloading(true)

    // might want to switch to download polling if it's not performant
    const csvData = await FetchExperimentResultsExport(experiment.id)

    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)

    download(url, true, {
      download: `experiment_${experimentSlug}_results.csv`,
    })

    setIsDownloading(false)
  }

  const fetchAllExperimentData = useCallback(async () => {
    if (!experimentSlug) return

    setIsLoading(true)

    try {
      const [experimentValue, evaluationQuestionBankValue] = await Promise.all([
        FetchExperimentBySlug(experimentSlug),
        FetchEvaluationQuestions(true),
      ])
      setExperiment(experimentValue)
      const experimentMetadata = experimentValue.meta as ExperimentMetadata
      const experimentConfigValue = experimentMetadata.config
      const evaluationQuestionsInner =
        experimentConfigValue.evaluationQuestions.sort(
          (a, b) => a.orderRank - b.orderRank
        )

      // assert evaluationQuestions doesn't contain any undefined values, indicating all questions were found [copied]
      const evaluationQuestions = evaluationQuestionsInner.map(
        (inner) =>
          evaluationQuestionBankValue.find(
            (bankQuestion) => bankQuestion.id === inner.questionId
          )!
      )
      setExperimentEvaluationQuestions(evaluationQuestions)

      // we could change it to fetch by slug in future
      const [modelResponses, userEvaluationResponses, userEvaluations, users] =
        await Promise.all([
          FetchModelResponsesByExperimentId(experimentValue.id),
          FetchUserEvaluationResponsesByExperimentId(experimentValue.id),
          FetchAllUserEvaluations(experimentValue.id),
          FetchUsersInExperiment(experimentValue.id),
        ])
      modelResponses.sort((a, b) => a.label.localeCompare(b.label))
      setExperimentModelResponses(modelResponses)

      // needed for user management / progress
      users.sort((a, b) => a.email.localeCompare(b.email))
      setExperimentUsers(users)

      setExperimentUserEvaluations(userEvaluations)

      setUserEvaluationResponses(userEvaluationResponses)

      const mapping: { [key: string]: UserEvaluationResponse[] } = {}
      evaluationQuestions.forEach((question) => {
        const userResponses = userEvaluationResponses.filter(
          (userEvaluationResponse) =>
            userEvaluationResponse.questionId === question.id
        )
        mapping[question.id] = userResponses
      })
      setEvaluationQuestionToUserResponsesMap(mapping)
    } catch (error) {
      displayErrorMessage(`Error fetching experiment data: ${error}`, 5)
    } finally {
      setIsLoading(false)
    }
  }, [experimentSlug])

  useEffect(() => {
    if (!experimentSlug) return
    void fetchAllExperimentData() // TODO fetching in use effect
  }, [experimentSlug, fetchAllExperimentData])

  const onAddUserToExperiment = async (
    experimentId: string,
    emails: string[]
  ): Promise<string[]> => {
    const emailsAdded = await AddUsersToExperiment(experimentId, emails)
    await fetchAllExperimentData()
    return emailsAdded
  }

  const onRemoveUserFromExperiment = async (
    experimentId: string,
    emails: string[]
  ): Promise<string[]> => {
    const emailsRemoved = await RemoveUsersFromExperiment(experimentId, emails)
    await fetchAllExperimentData()
    return emailsRemoved
  }

  const copyExperimentLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        encodeURI(`${window.location.origin}${experimentLink}`)
      )
      displaySuccessMessage('Experiment link copied to clipboard')
    } catch (err) {
      displayErrorMessage('Failed to copy experiment link')
    }
  }

  if (_.isEmpty(userInfo) || !userInfo.IsResponseComparisonAdmin) return <></>

  return (
    <>
      <SettingsAppHeader isInternalAdmin />
      <SettingsLayout>
        <SectionHeader
          title="Experiment management"
          sectionDescription={`Slug: ${experimentSlug} | Name: ${
            experiment?.name ?? ''
          } | Created: ${
            experiment?.createdAt
              ? readableFormat(new Date(parseIsoString(experiment!.createdAt)))
              : ''
          }`}
        />
        <ScrollArea className="h-full">
          <div
            className="container mx-auto flex h-full flex-col space-y-4 py-4"
            data-testid="experiment-management-container"
          >
            <BasicTransition show={!isLoading}>
              {!isLoading && !experiment && (
                <div>
                  <h1>No experiment found</h1>
                </div>
              )}
              {!isLoading && experiment && (
                <>
                  <div className="flex justify-between">
                    <NavLink
                      label="Click to enter experiment"
                      href={experimentLink}
                    />
                    <Button
                      className="flex min-w-20 text-muted"
                      variant="ghost"
                      onClick={copyExperimentLinkToClipboard}
                    >
                      <span className="truncate text-sm">
                        Copy experiment link
                      </span>
                    </Button>
                  </div>
                  <Card className="mt-4">
                    <CardHeader>
                      <CardTitle>Manage users</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <Tabs defaultValue="overview">
                        <TabsList className="justify-start">
                          <TabsTrigger value="overview">Overview</TabsTrigger>
                          <TabsTrigger value="add">Add</TabsTrigger>
                          <TabsTrigger value="remove">Remove</TabsTrigger>
                        </TabsList>
                        <TabsContent value="overview">
                          <ExperimentUserOverviewTable
                            userOverviewEntries={userOverviewEntries}
                            handleRemoveUser={async (email) => {
                              await onRemoveUserFromExperiment(experiment.id, [
                                email,
                              ])
                            }}
                          />
                        </TabsContent>
                        <TabsContent value="add">
                          <ExperimentMultiUserManagement
                            experimentId={experiment.id}
                            buttonText="Add"
                            modifyExperimentUsers={onAddUserToExperiment}
                          />
                        </TabsContent>
                        <TabsContent value="remove">
                          <>
                            <div className="mb-3 flex justify-start rounded-lg border bg-warning">
                              <AlertTriangle size={18} className="m-2" />
                              <span className="mt-2">
                                This will remove users and any of their existing
                                evaluation responses. You will not be able to
                                re-add them to the same experiment later.
                              </span>
                            </div>
                            <ExperimentMultiUserManagement
                              experimentId={experiment.id}
                              buttonText="Remove"
                              modifyExperimentUsers={onRemoveUserFromExperiment}
                            />
                          </>
                        </TabsContent>
                      </Tabs>
                    </CardContent>
                  </Card>
                  <Card className="mt-4">
                    <CardHeader>
                      <div className="flex justify-between">
                        <CardTitle>Experiment setup & results</CardTitle>
                        <Button
                          onClick={fetchAndDownloadExperimentResultsCsv}
                          variant="secondary"
                          disabled={isDownloading}
                          tooltip={isDownloading ? 'Downloading...' : ''}
                        >
                          Export results
                        </Button>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <h1 className="mt-2 text-sm font-semibold">Models</h1>
                      <div className="break-words text-sm text-muted">
                        {modelLabels.map((label) => (
                          <div key={label} className="text-sm text-muted">
                            {label}:{' '}
                            {
                              experimentModelResponses.find(
                                (model) => model.label === label
                              )?.model
                            }
                          </div>
                        ))}
                      </div>
                      {experimentEvaluationQuestions.map((question, idx) => (
                        <ExperimentResultsEvaluationQuestionCard
                          key={question.id}
                          question={question}
                          idx={idx}
                          evaluationQuestionToUserResponsesMap={
                            evaluationQuestionToUserResponsesMap
                          }
                          modelLabels={modelLabels}
                          experimentModelResponses={experimentModelResponses}
                        />
                      ))}
                    </CardContent>
                  </Card>
                </>
              )}
            </BasicTransition>
          </div>
        </ScrollArea>
      </SettingsLayout>
    </>
  )
}

interface ExperimentResultsEvaluationQuestionCardProps {
  question: EvaluationQuestion
  idx: number
  evaluationQuestionToUserResponsesMap: {
    [key: string]: UserEvaluationResponse[]
  }
  modelLabels: string[]
  experimentModelResponses: ModelResponse[]
}

const ExperimentResultsEvaluationQuestionCard = ({
  question,
  idx,
  evaluationQuestionToUserResponsesMap,
  modelLabels,
  experimentModelResponses,
}: ExperimentResultsEvaluationQuestionCardProps) => {
  // pulls all users responses to evaluation question across multiple evaluations
  // requires || [] because on initial render the map is empty
  const userResponsesToQuestion =
    evaluationQuestionToUserResponsesMap[question.id] || []
  let resultsComponent // TODO is there a better way to do this?
  if (question.responseType === EvaluationQuestionResponseType.PREFERENCE) {
    const modelPreferenceCounts: {
      [key: string]: number
    } = {}
    modelLabels.forEach((label) => {
      modelPreferenceCounts[label] = 0
    })
    modelPreferenceCounts[USER_PREFERENCE_RESPONSE_VALUE_TIE] = 0

    userResponsesToQuestion.forEach((userResponse) => {
      const responseObj =
        userResponse.response as EvaluationQuestionResponsePayloadPreference
      if (responseObj.responseValue === USER_PREFERENCE_RESPONSE_VALUE_TIE) {
        modelPreferenceCounts[USER_PREFERENCE_RESPONSE_VALUE_TIE] += 1
      } else {
        const preferredModelResponse = experimentModelResponses.find(
          (modelResponse) => modelResponse.id === responseObj.responseValue
        )
        if (preferredModelResponse) {
          modelPreferenceCounts[preferredModelResponse.label] += 1
        }
      }
    })

    resultsComponent = (
      <>
        <h1 className="text-lg font-semibold">Results</h1>
        <div>
          {Object.keys(modelPreferenceCounts).map((label) => (
            <div key={label} className="text-sm text-muted">
              {label}: {modelPreferenceCounts[label]} (
              {Math.round(
                (modelPreferenceCounts[label] /
                  userResponsesToQuestion.length) *
                  100
              )}
              %)
            </div>
          ))}
        </div>
      </>
    )
  } else if (question.responseType === EvaluationQuestionResponseType.RATING) {
    const meta = question.meta as EvaluationQuestionMeta
    const responseOption =
      meta.responseOptions as EvaluationQuestionResponseOptionRating
    const min = responseOption.min
    const max = responseOption.max
    const step = responseOption.step

    const ratingChoices = Array.from(
      { length: (max - min) / step + 1 },
      (_, i) => min + i * step
    )

    const modelLabelToStats: {
      [key: string]: RatingResponseStats
    } = {}

    modelLabels.forEach((modelLabel) => {
      const responseValues: number[] = []
      const freqs: { [key: number]: number } = {}
      ratingChoices.forEach((choice) => {
        freqs[choice] = 0
      })

      // pulls all model responses with specific label, should be one for each evaluation
      const modelResponsesWithLabel = experimentModelResponses.filter(
        (modelResponse) => modelResponse.label === modelLabel
      )
      const userResponsesToQuestionAndModel = userResponsesToQuestion.filter(
        (userResponse) =>
          userResponse.modelResponseId &&
          modelResponsesWithLabel
            .map((modelResponse) => modelResponse.id)
            .includes(userResponse.modelResponseId)
      )
      userResponsesToQuestionAndModel.forEach((userResponse) => {
        const responseObj =
          userResponse.response as EvaluationQuestionResponsePayloadRating
        freqs[responseObj.responseValue] += 1
        responseValues.push(responseObj.responseValue)
      })

      modelLabelToStats[modelLabel] = {
        mean: roundDecimalToXPlaces(_.mean(responseValues), 2),
        median: roundDecimalToXPlaces(
          findMedianOfNumberArray(responseValues),
          2
        ),
        ratingFrequencies: freqs,
        totalRatingResponses: userResponsesToQuestionAndModel.length,
      }
    })

    resultsComponent = (
      <>
        <h1 className="text-lg font-semibold">Results</h1>
        <div className="flex justify-start gap-4">
          {modelLabels.map((modelLabel) => {
            return (
              <Card key={modelLabel}>
                <CardHeader>
                  <div className="mt-2 text-sm font-semibold">
                    Model {modelLabel}
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="mt-2 text-sm font-semibold">
                    Ratings Summary
                  </div>
                  <div className="text-sm text-muted">
                    Mean: {modelLabelToStats[modelLabel].mean}
                  </div>
                  <div className="text-sm text-muted">
                    Median: {modelLabelToStats[modelLabel].median}
                  </div>
                  <div className="text-sm text-muted">
                    Total ratings:{' '}
                    {modelLabelToStats[modelLabel].totalRatingResponses}
                  </div>

                  <div className="mt-2 text-sm font-semibold">
                    Rating frequencies:
                  </div>
                  <div className="break-words text-sm text-muted">
                    {JSON.stringify(
                      modelLabelToStats[modelLabel].ratingFrequencies
                    )}
                  </div>
                </CardContent>
              </Card>
            )
          })}
        </div>
      </>
    )
  } // TODO implement for other question types

  return (
    <>
      <EvaluationQuestionCard
        key={question.id}
        evaluationQuestion={question}
        orderRank={idx + 1}
        footerChildren={resultsComponent}
      />
    </>
  )
}

export default ExperimentManagement
