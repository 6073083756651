import _ from 'lodash'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { TaskType } from 'types/task'

// Ensure correct path
import { SafeRecord } from 'utils/safe-types'

import { Prompt, Example, LibraryItem } from './library-types'

// Filter keys used as search parameters in the URL
export enum LibraryFilterKey {
  SEARCH = 'qs',
  DATE_RANGE = 'qdr',
  STARRED = 'qf',
  CLIENT_MATTER = 'qcm',
  TASK_TYPE = 'qtt',
  CATEGORY = 'qct',
  DOCUMENT_COUNT = 'qdc',
  WORKSPACE_VISIBLE = 'qvw',
  DOCUMENT_TYPE = 'qdt',
  PRACTICE_AREA = 'qpa',
  VISIBILITY_SCOPE = 'qtab',
}

export interface FilterFunction<T> {
  (record: T): boolean
}

interface State<T extends LibraryItem> {
  filterFunctions: Record<string, FilterFunction<T>>
  filterValues: SafeRecord<string, string>
}

interface Action<T extends LibraryItem> {
  registerFilter: (
    key: string,
    filterFunction: FilterFunction<T> | null
  ) => void
  setFilterValue: (key: string, value: string) => void
  setFilterValues: (values: SafeRecord<string, string>) => void
}

const createLibraryFilterStore = <T extends LibraryItem>() =>
  create(
    devtools(
      immer<State<T> & Action<T>>((set) => ({
        filterFunctions: {},
        registerFilter: (
          key: string,
          filterFunction: FilterFunction<T> | null
        ) => {
          set((state) => {
            if (filterFunction === null) {
              const newFilters = { ...state.filterFunctions }
              delete newFilters[key]
              return { filterFunctions: newFilters }
            } else {
              return {
                filterFunctions: {
                  ...state.filterFunctions,
                  [key]: filterFunction,
                },
              }
            }
          })
        },
        filterValues: {},
        setFilterValue: (key: string, value: string) => {
          set((state) => {
            if ((state.filterValues[key] ?? '') === value.trim()) {
              // No value changes, no need to update
              return {}
            }
            if (value.trim() === '') {
              const newValues = { ...state.filterValues }
              delete newValues[key]
              return { filterValues: newValues }
            } else {
              return {
                filterValues: {
                  ...state.filterValues,
                  [key]: value,
                },
              }
            }
          })
        },
        setFilterValues: (values: SafeRecord<string, string>) => {
          set((state) => {
            if (_.isEqual(state.filterValues, values)) {
              // No value changes, no need to update
              return {}
            } else {
              return { filterValues: values }
            }
          })
        },
      }))
    )
  )

export const usePromptFilterStore = createLibraryFilterStore<
  Prompt & { kind: TaskType }
>()
export const useExampleFilterStore = createLibraryFilterStore<Example>()
