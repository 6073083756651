export const ASSISTANT_INTERNET_TOOLTIP_HELP = `This allows Harvey to access real time information on the internet to answer your query.`

export const ASSISTANT_OPEN_ENDED_HELP = `<h4>About</h4>
<p class="ql-align-justify">In Assistant, you can query Harvey with or without uploading documents. When one or more documents are uploaded, Harvey will generate a response to your query based on your document(s) and include linked references to the document(s).</p>
<h4>Tips for using Assistant</h4>
<ul>
  <li>Consider asking for a table, email, memo, outline, bullet points, or other format.</li>
  <li>Consider asking for an informal, professional, firm, or other tone.</li>
  <li>Consider specifying the intended audience (e.g., “a client with little acquisition experience” or “a senior in-house attorney”).</li>
  <li>Queries should be explicit, descriptive, and specific. Providing some extra direction in a query can go a long way (e.g., saying “holders of Company Common Stock” rather than “common holders,” or saying “written legal opinion” rather than only “legal opinion”).</li>
  <li>Break up complex queries into simpler components, especially when there is an analytical or subjective task contained in your query.</li>
  <li>Provide some background facts and context (e.g., a legal jurisdiction) in your query.</li>
</ul>
<h4>Important reminders</h4>
<ul>
  <li>Document uploads must be PDF (.pdf), Word (.docx), Excel (.xlsx) files, or a zip file containing these files.</li>
  <li><b>Always double-check outputs for accuracy and completeness.</b> Responses may contain hallucinations. Be particularly careful when asking about specific cases, statutes, company filings, or other sources that may expose gaps in Harvey’s training data.</li>
  <li>When documents are uploaded, text is interpreted left to right, all the way across the page. This means Harvey’s Assistant feature is not able to recognize multi-column pages, vertical text, or images at this time.</li>
  <li>Responses may vary based on subtle changes to query language and even across multiple submissions of the same query. Consider refining your query to explore different answers.</li>
  <li>If one or more documents have been uploaded, you do not need to ask Harvey for references or citations, as they will be generated automatically.</li>
  <li>Assistant is not intended to be a proofreading tool at this time.</li>
</ul>`

export const ASSISTANT_WITHOUT_OPEN_ENDED_HELP = `<h4>About</h4>
<p class="ql-align-justify">In Assistant, Harvey will generate a response to your query based on the documents you upload. The response will include linked references to your documents.</p>
<h4>Tips for using Assistant</h4>
<ul>
 <li>Consider asking for a table, email, memo, outline, bullet points, or other format.</li>
 <li>Consider asking for an informal, professional, firm, or other tone.</li>
 <li>Consider specifying the intended audience (e.g., “a client with little acquisition experience” or “a senior in-house attorney”).</li>
 <li>Queries should be explicit, descriptive, and specific. Providing some extra direction in a query can go a long way (e.g., saying “holders of Company Common Stock” rather than “common holders”, or saying “written legal opinion” rather than only “legal opinion”).</li>
 <li>Break up complex queries into simpler components, especially when there is an analytical or subjective task contained in your query.</li>
 <li>Provide some background facts and context (e.g., a legal jurisdiction) in your query.</li>
</ul>
<h4>Important reminders</h4>
<ul>
 <li>Document uploads must be PDF (.pdf), Word (.docx), Excel (.xlsx) files, or a zip file containing these files.</li>
 <li><b>Always double-check outputs for accuracy and completeness.</b> Responses may contain hallucinations. Be particularly careful when asking about information beyond the uploaded documents, such as specific cases, statutes, company filings, or other sources that may expose gaps in Harvey’s training data.</li>
 <li>Document text is interpreted left to right, all the way across the page. This means Harvey’s Assistant feature is not able to recognize multi-column pages, vertical text, or images at this time.</li>
 <li>Responses may vary based on subtle changes to query language and even across multiple submissions of the same query. Consider refining your query to explore different answers.</li>
 <li>You do not need to ask Harvey for references or citations, as they will be generated automatically.</li>
 <li>Assistant is not intended to be a proofreading tool at this time.</li>
</ul>`

export const ASSISTANT_V2_MODE_HELP = `<h3>Choosing a mode</h3>
<p>Assistant has two modes, Assist and Draft. These modes feature different ways of interacting with Assistant and different styles of output.</p>

<p><strong>Assist mode is built for quick Q&A-style iteration.</strong> Assist mode is helpful for quickly learning about something, getting a high-level overview, or finding specific information within a document. After the initial response, you can ask follow-up questions to go deeper.</p>

<p><strong>Draft mode is best for generating content like emails, contract clauses, and sections of briefs.</strong> Draft mode is helpful for gaining in-depth understanding, drafting professional communication, or subjective tasks such as suggesting counterarguments. After the initial draft, you can request revisions and view the changes.</p>

Generally, Assist mode is more concise and provides clear, organized overviews. Draft mode is more verbose and helps you write sophisticated content.`

export const ASSISTANT_V2_OPEN_ENDED_HELP = `<p class="ql-align-justify">In Assistant, you can query Harvey with or without uploading documents. When one or more documents are uploaded, Harvey will generate a response to your query based on your document(s) and include linked references to the document(s).</p>

${ASSISTANT_V2_MODE_HELP}

<h3>Important reminders</h3>
<ul>
  <li><strong>Always double-check outputs for accuracy and completeness.</strong> Responses may contain hallucinations. Be particularly careful when asking about specific cases, statutes, company filings, or other sources that may expose gaps in Harvey’s training data.</li>
  <li>You can customize how Harvey responds to you by specifying additional information in your query, like a specific format, tone, or intended audience (e.g., “a professional email for a client with little acquisition experience”).</li>
  <li>Queries should have a specific request and include context on the documents.</li>
  <li>When documents are uploaded, text is interpreted left to right, all the way across the page. This means Assistant is unable to recognize multi-column pages, vertical text, or images.</li>
</ul>`

export const ASSISTANT_V2_WITHOUT_OPEN_ENDED_HELP = `<p class="ql-align-justify">In Assistant, you can query Harvey and generate a response on any topic.</p>

${ASSISTANT_V2_MODE_HELP}

<h3>Important reminders</h3>
<ul>
  <li><strong>Always double-check outputs for accuracy and completeness.</strong> Responses may contain hallucinations. Be particularly careful when asking about specific cases, statutes, company filings, or other sources that may expose gaps in Harvey’s training data.</li>
  <li>You can customize how Harvey responds to you by specifying additional information in your query, like a specific format, tone, or intended audience (e.g., “a professional email for a client with little acquisition experience”).</li>
</ul>`

export const FILE_DROPZONE_INFO_TEXT =
  'Upload up to 50 files, max 20MB each. Total size of all files cannot exceed 100MB'

export const ASSISTANT_SAVE_PROMPT_TOOLTIP_HELP = `Save a prompt to Library`
