import React, { useMemo } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'
import { Pie, PieChart } from 'recharts'

import { UsageData } from 'models/usage-data'

import { readableNumber, cn } from 'utils/utils'

import { Card, CardContent, CardHeader } from 'components/ui/card'
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  DATA_VIZ_COLORS_GRAY,
  DATA_VIZ_COLORS_JADE,
  DATA_VIZ_COLORS_VIOLET,
} from 'components/ui/chart-components/chart'
import { Separator } from 'components/ui/separator'
import Skeleton from 'components/ui/skeleton'

import { CHART_CONFIGS, ChartConfigType } from './dashboard-page'

export interface PieChartCardProps {
  usageData: UsageData | undefined
  isLoading: boolean
  unit: ChartConfigType
}

const PieChartCard = ({ usageData, isLoading, unit }: PieChartCardProps) => {
  const totalGraphCount = useMemo(() => {
    if (_.isNil(usageData)) return 0
    switch (unit) {
      case CHART_CONFIGS.users:
        return usageData.aggregate.userCountTotal
      case CHART_CONFIGS.queries:
        return usageData.aggregate.queryCountTotal
      default:
        console.error(`Unknown unit: ${unit}`)
        return 0
    }
  }, [usageData, unit])

  // descending order of count
  const aggregateUserCountByTypeSorted = useMemo(() => {
    if (_.isNil(usageData)) return []
    return Object.entries(usageData.aggregate.userCountByType).sort(
      ([, countA], [, countB]) => {
        return countB - countA // decreasing order
      }
    )
  }, [usageData])

  const aggregateQueryCountByTypeSorted = useMemo(() => {
    if (_.isNil(usageData)) return []
    return Object.entries(usageData.aggregate.queryCountByType).sort(
      ([, countA], [, countB]) => {
        return countB - countA // decreasing order
      }
    )
  }, [usageData])

  const chartData = useMemo(() => {
    switch (unit) {
      // map to data shape expected by pie chart
      // array of objects with name and value properties
      case CHART_CONFIGS.users:
        return aggregateUserCountByTypeSorted.map(
          ([product, count], index) => ({
            product,
            count,
            fill: `var(--color-${index})`,
          })
        )

      case CHART_CONFIGS.queries:
        return aggregateQueryCountByTypeSorted.map(
          ([product, count], index) => ({
            product,
            count,
            fill: `var(--color-${index})`,
          })
        )
      default:
        console.error(`Unknown unit: ${unit}`)
        return []
    }
  }, [unit, aggregateUserCountByTypeSorted, aggregateQueryCountByTypeSorted])

  const chartConfig = useMemo(() => {
    if (_.isNil(usageData)) return {}

    // don't use the lightest color in each palette
    const dataVizColorJade = DATA_VIZ_COLORS_JADE.slice(0, -1)
    const dataVizColorViolet = DATA_VIZ_COLORS_VIOLET.slice(0, -1)
    const dataVizColorGray = DATA_VIZ_COLORS_GRAY.slice(0, -1)

    let dataVizColorsSelected = [
      ...dataVizColorJade,
      ...dataVizColorViolet,
      ...dataVizColorGray,
    ]

    if (usageData.distinctTypes.length <= dataVizColorJade.length) {
      // 9 or fewer colors needed
      dataVizColorsSelected = dataVizColorJade
    } else if (
      usageData.distinctTypes.length <=
      dataVizColorJade.length + dataVizColorViolet.length
    ) {
      // 18 or fewer colors needed

      const numJadeSlices = Math.min(
        Math.ceil(usageData.distinctTypes.length / 2),
        dataVizColorJade.length
      )

      dataVizColorsSelected = [
        ...dataVizColorJade.slice(0, numJadeSlices),
        ...dataVizColorViolet.slice(
          0,
          usageData.distinctTypes.length - numJadeSlices
        ),
      ]
    } else if (
      usageData.distinctTypes.length <=
      dataVizColorJade.length +
        dataVizColorViolet.length +
        dataVizColorGray.length
    ) {
      // 27 or fewer colors needed
      const numJadeSlices = Math.min(
        Math.ceil(usageData.distinctTypes.length / 3),
        dataVizColorJade.length
      )
      const numVioletSlices = Math.min(
        Math.ceil(usageData.distinctTypes.length / 3),
        dataVizColorViolet.length
      )
      const numGraySlices =
        usageData.distinctTypes.length - numJadeSlices - numVioletSlices

      dataVizColorsSelected = [
        ...dataVizColorJade.slice(0, numJadeSlices),
        ...dataVizColorViolet.slice(0, numVioletSlices),
        ...dataVizColorGray.slice(0, numGraySlices),
      ]
    }

    return usageData.distinctTypes.reduce(
      (acc, _, index) => {
        acc[index] = {
          color: dataVizColorsSelected[index % dataVizColorsSelected.length],
        }
        return acc
      },
      {} as Record<string, { color: string }>
    )
  }, [usageData])

  return (
    <Card className="rounded-lg border">
      <CardHeader className="pl-2 pr-2 pt-2">
        <div className="flex items-center justify-between">
          <div className="text-neutral-950 truncate text-sm font-semibold">
            {unit.pieChart.title}
          </div>
          {/* TODO correct color ? */}
          <div className="truncate text-sm text-muted">
            Total: {readableNumber(totalGraphCount)}{' '}
            {pluralize(unit.tooltipLabel.toLowerCase(), totalGraphCount)}
          </div>
        </div>
      </CardHeader>
      <Separator className="w-full" />

      <CardContent
        className={cn('h-80', { 'p-0': !isLoading, 'pt-6': isLoading })}
      >
        {isLoading && <Skeleton className="h-full" />}
        {!isLoading && totalGraphCount > 0 && (
          <ChartContainer config={chartConfig}>
            <PieChart width={800} height={800}>
              <Pie
                data={chartData}
                dataKey="count"
                nameKey="product"
                cx="50%"
                cy="50%"
                outerRadius={124}
                blendStroke={chartData.length === 1}
              />
              <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            </PieChart>
          </ChartContainer>
        )}
        {!isLoading && totalGraphCount === 0 && (
          <div className="flex h-full items-center justify-center">
            <p className="text-sm text-muted">{unit.pieChart.emptyStateText}</p>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default PieChartCard
