import { GridApi, ColDef, IRowNode, ValueGetterParams } from 'ag-grid-community'
import { isNil } from 'lodash'

import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { HistoryItem } from 'types/history'

import { SafeRecord } from 'utils/safe-types'
import { TaskStatus, Source } from 'utils/task'
import { parseIsoString } from 'utils/utils'

import {
  ReviewColumn,
  QueryQuestions,
  ColumnDataType,
  ReviewAnswer,
  ReviewError,
} from 'components/vault/utils/vault'
import { FilterType } from 'components/vault/utils/vault-data-grid-filters-store'
import {
  getFoldersOnPath,
  getDisplayAnswer,
  isAnswerEmpty,
} from 'components/vault/utils/vault-helpers'

import { ReviewHistoryItem } from './vault-query-detail-store'

export interface QuestionColumnDef extends ColDef {
  originalQuestion: string
  questionId: string
  folderPath: string
  columnDataType: ColumnDataType
  eventId?: number
  columnId?: string
}

const computeIsQueryLoading = (
  historyItem: HistoryItem | ReviewHistoryItem | null | undefined
) => {
  if (!historyItem) return false

  return (
    historyItem.status != TaskStatus.COMPLETED &&
    historyItem.status != TaskStatus.CANCELLED &&
    historyItem.status != TaskStatus.ERRORED
  )
}

const getDisplayedRows = (gridApi: GridApi) => {
  const displayedRows: string[] = []
  gridApi.forEachNodeAfterFilterAndSort((node) => {
    if (node.data && node.data.file) {
      displayedRows.push(node.data.file.id)
    } else if (node.id) {
      displayedRows.push(node.id)
    }
  })
  return displayedRows
}

const reorderColumnIds = (
  columnIds: string[],
  toIndex: number,
  movedColumnId: string
) => {
  // 1. remove the moved columnId from the original position
  const originalIndex = columnIds.indexOf(movedColumnId)
  if (originalIndex === -1) {
    return columnIds
  }
  // 2. remove the column from its original position
  const newColumnIds = columnIds.splice(originalIndex, 1)

  // 3. insert the moved columnId at the new position
  newColumnIds.splice(toIndex, 0, movedColumnId)
  return newColumnIds
}

const customComparator = (
  valueA: string,
  valueB: string,
  nodeA: IRowNode<any>,
  nodeB: IRowNode<any>,
  isDescending: boolean
  // eslint-disable-next-line max-params
) => {
  const answersA = nodeA.data.answers
  const answersB = nodeB.data.answers
  const answerA = answersA.find(
    (answer: ReviewAnswer) => answer.text === valueA && answer.long
  )
  const answerB = answersB.find(
    (answer: ReviewAnswer) => answer.text === valueB && answer.long
  )
  const isAnswerAEmpty = !answerA || isAnswerEmpty(answerA.text)
  const isAnswerBEmpty = !answerB || isAnswerEmpty(answerB.text)
  if (isAnswerAEmpty && isAnswerBEmpty) {
    return 0
  }
  if (isAnswerAEmpty) {
    // if answerA is empty, then we want to put it to the end of the list
    return isDescending ? -1 : 1
  }
  if (isAnswerBEmpty) {
    // if answerB is empty, then we want to put it to the beginning of the list
    return isDescending ? 1 : -1
  }
  if (
    answerA.columnDataType === ColumnDataType.date &&
    answerB.columnDataType === ColumnDataType.date
  ) {
    const dateA = parseIsoString(answerA.text)
    const dateB = parseIsoString(answerB.text)
    return dateA > dateB ? 1 : -1
  }
  return valueA > valueB ? 1 : -1
}

interface CreateGridColumnDefsProps {
  gridApi: GridApi
  shouldGroupRows: boolean
  historyColumns: ReviewColumn[]
  pendingQueryQuestions: QueryQuestions[]
  eventId?: number
}

const createGridColumnDefs = ({
  gridApi,
  shouldGroupRows,
  historyColumns,
  pendingQueryQuestions,
  eventId,
}: CreateGridColumnDefsProps) => {
  const columnDefs: Array<ColDef | QuestionColumnDef> = [
    {
      valueGetter: (params: ValueGetterParams) => {
        // when we have row grouping, we need to get the index from the parent
        // and subtract it from the row index because group rows also have an index
        // and we don't want to count the group rows when determining the row index
        const node = params.node
        const rowIndex = node?.rowIndex ?? 0
        if (isNil(node?.parent?.rowIndex)) return rowIndex + 1
        const parentRowChildIndex = node?.parent?.childIndex ?? 0
        return rowIndex - parentRowChildIndex
      },
      field: 'row',
      headerName: '#',
      type: 'number',
      width: 48,
      resizable: false,
      pinned: 'left',
    },
    {
      field: 'name',
      headerName: 'Name',
      columnDataType: ColumnDataType.string,
      type: 'document',
      pinned: 'left',
      minWidth: 256,
      flex: 4,
    },
  ]

  if (shouldGroupRows) {
    columnDefs.push({
      field: 'folderPath',
      headerName: 'Folder path',
      type: 'hidden',
      rowGroup: true,
    })
  }

  historyColumns.forEach((eventColumn: ReviewColumn) => {
    if (eventColumn.isHidden) {
      return
    }
    const column = {
      field: eventColumn.displayId.toString(),
      eventId: eventId,
      columnId: eventColumn.id.toString(),
      questionId: eventColumn.displayId.toString(),
      headerName: eventColumn.header,
      columnDataType: eventColumn.dataType,
      originalQuestion: eventColumn.fullText,
      minWidth: 240,
      flex: 3,
      type:
        eventColumn.dataType === ColumnDataType.date
          ? FilterType.DATE
          : FilterType.TEXT,
      comparator: customComparator,
    }
    columnDefs.push(column)
  })

  pendingQueryQuestions.forEach((question: QueryQuestions) => {
    const column = {
      field: question.id,
      questionId: question.id,
      columndId: question.columnId,
      headerName: question.header,
      columnDataType: question.columnDataType,
      originalQuestion: question.text,
      minWidth: 240,
      flex: 3,
      type:
        question.columnDataType === ColumnDataType.date
          ? FilterType.DATE
          : FilterType.TEXT,
      comparator: customComparator,
    }
    columnDefs.push(column)
  })

  // Finally push gutter column
  columnDefs.push({
    field: 'gutter',
    headerName: 'gutter',
    type: 'gutter',
    resizable: false,
    width: 20,
    // Make it really small so it can flex but not taking up space larger than 20 px by default
    flex: 0.01,
  })

  gridApi.updateGridOptions({
    columnDefs: columnDefs,
  })
}

export enum GridTransactionAction {
  ADD = 'add',
  UPDATE = 'update',
}
interface AddFilesToGridProps {
  gridApi: GridApi
  setAgGridHeaderHeight: (height: number) => void
  files: VaultFile[]
  folderIdToVaultFolder: SafeRecord<string, VaultFolder>
  isLoading: boolean
  gridAction: GridTransactionAction
  isDryRun?: boolean
  fileIdToSources?: SafeRecord<string, Source[]>
  fileIdToAnswers?: SafeRecord<string, ReviewAnswer[]>
  fileIdToErrors?: SafeRecord<string, ReviewError[]>
  questions?: QueryQuestions[]
  suppressedFileIdsSet?: Set<string>
  processedFileIdsSet?: Set<string>
}

interface GetDisplayTextProps {
  answer: ReviewAnswer | undefined
  index: number
  isDryRun: boolean
  isLoading: boolean
  isErrorSuppressed: boolean
}

const getDisplayText = ({
  answer,
  index,
  isDryRun,
  isLoading,
  isErrorSuppressed,
}: GetDisplayTextProps) => {
  if (isDryRun && index > 0) {
    // if we are in dry run mode, then we want to show processing text for
    // the first row and then show empty text for the rest of the rows
    return '<DRY_RUN_EMPTY_CELL>'
  }
  if (!answer?.text && isLoading && !isErrorSuppressed) {
    // If the answer is empty and the file is still processing, then we want to show processing text
    return 'Processing…'
  }
  if (!answer?.text) {
    // If the answer is empty, then we want to show empty text
    return ''
  }
  return getDisplayAnswer(answer)
}

const addFilesToGrid = ({
  gridApi,
  setAgGridHeaderHeight,
  files,
  folderIdToVaultFolder,
  isLoading,
  gridAction,
  isDryRun,
  fileIdToSources,
  fileIdToAnswers,
  fileIdToErrors,
  suppressedFileIdsSet,
  questions,
}: AddFilesToGridProps) => {
  gridApi.setGridOption('headerHeight', 32)
  setAgGridHeaderHeight(32)

  const rowData = files.map((file, idx) => {
    const fileId = file.id
    const fileName = file.name

    const cells: { [key: string]: string } = {}
    const sources = fileIdToSources ? fileIdToSources[fileId] || [] : []
    const answers = fileIdToAnswers ? fileIdToAnswers[fileId] || [] : []
    const errors = fileIdToErrors ? fileIdToErrors[fileId] || [] : []
    const isErrorSuppressed = suppressedFileIdsSet
      ? suppressedFileIdsSet.has(fileId)
      : false

    const folderId = file.vaultFolderId
    const foldersOnPath =
      getFoldersOnPath(folderId, folderIdToVaultFolder) ?? []

    // once we get the folders on path, we want to drop the first folder because it is the root folder
    // and we are not showing the root folder in the group row
    const folderPath = foldersOnPath
      .slice(1)
      .map((f: VaultFolder) => f.name)
      .join('/')

    questions?.forEach((question) => {
      const questionId = question.id
      // TODO: change to answer.short once v2 algorithm is ready
      const questionAnswer = answers.find(
        (answer) => answer.columnId === questionId && answer.long
      )
      const longDisplayText = getDisplayText({
        answer: questionAnswer,
        index: idx,
        isDryRun: isDryRun ?? false,
        isLoading: isLoading,
        isErrorSuppressed: isErrorSuppressed,
      })

      cells[questionId] = longDisplayText
    })

    return {
      id: fileId,
      name: fileName,
      file: file,
      folderPath: folderPath,
      errors: isErrorSuppressed ? [] : errors,
      sources: sources,
      answers: answers,
      ...cells,
    }
  })

  gridApi.applyTransactionAsync({ [gridAction]: rowData }, () => {
    if (!fileIdToErrors) return
    const fileIdsWithErrors = Object.keys(fileIdToErrors)
    gridApi.refreshCells({
      force: true,
      rowNodes: rowData
        .map((row) => gridApi.getRowNode(row.id))
        .filter(Boolean)
        .filter((row) =>
          fileIdsWithErrors.includes(row!.id!)
        ) as IRowNode<any>[],
    })
  })
}

const computeNextColumnId = (columns: (QueryQuestions | ReviewColumn)[]) => {
  if (!columns || columns.length === 0) return 1
  const columnIds = columns.map((column) => {
    if ('displayId' in column) {
      return column.displayId
    }
    return parseInt(column.id)
  })
  return Math.max(...columnIds) + 1
}

export {
  computeIsQueryLoading,
  getDisplayedRows,
  reorderColumnIds,
  createGridColumnDefs,
  addFilesToGrid,
  computeNextColumnId,
}
