import React from 'react'

import { ColumnDef } from '@tanstack/react-table'

import { HistoryItem } from 'types/history'

import {
  AssistantKindCell,
  AssistantOwnerCell,
  AssistantQueryCell,
  AssistantSourcesCell,
  AssistantTimeCell,
} from 'components/assistant-v2/components/assistant-cells'

export const getAssistantSharingColumns = () => {
  const columns: Array<ColumnDef<HistoryItem>> = [
    {
      id: 'name',
      header: 'Name',
      cell: ({ row }) => <AssistantQueryCell row={row} />,
      size: 720,
    },
    {
      id: 'userId',
      header: 'Owner',
      cell: ({ row }) => <AssistantOwnerCell row={row} />,
      size: 128,
    },
    {
      id: 'kind',
      header: 'Type',
      cell: ({ row }) => <AssistantKindCell row={row} />,
      size: 128,
    },
    {
      id: 'sources',
      header: 'Sources',
      cell: ({ row }) => <AssistantSourcesCell row={row} />,
      size: 160,
    },
    {
      id: 'createdAt',
      header: 'Created',
      cell: ({ row }) => <AssistantTimeCell row={row} />,
      size: 160,
    },
  ]
  return columns
}
