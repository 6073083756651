import { Row } from '@tanstack/react-table'

import { UserInfo } from 'models/user-info'
import { DocumentClassificationTag } from 'openapi/models/DocumentClassificationTag'
import { EventKind } from 'openapi/models/EventKind'
import { ReviewEventRunType } from 'openapi/models/ReviewEventRunType'
import { Tag } from 'openapi/models/Tag'
import { VaultFile } from 'openapi/models/VaultFile'
import { VaultFolder } from 'openapi/models/VaultFolder'
import { Maybe } from 'types'
import { FileType } from 'types/file'

import { SafeRecord } from 'utils/safe-types'
import { TaskStatus } from 'utils/task'

export const SUB_MENU_HEIGHT = 256
export const DOCUMENT_CLASSIFICATION_TOOLTIP_TEXT = `Harvey will automatically categorize your document, but you can override if you'd like. Harvey can use this to better understand your document.`

export const HIGHLIGHT_COLOR = '#1452f133'
export const EXPIRATION_URL_KEY = 'se'
export const NUM_ALL_QUERIES_TO_FETCH = 100

export const folderIdSearchParamKey = 'folder_id'
export const fileIdSearchParamKey = 'file_id'
export const queryIdSearchParamKey = 'queryId'
export const sourceIdSearchParamKey = 'source_id'
export const questionIdSearchParamKey = 'question_id'
export const viewSearchParamKey = 'view'
export const filtersSearchParamKey = 'filters'
export const homePageTabSearchParamKey = 'tab'
export const projectDetailPageTabSearchParamKey = 'project_detail_tab'

export const newProjectPathRaw = 'new'
export const newProjectPath = `/${newProjectPathRaw}/`
export const projectsPathRaw = 'projects'
export const projectsPath = `/${projectsPathRaw}/`
export const filesPathRaw = 'files'
export const filesPath = `/${filesPathRaw}/`
export const queriesPathRaw = 'queries'
export const queriesPath = `/${queriesPathRaw}/`

export const REMOVE_PARAMS = [
  folderIdSearchParamKey,
  fileIdSearchParamKey,
  queryIdSearchParamKey,
  sourceIdSearchParamKey,
  questionIdSearchParamKey,
  viewSearchParamKey,
  filtersSearchParamKey,
  // want to preserve home page tab state
  // homePageTabSearchParamKey,
  projectDetailPageTabSearchParamKey,
]

const MAX_FILE_SIZE_IN_MB = 100
const MAX_FILE_SIZE_IN_MB_FOR_VAULT_LARGE_FILE_SIZE = 100
export const MAX_EXCEL_FILE_SIZE_IN_MB = 100
const MAX_TOTAL_FILE_SIZE_IN_MB = 5120
const MAX_TOTAL_FILE_SIZE_IN_MB_FOR_VAULT_LARGE_FILE_SIZE = 5120
export const ACCEPTED_FILE_TYPES = [
  FileType.PDF,
  FileType.WORD,
  FileType.WORD_LEGACY,
  FileType.ZIP,
  FileType.EXCEL,
  FileType.EXCEL_LEGACY,
  FileType.POWERPOINT,
  FileType.POWERPOINT_LEGACY,
  FileType.TEXT,
  FileType.CSV,
  FileType.EMAIL,
  FileType.OUTLOOK,
  FileType.RTF,
]

export const maxFileSizeInMb = (userInfo: UserInfo) => {
  return userInfo.IsVaultLargeFileSizeUser || userInfo.IsVaultInternalOnlyUser
    ? MAX_FILE_SIZE_IN_MB_FOR_VAULT_LARGE_FILE_SIZE
    : MAX_FILE_SIZE_IN_MB
}

export const maxTotalFileSizeInMb = (userInfo: UserInfo) => {
  return userInfo.IsVaultLargeFileSizeUser || userInfo.IsVaultInternalOnlyUser
    ? MAX_TOTAL_FILE_SIZE_IN_MB_FOR_VAULT_LARGE_FILE_SIZE
    : MAX_TOTAL_FILE_SIZE_IN_MB
}

// We will use job queue for vault review query, switch to false if we want to use socket directly.
const USE_JOB_QUEUE_FOR_VAULT_REVIEW = true

export const shouldUseJobQueueForVaultReview = (userInfo: UserInfo) => {
  return USE_JOB_QUEUE_FOR_VAULT_REVIEW || userInfo.IsVaultInternalOnlyUser
}

export const DOT_SEPARATOR = ' ・ '

export const MIN_QUERY_LENGTH = 9
export const MIN_QUESTION_CHAR_LENGTH = 8
export const MAX_QUESTION_CHAR_LENGTH = 250
export const MAX_QUESTIONS_LIMIT = 50

export const MAX_REVIEW_FILES_LIMIT_FOR_VAULT_ADD_ON = 150
export const MAX_REVIEW_QUESTIONS_LIMIT_FOR_VAULT_ADD_ON = 10

export const PUSHSHEET_REVIEW_PDF_PERCENTAGE = 50
export const PUSHSHEET_REVIEW_ANSWERS_PERCENTAGE = 50

export const PROJECT_COUNT_DISABLED_TOOLTIP =
  'You have reached the maximum number of projects. Please contact your Harvey admin.'
export const FILE_COUNT_OR_SIZE_OVER_LIMIT_TOOLTIP =
  'You have to remove some files because you’re over the limit'
export const LOADING_REVIEW_QUERY_LIMIT_HELP_TEXT =
  'Loading review query usage…'
export const REVIEW_QUERY_LIMIT_HELP_TEXT =
  'You have reached the review query limit.'

export const ETA_TOOLTIP_TEXT =
  'This is the time taken for our AI to ingest & understand your document. This time may vary based on the size/complexity of the files & time of day.'

export const MAX_TOKEN_LIMIT_MESSAGE =
  '\n\n<i>(response was cut-off due to the query answer being too long. This might be a better query to run in Vault Review instead)</i>'

interface FileHierarchy {
  id: string
  name: string
  prefix: string
  files: FileToUpload[]
  children: FileHierarchy[]
}

// streaming types
interface GenerateQuestionsProps {
  query: string
  folderId: string
}

interface QueryQuestions {
  id: string
  text: string
  header?: string
  columnDataType?: ColumnDataType
  columnId?: string
}

interface QueryAnswer {
  columnId: string
  long: boolean
  text: string
}

interface QueryQuestionsResponseData {
  response: string
  questions: QueryQuestions[]
  questionsLimit: number
  filesLimit: number | null
  maxQuestionCharacterLength: number
  minQuestionCharacterLength: number
}

export type GenerateNNRequestType =
  | 'new'
  | 'retry'
  | 'extra_files'
  | 'extra_columns'
  | 'extra_files_and_columns'
  | 'retry_empty'
  | 'retry_error'

export interface DocumentClassificationAnalyticsData {
  groupingDocumentClassification: string
  typeDocumentClassification: string
  numDocuments: number
}

interface GenerateNNResponseProps {
  requestType: GenerateNNRequestType
  queryId?: string
  query: string
  questions: QueryQuestions[]
  questionsNotAnswered: QueryQuestions[]
  existingQuestions?: QueryQuestions[]
  questionsLimit: number
  filesLimit: number | null
  maxQuestionCharacterLength: number
  minQuestionCharacterLength: number
  dryRun?: boolean
  isWorkflowRepsWarranties?: boolean
  folderId: string
  fileIds: string[]
  documentClassificationAnalyticsData?: DocumentClassificationAnalyticsData[]
  answers?: SafeRecord<string, ReviewAnswer[]>
  existingFileIds?: string[]
  existingProcessedFileIds?: string[]
  existingColumnOrder?: string[]
  clientMatterId?: string
  workflowId?: string
  selectedWorkflowColumnIds?: string[]
  onAuthCallback: (queryId: string) => void
}

export enum ColumnDataType {
  date = 'date',
  duration = 'duration',
  classify = 'classify',
  currency = 'currency',
  numeric = 'numeric',
  extraction = 'extraction',
  freeResponse = 'free_response',

  // not user specified options
  list = 'list',
  empty = 'empty',
  compoundResponse = 'compound_response',
  noFormat = 'no_format',

  // to delete after Review V2
  string = 'string',
  binary = 'binary',
}

export const COLUMN_DATA_TYPES_NOT_TO_BE_USED_FOR_QUESTION = [
  ColumnDataType.list,
  ColumnDataType.empty,
  ColumnDataType.compoundResponse,
  ColumnDataType.string,
  ColumnDataType.binary,
  ColumnDataType.noFormat,
]

export interface ReviewAnswer {
  columnId: string
  long: boolean
  text: string
  columnDataType?: ColumnDataType
}

export interface ReviewError {
  columnId: string
  text: string
}

interface GenerateNNResponseMetadata {
  type:
    | 'title'
    | 'header'
    | 'cell'
    | 'cell_error'
    | 'sources'
    | 'eta'
    | 'processed_file_ids'
    | 'max_token_limit_reached'
    | 'sourced_file_ids'
  fileId?: string
  columnId?: string
  longResponse?: boolean
  columnDataType?: ColumnDataType
}

interface GenerateN1ResponseProps {
  query: string
  folderId: string
  fileIds: string[]
  sourceQueryId?: string
  onAuthCallback: (queryId: string) => void
  documentClassificationAnalyticsData?: DocumentClassificationAnalyticsData[]
}

// fetcher types
interface FileToUpload {
  file: File
  name: string
}

interface JobQueueEtaApiResponseData {
  etaInSeconds: number
  lastCompletedAt: string
}

interface VaultDeleteFolder {
  deletedFilesMetadata: VaultFile[]
  deletedFoldersMetadata: VaultFolder[]
  failedFilesWithError: string[]
  failedFoldersWithError: string[]
}

// vault cell types
export enum VaultItemType {
  project = 'project',
  folder = 'folder',
  file = 'file',
}

export enum VaultItemStatus {
  default = '',
  uploading = 'uploading',
  processing = 'processing',
  failedToUpload = 'failedToUpload',
  recoverableFailure = 'recoverableFailure',
  unrecoverableFailure = 'unrecoverableFailure',
  readyToQuery = 'readyToQuery',
  allFilesFailed = 'allFilesFailed',
  readyToQueryWithFailedFiles = 'readyToQueryWithFailedFiles',
}

type VaultItemBase = {
  id: string
  name: string
  createdAt: string
  updatedAt: string
  status: VaultItemStatus
  totalFiles?: Maybe<number>
  numPages?: Maybe<number>
  size?: Maybe<number>
  parentId?: Maybe<string>
  contentType?: Maybe<string>
  failureReason?: Maybe<string>
  url?: Maybe<string>
  docAsPdfUrl?: Maybe<string>
  disabled?: boolean
  isAlreadySelected?: boolean
  tags?: (Tag | DocumentClassificationTag)[]
}

interface VaultProjectItem extends VaultItemBase {
  type: VaultItemType.project
  data: VaultFolder
  children?: VaultItem[]
}

interface VaultFolderItem extends VaultItemBase {
  type: VaultItemType.folder
  data: VaultFolder
  children?: VaultItem[]
  isSomeAlreadySelected?: boolean
}

interface VaultFileItem extends VaultItemBase {
  type: VaultItemType.file
  data: VaultFile
}

type VaultItem = VaultProjectItem | VaultFolderItem | VaultFileItem

type VaultItemWithIndex = VaultItem & {
  index: string
  isAllDescendantsSelected?: boolean
}

interface CellProps {
  row: Row<VaultItem>
}

interface TimeCellProps extends CellProps {
  timeKey: 'createdAt' | 'updatedAt'
}

export type ReviewEventRun = {
  id: string
  runType: ReviewEventRunType
  query: string
  dryRun: boolean
  createdAt: string
  updatedAt: string
  eta: string | null
}

export type ReviewColumn = {
  id: string
  dataType: ColumnDataType
  displayId: number
  fullText: string
  header: string
  order: number
  isHidden: boolean
  createdAt: string
  updatedAt: string
  reviewEventRunId: string
}

export type ReviewRow = {
  id: string
  fileId: string
  order: number
  isHidden: boolean
  createdAt: string
  updatedAt: string
  reviewEventRunId: string
}

export enum ReviewCellStatus {
  EMPTY = 'EMPTY',
  COMPLETED = 'COMPLETED',
  ERRORED = 'ERRORED',
  ERRORED_IGNORED = 'ERRORED_IGNORED',
}

export type ReviewCell = {
  id: string
  columnDataType: ColumnDataType
  error: string | null
  response: string
  shortResponse: string | null
  status: ReviewCellStatus
  createdAt: string
  updatedAt: string
  reviewColumnId: string
  reviewRowId: string
  reviewEventRunId: string
}

export type ReviewSource = {
  id: string
  cellId: string
  footnote: number
  pageNumber: number
  text: string
  createdAt: string
  updatedAt: string
}

export type ReviewEvent = {
  id: string
  eventId: number
  eventKind: EventKind
  eventStatus: TaskStatus
  eventCreatedAt: string
  // Prefer to use eventUpdatedAt over updatedAt as that's usually the latest timestamp
  eventUpdatedAt: string
  eventCreatorEmail: string
  title: string
  vaultFolderId: string
  userId: string
  questionsLimit: number
  filesLimit: number | null
  runs: ReviewEventRun[]
  columns: ReviewColumn[]
  rows: ReviewRow[]
  cells: ReviewCell[]
  sources: ReviewSource[]
}

export type {
  FileHierarchy,
  // streaming types
  QueryQuestions,
  QueryAnswer,
  QueryQuestionsResponseData,
  GenerateQuestionsProps,
  GenerateNNResponseMetadata,
  GenerateNNResponseProps,
  GenerateN1ResponseProps,

  // fetcher types
  FileToUpload,
  JobQueueEtaApiResponseData,
  VaultDeleteFolder,

  // cell types
  VaultItem,
  VaultItemWithIndex,
  VaultProjectItem,
  CellProps,
  TimeCellProps,
}
