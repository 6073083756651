import React from 'react'

import { ArrowLeft } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'

import { AssistantMode } from 'components/assistant-v2/components/assistant-mode-select'
import { BaseAppPath } from 'components/base-app-path'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { Button } from 'components/ui/button'
import { DialogHeader, DialogTitle } from 'components/ui/dialog'
import Icon from 'components/ui/icon/icon'
import VaultFileExplorer from 'components/vault/components/file-explorer/vault-file-explorer'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import {
  projectsPath,
  queriesPath,
  QueryQuestions,
} from 'components/vault/utils/vault'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import {
  getSelectedFiles,
  columnToQueryQuestion,
} from 'components/vault/utils/vault-helpers'
import useVaultWorkflowStore, {
  WorkflowModalState,
} from 'components/vault/workflows/vault-workflow-store'

/**
 * Dialog contents for adding files to an existing or new blank workflow
 */
const VaultWorkflowAddFiles = ({
  projectId,
  onBackButtonClicked,
  buttonLabel,
  preselectFileIds,
  handleSubmit,
  assistantMode,
}: {
  projectId: string
  onBackButtonClicked: () => void
  buttonLabel: string
  preselectFileIds: Set<string>
  handleSubmit?: (selectedFileIds: string[], mode: AssistantMode) => void
  assistantMode?: AssistantMode
}) => {
  const navigate = useNavigateWithQueryParams()
  const selectedWorkflow = useVaultWorkflowStore(
    useShallow((state) => state.selectedWorkflow)
  )
  const [setWorkflowModalState] = useVaultWorkflowStore(
    useShallow((state) => [state.setWorkflowModalState])
  )
  const selectedRows = useVaultFileExplorerStore((s) => s.selectedRows)
  const [setPendingQueryQuestions, setPendingQueryFileIds, setWorkflow] =
    useVaultQueryDetailStore(
      useShallow((s) => [
        s.setPendingQueryQuestions,
        s.setPendingQueryFileIds,
        s.setWorkflow,
      ])
    )

  return (
    <>
      <DialogHeader className="gap-4">
        <Button
          variant="ghost"
          onClick={onBackButtonClicked}
          className="absolute left-2 top-2"
        >
          <Icon icon={ArrowLeft} />
        </Button>
        <DialogTitle className="w-full text-center">Choose files</DialogTitle>
        <div className="-mx-6 border-b" />
      </DialogHeader>
      <VaultFileExplorer
        isAddingFilesToQuery
        preselectFileIds={preselectFileIds}
        projectId={projectId}
        className="rounded-none"
      />
      <div className="flex items-center justify-between gap-2">
        <p className="text-xs text-muted">
          {selectedRows.length} files selected
        </p>
        <AskHarveyButton
          buttonTitle={buttonLabel}
          disabled={selectedRows.length === 0}
          tooltip={
            selectedRows.length === 0
              ? 'You must select at least one file'
              : undefined
          }
          handleSubmit={() => {
            const selectedFiles = getSelectedFiles(selectedRows)
            const selectedFileIds = selectedFiles.map((file) => file.id)

            if (handleSubmit && assistantMode) {
              handleSubmit(selectedFileIds, assistantMode)
              setWorkflowModalState(WorkflowModalState.None)
              return
            }

            if (selectedRows.length > 0) {
              setPendingQueryFileIds(selectedFileIds)
            }
            if (selectedWorkflow) {
              setWorkflow(selectedWorkflow)
              setPendingQueryQuestions(
                selectedWorkflow.columns
                  .toSorted((a, b) => a.displayId - b.displayId)
                  .map(columnToQueryQuestion) as QueryQuestions[]
              )
            }
            navigate(
              `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}new`
            )
            setWorkflowModalState(WorkflowModalState.None)
          }}
          className="w-fit"
        />
      </div>
    </>
  )
}

export default VaultWorkflowAddFiles
