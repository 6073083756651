import React from 'react'

import { useRunFollowUpQuestion } from 'components/workflows/workflow/discovery/common/use-run-follow-up-question'

import FollowUpInput from './follow-up-input'

export const FollowUpSectionWrapper = () => {
  const runFollowUp = useRunFollowUpQuestion()

  return (
    <div className="mt-3 w-[90%] max-w-[936px] px-8">
      <FollowUpInput onAsk={runFollowUp} />
    </div>
  )
}
