import React from 'react'

import _ from 'lodash'

import {
  ExternalWebSource,
  instanceOfExternalWebSource,
} from 'openapi/models/ExternalWebSource'
import { WorkflowType, WorkflowTypeToTaskType } from 'types/workflows'

import { exportWord } from 'utils/markdown'
import { TaskType } from 'utils/task'
import { hasCompletedStreaming } from 'utils/use-harvey-socket-utils'

import { AppHeaderActions } from 'components/common/app-header-actions'
import { useAuthUser } from 'components/common/auth-context'
import ExportDialog from 'components/common/export/export-dialog'
import { ExportOptionValues } from 'components/common/export/types'
import Response from 'components/common/response/response'
import { COMPANY_PROFILE_HELP } from 'components/workflows/constants'
import { WorkflowTypeToDetails } from 'components/workflows/workflow-definitions'
import WorkflowLayout from 'components/workflows/workflow/workflow-layout'

import { useCompanyProfileStore } from './store'

type Props = {
  onCancel: () => void
  onReset: () => void
}

const CompanyProfileResponse: React.FC<Props> = ({ onCancel, onReset }) => {
  const userInfo = useAuthUser()
  const workflow = WorkflowTypeToDetails[WorkflowType.COMPANY_PROFILE]
  const {
    response,
    queryId,
    setFeedback,
    feedback,
    isLoading,
    headerText,
    completionStatus,
    annotations,
  } = useCompanyProfileStore()

  const handleExportInner = async (withInlineCitations: boolean) => {
    await exportWord({
      content: response,
      taskType: TaskType.COMPANY_PROFILE,
      includeAnnotation: withInlineCitations,
      queryId,
    })
    return
  }

  const handleExport = async (exportValues: ExportOptionValues) => {
    await handleExportInner(!!exportValues.includeAnnotation)
  }

  const getHrvyInfoMetadata = (identifier: string) => {
    if (_.isEmpty(annotations)) {
      return
    }

    if (!(identifier in annotations)) {
      return
    }

    const annotation = annotations[identifier]

    if (!instanceOfExternalWebSource(annotation)) {
      return
    }

    const { documentUrl: url } = annotation as ExternalWebSource

    return { url }
  }

  const showFeedback =
    hasCompletedStreaming(completionStatus) && !feedback?.closed

  const actionDisabled = isLoading

  return (
    <WorkflowLayout
      workflowType={workflow.type}
      title="Company profile"
      appHeaderActions={
        <>
          <AppHeaderActions
            handleReset={onReset}
            resetDisabled={actionDisabled}
            saveExample={{
              show: userInfo.canSeeSaveExample,
              disabled: actionDisabled,
              params: {
                eventId: String(queryId),
              },
            }}
          />
          <ExportDialog
            hasSources
            onExport={handleExport}
            disabled={actionDisabled}
          />
        </>
      }
      // Adds padding below the response card
      // eslint-disable-next-line prefer-smart-quotes/prefer
      childClassName="after:content-[''] after:block after:h-4 after:shrink-0"
    >
      <Response
        getHrvyInfoMetadata={getHrvyInfoMetadata}
        markdown={response}
        isLoading={isLoading}
        headerText={headerText}
        handleCancel={onCancel}
        emptyStateText={
          userInfo.GetHelpPanel(WorkflowTypeToTaskType[workflow.type])
            ?.content ?? COMPANY_PROFILE_HELP
        }
        expectImageDataUri
        queryId={queryId}
        handleSaveFeedback={showFeedback ? setFeedback : undefined}
      />
    </WorkflowLayout>
  )
}

export default CompanyProfileResponse
