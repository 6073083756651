import _ from 'lodash'

import { EventKind } from 'openapi/models/EventKind'

import { HarveySocketCompletionStatus } from 'utils/use-harvey-socket-utils'
import { ToBackendKeys } from 'utils/utils'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { useAuthUser } from 'components/common/auth-context'

import useVaultSocket from './use-vault-socket'
import {
  GenerateN1ResponseProps,
  GenerateNNResponseProps,
  GenerateQuestionsProps,
  MAX_QUESTIONS_LIMIT,
  MAX_QUESTION_CHAR_LENGTH,
  MIN_QUESTION_CHAR_LENGTH,
  QueryQuestionsResponseData,
  shouldUseJobQueueForVaultReview,
} from './vault'
import { FetchFolderQueryQuestions } from './vault-fetcher'
import { useVaultStore } from './vault-store'

const useVaultStreamingHandler = () => {
  const setQueryId = useVaultStore((state) => state.setQueryId)
  const setTask = useVaultStore((state) => state.setTask)
  const setReviewTask = useVaultStore((state) => state.setReviewTask)
  const markInProgressTaskAsFromHistory = useVaultStore(
    (state) => state.markInProgressTaskAsFromHistory
  )
  const { recordQuerySubmitted, recordQueryCompletion } = useQueryAnalytics(
    EventKind.VAULT_REVIEW
  )
  const { trackEvent } = useAnalytics()

  const userInfo = useAuthUser()
  const selectedClientMatter = useClientMattersStore(
    (s) => s.selectedClientMatter
  )
  const endCallback = (
    queryId: string,
    completionStatus: HarveySocketCompletionStatus,
    taskType: EventKind
  ) => {
    markInProgressTaskAsFromHistory(taskType)
    switch (completionStatus) {
      case HarveySocketCompletionStatus.Completed:
        recordQueryCompletion()
        setTask({
          queryId: queryId,
          completedAt: new Date(),
        })
        break
      case HarveySocketCompletionStatus.Cancelled:
        trackEvent('Vault Query Cancelled', {
          event_kind: taskType,
          query_id: queryId,
        })
        setTask({
          queryId: queryId,
          cancelledAt: new Date(),
        })
        break
      case HarveySocketCompletionStatus.Error:
        trackEvent('Vault Query Failed', {
          event_kind: taskType,
          query_id: queryId,
        })
        setTask({
          queryId: queryId,
          failedAt: new Date(),
        })
        break
      default:
        break
    }
  }

  const {
    initSocketAndSendQuery: initSocketAndSendQueryN1,
    sendCancelRequest: sendCancelRequestN1,
  } = useVaultSocket({
    path: 'vault',
    setter: setTask,
    endCallback: (queryId, completionStatus) =>
      endCallback(queryId, completionStatus, EventKind.VAULT),
  })

  const {
    initSocketAndSendQuery: initSocketAndSendQueryNN,
    sendCancelRequest: sendCancelRequestNN,
  } = useVaultSocket({
    path: 'vault_review',
    setter: setReviewTask,
    endCallback: (queryId, completionStatus) =>
      endCallback(queryId, completionStatus, EventKind.VAULT_REVIEW),
  })

  const generateQuestions = async (
    props: GenerateQuestionsProps
  ): Promise<QueryQuestionsResponseData> => {
    setReviewTask({
      // empty query ID for showing the loading state in project detail page
      queryId: '',
      query: props.query.trim(),
      isLoading: true,
      headerText: 'Reviewing',
      taskType: EventKind.VAULT_REVIEW,
      questions: [],
    })
    try {
      // take the folder id and query and return the questions
      const response = await FetchFolderQueryQuestions(
        props.folderId,
        props.query.trim()
      )
      setReviewTask({
        queryId: '',
        isLoading: false,
        headerText: '',
      })
      return response
    } catch (error) {
      console.error(error)
      setReviewTask({
        queryId: '',
        isLoading: false,
        headerText: '',
      })
      return {
        response:
          'Error generating questions, please click “Edit query” and try again, or add your own questions below',
        questions: [],
        questionsLimit: MAX_QUESTIONS_LIMIT,
        filesLimit: null,
        maxQuestionCharacterLength: MAX_QUESTION_CHAR_LENGTH,
        minQuestionCharacterLength: MIN_QUESTION_CHAR_LENGTH,
      }
    }
  }

  const generateNNResponse = async (props: GenerateNNResponseProps) => {
    // take the query id & file id and stream title, column headers, and answers for each file
    if (props.requestType === 'new') {
      setQueryId('')
      setReviewTask({
        // empty query ID for showing the loading state in project detail page
        queryId: '',
        query: props.query.trim(),
        isLoading: true,
        headerText: 'Preparing',
        taskType: EventKind.VAULT_REVIEW,
        vaultFolderId: props.folderId,
        numFiles: props.fileIds.length,
        fileIds: props.fileIds,
        startedAt: new Date(),
        questions: props.questions,
        columnHeaders: props.questions.map((question) => ({
          id: question.id,
          text: question.header ?? '',
          columnDataType: question.columnDataType,
        })),
        columnOrder: props.questions.map((q) => q.id),
      })
    } else if (props.requestType === 'extra_files_and_columns') {
      const updatedQuestions = [
        ...(props.existingQuestions ?? []),
        ...props.questions,
      ]
      const updatedColumnOrder =
        props.existingColumnOrder && props.existingColumnOrder.length > 0
          ? [...props.existingColumnOrder, ...props.questions.map((q) => q.id)]
          : [
              ...(props.existingQuestions ?? []).map((q) => q.id),
              ...props.questions.map((q) => q.id),
            ]
      const updatedFileIds = [
        ...(props.existingFileIds ?? []),
        ...props.fileIds.filter((id) => !props.existingFileIds?.includes(id)),
      ]
      setReviewTask({
        // We always query the new vault v2 endpoint for review task
        isFromHistory: userInfo.IsVaultV2User,
        // It's not a dry run if we are extending columns and files for the review query
        dryRun: false,
        queryId: props.queryId,
        isLoading: true,
        headerText: 'Preparing',
        eta: null,
        numFiles: updatedFileIds.length,
        fileIds: updatedFileIds,
        processedFileIds: props.existingProcessedFileIds ?? [],
        // Showing new start time to indicate that the task is in progress
        startedAt: new Date(),
        // Clear the cancelled and failed times
        cancelledAt: null,
        failedAt: null,
        questions: updatedQuestions,
        columnHeaders: updatedQuestions.map((question) => ({
          id: question.id,
          text: question.header ?? '',
          columnDataType: question.columnDataType,
        })),
        questionsNotAnswered: props.questionsNotAnswered,
        columnOrder: updatedColumnOrder,
      })
    } else if (props.requestType === 'extra_columns') {
      const updatedQuestions = [
        ...(props.existingQuestions ?? []),
        ...props.questions,
      ]
      const updatedColumnOrder =
        props.existingColumnOrder && props.existingColumnOrder.length > 0
          ? [...props.existingColumnOrder, ...props.questions.map((q) => q.id)]
          : [
              ...(props.existingQuestions ?? []).map((q) => q.id),
              ...props.questions.map((q) => q.id),
            ]
      setReviewTask({
        // We always query the new vault v2 endpoint for review task
        isFromHistory: userInfo.IsVaultV2User,
        // It's not a dry run if we are extending columns for the review query
        dryRun: false,
        queryId: props.queryId,
        isLoading: true,
        headerText: 'Preparing',
        eta: null,
        processedFileIds: [],
        // Showing new start time to indicate that the task is in progress
        startedAt: new Date(),
        // Clear the cancelled and failed times
        cancelledAt: null,
        failedAt: null,
        questions: updatedQuestions,
        columnHeaders: updatedQuestions.map((question) => ({
          id: question.id,
          text: question.header ?? '',
          columnDataType: question.columnDataType,
        })),
        questionsNotAnswered: props.questionsNotAnswered,
        columnOrder: updatedColumnOrder,
      })
    } else {
      const newFileIds = [
        ...(props.existingFileIds ?? []),
        ...props.fileIds.filter((id) => !props.existingFileIds?.includes(id)),
      ]
      // Filter out the processed file ids that are being retried to show the new progress.
      const filteredOutProcessedFileIds =
        props.existingProcessedFileIds?.filter(
          (id) => !props.fileIds.includes(id)
        ) ?? []
      const filteredOutAnswers = _.pick(
        props.answers,
        filteredOutProcessedFileIds
      )
      setReviewTask({
        // We always query the new vault v2 endpoint for review task
        isFromHistory: userInfo.IsVaultV2User,
        // It's not a dry run if we are retrying the review query
        dryRun: false,
        queryId: props.queryId,
        isLoading: true,
        headerText: 'Preparing',
        eta: null,
        numFiles: newFileIds.length,
        fileIds: newFileIds,
        processedFileIds: filteredOutProcessedFileIds,
        answers: filteredOutAnswers,
        // Showing new start time to indicate that the task is in progress
        startedAt: new Date(),
        // Clear the cancelled and failed times
        cancelledAt: null,
        failedAt: null,
        columnOrder:
          props.existingColumnOrder ?? props.questions.map((q) => q.id),
      })
    }
    const task_type = EventKind.VAULT_REVIEW
    const clientMatterId = userInfo.IsVaultProjectClientMatterUser
      ? props.clientMatterId
      : userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null
    recordQuerySubmitted({
      event_kind: task_type,
      event_id: props.queryId ?? '',
      query_length: props.query.trim().length,
      num_files: props.fileIds.length,
      document_classification: ToBackendKeys(
        props.documentClassificationAnalyticsData ?? []
      ),
    })
    initSocketAndSendQueryNN({
      query: props.query.trim(),
      onAuthCallback: props.onAuthCallback,
      additionalAuthParams: {
        event_id: props.queryId,
        task_type,
        vault_folder_id: props.folderId,
        request_type: props.requestType,
        client_matter_id: clientMatterId,
        pending_query_usage: props.fileIds.length * props.questions.length,
        pending_file_usage:
          props.requestType === 'extra_columns' ? 0 : props.fileIds.length,
        use_job_queue: shouldUseJobQueueForVaultReview(userInfo),
      },
      additionalRequestParams: {
        request_type: props.requestType,
        task_type,
        vault_folder_id: props.folderId,
        file_ids: props.fileIds,
        questions: props.questions,
        questions_not_answered: props.questionsNotAnswered,
        questions_limit: props.questionsLimit,
        files_limit: props.filesLimit,
        max_question_character_length: props.maxQuestionCharacterLength,
        min_question_character_length: props.minQuestionCharacterLength,
        dry_run: props.dryRun ?? false,
        is_workflow_reps_warranties: props.isWorkflowRepsWarranties ?? false,
        workflow_id: props.workflowId ?? null,
        selected_workflow_column_ids: props.selectedWorkflowColumnIds ?? null,
      },
    })
  }

  const generateN1Response = async (
    props: GenerateN1ResponseProps
  ): Promise<void> => {
    setQueryId('')
    setTask({
      // empty query ID for showing the loading state in project detail page
      queryId: '',
      query: props.query.trim(),
      isLoading: true,
      headerText: 'Processing',
      taskType: EventKind.VAULT,
      vaultFolderId: props.folderId,
      numFiles: props.fileIds.length,
      fileIds: props.fileIds,
      startedAt: new Date(),
    })
    const task_type = EventKind.VAULT
    const clientMatterId = userInfo.isClientMattersReadUser
      ? selectedClientMatter?.id
      : null
    recordQuerySubmitted({
      event_kind: task_type,
      query_length: props.query.trim().length,
      num_files: props.fileIds.length,
      document_classification: ToBackendKeys(
        props.documentClassificationAnalyticsData ?? []
      ),
    })
    initSocketAndSendQueryN1({
      query: props.query.trim(),
      onAuthCallback: props.onAuthCallback,
      additionalAuthParams: {
        task_type,
        vault_folder_id: props.folderId,
        client_matter_id: clientMatterId,
      },
      additionalRequestParams: {
        task_type,
        source_event_id: props.sourceQueryId,
        vault_folder_id: props.folderId,
        file_ids: props.fileIds,
      },
    })
  }

  return {
    sendCancelRequestN1,
    sendCancelRequestNN,
    generateQuestions,
    generateN1Response,
    generateNNResponse,
  }
}

export default useVaultStreamingHandler
