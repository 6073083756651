/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DocumentClassificationTagParent = {
    CONTRACT: 'Contract',
    LITIGATION_DOCUMENT: 'Litigation Document',
    REGULATORY_DOCUMENT: 'Regulatory Document',
    FINANCIAL_DOCUMENT: 'Financial Document',
    STATUTORY_LAW: 'Statutory Law',
    EMAIL: 'Email',
    OTHER: 'Other'
} as const;
export type DocumentClassificationTagParent = typeof DocumentClassificationTagParent[keyof typeof DocumentClassificationTagParent];


export function instanceOfDocumentClassificationTagParent(value: any): boolean {
    for (const key in DocumentClassificationTagParent) {
        if (Object.prototype.hasOwnProperty.call(DocumentClassificationTagParent, key)) {
            if (DocumentClassificationTagParent[key as keyof typeof DocumentClassificationTagParent] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DocumentClassificationTagParentFromJSON(json: any): DocumentClassificationTagParent {
    return DocumentClassificationTagParentFromJSONTyped(json, false);
}

export function DocumentClassificationTagParentFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentClassificationTagParent {
    return json as DocumentClassificationTagParent;
}

export function DocumentClassificationTagParentToJSON(value?: DocumentClassificationTagParent | null): any {
    return value as any;
}

