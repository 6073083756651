import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import PSPDFKit from 'pspdfkit'

import useAzureWebPubSub from 'hooks/use-azure-web-pub-sub'
import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { getPdfKitConfig } from 'utils/pspdfkit'

import { TooltipProvider } from 'components/ui/tooltip'

import { AnalyticsProvider } from './common/analytics/analytics-context'
import WelcomeInterstitial from './common/welcome-interstitial'
import WelcomeMessage from './common/welcome-message'
import Sidebar from './sidebar/sidebar'
import { Toaster } from './ui/toaster'

interface AppLayoutProps {
  basePath: string
}

const AppLayout: React.FC<AppLayoutProps> = ({ basePath }) => {
  const navigate = useNavigateWithQueryParams()
  const location = useLocation()

  // setup azure web pub/sub
  useAzureWebPubSub()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(basePath)
    }
  }, [basePath, location, navigate])

  useEffect(() => {
    const config = getPdfKitConfig()
    void PSPDFKit.preloadWorker(config)
  }, [])

  return (
    <AnalyticsProvider>
      <TooltipProvider>
        <div className="safe-h-screen flex items-stretch overflow-hidden">
          <Sidebar />
          <main className="flex grow flex-col">
            <Outlet />
          </main>
          <WelcomeMessage />
          <WelcomeInterstitial />
          <Toaster
            position="bottom-right"
            expand
            closeButton
            visibleToasts={5}
            className="pointer-events-auto list-none"
          />
          {/* need target to load pspdfkit document against for validation */}
          <div
            id="pspdfkit-container"
            className="fixed bottom-0 right-0 hidden h-[1px] w-[1px]"
          />
        </div>
      </TooltipProvider>
    </AnalyticsProvider>
  )
}

export default AppLayout
