import React from 'react'

import _ from 'lodash'

import { Source } from 'utils/task'

import { SourceCard } from 'components/common/source-card/source-card'
import BasicTransition from 'components/ui/basic-transition'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Separator } from 'components/ui/separator'

interface AssistantSourcesProps {
  sources: Source[]
  onSourceClick: (source: Source) => Promise<void>
}

const AssistantSources = ({
  sources,
  onSourceClick,
}: AssistantSourcesProps): JSX.Element => {
  if (_.isEmpty(sources)) {
    return <></>
  }
  const sourcesCleaned = sources.filter(
    (source) => source.text !== 'Harvey is finding sources…'
  )

  const showSources = sourcesCleaned.length > 0

  return (
    <BasicTransition show={showSources}>
      <Card className="border-0" data-testid="assistant--sources">
        <CardHeader className="px-0">
          <CardTitle>References</CardTitle>
          <Separator />
        </CardHeader>
        <CardContent className="space-y-4 p-0">
          {sources.map((source, idx) => (
            <SourceCard
              key={idx}
              title={source.documentName || ''}
              number={String(idx + 1)}
              text={source.text || 'Click to view reference'}
              useLongSnippets
              badges={[
                {
                  text: !_.isNil(source.page)
                    ? `Page ${String(source.page + 1)}`
                    : '',
                  variant: 'secondary',
                },
              ]}
              onClick={() => onSourceClick(source)}
            />
          ))}
        </CardContent>
      </Card>
    </BasicTransition>
  )
}

export default AssistantSources
