import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { cn, findScrollingContainer } from 'utils/utils'

import { FILE_ID_PARAM } from 'components/assistant-v2/utils/assistant-helpers'

interface LayoutProps {
  children: React.ReactNode
}

export const AssistantThreadLayout = ({ children }: LayoutProps) => {
  return <div className="align-stretch flex h-full">{children}</div>
}

interface ContentProps {
  children: React.ReactNode
  className?: string
  contentId?: string
  hasSidebar?: boolean
  sidebar?: React.ReactNode
}

export const AssistantThreadContent = ({
  children,
  className,
  contentId,
  hasSidebar,
  sidebar,
}: ContentProps) => {
  return (
    <div
      className={cn('mx-auto flex w-full max-w-[1000px] px-6', className, {
        // Horizontally center main content with placeholder sidebars on either side.
        // 1640 = (1000px = max width of regular container) + 320px * 2 (= width of sidebar)
        'max-w-[1640px]': hasSidebar,
      })}
    >
      {hasSidebar && <AssistantThreadSidebarPlaceholderLeft />}
      {/* 936 = (1000px = max width of container) - 32px * 2 (= container padding) */}
      <div className="w-full max-w-[936px] grow" id={contentId}>
        {children}
      </div>
      {hasSidebar && (sidebar || <AssistantThreadSidebarPlaceholderRight />)}
    </div>
  )
}

interface SidebarProps {
  children: React.ReactNode
  className?: string
}

export const AssistantThreadSidebar = ({
  children,
  className,
}: SidebarProps) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const [isSticky, setIsSticky] = useState(true)

  useEffect(() => {
    if (!sidebarRef.current) return

    const containerEl = findScrollingContainer(sidebarRef.current)
    if (!containerEl) return

    setIsSticky(sidebarRef.current.offsetHeight <= containerEl.offsetHeight)
  }, [children])

  // Hide the sidebar sections when push sheet is open
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)
  if (fileId) return null

  return (
    <div
      className={cn(
        'box-border w-40 min-w-[auto] grow basis-0 sm:w-80',
        className
      )}
    >
      <div
        className={cn('space-y-4', {
          'sticky top-8': isSticky,
        })}
        ref={sidebarRef}
      >
        {children}
      </div>
    </div>
  )
}

interface SidebarSectionProps {
  children: React.ReactNode
  className?: string
  innerClassName?: string
  title?: string
}

export const AssistantThreadSidebarSection = ({
  children,
  className,
  title,
}: SidebarSectionProps) => {
  return (
    <div
      className={cn(
        'ml-4 w-36 space-y-2 rounded-md border px-2 py-4 sm:ml-8 sm:w-72',
        className
      )}
    >
      {title && <h5 className="px-2 text-sm font-semibold">{title}</h5>}
      {children}
    </div>
  )
}

export const AssistantThreadSidebarSubSection = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div
      className={cn(
        // eslint-disable-next-line prefer-smart-quotes/prefer
        "relative mt-4 px-2 pb-2 pt-4 before:absolute before:-left-2 before:-right-2 before:top-0 before:h-px before:border-t before:border-primary before:content-[''] last:pb-0",
        className
      )}
    >
      {children}
    </div>
  )
}

// Placeholder that doesn't shrink, so it matches sidebar section width
export const AssistantThreadSidebarPlaceholderRight = () => {
  return (
    <AssistantThreadSidebar>
      <AssistantThreadSidebarSection className="invisible">
        {null}
      </AssistantThreadSidebarSection>
    </AssistantThreadSidebar>
  )
}

// Placeholder that shrinks on smaller screens so the main content can take up more space
export const AssistantThreadSidebarPlaceholderLeft = () => {
  return (
    <AssistantThreadSidebar>
      <AssistantThreadSidebarSection className="hidden">
        {null}
      </AssistantThreadSidebarSection>
    </AssistantThreadSidebar>
  )
}
