import { Text, Html, Node, Parent, Root, RootContent } from 'mdast'
import { remark } from 'remark'
import { remarkExtendedTable } from 'remark-extended-table'
import remarkGfm from 'remark-gfm'
import remarkParse from 'remark-parse'

interface HighlightState {
  selectedText: string
  offset: number
  currentOffset: number
  remainingHighlight: string
  highlightStarted: boolean
  highlightedNodes: Node[]
}

// Anchor popover to the last highlighted part
export const HIGHLIGHT_END_ID = 'highlight-end'

export class MarkdownHighlighter {
  private state: HighlightState

  constructor() {
    this.state = {
      selectedText: '',
      offset: 0,
      currentOffset: 0,
      remainingHighlight: '',
      highlightStarted: false,
      highlightedNodes: [],
    }
  }

  public highlight(
    rawResponse: string,
    selectedText: string,
    offset: number
  ): string {
    const processor = remark()
      .use(remarkParse)
      .use(remarkGfm)
      .use(remarkExtendedTable)
    const originalAst = processor.parse(rawResponse)

    this.state = {
      selectedText,
      offset,
      currentOffset: 0,
      remainingHighlight: selectedText,
      highlightStarted: false,
      highlightedNodes: [],
    }

    const newAst = this.traverseAST(originalAst)

    const { highlightedNodes } = this.state
    processor.stringify({
      type: 'root',
      children: highlightedNodes as RootContent[],
    })

    this.resetState()
    return processor.stringify(newAst as Root).trim()
  }

  private resetState(): void {
    this.state = {
      selectedText: '',
      offset: 0,
      currentOffset: 0,
      remainingHighlight: '',
      highlightStarted: false,
      highlightedNodes: [],
    }
  }

  private traverseAST(node: Node): Node | Node[] {
    if (this.isTextNode(node)) return this.processTextNode(node)
    if (this.isParentNode(node)) return this.processParentNode(node)
    return node
  }

  private processTextNode(node: Text): Node | Node[] {
    const { offset, currentOffset, remainingHighlight } = this.state
    const nodeStartOffset = currentOffset
    const nodeEndOffset = currentOffset + node.value.length
    this.state.currentOffset = nodeEndOffset

    if (
      !this.state.highlightStarted &&
      nodeStartOffset <= offset &&
      offset <= nodeEndOffset
    ) {
      return this.handleHighlightStart(node, nodeStartOffset)
    }
    if (this.state.highlightStarted && remainingHighlight.length > 0) {
      return this.handleHighlightContinuation(node)
    }
    return node
  }

  private handleHighlightStart(node: Text, nodeStartOffset: number): Node[] {
    const { offset, remainingHighlight } = this.state
    const beforeHighlight = node.value.slice(0, offset - nodeStartOffset)
    const highlightInNode = node.value.slice(offset - nodeStartOffset)
    const highlightLength = Math.min(
      highlightInNode.length,
      remainingHighlight.length
    )
    this.state.remainingHighlight = remainingHighlight.slice(highlightLength)

    const result: Node[] = []

    if (beforeHighlight) {
      result.push({ type: 'text', value: beforeHighlight } as Text)
    }

    if (highlightLength > 0) {
      const value = `<span class="font-inherit bg-highlight">${highlightInNode.slice(
        0,
        highlightLength
      )}</span>`
      result.push({
        type: 'html',
        value:
          this.state.remainingHighlight.length > 0
            ? value
            : `<mark id="${HIGHLIGHT_END_ID}">${value}</mark>`,
      } as Html)
    }

    if (highlightLength < highlightInNode.length) {
      result.push({
        type: 'text',
        value: highlightInNode.slice(highlightLength),
      } as Text)
    }

    this.state.highlightStarted = true

    return result
  }

  private handleHighlightContinuation(node: Text): Node[] {
    const { remainingHighlight } = this.state
    const highlightLength = Math.min(
      node.value.length,
      remainingHighlight.length
    )
    this.state.remainingHighlight = remainingHighlight.slice(highlightLength)

    const result: Node[] = []

    if (highlightLength > 0) {
      const value = `<span class="font-inherit bg-highlight">${node.value.slice(
        0,
        highlightLength
      )}</span>`
      result.push({
        type: 'html',
        value:
          this.state.remainingHighlight.length > 0
            ? value
            : `<mark id="${HIGHLIGHT_END_ID}">${value}</mark>`,
      } as Html)
    }

    if (highlightLength < node.value.length) {
      result.push({
        type: 'text',
        value: node.value.slice(highlightLength),
      } as Text)
    }
    this.state.highlightStarted = this.state.remainingHighlight.length > 0

    return result
  }

  private processParentNode(node: Parent): Node {
    const newChildren = node.children.flatMap((child) =>
      this.traverseAST(child)
    )
    return { ...node, children: newChildren } as Parent
  }

  private isTextNode(node: Node): node is Text {
    return node.type === 'text'
  }

  private isParentNode(node: Node): node is Parent {
    return 'children' in node
  }
}
