import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { groupBy, keyBy } from 'lodash'
import { ChevronDown, ChevronUp } from 'lucide-react'
import pluralize from 'pluralize'

import { SourceBadge } from 'openapi/models/SourceBadge'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { Maybe } from 'types'

import { Source } from 'utils/task'
import { cn, isAzureBlobPdf } from 'utils/utils'

import { AssistantMessage } from 'components/assistant-v2/types'
import {
  FILE_ID_PARAM,
  getSourceDocumentId,
  MESSAGE_ID_PARAM,
  SOURCE_ID_PARAM,
  sourceUrlModifier,
} from 'components/assistant-v2/utils/assistant-helpers'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { Badge } from 'components/ui/badge'
import BasicTransition from 'components/ui/basic-transition'
import { Button } from 'components/ui/button'
import Citation from 'components/ui/citation'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import { SkeletonBlock } from 'components/ui/skeleton'

import {
  AssistantThreadSidebarSection,
  AssistantThreadSidebarSubSection,
} from './assistant-thread-layout'

interface Props {
  className?: string
  isStreaming?: boolean
  allDocuments: UploadedFile[]
  sources: Maybe<Source[]>
  message?: AssistantMessage
}

const AssistantSources = ({
  className,
  isStreaming,
  allDocuments,
  sources,
  message,
}: Props) => {
  const [, setSearchParams] = useSearchParams()

  const getMessageDocuments = () => {
    // XXX: In the past, knowledge sources weren't stored on the message object (just top-level)
    // In which case, we should just show all documents. If there are knowledge sources, we should
    // filter the documents to only show the ones that are referenced in the message.
    // Going forward, we should always have knowledge sources on the message object.
    const messageHasKnowledgeSources =
      (message?.knowledgeSources?.length ?? 0) > 0

    if (!messageHasKnowledgeSources) return allDocuments

    const messageFileIds = message?.knowledgeSources?.flatMap((ks) => {
      if ('fileIds' in ks) return ks.fileIds || []
      return []
    })
    return allDocuments?.filter((doc) => messageFileIds?.includes(doc.id))
  }
  const documentsToInclude = getMessageDocuments()

  const handleSetActiveFileId = (
    fileId: string | undefined,
    sourceId?: string
  ) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams)
      if (fileId) {
        newParams.set(FILE_ID_PARAM, fileId)
        if (message) newParams.set(MESSAGE_ID_PARAM, message.messageId)
        if (sourceId) newParams.set(SOURCE_ID_PARAM, sourceId)
      } else {
        newParams.delete(FILE_ID_PARAM)
        newParams.delete(MESSAGE_ID_PARAM)
        newParams.delete(SOURCE_ID_PARAM)
      }
      return newParams
    })
  }

  const sourcesByName = groupBy(sources, 'documentName')
  const hasSources = Object.keys(sourcesByName).length > 0
  const unreferencedDocuments = documentsToInclude?.filter(
    (document) => !sourcesByName[document.name]
  )
  const hasUnreferencedDocuments =
    !isStreaming && unreferencedDocuments.length > 0

  return (
    <AssistantThreadSidebarSection
      className={cn(className, {
        'pb-2': hasUnreferencedDocuments,
      })}
      title="Sources"
    >
      {isStreaming ? (
        <div className="space-y-1 px-2">
          <SkeletonBlock className="h-4 w-4/5" hasDarkBackground />
          <SkeletonBlock className="h-4 w-11/12" hasDarkBackground />
          <SkeletonBlock className="h-4 w-3/5" />
          <SkeletonBlock className="h-4 w-4/5" />
        </div>
      ) : !hasSources ? (
        <p className="p-2 pb-4 text-xs text-muted">
          No sources were cited in this response
        </p>
      ) : (
        Object.keys(sourcesByName).map((documentName) => {
          const sources = sourcesByName[documentName]
          const source = sources[0]
          return (
            <AssistantSource
              key={documentName}
              badges={source.badges}
              documentName={documentName}
              documentId={getSourceDocumentId(source)}
              documentUrl={source.documentUrl}
              sources={sources}
              handleSetActiveFileId={handleSetActiveFileId}
            />
          )
        })
      )}
      {hasUnreferencedDocuments && (
        <UnreferencedSources
          documents={unreferencedDocuments}
          handleSetActiveFileId={handleSetActiveFileId}
        />
      )}
    </AssistantThreadSidebarSection>
  )
}

export default AssistantSources

const AssistantSource = ({
  badges,
  documentName,
  documentId,
  documentUrl,
  sources,
  handleSetActiveFileId,
}: {
  badges?: SourceBadge[]
  documentName: string
  documentId: string
  documentUrl?: string
  sources: Source[]
  handleSetActiveFileId: (fileId: string | undefined, sourceId?: string) => void
}) => {
  const { trackEvent } = useAnalytics()

  const handleClickSourceWithAnalytics = (
    fileId: string,
    url: string | undefined,
    sourceId?: string
  ) => {
    trackEvent('Source Clicked', {
      source_id: fileId,
    })
    const isPdfUrl = url ? isAzureBlobPdf(url) : false
    if ((!url || isPdfUrl) && fileId) {
      handleSetActiveFileId(fileId, sourceId)
    }
  }

  const Comp = documentUrl && !isAzureBlobPdf(documentUrl) ? Link : 'button'

  return (
    <div className="relative rounded p-2 hover:bg-button-secondary">
      <Comp
        className="apply-click-on-parent block w-full text-left"
        onClick={() =>
          handleClickSourceWithAnalytics(
            documentId || documentName,
            documentUrl
          )
        }
        to={documentUrl ? sourceUrlModifier(documentUrl) : ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="line-clamp-3 break-words text-xs">{documentName}</span>
      </Comp>
      {badges && badges.length > 0 && (
        <span className="mb-2 mt-1 flex flex-wrap gap-1">
          {badges.map((badge) => (
            <Badge
              key={badge.text}
              variant="secondary"
              className="px-1 hover:bg-button-secondary"
            >
              {badge.text}
            </Badge>
          ))}
        </span>
      )}
      {sources.length > 0 && (
        <span className="mt-1 flex flex-wrap gap-1">
          {sources.map((source) => (
            <Citation
              key={source.footnote}
              className="relative"
              onClick={(e) => {
                e.stopPropagation()
                handleClickSourceWithAnalytics(
                  getSourceDocumentId(source),
                  source.documentUrl,
                  source.id
                )
              }}
              href={
                source.documentUrl && !isAzureBlobPdf(source.documentUrl)
                  ? sourceUrlModifier(source.documentUrl)
                  : undefined
              }
            >
              {source.footnote}
            </Citation>
          ))}
        </span>
      )}
    </div>
  )
}

const UnreferencedSources = ({
  documents,
  handleSetActiveFileId,
}: {
  documents: UploadedFile[]
  handleSetActiveFileId: (fileId: string | undefined, sourceId?: string) => void
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = () => {
    setIsExpanded((prevExpanded) => !prevExpanded)
  }
  const documentsById = keyBy(documents, 'id')

  return (
    <AssistantThreadSidebarSubSection className="px-0 py-2">
      <Button
        className="group w-full text-left"
        onClick={toggleExpanded}
        variant="ghost"
      >
        <span className="grow text-xs text-secondary">Additional sources</span>
        <span className="text-xs text-muted">
          {documents.length} {pluralize('source', documents.length)}
        </span>
        <Icon
          icon={isExpanded ? ChevronUp : ChevronDown}
          className="ml-2 text-muted group-hover:text-secondary"
        />
      </Button>
      <BasicTransition
        show={isExpanded}
        entryTransitionDuration="fade"
        exitTransitionDuration="fade"
      >
        {Object.values(documentsById).map((document, i) => (
          <AssistantSource
            key={`${document.id}-${i}`}
            documentName={document.name}
            documentId={document.id}
            sources={[]}
            handleSetActiveFileId={handleSetActiveFileId}
          />
        ))}
      </BasicTransition>
    </AssistantThreadSidebarSubSection>
  )
}
