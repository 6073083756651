import { useRef } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import useHarveySocket, {
  InitSocketAndSendQueryParams,
} from 'utils/use-harvey-socket'
import { ToBackendKeys } from 'utils/utils'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  AssistantDraftInlineEditQuery,
  AssistantDraftMessage,
} from 'components/assistant-v2/types'
import { generateTitle } from 'components/assistant-v2/utils/assistant-api'
import {
  isVaultKnowledgeSource,
  KnowledgeSourceItem,
} from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { useAuthUser } from 'components/common/auth-context'

import { useSocketCompletedCallback } from './use-assistant-socket-completed-callback'

export type AssistantDraftStreamHandler = {
  initDraft: (
    query: string,
    eventId: string | null,
    onAuthCallback?: (eventId: string) => void,
    knowledgeSourceInput?: KnowledgeSourceItem
  ) => Promise<void>
  createDraftEditRequest: (
    query: string,
    prevMessageId: string | undefined,
    onAuthCallback?: (eventId: string) => void
  ) => void
  createDraftInlineEditRequest: (
    params: AssistantDraftInlineEditQuery & {
      prevMessageId?: string
    }
  ) => void
  sendCancelRequest: () => void
  regenerateMessage: (message: AssistantDraftMessage) => void
}

export const useAssistantDraftStreamHandler =
  (): AssistantDraftStreamHandler => {
    const isCancelled = useRef<boolean>(false)
    const [
      setTask,
      setStreamingMessage,
      eventId,
      getDocumentFileIds,
      messages,
      getCurrentThreadMessages,
      setCurrentMessageId,
      documentsUploading,
      preserveState,
      currentMessageId,
      documents,
      knowledgeSource,
    ] = useAssistantStore(
      useShallow((s) => [
        s.setTask,
        s.setStreamingMessage,
        s.eventId,
        s.getDocumentFileIds,
        s.messages,
        s.getCurrentThreadMessages,
        s.setCurrentMessageId,
        s.documentsUploading,
        s.preserveState,
        s.currentMessageId,
        s.documents,
        s.knowledgeSource,
      ])
    )

    const userInfo = useAuthUser()
    const queryAnalytics = useQueryAnalytics(EventKind.ASSISTANT_DRAFT)

    const selectedClientMatter = useClientMattersStore(
      (s) => s.selectedClientMatter
    )

    const { socketCompletedCallback } = useSocketCompletedCallback()

    const socket = useHarveySocket({
      path: 'assistant/draft',
      setter: setTask,
      endCallback: socketCompletedCallback,
      closeOnUnmount: false,
    })

    const createBaseRequest = (
      query: string,
      onAuthCallback?: (eventId: string) => void
    ): InitSocketAndSendQueryParams => {
      const baseRecordFields = {
        thread_number: getCurrentThreadMessages().length + 1,
        message_number: messages.length + 1,
        num_documents: documents.length + documentsUploading.length,
        knowledge_source_type: knowledgeSource?.type ?? '',
      }

      const recordFields = userInfo?.workspace.isEyesOff
        ? baseRecordFields
        : {
            ...baseRecordFields,
            document_names: documents.map((doc) => doc.name),
            query: query,
          }

      return {
        query,
        recordFields,
        onAuthCallback,
        ...queryAnalytics,
        additionalAuthParams: selectedClientMatter
          ? { client_matter_id: selectedClientMatter.id }
          : {},
        additionalRequestParams: {},
      }
    }

    const initDraft = async (
      query: string,
      eventId: string | null,
      onAuthCallback?: (eventId: string) => void,
      providedKnowledgeSource?: KnowledgeSourceItem
      // eslint-disable-next-line max-params
    ) => {
      // make sure we are not cancelled at start
      isCancelled.current = false
      setStreamingMessage({
        query,
        requestType: 'init',
        isLoading: true,
        sources: [],
      })

      if (documentsUploading.length && eventId) {
        const caption = await generateTitle({
          query,
          eventId: parseInt(eventId),
        })
        setStreamingMessage({
          query,
          requestType: 'init',
          isLoading: true,
          sources: [],
          caption,
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (isCancelled.current) {
        isCancelled.current = false
        return
      }

      const req = createBaseRequest(query, onAuthCallback)
      req.additionalRequestParams = {
        ...req.additionalRequestParams,
      }
      if (eventId)
        req.additionalAuthParams = {
          ...req.additionalAuthParams,
          event_id: eventId,
        }

      const knowledgeSourceToUse = providedKnowledgeSource ?? knowledgeSource
      if (isVaultKnowledgeSource(knowledgeSourceToUse)) {
        const knowledgeSourceInput = ToBackendKeys(knowledgeSourceToUse)
        req.additionalRequestParams = {
          ...req.additionalRequestParams,
          knowledge_sources: [knowledgeSourceInput],
          vault_project_id: knowledgeSourceToUse.folderId,
        }
      } else {
        const fileIds = await getDocumentFileIds()
        if (fileIds.length > 0) {
          req.additionalRequestParams = {
            ...req.additionalRequestParams,
            knowledge_sources: [{ type: 'files', file_ids: fileIds }],
          }
        }
      }

      preserveState(query, currentMessageId)
      socket.initSocketAndSendQuery(req)
      isCancelled.current = false
    }

    const createDraftInlineEditRequest = async ({
      query,
      selectedText,
      prevMessageId,
    }: AssistantDraftInlineEditQuery & { prevMessageId?: string }) => {
      setStreamingMessage({
        query,
        requestType: 'inlineEdit',
        selectedText,
        isLoading: true,
        prevMessageId,
      })

      const req = createBaseRequest(query)
      if (isVaultKnowledgeSource(knowledgeSource)) {
        const knowledgeSourceInput = ToBackendKeys(knowledgeSource)
        req.additionalRequestParams = {
          ...req.additionalRequestParams,
          knowledge_sources: [knowledgeSourceInput],
          vault_project_id: knowledgeSource.folderId,
        }
      } else {
        const fileIds = await getDocumentFileIds()
        if (fileIds.length > 0) {
          req.additionalRequestParams = {
            ...req.additionalRequestParams,
            knowledge_sources: [{ type: 'files', file_ids: fileIds }],
          }
        }
      }
      req.additionalAuthParams = {
        ...req.additionalAuthParams,
        event_id: eventId,
        prev_message_id: prevMessageId,
      }
      req.additionalRequestParams = {
        ...req.additionalRequestParams,
        selected_text: selectedText,
      }

      preserveState(query, currentMessageId)
      socket.initSocketAndSendQuery(req)
    }

    const createDraftEditRequest = async (
      query: string,
      prevMessageId: string | undefined,
      onAuthCallback?: (eventId: string) => void
    ) => {
      setStreamingMessage({
        query,
        requestType: 'edit',
        isLoading: true,
        prevMessageId,
      })

      const req = createBaseRequest(query, onAuthCallback)
      if (isVaultKnowledgeSource(knowledgeSource)) {
        const knowledgeSourceInput = ToBackendKeys(knowledgeSource)
        req.additionalRequestParams = {
          ...req.additionalRequestParams,
          knowledge_sources: [knowledgeSourceInput],
          vault_project_id: knowledgeSource.folderId,
        }
      } else {
        const fileIds = await getDocumentFileIds()
        if (fileIds.length > 0) {
          req.additionalRequestParams = {
            ...req.additionalRequestParams,
            knowledge_sources: [{ type: 'files', file_ids: fileIds }],
          }
        }
      }

      req.additionalAuthParams = {
        ...req.additionalAuthParams,
        event_id: eventId,
        prev_message_id: prevMessageId,
      }

      preserveState(query, currentMessageId)
      socket.initSocketAndSendQuery(req)
    }

    const regenerateMessage = (message: AssistantDraftMessage) => {
      setCurrentMessageId(message.prevMessageId || null)

      if (message.selectedText) {
        createDraftInlineEditRequest({
          query: message.query,
          selectedText: message.selectedText,
          prevMessageId: message.prevMessageId || undefined,
        })
      } else {
        createDraftEditRequest(
          message.query,
          message.prevMessageId || undefined
        )
      }
    }

    const sendCancelRequest = () => {
      isCancelled.current = true
      queryAnalytics.recordQueryCancel({
        num_documents: documents.length + documentsUploading.length,
      })
      socket.sendCancelRequest()
    }

    return {
      initDraft,
      createDraftInlineEditRequest,
      createDraftEditRequest,
      sendCancelRequest,
      regenerateMessage,
    }
  }
