import React, { useCallback } from 'react'
import { useUnmount } from 'react-use'

import { FilePlus2, SquarePlus } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { computeNextColumnId } from 'components/vault/query-detail/data-grid-helpers'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import {
  ColumnDataType,
  QueryQuestions,
  ReviewColumn,
} from 'components/vault/utils/vault'
import {
  FilterType,
  useVaultDataGridFilterStore,
} from 'components/vault/utils/vault-data-grid-filters-store'
import { getQuestionsLimit } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { useVaultUsageStore } from 'components/vault/utils/vault-usage-store'

import ErrorsPopover from './errors-popover'
import FilterDropdown from './filter-dropdown'

const AddFileButton = ({
  isAddFilesDisabled,
}: {
  isAddFilesDisabled: boolean
}) => {
  const setIsAddFilesDialogOpen = useVaultStore(
    useShallow((state) => state.setIsAddFilesDialogOpen)
  )

  const addFileHandler = () => {
    setIsAddFilesDialogOpen(true)
  }

  return (
    <Button
      onClick={addFileHandler}
      className="flex items-center gap-1"
      variant="outline"
      size="sm"
      disabled={isAddFilesDisabled}
    >
      <Icon icon={FilePlus2} size="small" />
      <p className="truncate text-xs">Add file</p>
    </Button>
  )
}

const AddColumnButton = ({
  isAddColumnDisabled,
  allColumns,
}: {
  isAddColumnDisabled: boolean
  allColumns: (QueryQuestions | ReviewColumn)[]
}) => {
  const { trackEvent } = useAnalytics()

  const [gridApi, historyItem, addToPendingQueryQuestions] =
    useVaultQueryDetailStore(
      useShallow((state) => [
        state.gridApi,
        state.historyItem,
        state.addToPendingQueryQuestions,
      ])
    )

  const onAddColumn = () => {
    if (!gridApi) return
    trackEvent('Vault Review Query Add Column Button Clicked', {
      query_id: historyItem?.id || 'new',
    })

    // 1. add the column
    const id = computeNextColumnId(allColumns).toString()
    const queryQuestion = {
      id: id,
      text: '',
      header: 'Untitled',
      columnDataType: ColumnDataType.date,
    }
    const newColumnDef = {
      field: id.toString(),
      questionId: id,
      headerName: 'Untitled',
      columnDataType: ColumnDataType.date,
      originalQuestion: '',
      minWidth: 240,
      flex: 3,
      type: FilterType.DATE,
    }
    const existingColumnDefs = gridApi.getColumnDefs() ?? []
    gridApi.setGridOption('columnDefs', [...existingColumnDefs, newColumnDef])

    // 2. add to pending query questions
    addToPendingQueryQuestions(queryQuestion)

    // 3. scroll to the new column
    gridApi.ensureColumnVisible('gutter', 'end')
  }

  return (
    <Button
      className="flex items-center gap-1"
      variant="outline"
      size="sm"
      onClick={onAddColumn}
      disabled={isAddColumnDisabled}
    >
      <Icon icon={SquarePlus} size="small" />
      <p className="truncate text-xs">Add column</p>
    </Button>
  )
}

const VaultDataGridHeader = () => {
  const userInfo = useAuthUser()
  const [currentProject, currentProjectMetadata, exampleProjectIds] =
    useVaultStore(
      useShallow((state) => [
        state.currentProject,
        state.currentProjectMetadata,
        state.exampleProjectIds,
      ])
    )

  const [agGridHeaderHeight, resetFilterState, clearSelectedRows] =
    useVaultDataGridFilterStore(
      useShallow((state) => [
        state.agGridHeaderHeight,
        state.resetFilterState,
        state.clearSelectedRows,
      ])
    )

  const [reviewFilesPerQueryLimit, reviewQuestionsPerQueryLimit] =
    useVaultUsageStore(
      useShallow((state) => [
        state.reviewFilesPerQueryLimit,
        state.reviewQuestionsPerQueryLimit,
      ])
    )
  const [
    gridApi,
    isQueryLoading,
    historyItem,
    pendingQueryFileIds,
    pendingQueryQuestions,
    setCurrentPendingColumnId,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.gridApi,
      state.isQueryLoading,
      state.historyItem,
      state.pendingQueryFileIds,
      state.pendingQueryQuestions,
      state.setCurrentPendingColumnId,
    ])
  )

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: currentProject?.id,
  })

  const pendingFileIds = pendingQueryFileIds ?? []
  const pendingQuestions = pendingQueryQuestions ?? []

  const reviewEvent = historyItem as ReviewHistoryItem
  const filesLimit = reviewEvent?.filesLimit ?? reviewFilesPerQueryLimit
  const numQueryFiles = reviewEvent?.numFiles ?? 0
  const numQueryQuestions = reviewEvent?.numQuestions ?? 0
  const isExampleProject =
    currentProject && exampleProjectIds.has(currentProject.id)
  const canCurrentUserEditProject =
    !isExampleProject && doesCurrentUserHaveEditPermission

  const allColumns: (QueryQuestions | ReviewColumn)[] = [
    ...pendingQuestions,
    ...(reviewEvent?.columns ?? []),
  ]

  const questionsLimit = getQuestionsLimit(
    reviewEvent?.questionsLimit,
    reviewQuestionsPerQueryLimit
  )

  const totalFileIds = numQueryFiles + pendingFileIds.length
  const totalQuestions = numQueryQuestions + pendingQuestions.length
  const shouldCheckIsQueryLoadingForRunButton =
    !userInfo.IsVaultInternalOnlyUser

  const isAddFilesDisabled =
    !canCurrentUserEditProject ||
    (shouldCheckIsQueryLoadingForRunButton && isQueryLoading) ||
    !!(filesLimit && totalFileIds >= filesLimit) ||
    totalFileIds === currentProjectMetadata.totalFiles

  const isAddColumnDisabled =
    !canCurrentUserEditProject ||
    (shouldCheckIsQueryLoadingForRunButton && isQueryLoading) ||
    totalFileIds === 0 ||
    totalQuestions >= questionsLimit

  const clearFilters = useCallback(() => {
    resetFilterState()
    gridApi?.setFilterModel(null)
  }, [resetFilterState, gridApi])

  useUnmount(() => {
    resetFilterState()
    clearSelectedRows()
    setCurrentPendingColumnId(null)
  })

  return (
    <div
      className={cn(
        'flex max-h-[49px] min-h-[49px] w-full shrink-0 items-center justify-between gap-2 px-4 py-2',
        {
          'border-b': agGridHeaderHeight > 0,
        }
      )}
    >
      <div className="flex items-center gap-2">
        <FilterDropdown clearFilters={clearFilters} />
        <div className="min-h-6 w-px border-r border-primary" />
        <AddFileButton isAddFilesDisabled={isAddFilesDisabled} />
        <AddColumnButton
          isAddColumnDisabled={isAddColumnDisabled}
          allColumns={allColumns}
        />
      </div>
      <ErrorsPopover />
    </div>
  )
}

export default VaultDataGridHeader
