import React, { useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'

import { X } from 'lucide-react'

import { cn } from 'utils/utils'

import { Badge } from 'components/ui/badge'
import { Button } from 'components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command'
import { Icon } from 'components/ui/icon/icon'

export type Tag = {
  value: string
  badgeDisplayText: string
  component?: React.ReactNode // optional custom component to render in options list
}

interface TagInputProps {
  placeholder: string
  sortedTags: Tag[]
  selectedTagValues: string[]
  setSelectedTagValues: (tagValues: string[]) => void
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void
}

const TagInput: React.FC<TagInputProps> = ({
  placeholder,
  sortedTags,
  selectedTagValues,
  setSelectedTagValues,
  onFocus,
}: TagInputProps) => {
  const [isInputFocused, setIsInputFocused] = React.useState(false)
  const [searchInputValue, setSearchInputValue] = useState('')

  const ref = useRef<HTMLDivElement>(null)
  const commandInputRef = useRef<HTMLInputElement>(null)

  const badgeDisplayTextByValue = useMemo(() => {
    return (sortedTags || []).reduce(
      (acc, tag) => {
        acc[tag.value] = tag.badgeDisplayText
        return acc
      },
      {} as Record<string, string>
    )
  }, [sortedTags])

  useClickAway(ref, () => {
    setIsInputFocused(false)
    setSearchInputValue('')
  })

  const handleSelect = (tagValue: string) => {
    setSelectedTagValues([...selectedTagValues, tagValue])
    // Clear search input
    setSearchInputValue('')

    // Refocus input
    setTimeout(() => {
      commandInputRef.current?.focus()
    }, 0)
  }

  const handleRemoveTag = (tagValue: string) => {
    setSelectedTagValues(
      selectedTagValues.filter((value) => value !== tagValue)
    )
  }

  const handleKeyDownOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Backspace' &&
      searchInputValue === '' &&
      selectedTagValues.length > 0
    ) {
      setSelectedTagValues(selectedTagValues.slice(0, -1))
    }
  }

  // Show command list if user is actively typing
  const shouldShowCommandList = isInputFocused && searchInputValue.length > 0

  return (
    <div
      className="relative"
      ref={ref}
      onFocus={onFocus}
      data-testid="vault-sharing-tag-input"
    >
      <Command className="rounded">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className="flex cursor-text flex-wrap items-center gap-1 rounded border p-1"
          onClick={() => commandInputRef.current?.focus()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              commandInputRef.current?.focus()
            }
          }}
        >
          {(selectedTagValues || []).map((tagValue) => (
            <Badge
              key={`${tagValue}-badge`}
              variant="secondary"
              className="flex h-fit items-center gap-1 px-1"
            >
              <span className="text-xs font-normal">
                {badgeDisplayTextByValue[tagValue]}
              </span>
              <Button
                variant="ghost"
                className="h-3 w-3 p-0 text-muted"
                onClick={() => {
                  handleRemoveTag(tagValue)
                }}
              >
                <Icon icon={X} size="small" />
              </Button>
            </Badge>
          ))}
          <CommandInput
            ref={commandInputRef}
            showSearchStyling={false}
            placeholder={
              selectedTagValues.length === 0 ? placeholder : undefined
            }
            value={searchInputValue}
            onValueChange={setSearchInputValue}
            onFocus={() => setIsInputFocused(true)}
            onKeyDown={handleKeyDownOnInput}
            className={cn('h-3 px-1 text-xs', {
              'w-0': !isInputFocused && selectedTagValues.length > 0,
            })}
          />
        </div>
        <CommandList
          className={cn(
            'absolute top-full z-20 mt-1 max-h-60 w-full overflow-y-auto rounded border bg-primary',
            {
              hidden: !shouldShowCommandList,
            }
          )}
        >
          {shouldShowCommandList && (
            <>
              <CommandEmpty className="py-4 text-center">
                <p className="text-xs text-muted">No results found</p>
              </CommandEmpty>
              <CommandGroup>
                {(sortedTags || [])
                  .filter((tag) => !selectedTagValues.includes(tag.value))
                  .map((tag) => (
                    <CommandItem
                      key={tag.value}
                      onSelect={() => handleSelect(tag.value)}
                      value={tag.badgeDisplayText}
                    >
                      {/* render custom component if it exists, otherwise show badgeDisplayText */}
                      {tag.component ? (
                        tag.component
                      ) : (
                        <span className="text-xs">{tag.badgeDisplayText}</span>
                      )}
                    </CommandItem>
                  ))}
              </CommandGroup>
            </>
          )}
        </CommandList>
      </Command>
    </div>
  )
}

export { TagInput }
