import { FileType } from 'types/file'

export const NUM_MAX_FILES = 50

export const ACCEPTED_FILE_TYPES = [
  FileType.PDF,
  FileType.WORD,
  FileType.WORD_LEGACY,
  FileType.EXCEL,
  FileType.EXCEL_LEGACY,
  FileType.ZIP,
  FileType.EMAIL,
  FileType.TEXT,
  FileType.CSV,
  FileType.POWERPOINT,
  FileType.POWERPOINT_LEGACY,
  FileType.OUTLOOK,
  FileType.RTF,
]
