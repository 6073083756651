import React from 'react'

import { CopyIcon, InfoIcon } from 'lucide-react'

import { displayInfoMessage } from 'utils/toast'

import { Button } from 'components/ui/button'
import { Icon } from 'components/ui/icon/icon'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip'
import { DOT_SEPARATOR } from 'components/vault/utils/vault'

const OriginalQueryPopover = ({ query }: { query: string }) => {
  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(query)
    displayInfoMessage('Query copied to clipboard')
  }

  return (
    <Popover>
      <PopoverTrigger>
        <Icon
          icon={InfoIcon}
          size="small"
          className="block text-muted sm:hidden"
        />
        <p className="hidden truncate text-xs text-muted sm:block">
          {DOT_SEPARATOR}
          <span className="hover:underline">View original query</span>
        </p>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        onOpenAutoFocus={(e) => {
          e.preventDefault()
        }}
      >
        <div className="mb-1 flex items-center justify-between">
          <p className=" text-xs font-semibold text-muted">Original query</p>
          <Tooltip>
            <TooltipTrigger>
              <Button size="xsIcon" variant="ghost" onClick={handleCopyClick}>
                <CopyIcon className="size-3 text-muted" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Copy</TooltipContent>
          </Tooltip>
        </div>
        <p className="text-xs">
          {query.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </PopoverContent>
    </Popover>
  )
}

export default OriginalQueryPopover
