import React, { useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'

import _ from 'lodash'
import { Ban, InfoIcon, UploadIcon } from 'lucide-react'

import { EventKind } from 'openapi/models/EventKind'
import Services from 'services'
import { FileType } from 'types/file'
import { AssistantDocument } from 'types/task'

import { onDrop } from 'utils/dropzone'
import { mbToBytes, uploadAssistantFiles } from 'utils/file-utils'
import {
  useBroadcastChannelReceive,
  useBroadcastChannelSend,
} from 'utils/use-broadcast-channel'
import {
  BroadcastChannelType,
  IssuesListRedirectData,
  RegisteredHarveyBroadcastChannels,
} from 'utils/use-broadcast-channel-utils'
import { useDropzoneTrack } from 'utils/use-dropzone-track'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import BasicTransition from 'components/ui/basic-transition'
import { Label } from 'components/ui/label'
import { Spinner } from 'components/ui/spinner'
import { Switch } from 'components/ui/switch'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import {
  ASSISTANT_INTERNET_TOOLTIP_HELP,
  FILE_DROPZONE_INFO_TEXT,
} from './assistant-constants'
import AssistantInputFiles from './assistant-input-files'
import { useAssistantStore } from './assistant-store'
import {
  ASSISTANT_REDLINES_UPLOADED_METRIC,
  AssistantFileTypes,
  ISSUES_LIST_REDIRECT_FILE_LIMIT,
  MAX_FILES,
  MAX_FILE_SIZE,
  MAX_TOTAL_FILE_SIZE,
  MAX_ZIP_FILE_SIZE,
  MAX_ZIP_FILE_SIZE_MB,
  sumFileSizes,
} from './assistant-utils'

export const AssistantDropzone = (): JSX.Element => {
  const {
    uploadedFiles,
    internetBrowsing,
    setInternetBrowsing,
    isLoading,
    setUploadedFiles,
    setHasRedlineDoc,
  } = useAssistantStore()
  const [isDropzoneLoading, setIsDropzoneLoading] = useState(false)
  const userInfo = useAuthUser()
  const [isNewTab, setIsNewTab] = useState(true)

  useEffect(() => {
    // After the component mounts, set isNewTab to false after a delay
    const timeoutId = setTimeout(() => {
      setIsNewTab(false)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const sendBroadcastMessage = useBroadcastChannelSend<IssuesListRedirectData>(
    RegisteredHarveyBroadcastChannels[
      BroadcastChannelType.ASSISTANT_ISSUES_LIST_REDIRECT
    ]
  )

  useBroadcastChannelReceive<IssuesListRedirectData>(
    RegisteredHarveyBroadcastChannels[
      BroadcastChannelType.ISSUES_LIST_ASSISTANT_REDIRECT
    ],
    (message) => {
      if (uploadedFiles?.length === 0 && isNewTab) {
        const assistantDoc: AssistantDocument = {
          id: message.uploadedDoc.id,
          name: message.file.name,
          file: message.file,
          url: URL.createObjectURL(message.file),
          mimeType: message.file.type,
          path: message.uploadedDoc.path,
        }
        setUploadedFiles([assistantDoc])
      }
    }
  )

  const [newUploadedFiles, setNewUploadedFiles] = useState<AssistantDocument[]>(
    []
  )
  const newUploadedFilesRef = React.useRef(newUploadedFiles)
  useEffect(() => {
    if (newUploadedFilesRef.current === newUploadedFiles) {
      return
    }
    newUploadedFilesRef.current = newUploadedFiles
    // Only execute the following logic if the newUploadedFiles has changed

    if (!isDropzoneLoading) return
    if (newUploadedFiles.length === 0) {
      setIsDropzoneLoading(false)
      return
    }

    const existingFilesUpdated =
      uploadedFiles?.map(
        (existingFile) =>
          newUploadedFiles.find(
            (newFile) => newFile.name === existingFile.name
          ) ?? existingFile
      ) ?? []
    const newFilesToAdd = newUploadedFiles.filter(
      (newFile) =>
        !uploadedFiles?.some(
          (existingFile) => existingFile.name === newFile.name
        )
    )
    const combinedUploadedFiles = [...existingFilesUpdated, ...newFilesToAdd]

    // if newUploaded length is one, and issues list perm exists
    if (
      combinedUploadedFiles.length === ISSUES_LIST_REDIRECT_FILE_LIMIT &&
      userInfo.IsRedlinesUser &&
      !_.isEmpty(newUploadedFiles) &&
      !_.isNil(newUploadedFiles[0]) &&
      !_.isNil(newUploadedFiles[0].uploadPromise)
    ) {
      const uploadPromise = newUploadedFiles[0].uploadPromise
      const checkRedlines = async () => {
        const firstUploadedFile = await uploadPromise
        if (firstUploadedFile.isRedlinesDocument) {
          Services.HoneyComb.Record({
            metric: ASSISTANT_REDLINES_UPLOADED_METRIC,
            user: userInfo.id,
            workspace: userInfo.workspace.slug,
          })
          setHasRedlineDoc(true)
          const file = newUploadedFiles[0].file
          if (file) {
            sendBroadcastMessage({
              file: file,
              uploadedDoc: firstUploadedFile,
            })
          }
        }
      }
      void checkRedlines()
    }
    setUploadedFiles(combinedUploadedFiles)
    setIsDropzoneLoading(false)
  }, [
    isDropzoneLoading,
    setUploadedFiles,
    setHasRedlineDoc,
    userInfo,
    sendBroadcastMessage,
    uploadedFiles,
    newUploadedFiles,
  ])

  const recordFileDrop = useDropzoneTrack(EventKind.ASSISTANT)

  const onFileDrop = React.useCallback(
    async (files: File[]) => {
      const redlinesCheck = _.isEmpty(uploadedFiles) && userInfo.IsRedlinesUser
      const assistantFiles = uploadAssistantFiles(
        files,
        uploadedFiles ?? [],
        redlinesCheck
      )
      // set optimistically when the user wants to query first
      // delay 100ms to allow the loading spinner to show without flickering
      _.delay(
        () => setUploadedFiles([...(uploadedFiles ?? []), ...assistantFiles]),
        100
      )
      setNewUploadedFiles(
        (await Promise.all(
          assistantFiles.map(async (x) => ({
            ...x,
            ...(await x.uploadPromise),
          }))
        )) as AssistantDocument[]
      )
      recordFileDrop(files)
    },
    [recordFileDrop, setUploadedFiles, uploadedFiles, userInfo]
  )

  useEffect(() => {
    const uploadedFilesLength = uploadedFiles?.length ?? 0
    if (uploadedFilesLength !== ISSUES_LIST_REDIRECT_FILE_LIMIT) {
      setHasRedlineDoc(false)
    }
  }, [uploadedFiles, setHasRedlineDoc])

  const dropzoneDisabled =
    internetBrowsing || (uploadedFiles?.length ?? 0) >= MAX_FILES || isLoading

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: React.useCallback(
      async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        setIsDropzoneLoading(true)
        return onDrop({
          acceptedFiles,
          fileRejections,
          currentFileCount: uploadedFiles?.length ?? 0,
          maxFiles: MAX_FILES,
          acceptedFileTypes: Object.keys(AssistantFileTypes) as FileType[],
          maxFileSize: mbToBytes(MAX_FILE_SIZE),
          maxZipFileSize: mbToBytes(MAX_ZIP_FILE_SIZE),
          maxTotalFileSizeProps: {
            maxTotalFileSize: mbToBytes(MAX_TOTAL_FILE_SIZE),
            currentTotalFileSize: uploadedFiles
              ? await sumFileSizes(uploadedFiles)
              : 0,
          },
          handleAcceptedFiles: onFileDrop,
          handleRejectedFiles: () => {
            setIsDropzoneLoading(false)
          },
        })
      },
      [onFileDrop, uploadedFiles]
    ),
    maxFiles: MAX_FILES,
    maxSize: MAX_ZIP_FILE_SIZE_MB,
    accept: AssistantFileTypes,
    disabled: dropzoneDisabled,
  })

  return (
    <div className="relative h-full">
      <BasicTransition
        show={isDropzoneLoading}
        className="absolute inset-0 z-10 flex h-full w-full flex-col items-center justify-center space-y-2 border bg-secondary px-4 text-muted backdrop-blur-sm"
      >
        <Spinner data-testid="dropzone-loading" />
      </BasicTransition>
      {_.isEmpty(uploadedFiles) || internetBrowsing ? (
        <div
          {...getRootProps()}
          className={cn(
            'relative flex h-full cursor-pointer items-center justify-center py-8 shadow-inner transition-colors hover:bg-secondary-hover',
            {
              'bg-primary': !isDragActive,
              'bg-secondary-hover': isDragActive,
              'cursor-default  ': internetBrowsing,
              disabled: dropzoneDisabled,
            }
          )}
          data-testid="dropzone"
        >
          <input {...getInputProps()} />
          <div className="flex h-full w-full flex-col items-center justify-center space-y-2 text-center">
            <UploadIcon size={20} />
            {isDragActive ? (
              <p className="font-semibold">Drop files here</p>
            ) : (
              <>
                <div>
                  <p className="font-semibold">Click to upload</p>
                  <p>or drag and drop</p>
                </div>
                <p className="text-center text-xs">
                  PDF, Word, Excel, and Zip accepted.
                </p>
                <div className="flex items-center">
                  <p className="text-xs text-muted">Up to {MAX_FILES} files</p>
                  <Tooltip>
                    <TooltipTrigger>
                      <InfoIcon
                        strokeWidth={1.5}
                        size={14}
                        className="ml-1 text-muted"
                      />
                    </TooltipTrigger>
                    <TooltipContent className="w-25">
                      {FILE_DROPZONE_INFO_TEXT}
                    </TooltipContent>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
          <BasicTransition
            show={internetBrowsing}
            className="absolute flex h-full w-full flex-col items-center space-y-2 rounded-lg bg-primary px-4 text-muted backdrop-blur-sm"
          >
            <div
              className="mt-4 flex w-full items-center space-x-2 rounded-lg border px-4 py-3"
              data-testid="disable-internet-browsing-text"
            >
              <Ban size={16} />
              <p>Turn off internet browsing to upload files</p>
            </div>
          </BasicTransition>
        </div>
      ) : (
        <div className="h-full">
          <div
            {...getRootProps({
              onClick: (event) => {
                event.stopPropagation()
                event.preventDefault()
              },
            })}
            className={cn('relative h-full', {
              'flex cursor-pointer items-center justify-center rounded-lg bg-secondary-hover py-8 shadow-inner transition-colors hover:bg-secondary-hover':
                isDragActive,
            })}
            data-testid="inline-dropzone"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className="flex h-full w-full flex-col items-center justify-center space-y-2 text-muted">
                <UploadIcon />
                <p className="font-semibold">Drop files here</p>
              </div>
            ) : (
              <AssistantInputFiles
                open={open}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
              />
            )}
          </div>
        </div>
      )}
      <div className="absolute bottom-0 left-0 z-10 w-full bg-primary px-4 py-1">
        {userInfo.IsInternetBrowsingUser && (
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center">
                <Switch
                  checked={internetBrowsing}
                  onCheckedChange={() => setInternetBrowsing(!internetBrowsing)}
                  data-testid="assistant--use-internet"
                  disabled={isLoading}
                  id="assistant-use-internet"
                />
                <Label
                  className="ml-2 cursor-default text-xs"
                  htmlFor="assistant-use-internet"
                >
                  Use Internet
                </Label>
              </div>
            </TooltipTrigger>
            <TooltipContent className="w-60" align="start">
              {ASSISTANT_INTERNET_TOOLTIP_HELP}
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </div>
  )
}
