import React from 'react'

import _ from 'lodash'

import Services from 'services'

import { getCurrentRouteStringFromLocation } from 'utils/utils'

import {
  AnalyticsProperties,
  useAnalytics,
} from 'components/common/analytics/analytics-context'
import { default as CitationComponent } from 'components/ui/citation'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/ui/hover-card/hover-card'

interface CitationProps {
  label: string | undefined
  href?: string | undefined
  onClick?: (e: React.MouseEvent) => void
  className?: string
  hoverContent?: React.ReactNode
  eventData?: AnalyticsProperties
}

const Citation: React.FC<CitationProps> = ({
  label,
  href,
  onClick,
  hoverContent,
  eventData,
  ...citationProps
}) => {
  const { trackEvent } = useAnalytics()
  const internalOnClick = (e: React.MouseEvent) => {
    onClick?.(e)
    Services.HoneyComb.Record({
      metric: 'ui.citation_clicked',
      route: getCurrentRouteStringFromLocation(window.location.pathname),
    })
    trackEvent('Citation Clicked', { location: 'output-panel' })
  }

  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      trackEvent('Citation Hovered', eventData)
    }
  }

  if (_.isNil(label)) return null

  const citationChild = getCitationChild(label)

  const rawCitation = (
    <CitationComponent
      className="m-0 -translate-y-0.5"
      href={href}
      onClick={internalOnClick}
      {...citationProps}
    >
      {citationChild}
    </CitationComponent>
  )

  if (!hoverContent || typeof citationChild !== 'string') {
    return rawCitation
  }

  return (
    <HoverCard openDelay={350} closeDelay={150} onOpenChange={handleOpenChange}>
      <HoverCardTrigger
        asChild
        className="last-of-type:mr-0.5 [ins_&]:first-of-type:ml-0.5"
      >
        {rawCitation}
      </HoverCardTrigger>
      {hoverContent && (
        <HoverCardContent className="w-full overflow-hidden px-4 py-3">
          {hoverContent}
        </HoverCardContent>
      )}
    </HoverCard>
  )
}

export default Citation

const getCitationChild = (
  label: string | React.ReactNode
): string | React.ReactNode => {
  const maybeCitationString =
    typeof label === 'string'
      ? label
      : React.isValidElement(label)
      ? label.props.children
      : label

  if (typeof maybeCitationString === 'string') {
    return maybeCitationString.replace(/\[|\]/g, '')
  }

  return label
}
