import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import _ from 'lodash'
import queryString from 'query-string'

import { cn } from 'utils/utils'

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbEllipsis,
  BreadcrumbPage,
} from 'components/ui/breadcrumbs/breadcrumb'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Link from 'components/ui/link/link'

interface RouterBreadcrumbsProps {
  children?: React.ReactNode
  className?: string
  breadcrumbItemClassName?: string
  overridePath?: string
  // If set, the caller manages setting their own document.title
  overridesDocumentTitle?: boolean
  // If keepCaseStartingFromIndex is provided, it will keep the case of the path starting from that index
  keepCaseStartingFromIndex?: number
  checkHistoryIdInPath?: boolean
  pathForIndexFunc?: (index: number, pathname: string, search: string) => string
  removeParams?: string[]
  collapseMargin?: number
  keepLastItem?: boolean
}

const pathToDisplayName: Record<string, string> = {
  'assistant-v2': 'Assistant',
  tax: 'Tax AI Assistant',
  edgar: 'EDGAR',
  eurlex: 'EUR-Lex',
  usacaselaw: 'US Case Law',
  uscaselaw: 'US Case Law',
  francecaselaw: 'French Case Law',
  ausbreachreporting: 'Australia Breach Reporting',
  ogc: 'OGC',
  cuatrecasas: 'Cuatrecasas',
  fromcounsel: 'From Counsel',
}

const RouterBreadcrumbs: React.FC<RouterBreadcrumbsProps> = ({
  overridePath,
  className,
  breadcrumbItemClassName,
  overridesDocumentTitle = false,
  keepCaseStartingFromIndex,
  checkHistoryIdInPath = true,
  pathForIndexFunc,
  removeParams,
  collapseMargin = 192,
  keepLastItem = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const location = useLocation()
  const isHistoryID = useCallback(
    (path: string) => {
      if (!checkHistoryIdInPath) return false
      const regexExp = /^\d+$/
      return regexExp.test(path)
    },
    [checkHistoryIdInPath]
  )

  const currentPathAsArray = useMemo(
    () =>
      (overridePath || location.pathname)
        .split('/')
        .filter(Boolean)
        .filter((path) => !isHistoryID(path)),
    [overridePath, location.pathname, isHistoryID]
  )

  const pathForIndex = useCallback(
    (index: number) => {
      if (pathForIndexFunc) {
        return pathForIndexFunc(index, location.pathname, location.search)
      }
      const path = currentPathAsArray.slice(0, index + 1).join('/')
      const search = location.search
      const queryParams = queryString.parse(search)
      if (removeParams) {
        removeParams.forEach((param) => {
          delete queryParams[param]
        })
      }
      if (Object.keys(queryParams).length === 0) {
        return `/${path}`
      }
      return `/${path}?${queryString.stringify(queryParams)}`
    },
    [
      pathForIndexFunc,
      currentPathAsArray,
      removeParams,
      location.pathname,
      location.search,
    ]
  )

  const getCollapsedItems = useMemo(
    () => currentPathAsArray.slice(1, currentPathAsArray.length - 1),
    [currentPathAsArray]
  )

  const getDisplayName = useCallback(
    (index: number) => {
      if (index < 0 || index >= currentPathAsArray.length) return ''
      const pathname = currentPathAsArray[index]
      const displayName = pathToDisplayName[decodeURIComponent(pathname)]
      const keepCase =
        keepCaseStartingFromIndex !== undefined &&
        index >= keepCaseStartingFromIndex

      return (
        displayName ||
        (keepCase
          ? decodeURIComponent(pathname)
          : _.capitalize(_.lowerCase(decodeURIComponent(pathname))))
      )
    },
    [currentPathAsArray, keepCaseStartingFromIndex]
  )

  useEffect(() => {
    if (overridesDocumentTitle) return
    if (currentPathAsArray.length > 0) {
      document.title = getDisplayName(currentPathAsArray.length - 1)
    } else {
      document.title = 'Harvey'
    }

    return () => {
      document.title = 'Harvey'
    }
  }, [currentPathAsArray, getDisplayName, overridesDocumentTitle])

  useLayoutEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      setContainerWidth(rect.width)
    }
  }, [])

  useLayoutEffect(() => {
    const debounceDelay = 100 // Delay in milliseconds
    let debounceTimer: number

    const checkCollapse = () => {
      const windowWidth = window.innerWidth
      const collapseThreshold = windowWidth * 0.5 - collapseMargin

      if (containerWidth >= collapseThreshold) {
        setIsCollapsed(true)
      } else {
        setIsCollapsed(false)
      }
    }

    const debouncedCheckCollapse = () => {
      clearTimeout(debounceTimer)
      debounceTimer = window.setTimeout(() => {
        checkCollapse()
      }, debounceDelay)
    }

    // Run once to set initial state
    checkCollapse()

    // Set up event listener for window resize with debouncing
    window.addEventListener('resize', debouncedCheckCollapse)

    // Clean up event listener and clear debounce timer
    return () => {
      window.removeEventListener('resize', debouncedCheckCollapse)
      clearTimeout(debounceTimer)
    }
  }, [currentPathAsArray, containerWidth, collapseMargin])

  const [collapsedDropdownOpen, setCollapsedDropdownOpen] = useState(false)

  if (currentPathAsArray.length < 2 && !keepLastItem) return <div />

  return (
    <Breadcrumb className={cn('shrink-0', className)} ref={containerRef}>
      <BreadcrumbList>
        {currentPathAsArray.map((path, index) => {
          // Last item i.e. current page is not rendered or has special rendering
          if (index === currentPathAsArray.length - 1) {
            return (
              <React.Fragment key={index}>
                {index !== 0 && <BreadcrumbSeparator className="shrink-0" />}
                {keepLastItem && (
                  <BreadcrumbItem>
                    <BreadcrumbPage
                      className={cn(
                        'line-clamp-1 max-w-sm text-xs',
                        breadcrumbItemClassName
                      )}
                    >
                      {getDisplayName(index)}
                    </BreadcrumbPage>
                  </BreadcrumbItem>
                )}
              </React.Fragment>
            )
          }

          // First item should never be collapsed
          if (index === 0) {
            return (
              <BreadcrumbItem key={index}>
                <Link
                  className={cn('text-xs', breadcrumbItemClassName)}
                  to={pathForIndex(index)}
                >
                  {getDisplayName(index)}
                </Link>
              </BreadcrumbItem>
            )
          }

          // Replace first collapsed with dropdown
          if (isCollapsed && index === 1) {
            return (
              <BreadcrumbItem key={index} className="shrink-0">
                <BreadcrumbSeparator className="shrink-0" />
                <DropdownMenu
                  open={collapsedDropdownOpen}
                  onOpenChange={setCollapsedDropdownOpen}
                >
                  <DropdownMenuTrigger className="flex items-center gap-1">
                    <BreadcrumbEllipsis className="h-4 w-4" />
                    <span className="sr-only">Toggle menu</span>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    {getCollapsedItems.map((item, idx) => (
                      <DropdownMenuItem className="capitalize" key={idx}>
                        <Link
                          className={cn('text-xs', breadcrumbItemClassName)}
                          to={pathForIndex(idx + 1)}
                          onClick={() => setCollapsedDropdownOpen(false)}
                        >
                          {getDisplayName(idx + 1)}
                        </Link>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </BreadcrumbItem>
            )
          }

          // Don't render other collapsed items
          if (isCollapsed && index > 1) return

          return (
            <React.Fragment key={index}>
              {index !== 0 && <BreadcrumbSeparator className="shrink-0" />}
              <BreadcrumbItem>
                <Link
                  className={cn('text-xs', breadcrumbItemClassName)}
                  to={pathForIndex(index)}
                >
                  {getDisplayName(index)}
                </Link>
              </BreadcrumbItem>
            </React.Fragment>
          )
        })}
      </BreadcrumbList>
    </Breadcrumb>
  )
}

export default RouterBreadcrumbs
