/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Enum for specifying user permissions.
 * @export
 */
export const Permission = {
    ADMINORGANIZATION: 'admin:organization',
    ADMINTERRITORY: 'admin:territory',
    AUS_BREACH_REPORTING: 'aus_breach_reporting',
    CLIENT_ADMINADD_USERS: 'client_admin:add_users',
    CLIENT_ADMINMANAGE_SHARING: 'client_admin:manage_sharing',
    CLIENT_ADMINREMOVE_USERS: 'client_admin:remove_users',
    CLIENT_ADMINVAULT_ADD_USERS: 'client_admin:vault_add_users',
    CLIENT_ADMINVAULT_REMOVE_USERS: 'client_admin:vault_remove_users',
    CLIENT_ADMINVIEW_USERS: 'client_admin:view_users',
    CREATEADVANCED_DOC_QA: 'create:advanced_doc_qa',
    CREATEASSISTANT_DRAFT: 'create:assistant_draft',
    CREATEASSISTANT_V2_SURVEY_FEEDBACK: 'create:assistant_v2_survey_feedback',
    CREATEAUSTRALIA_TAX_QA: 'create:australia_tax_qa',
    CREATECOMPANY_PROFILE: 'create:company_profile',
    CREATECOMPLETION: 'create:completion',
    CREATECONTRACTS: 'create:contracts',
    CREATECORPUS_QA: 'create:corpus_qa',
    CREATECUATRECASAS: 'create:cuatrecasas',
    CREATEDILIGENCE_TRANSCRIPTS: 'create:diligence_transcripts',
    CREATEDILIGENCE_UK_TAX: 'create:diligence_uk_tax',
    CREATEDOCUMENT_QA: 'create:document_qa',
    CREATEDRAFTING: 'create:drafting',
    CREATEEDGAR_ALL_FILINGS: 'create:edgar_all_filings',
    CREATEEURLEX_QA: 'create:eurlex_qa',
    CREATEFEEDBACK: 'create:feedback',
    CREATEFRANCE_CASE_LAW: 'create:france_case_law',
    CREATEFROM_COUNSEL: 'create:from_counsel',
    CREATEHARVEY_LIBRARY_ITEMS: 'create:harvey_library_items',
    CREATEHMRC_QA: 'create:hmrc_qa',
    CREATEICERTIS_FIELD_EXTRACTION_API: 'create:icertis_field_extraction_api',
    CREATEICERTIS_REVIEW_API: 'create:icertis_review_api',
    CREATEASYNC_FIELD_EXTRACTION_API: 'create:async_field_extraction_api',
    CREATEINDIA_TAX_QA: 'create:india_tax_qa',
    CREATEINTERNET_BROWSING: 'create:internet_browsing',
    CREATEIRELAND_TAX_QA: 'create:ireland_tax_qa',
    CREATEKOREA_TAX_QA: 'create:korea_tax_qa',
    CREATEJAPAN_TAX_QA: 'create:japan_tax_qa',
    CREATEMEMOS_QA: 'create:memos_qa',
    CREATEMULTI_DOC_QA: 'create:multi_doc_qa',
    CREATENETHERLANDS_TAX_QA: 'create:netherlands_tax_qa',
    CREATEUAE_TAX_QA: 'create:uae_tax_qa',
    CREATEOGC_REVIEW: 'create:ogc_review',
    CREATEPILLAR_TWO_TAX_QA: 'create:pillar_two_tax_qa',
    CREATEPRIVATE_LIBRARY_PROMPTS: 'create:private_library_prompts',
    CREATEPWC_HR_WORKFORCE_API: 'create:pwc_hr_workforce_api',
    CREATEPWC_TAX_FEEDBACK_EXPORT: 'create:pwc_tax_feedback_export',
    CREATEREDLINES: 'create:redlines',
    CREATERESPONSE_COMPARISON: 'create:response_comparison',
    CREATESEC_EDGAR_QA: 'create:sec_edgar_qa',
    CREATESWEDEN_TAX_QA: 'create:sweden_tax_qa',
    CREATESWITZERLAND_TAX_QA: 'create:switzerland_tax_qa',
    CREATETRANSLATION_API: 'create:translation_api',
    CREATETRANSLATION: 'create:translation',
    CREATEUSA_CASE_LAW: 'create:usa_case_law',
    CREATEUSER_LIBRARY_ITEMS: 'create:user_library_items',
    CREATEVAULT_INTERNAL_ONLY: 'create:vault_internal_only',
    CREATEVAULT_LARGE_FILE_SIZE: 'create:vault_large_file_size',
    CREATEVAULT_REVIEW: 'create:vault_review',
    CREATEVAULT_SANDBOX_PROJECT: 'create:vault_sandbox_project',
    CREATEVAULT_WORKFLOW_REPS_WARRANTIES: 'create:vault_workflow_reps_warranties',
    CREATEVAULT: 'create:vault',
    CREATEVAULT_SHARES: 'create:vault_shares',
    CREATEEVENT_SHARES: 'create:event_shares',
    CREATEWORKSPACE_LIBRARY_ITEMS: 'create:workspace_library_items',
    CREATEWWTS_QA: 'create:wwts_qa',
    DECIDERESPONSE_COMPARISON: 'decide:response_comparison',
    DELETEUSER_HISTORY: 'delete:user_history',
    DELETEWORKSPACE_HISTORY_BULK: 'delete:workspace_history_bulk',
    DELETEWORKSPACE_HISTORY: 'delete:workspace_history',
    DOCUMENT_COMPARISON: 'document_comparison',
    LEGACY_OPEN_ENDED: 'legacy_open_ended',
    MANAGEUSERS: 'manage:users',
    PWC_DEALS: 'pwc_deals',
    USEDEALS_DISCOVERY_ASYNC_IMPL: 'use:deals_discovery_async_impl',
    READALL_SENSITIVE: 'read:all_sensitive',
    READALL_WORKSPACES: 'read:all_workspaces',
    READCLIENT_MATTERS: 'read:client_matters',
    READENG_INTERNAL: 'read:eng_internal',
    READEXAMPLES: 'read:examples',
    READHISTORY: 'read:history',
    READSOME_SENSITIVE_DATA: 'read:some_sensitive_data',
    READSTATUS_BAR: 'read:status_bar',
    READWORKSPACE_HISTORY: 'read:workspace_history',
    READWORKSPACE_VAULT: 'read:workspace_vault',
    TRANSCRIPTS: 'transcripts',
    USEASSISTANT_V2: 'use:assistant_v2',
    USEASSISTANT_RESEARCH_KS: 'use:assistant_research_ks',
    CREATEVAULT_EVENTS_DUAL_WRITE: 'create:vault_events_dual_write',
    USEASSISTANT_TAX_KS: 'use:assistant_tax_ks',
    USEVAULT_PROJECT_CLIENT_MATTER: 'use:vault_project_client_matter',
    CREATEVAULT_WORKFLOW_ADMIN: 'create:vault_workflow_admin',
    CREATEVAULT_V2: 'create:vault_v2',
    VIEWUSAGE_DASHBOARD_V2: 'view:usage_dashboard_v2',
    USECOMPETITIVE_ANALYSIS: 'use:competitive_analysis',
    USEDISALLOWED_CLIENT_MATTERS: 'use:disallowed_client_matters',
    USEHARVEY_FOR_COPILOT: 'use:harvey_for_copilot',
    USEHARVEY_FOR_CUSTOM_ENGINE_COPILOT: 'use:harvey_for_custom_engine_copilot',
    USEHARVEY_FOR_WORD: 'use:harvey_for_word',
    USELIBRARY_EXAMPLES: 'use:library_examples',
    USEOPEN_ENDED_BRIDGE_ROUTING_FULL: 'use:open_ended_bridge_routing_full',
    USEOPEN_ENDED_BRIDGE_ROUTING: 'use:open_ended_bridge_routing',
    USESKIP_RATE_LIMIT: 'use:skip_rate_limit',
    USESWEDEN_CASE_LAW: 'use:sweden_case_law',
    USEVAULT_KNOWLEDGE_SOURCE: 'use:vault_knowledge_source',
    USEVAULT_SHARING: 'use:vault_sharing',
    USEEVENT_SHARING: 'use:event_sharing',
    VIEWEXPORT_TIMEZONE_SELECTION: 'view:export_timezone_selection',
    VIEWHELP_CENTER: 'view:help_center',
    VIEWTOUR_CLIENT_MATTER: 'view:tour_client_matter',
    VIEWUSAGE_DASHBOARD: 'view:usage_dashboard',
    VIEWWORKSPACE_INFO: 'view:workspace_info',
    WRITEALL_WORKSPACES: 'write:all_workspaces',
    WRITECLIENT_MATTERS: 'write:client_matters',
    WRITEENG_INTERNAL: 'write:eng_internal',
    WRITEEXAMPLES: 'write:examples'
} as const;
export type Permission = typeof Permission[keyof typeof Permission];


export function instanceOfPermission(value: any): boolean {
    for (const key in Permission) {
        if (Object.prototype.hasOwnProperty.call(Permission, key)) {
            if (Permission[key as keyof typeof Permission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PermissionFromJSON(json: any): Permission {
    return PermissionFromJSONTyped(json, false);
}

export function PermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permission {
    return json as Permission;
}

export function PermissionToJSON(value?: Permission | null): any {
    return value as any;
}

export function PermissionToJSONTyped(value: any, ignoreDiscriminator: boolean): Permission {
    return value as Permission;
}

