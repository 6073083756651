import React, { useEffect, useMemo, useState } from 'react'

import { isNil } from 'lodash'

import { Button, ButtonVariantProps } from 'components/ui/button'
import { Checkbox, CheckboxVariant } from 'components/ui/checkbox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group'
import { Switch } from 'components/ui/switch'

import {
  ExportOptionGroup,
  ExportOptionValues,
  ExportOptionValue,
  CITATION_OPTION_GROUP,
  CHECKBOX_ALL_VALUE,
} from './types'

interface Props extends ButtonVariantProps {
  hasSources?: boolean
  optionGroups?: ExportOptionGroup[]
  onChange?: (values: ExportOptionValues) => void
  onExport: (values: ExportOptionValues) => void
  title?: string
  disabled: boolean
  getIsSubmitDisabled?: (values: ExportOptionValues) => boolean
}

const ExportDialog = ({
  hasSources,
  optionGroups,
  onChange,
  onExport,
  title,
  disabled,
  getIsSubmitDisabled,
  variant,
}: Props) => {
  const memoizedOptionGroups = useMemo(() => {
    if (hasSources) return [...(optionGroups ?? []), CITATION_OPTION_GROUP]
    return optionGroups ?? []
  }, [hasSources, optionGroups])

  const [dialogOpen, setDialogOpen] = useState(false)
  const [values, setValues] = useState<ExportOptionValues>({})

  useEffect(() => {
    setValues((prevValues) => {
      const newValues = { ...prevValues }
      memoizedOptionGroups.forEach((group) => {
        if (group.defaultValue && isNil(newValues[group.id])) {
          newValues[group.id] = group.defaultValue
        }
      })
      return newValues
    })
  }, [memoizedOptionGroups])

  const handleChange = (group: ExportOptionGroup, value: ExportOptionValue) => {
    setValues((prevValues) => {
      const newValues = { ...prevValues }
      switch (group.type) {
        case 'radio':
          newValues[group.id] = value
          break
        case 'toggle':
          newValues[group.id] = !newValues[group.id]
          break
        case 'checkbox': {
          if (!newValues[group.id]) newValues[group.id] = []
          const currentValue = newValues[group.id] as string[]

          if (value === CHECKBOX_ALL_VALUE) {
            const allSelected = group.options.every((option) =>
              currentValue.includes(option.value as string)
            )
            newValues[group.id] = allSelected
              ? []
              : group.options.map((opt) => opt.value as string)
          } else {
            newValues[group.id] = currentValue.includes(value as string)
              ? currentValue.filter((item) => item !== value)
              : [...currentValue, value as string]
          }
          break
        }
        default:
          break
      }
      return newValues
    })
  }

  useEffect(() => {
    if (onChange) onChange(values)
  }, [onChange, values])

  const renderOptions = (group: ExportOptionGroup) => {
    switch (group.type) {
      case 'radio':
        return (
          <RadioGroup>
            {group.options.map((option) => (
              <RadioGroupItem
                className="px-2 py-0.5"
                key={option.label}
                id={option.label}
                label={option.label}
                value={option.value as string}
                checked={values[group.id] === option.value}
                onClick={() => handleChange(group, option.value)}
              />
            ))}
          </RadioGroup>
        )
      case 'toggle':
        return group.options.map((option) => (
          <label
            key={option.label}
            className="flex justify-between text-sm"
            htmlFor={option.label}
          >
            {option.label}
            <Switch
              id={option.label}
              checked={!!values[group.id]}
              onCheckedChange={() => handleChange(group, option.value)}
            />
          </label>
        ))
      case 'checkbox':
        return group.options.map((option) => {
          const currentValue = values[group.id]
          const currentArray = Array.isArray(currentValue) ? currentValue : []
          const currentSet = new Set(currentArray)

          const isAllOption = option.value === CHECKBOX_ALL_VALUE
          const allChecked = group.options.every((opt) =>
            opt.value === CHECKBOX_ALL_VALUE
              ? true
              : currentSet.has(opt.value as string)
          )

          const isChecked =
            (isAllOption && allChecked) ||
            currentSet.has(option.value as string)
          const isIndeterminate =
            isAllOption && !allChecked && currentSet.size > 0

          return (
            <Checkbox
              className="px-2 py-0.5"
              key={option.label}
              id={option.label}
              label={option.label}
              value={option.value as string}
              checked={isChecked || isIndeterminate}
              isIndeterminate={isIndeterminate}
              onClick={() => handleChange(group, option.value)}
              variant={isAllOption ? CheckboxVariant.SELECT_ALL : undefined}
            />
          )
        })
      default:
        return null
    }
  }

  const handleExport = (e: React.FormEvent) => {
    e.preventDefault()
    onExport(values)
    setDialogOpen(false)
  }

  if (memoizedOptionGroups.length === 0) {
    return (
      <Button
        onClick={handleExport}
        variant={variant}
        disabled={disabled}
        data-testid="export-dropdown-common"
      >
        Export
      </Button>
    )
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild disabled={disabled}>
        <Button variant={variant} data-testid="export-dropdown-common">
          Export
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-80">
        <DialogHeader>
          <DialogTitle>{title ?? 'Export to Microsoft Word'}</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleExport}>
          <div className="space-y-6 pb-6 pt-2">
            {memoizedOptionGroups.map((group) => (
              <div key={group.id}>
                <div className="mb-3 text-xs font-medium text-muted">
                  {group.name}
                </div>
                {renderOptions(group)}
              </div>
            ))}
          </div>
          <div className="flex justify-end space-x-2">
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button
              disabled={
                getIsSubmitDisabled ? getIsSubmitDisabled(values) : false
              }
              type="submit"
              data-testid="export-dialog--export-button"
            >
              Export
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ExportDialog
