import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { RowNode } from 'ag-grid-community'
import { Trash, Download, X, SquareAsterisk } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { TaskType } from 'utils/task'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import { FileSource } from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { BaseAppPath } from 'components/base-app-path'
import Toolbelt, {
  ToolbeltButton,
  ToolbeltDivider,
  ToolbeltMenu,
} from 'components/ui/toolbelt'
import useVaultQueryDetailStore from 'components/vault/query-detail/vault-query-detail-store'
import { fileIdSearchParamKey } from 'components/vault/utils/vault'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import {
  exportExcelWithReviewState,
  exportWordWithReviewState,
  downloadFiles,
} from 'components/vault/utils/vault-exporter'
import { getReadyToQueryFileIds } from 'components/vault/utils/vault-helpers'
import {
  useVaultStore,
  VaultReviewSocketState,
} from 'components/vault/utils/vault-store'
import { pluralizeDocuments } from 'components/vault/utils/vault-text-utils'

const VaultDataGridToolbelt = ({ className }: { className?: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const fileId = searchParams.get(fileIdSearchParamKey)

  const navigate = useNavigateWithQueryParams()

  const setKnowledgeSource = useAssistantStore((s) => s.setKnowledgeSource)

  const [
    currentProject,
    currentProjectMetadata,
    fileIdToVaultFile,
    folderIdToVaultFolder,
    upsertVaultFiles,
  ] = useVaultStore(
    useShallow((s) => [
      s.currentProject,
      s.currentProjectMetadata,
      s.fileIdToVaultFile,
      s.folderIdToVaultFolder,
      s.upsertVaultFiles,
    ])
  )

  const [selectedRows, clearSelectedRows, bulkAddSelectedRows] =
    useVaultDataGridFilterStore(
      useShallow((s) => [
        s.selectedRows,
        s.clearSelectedRows,
        s.bulkAddSelectedRows,
      ])
    )

  const [
    queryId,
    historyItem,
    gridApi,
    pendingQueryFileIds,
    setPendingQueryFileIds,
    removeFileIdsFromPendingQueryFileIds,
  ] = useVaultQueryDetailStore(
    useShallow((s) => [
      s.queryId,
      s.historyItem,
      s.gridApi,
      s.pendingQueryFileIds,
      s.setPendingQueryFileIds,
      s.removeFileIdsFromPendingQueryFileIds,
    ])
  )

  const isToolbeltVisible = selectedRows.length > 0 && !fileId
  const displayText = `${pluralizeDocuments(selectedRows.length)} selected`

  if (!isToolbeltVisible) {
    return null
  }

  const onOpenInAssistant = () => {
    if (!currentProject) return
    setKnowledgeSource({
      type: FileSource.VAULT,
      folderId: currentProject.id,
      fileIds: getReadyToQueryFileIds(
        currentProjectMetadata,
        selectedRows,
        folderIdToVaultFolder
      ),
    })
    navigate(BaseAppPath.Assistant, { state: { skipReset: true } })
  }

  const onDeleteSelectedRows = async () => {
    if (!gridApi) return

    const gridRowsCopy: RowNode[] = []
    const selectedRowsCopy = [...selectedRows]
    const pendingQueryFileIdsCopy = pendingQueryFileIds ?? null
    // 1. delete the rows from the grid

    const newRowData: any[] = []
    gridApi.forEachNode((node) => {
      gridRowsCopy.push(node.data)
      if (selectedRows.includes(node.data.id)) {
        return
      }
      newRowData.push(node.data)
    })
    gridApi.setGridOption('rowData', newRowData)

    // 2. if row is a pending row, then remove it from the pending query file ids
    removeFileIdsFromPendingQueryFileIds(selectedRows)

    // 3. clear any selected rows
    gridApi.deselectAll()
    clearSelectedRows()

    try {
      // TODO:
      // 1. make api request to delete already processed files
      // 2. if successful remove the file from the historyItem
      throw new Error('Failed to delete row(s)')
    } catch (e) {
      // revert the grid to the original state
      displayErrorMessage('Failed to delete row(s)')
      gridApi.setGridOption('rowData', gridRowsCopy)

      // add the selected rows back to the selected rows
      selectedRowsCopy.forEach((row) => {
        const node = gridApi.getRowNode(row)
        if (node && selectedRowsCopy.includes(node.data.id)) {
          node.setSelected(true)
        }
      })

      bulkAddSelectedRows(selectedRowsCopy)

      // add the selected rows back to the pending query file ids
      setPendingQueryFileIds(pendingQueryFileIdsCopy)
    }
  }

  const downloadHandler = async () => {
    await downloadFiles({
      fileIdsToDownload: selectedRows,
      fileIdToVaultFile,
      downloadFileName: currentProject?.name || '',
      upsertVaultFiles,
    })
  }

  const exportWordHandler = async () => {
    if (!gridApi || !queryId) return
    const reviewState = historyItem?.metadata as VaultReviewSocketState
    if (!reviewState) {
      displayErrorMessage(
        'Sorry we could not export the word file at this time. Please try again later.'
      )
      return
    }

    await exportWordWithReviewState({
      taskType: TaskType.VAULT_REVIEW,
      gridApi,
      queryId,
      onlySelected: true,
      exportAll: false,
      reviewState,
      fileIdToVaultFile,
    })
  }

  const exportExcelHandler = async () => {
    if (!gridApi || !queryId) return
    const sheetName = currentProject?.name ?? 'Untitled'
    const reviewState = historyItem?.metadata as VaultReviewSocketState
    if (!reviewState) {
      displayErrorMessage(
        'Sorry we could not export the excel file at this time. Please try again later.'
      )
      return
    }

    await exportExcelWithReviewState({
      taskType: TaskType.VAULT_REVIEW,
      sheetName,
      queryId,
      reviewState,
      fileIdToVaultFile,
      gridApi,
      exportAll: false,
      isShowingLongResponses: false,
      onlySelected: true,
    })
  }

  const onDeselectAllRows = () => {
    gridApi?.deselectAll()
    clearSelectedRows()
  }

  return (
    <Toolbelt
      className={cn(
        'flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0',
        className
      )}
    >
      <div className="flex items-center">
        <ToolbeltButton className="truncate rounded-l-md rounded-r-none border border-r-0 border-dotted border-primary hover:bg-interactive">
          {displayText}
        </ToolbeltButton>
        <ToolbeltButton
          icon={X}
          className="rounded-l-none rounded-r-md border border-dotted border-primary"
          onClick={onDeselectAllRows}
        />
      </div>
      <ToolbeltDivider className="hidden md:block" />
      <div className="flex flex-wrap items-center justify-center gap-1 md:flex-nowrap md:gap-2">
        <ToolbeltButton icon={SquareAsterisk} onClick={onOpenInAssistant}>
          Assistant
        </ToolbeltButton>
        <ToolbeltButton icon={Trash} onClick={onDeleteSelectedRows}>
          Delete
        </ToolbeltButton>
        <ToolbeltMenu
          items={[
            {
              label: 'Export selected rows to Word',
              onClick: exportWordHandler,
            },
            {
              label: 'Export selected rows to Excel',
              onClick: exportExcelHandler,
            },
            { label: 'Download selected files', onClick: downloadHandler },
          ]}
        >
          <ToolbeltButton id="download" data-testid="download" icon={Download}>
            Export
          </ToolbeltButton>
        </ToolbeltMenu>
      </div>
    </Toolbelt>
  )
}

export default VaultDataGridToolbelt
