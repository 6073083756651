import React from 'react'

import { isEmpty } from 'lodash'

import Services from 'services'

import { cn } from 'utils/utils'

import {
  FILTER_CHANGED_EVENT_NAME,
  AnalyticsContextType,
} from 'components/common/analytics/analytics-context'
import { SourceRecord } from 'components/filter/types/source-record'
import { MultiSelect, MultiSelectEntry } from 'components/ui/multi-select'

interface SourceFilterProps extends AnalyticsContextType {
  filterKey: string
  value?: string
  setValue: (value: string) => void
  sortedSourceTypes: MultiSelectEntry[]
}

const SourceFilter = (props: SourceFilterProps) => {
  const { filterKey, setValue, trackEvent } = props
  const selectedSourceTypes = props.value?.split(',') || []

  const render: React.FC = () => {
    return (
      <MultiSelect
        key={filterKey}
        placeholder="Select"
        sortedEntries={props.sortedSourceTypes}
        selectedValues={selectedSourceTypes}
        setSelectedValues={(selectedValues) => {
          setValue(selectedValues.join(','))
          Services.HoneyComb.Record({
            metric: 'ui.history_source_filter_change',
            sources: selectedValues.join(','),
          })
          trackEvent(FILTER_CHANGED_EVENT_NAME, {
            entity_name: 'source filter',
            field_changed: 'source',
            sources: selectedValues.join(','),
          })
        }}
        className={cn('w-40', {
          'text-muted': isEmpty(selectedSourceTypes),
        })}
        align="end"
      />
    )
  }

  const filterLogic = (record: SourceRecord) => {
    if (selectedSourceTypes.length === 0) {
      return true
    }
    return (
      record.knowledgeSources?.some((source) =>
        selectedSourceTypes.includes(source.type)
      ) ?? false
    )
  }

  return { filterKey, displayName: 'Source', render, filterLogic }
}

export default SourceFilter
