import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import PSPDFKit, {
  Color,
  HighlightAnnotation,
  Instance,
  TextLine,
  type Annotation as PSPDFKitAnnotation,
} from 'pspdfkit'

import { UploadedFile } from 'openapi/models/UploadedFile'
import Services from 'services'
import { Maybe } from 'types'

import { findClosestMatchLev } from 'utils/levenshtein'
import { PromiseHelperAllSettled } from 'utils/promise'
import { extractMarkedTextSimple } from 'utils/source'
import { findClosestMatchRegex } from 'utils/string'
import { Source } from 'utils/task'

import {
  HIGHLIGHT_DEFAULT_OPACITY,
  HIGHLIGHT_SELECTED_OPACITY,
} from './assistant-utils'

const getHighlightedParams = async (
  text: string,
  startPage: number,
  document: Instance
) => {
  // get all textlines from startPage
  let textLines = Array.from(await document.textLinesForPageIndex(startPage))
  // only add second page if within range
  if (startPage + 1 < document.totalPageCount) {
    textLines = [
      ...textLines,
      ...Array.from(await document.textLinesForPageIndex(startPage + 1)),
    ]
  }

  return getLinesToHighlight(text, textLines)
}

const getMergedHighlightIntervals = (intervals: number[][]): number[][] => {
  const mergedIntervals: number[][] = []

  if (intervals.length === 0) return mergedIntervals

  intervals.sort((a, b) => a[0] - b[0])
  let currentInterval = intervals[0]

  for (let i = 1; i < intervals.length; i++) {
    const nextInterval = intervals[i]

    // Check if intervals overlap
    if (nextInterval[0] <= currentInterval[1]) {
      currentInterval[1] = Math.max(currentInterval[1], nextInterval[1])
    } else {
      mergedIntervals.push(currentInterval)
      currentInterval = nextInterval
    }
  }

  mergedIntervals.push(currentInterval)

  return mergedIntervals
}

const getHighlightParamsWithStartingPage = async (
  text: string,
  startPage: number,
  document: Instance
) => {
  // get all textlines from startPage
  let textLines = Array.from(await document.textLinesForPageIndex(startPage))
  // only add second page if within range
  if (startPage + 1 < document.totalPageCount) {
    textLines = [
      ...textLines,
      ...Array.from(await document.textLinesForPageIndex(startPage + 1)),
    ]
  }

  const ret = getLinesToHighlight(text, textLines)
  const startingPage =
    ret.length > 0 ? ret[0].pageIndex ?? startPage : startPage

  return { startingPage, textLines: ret }
}

const normalizeString = (input: string) => {
  // Convert to lower case and remove all non-alphanumeric characters
  return input.toLowerCase().replace(/[^a-z0-9]/g, '')
}

const getLineIndexRangeToHighlight = (text: string, textLines: TextLine[]) => {
  const { indexesOfLines, textNoWhiteSpace } = textLines.reduce(
    ({ contentLength, indexesOfLines, textNoWhiteSpace }, line) => {
      // clean current line of string and remove whitespace
      const contents = normalizeString(line.contents)

      // add its content length to the total content length
      contentLength += contents.length

      // append index
      indexesOfLines.push(contentLength)

      return {
        contentLength,
        indexesOfLines,
        textNoWhiteSpace: textNoWhiteSpace + contents,
      }
    },
    {
      contentLength: 0,
      indexesOfLines: new Array<number>(),
      textNoWhiteSpace: '',
    }
  )

  // normalize snippet text
  const splitText = text.split(' ')
  const noWhiteSpace = normalizeString(text)

  // get the index inside of the textNoWhiteSpace
  let startIdx = textNoWhiteSpace.indexOf(noWhiteSpace)

  // if we can't highlight try a minimizing regex match
  if (startIdx == -1) {
    const firstWord = normalizeString(splitText[0])
    const lastWord = normalizeString(splitText[splitText.length - 1])
    const { index } = findClosestMatchRegex({
      haystack: textNoWhiteSpace,
      firstWord,
      lastWord,
      length: noWhiteSpace.length,
      threshold: 0.3,
    })
    startIdx = index
  }

  // if we still can't find it, we try to find the closest match
  if (startIdx == -1) {
    startIdx = findClosestMatchLev(textNoWhiteSpace, noWhiteSpace)
  }

  if (startIdx == -1) {
    console.warn('text not found in document', {
      snippet: noWhiteSpace,
      full: textNoWhiteSpace,
    })
    return []
  }

  // find the index of the startLine and endLine
  const startLine = _.sortedIndex(indexesOfLines, startIdx)
  const endLine = _.sortedIndex(indexesOfLines, startIdx + noWhiteSpace.length)

  return [startLine, endLine]
}

const getLinesToHighlight = (text: string, textLines: TextLine[]) => {
  const [startLine, endLine] = getLineIndexRangeToHighlight(text, textLines)

  // should be inclusive of startLine and endLine
  return textLines.slice(startLine, endLine + 1)
}

const highlightPdfText = (textLine: TextLine, isSelectedSource = false) => {
  return new PSPDFKit.Annotations.HighlightAnnotation({
    pageIndex: textLine.pageIndex,
    rects: PSPDFKit.Immutable.List([textLine.boundingBox]),
    boundingBox: textLine.boundingBox,
    opacity: isSelectedSource
      ? HIGHLIGHT_SELECTED_OPACITY
      : HIGHLIGHT_DEFAULT_OPACITY,
    blendMode: 'multiply',
    color: Color.fromHex('#BFDBFE'),
  })
}

export const applyPageOnlyAnnotations = async (
  activeDocument: UploadedFile,
  sources: Source[],
  pdfkitInstance: Instance,
  selectedSource: Maybe<Source>
  // eslint-disable-next-line max-params
): Promise<any> => {
  await removeAnnotations(pdfkitInstance)
  // highlight selectedSource first
  if (!_.isNil(selectedSource)) {
    // scroll to page
    const newViewState = pdfkitInstance.viewState.set(
      'currentPageIndex',
      selectedSource.page
    )
    pdfkitInstance.setViewState(newViewState)

    // remove mark from text
    const extracted = selectedSource.text
      .replace('<mark>', '')
      .replace('</mark>', '')
    const textLines = (
      await getHighlightedParams(extracted, selectedSource.page, pdfkitInstance)
    ).flat()

    // create highlights
    for (const textLine of textLines) {
      const highlight = highlightPdfText(textLine, true)
      void pdfkitInstance.create(highlight)
    }
  }

  // apply all other highlights for other sources in doc
  const annotations = await Promise.all(
    sources
      .filter(
        (source) =>
          source.documentName === activeDocument.name && !_.isNil(source.page)
      )
      .map(async ({ text, page }) => {
        const extracted = extractMarkedTextSimple(text)
        return await getHighlightedParams(extracted, page, pdfkitInstance)
      })
  )

  await Promise.all(
    annotations
      .flat()
      .map((textLine) => highlightPdfText(textLine, false))
      .map((annotation) => pdfkitInstance.create(annotation))
  )
}

export const applyAnnotations = async (
  activeDocument: Maybe<UploadedFile>,
  sources: Source[],
  pdfkitInstance: Maybe<Instance>,
  selectedSource: Maybe<Source>
  // eslint-disable-next-line max-params
): Promise<any> => {
  if (_.isNil(activeDocument)) {
    return
  }
  const annotations = AllPSPDFKitAnnotations(sources, activeDocument.name)

  if (annotations.length === 0) {
    return
  }

  if (_.isNil(pdfkitInstance)) {
    console.warn('No pdfkit instance to apply annotations')
    return
  }

  await Promise.all(
    Array.from({
      length: pdfkitInstance.totalPageCount ?? 0,
    }).map(async (_, pageIndex) => {
      const annotations = await pdfkitInstance.getAnnotations(pageIndex)
      return await Promise.all(
        (annotations ?? []).map(
          async (annotation) => await pdfkitInstance.delete(annotation)
        )
      )
    })
  )

  // create annotation in pspdfkit document
  await Promise.all(
    // eslint-disable-next-line
    annotations.map((annotation) => {
      return pdfkitInstance.create(annotation)
    })
  )

  // if source is selected, scroll to that annotation
  if (!_.isNil(selectedSource)) {
    await scrollToSourceHighlight(selectedSource, pdfkitInstance)
  }
}

export const AllPSPDFKitAnnotations = (
  sources: Source[],
  docName?: string
): PSPDFKitAnnotation[] => {
  let docSources: Source[] = []
  if (docName !== undefined && docName !== '' && !_.isEmpty(sources)) {
    docSources = sources.filter((source) => source.documentName === docName)
  }

  return docSources
    .flatMap((source) => source.annotations as any[])
    .filter((annotation) => annotation)
    .map(
      (raw) =>
        PSPDFKit.Annotations.fromSerializableObject({
          ...raw,
          color: '#BFDBFE',
        }) as PSPDFKitAnnotation
    )
}

export const scrollToSourceHighlight = async (
  source: Source,
  pspdfInstance: Maybe<Instance>
): Promise<void> => {
  const group = source.id
  if (_.isNil(group) || _.isNil(pspdfInstance)) {
    console.warn('No pdfkit instance to apply annotations')
    return
  }

  if (source.annotations.length === 0) {
    // if no annotations, jump to page
    const sourceText = source.text.replace(/…$/, '').substring(0, 30) // remove trailing ellipsis and truncate before searching

    try {
      const searchResults = await pspdfInstance.search(sourceText)

      // @ts-expect-error todo
      let pageIndex = searchResults.first()?.pageIndex ?? source.page

      // XXX: Guarding against over-indexing, not clear how this happens
      if (pageIndex >= pspdfInstance.totalPageCount) {
        const newPage =
          source.page < pspdfInstance.totalPageCount ? source.page : 0

        console.warn(
          `Page index out of bounds: ${pageIndex} of ${pspdfInstance.totalPageCount}, ` +
            `setting to ${newPage}`,
          source
        )
        pageIndex = newPage
      }

      const newViewState = pspdfInstance?.viewState.set(
        'currentPageIndex',
        pageIndex
      )
      pspdfInstance.setViewState(newViewState)
    } catch (e) {
      console.error('cant scroll')
      Sentry.captureException(e)
      Services.HoneyComb.RecordError(e)
    }
    return
  }

  // get current document annotations
  const pagesAnnotations = await Promise.all(
    Array.from({
      length: pspdfInstance.totalPageCount ?? 0,
    }).map(
      // eslint-disable-next-line
      (_, pageIndex) => pspdfInstance.getAnnotations(pageIndex)
    )
  )

  // highlight selected annotation
  pagesAnnotations.forEach((annotations) => {
    if (_.isNil(annotations)) return

    annotations.forEach(async (annotation) => {
      let newOpacity = 0.4
      if (annotation.get('name') === group) {
        newOpacity = 1
      }

      if (annotation.get('opacity') === newOpacity || _.isNil(pspdfInstance)) {
        return
      }

      try {
        await pspdfInstance.update(annotation.set('opacity', newOpacity))
      } catch (e) {
        console.warn('Failed to update highlighted opacity', e)
      }
    })

    const matchingAnnotations = annotations.filter(
      (annotation) => annotation.get('name') === group
    )
    if (matchingAnnotations.size > 0) {
      const lastMatchingAnnotation =
        matchingAnnotations.last() as PSPDFKitAnnotation
      const pageIndex = lastMatchingAnnotation.get('pageIndex') ?? -1
      pspdfInstance.jumpToRect(pageIndex, lastMatchingAnnotation.boundingBox)
    }
  })
}

// documentation from pspdfkit to remove annotations
// https://pspdfkit.com/guides/web/knowledge-base/delete-all-annotations/
export const removeAnnotations = async (instance: Instance) => {
  const pagesAnnotations = (
    await PromiseHelperAllSettled(
      Array.from({
        length: instance.totalPageCount,
      }).map((_, pageIndex) => instance.getAnnotations(pageIndex))
    )
  )
    .filter((result) => result.status === 'fulfilled')
    .map((result) => result.value)

  const annotationIds = pagesAnnotations.flatMap((pageAnnotations) =>
    pageAnnotations
      .map((annotation: PSPDFKitAnnotation) => annotation.id)
      .toArray()
  )
  await instance.delete(annotationIds)
}

export const navigateToPage = (pageIndex: number, pdfkitInstance: Instance) => {
  const newViewState = pdfkitInstance.viewState.set(
    'currentPageIndex',
    pageIndex
  )
  pdfkitInstance.setViewState(newViewState)
}

const getOrCreateAnnotations = async (params: {
  isSelectedSource: boolean
  selectedSource: Source
  pdfkitInstance: Instance
  cachedAnnotations: {
    [key: string]: { startingPage: number; textLines: TextLine[] }
  }
  updateAnnotationStore: (
    sourceId: string,
    annotation: { startingPage: number; textLines: TextLine[] }
  ) => void
}): Promise<{ startingPage: number; annotations: HighlightAnnotation[] }> => {
  const {
    isSelectedSource,
    selectedSource,
    cachedAnnotations,
    pdfkitInstance,
    updateAnnotationStore,
  } = params
  const val = cachedAnnotations[selectedSource.id]
  let startingPage = val?.startingPage
  let textLines: TextLine[] = val?.textLines
  if (_.isNil(val)) {
    const extracted = extractMarkedTextSimple(selectedSource.text)

    const res = await getHighlightParamsWithStartingPage(
      extracted,
      selectedSource.page ?? 0,
      pdfkitInstance
    )
    startingPage = res.startingPage
    textLines = res.textLines

    updateAnnotationStore(selectedSource.id, res)
  }

  const annotations = textLines.map((textLine) =>
    highlightPdfText(textLine, isSelectedSource)
  )
  return { startingPage, annotations }
}

const renderAnnotations = async (
  annotations: HighlightAnnotation[],
  pdfkitInstance: Instance
) => {
  await Promise.all(
    annotations.map((annotation) => pdfkitInstance.create(annotation))
  )
}

const getMergedHighlightedAnnotations = async (
  sources: Source[],
  document: Instance,
  cachedDocumentAnnotations: {
    [key: number]: TextLine[]
  },
  updateDocumentAnnotationStore: (page: number, annotations: TextLine[]) => void
  // eslint-disable-next-line max-params
) => {
  const textLineIndicesByPage: { [key: number]: number[][] } = {}
  const allTextLinesByPage: { [key: number]: TextLine[] } = {}

  const annotations: HighlightAnnotation[] = []

  // calculate index ranges if not cached
  if (_.isEmpty(cachedDocumentAnnotations)) {
    for (const source of sources) {
      const extracted = extractMarkedTextSimple(source.text)
      // get all textlines from startPage
      let textLines = Array.from(
        await document.textLinesForPageIndex(source.page)
      )
      // only add second page if within range
      if (source.page + 1 < document.totalPageCount) {
        textLines = [
          ...textLines,
          ...Array.from(await document.textLinesForPageIndex(source.page + 1)),
        ]
      }
      allTextLinesByPage[source.page] = textLines
      const indexRangeToHighlight = getLineIndexRangeToHighlight(
        extracted,
        textLines
      )
      if (_.isNil(textLineIndicesByPage[source.page])) {
        textLineIndicesByPage[source.page] = []
      }
      textLineIndicesByPage[source.page].push(indexRangeToHighlight)
    }

    for (const page of Object.keys(textLineIndicesByPage).map(Number)) {
      const textLineIndicesForPage = textLineIndicesByPage[page]
      const mergedHighlightIntervals = getMergedHighlightIntervals(
        textLineIndicesForPage
      )
      // go through all merged intervals and add text lines
      const textLinesForPage = []
      for (const interval of mergedHighlightIntervals) {
        const textLines = allTextLinesByPage[page].slice(
          interval[0],
          interval[1] + 1
        )
        textLinesForPage.push(...textLines)
      }
      updateDocumentAnnotationStore(page, textLinesForPage)
      const pageAnnotations = textLinesForPage.map((textLine) =>
        highlightPdfText(textLine)
      )
      annotations.push(...pageAnnotations)
    }
  } else {
    // fetch from cache instead
    for (const page of Object.keys(cachedDocumentAnnotations).map(Number)) {
      const textLinesForPage: TextLine[] = cachedDocumentAnnotations[page]
      const pageAnnotations = textLinesForPage.map((textLine) =>
        highlightPdfText(textLine)
      )
      annotations.push(...pageAnnotations)
    }
  }

  return annotations
}

export const applyFrontendAnnotations = async (params: {
  sources: Source[]
  pdfkitInstance: Instance
  selectedSource: Maybe<Source>
  cachedSourceAnnotations: {
    [key: string]: { startingPage: number; textLines: TextLine[] }
  }
  updateSourceAnnotationStore: (
    sourceId: string,
    annotation: { startingPage: number; textLines: TextLine[] }
  ) => void
  cachedDocumentAnnotations: { [key: number]: TextLine[] }
  updateDocumentAnnotationStore: (page: number, annotations: TextLine[]) => void
}) => {
  const {
    sources,
    pdfkitInstance,
    selectedSource,
    cachedSourceAnnotations,
    updateSourceAnnotationStore,
    cachedDocumentAnnotations,
    updateDocumentAnnotationStore,
  } = params
  // delete all annotations
  await removeAnnotations(pdfkitInstance)

  const allAnnotations = []
  // highlight selectedSource
  if (!_.isNil(selectedSource)) {
    // scroll to page
    navigateToPage(selectedSource.page ?? 0, pdfkitInstance)

    const { startingPage, annotations } = await getOrCreateAnnotations({
      isSelectedSource: true,
      selectedSource,
      cachedAnnotations: cachedSourceAnnotations,
      pdfkitInstance,
      updateAnnotationStore: updateSourceAnnotationStore,
    })
    if (selectedSource.page !== startingPage) {
      navigateToPage(startingPage, pdfkitInstance)
    }
    allAnnotations.push(...annotations)
  }

  // apply all other highlights for other sources in doc
  const annotations = await getMergedHighlightedAnnotations(
    sources,
    pdfkitInstance,
    cachedDocumentAnnotations,
    updateDocumentAnnotationStore
  )
  allAnnotations.push(...annotations)
  await renderAnnotations(allAnnotations, pdfkitInstance)
}
