import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { SafeRecord } from 'utils/safe-types'

import { ReviewWorkflow } from 'components/vault/utils/vault-fetcher'

export enum WorkflowModalState {
  None,
  Selector,
  Builder,
  New,
  Publish,
  Unpublish,
  Delete,
  AddFilesWorkflow,
  AddFilesReviewTable,
  AddFilesAssistantThread,
  CreateBlank,
  AssistantThreadInput,
}

interface VaultWorkflowState {
  workflows: SafeRecord<string, ReviewWorkflow>
  workflowModalState: WorkflowModalState
  selectedWorkflow: ReviewWorkflow | null
}

interface VaultWorkflowAction {
  setWorkflows: (workflows: ReviewWorkflow[]) => void
  setWorkflow: (workflow: ReviewWorkflow) => void
  removeWorkflow: (workflowId: string) => void
  setWorkflowModalState: (state: WorkflowModalState) => void
  setSelectedWorkflow: (workflow: ReviewWorkflow | null) => void
}

const useVaultWorkflowStore = create(
  devtools(
    immer<VaultWorkflowState & VaultWorkflowAction>((set) => ({
      // VaultWorkflowState
      workflows: {},
      workflowModalState: WorkflowModalState.None,
      selectedWorkflow: null,
      // VaultWorkflowAction
      setWorkflows: (workflows) =>
        set((state) => {
          state.workflows = workflows.reduce(
            (acc, workflow) => {
              acc[workflow.id] = workflow
              return acc
            },
            {} as SafeRecord<string, ReviewWorkflow>
          )
        }),
      setWorkflow: (workflow) =>
        set((state) => {
          state.workflows[workflow.id] = workflow
        }),
      removeWorkflow: (workflowId) =>
        set((state) => {
          delete state.workflows[workflowId]
        }),
      setWorkflowModalState: (state) =>
        set(() => ({ workflowModalState: state })),
      setSelectedWorkflow: (workflow) =>
        set(() => ({ selectedWorkflow: workflow })),
    }))
  )
)

export default useVaultWorkflowStore
