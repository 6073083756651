import { useRef } from 'react'

import { useShallow } from 'zustand/react/shallow'

import { EventKind } from 'openapi/models/EventKind'

import useHarveySocket, {
  InitSocketAndSendQueryParams,
} from 'utils/use-harvey-socket'
import { ToBackendKeys } from 'utils/utils'

import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import { generateTitle } from 'components/assistant-v2/utils/assistant-api'
import {
  isVaultKnowledgeSource,
  KnowledgeSourceItem,
} from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import useQueryAnalytics from 'components/common/analytics/use-query-analytics'
import { useAuthUser } from 'components/common/auth-context'

import { useSocketCompletedCallback } from './use-assistant-socket-completed-callback'

export type AssistantChatStreamHandler = {
  initChat: (
    query: string,
    eventId: string | null,
    onAuthCallback?: (eventId: string) => void,
    additionalRequestParams?: { [key: string]: unknown },
    providedKnowledgeSource?: KnowledgeSourceItem
  ) => Promise<void>
  createChatReply: (
    query: string,
    prevMessageId: string | undefined,
    onAuthCallback?: (eventId: string) => void
  ) => Promise<void>
  sendCancelRequest: () => void
}

export const useAssistantChatStreamHandler = (): AssistantChatStreamHandler => {
  const isCancelled = useRef<boolean>(false)
  const [
    setTask,
    setStreamingMessage,
    eventId,
    getDocumentFileIds,
    messages,
    getCurrentThreadMessages,
    documentsUploading,
    preserveState,
    currentMessageId,
    documents,
    knowledgeSource,
  ] = useAssistantStore(
    useShallow((s) => [
      s.setTask,
      s.setStreamingMessage,
      s.eventId,
      s.getDocumentFileIds,
      s.messages,
      s.getCurrentThreadMessages,
      s.documentsUploading,
      s.preserveState,
      s.currentMessageId,
      s.documents,
      s.knowledgeSource,
    ])
  )

  const userInfo = useAuthUser()
  const queryAnalytics = useQueryAnalytics(EventKind.ASSISTANT_CHAT)

  const selectedClientMatter = useClientMattersStore(
    (s) => s.selectedClientMatter
  )

  const { socketCompletedCallback } = useSocketCompletedCallback()

  const socket = useHarveySocket({
    path: 'assistant/chat',
    setter: setTask,
    endCallback: socketCompletedCallback,
    closeOnUnmount: false,
  })

  const createBaseRequest = (
    query: string,
    onAuthCallback?: (eventId: string) => void,
    additionalRequestParams?: { [key: string]: any }
  ): InitSocketAndSendQueryParams => {
    const baseRecordFields = {
      thread_number: getCurrentThreadMessages().length + 1,
      message_number: messages.length + 1,
      num_documents: documents.length + documentsUploading.length,
      knowledge_source_type: knowledgeSource?.type ?? '',
    }

    const recordFields = userInfo?.workspace.isEyesOff
      ? baseRecordFields
      : {
          ...baseRecordFields,
          document_names: documents.map((doc) => doc.name),
          query: query,
        }

    return {
      query,
      recordFields,
      onAuthCallback,
      ...queryAnalytics,
      additionalAuthParams: selectedClientMatter
        ? { client_matter_id: selectedClientMatter.id }
        : {},
      additionalRequestParams: {
        ...additionalRequestParams,
      },
    }
  }

  const initChat = async (
    query: string,
    eventId: string | null,
    onAuthCallback?: (eventId: string) => void,
    additionalRequestParams?: { [key: string]: unknown },
    providedKnowledgeSource?: KnowledgeSourceItem
    // eslint-disable-next-line max-params
  ) => {
    // make sure we are not cancelled at start
    isCancelled.current = false
    setStreamingMessage({ query, isLoading: true, sources: [] })
    preserveState(query, currentMessageId)

    if (documentsUploading.length && eventId) {
      const caption = await generateTitle({ query, eventId: parseInt(eventId) })
      setStreamingMessage({ query, isLoading: true, sources: [], caption })
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isCancelled.current) {
      isCancelled.current = false
      return
    }

    const req = createBaseRequest(
      query,
      onAuthCallback,
      additionalRequestParams
    )
    req.additionalAuthParams = {
      ...req.additionalAuthParams,
      ...(eventId && { event_id: eventId }),
    }

    // Use knowledge source from input if directly provided, otherwise use value in the store
    const knowledgeSourceToUse = providedKnowledgeSource ?? knowledgeSource

    if (knowledgeSourceToUse) {
      const knowledgeSourceInput = ToBackendKeys(knowledgeSourceToUse)
      req.additionalRequestParams = {
        ...req.additionalRequestParams,
        knowledge_sources: [knowledgeSourceInput],
      }
      if (isVaultKnowledgeSource(knowledgeSourceToUse)) {
        req.additionalRequestParams = {
          ...req.additionalRequestParams,
          vault_project_id: knowledgeSourceToUse.folderId,
        }
      }
    } else {
      const fileIds = await getDocumentFileIds()
      if (fileIds.length > 0) {
        req.additionalRequestParams = {
          ...req.additionalRequestParams,
          knowledge_sources: [{ type: 'files', file_ids: fileIds }],
        }
      }
    }

    socket.initSocketAndSendQuery(req)
    isCancelled.current = false
  }

  const createChatReply = async (
    query: string,
    prevMessageId: string | undefined,
    onAuthCallback?: (eventId: string) => void
  ) => {
    setStreamingMessage({ query, isLoading: true, prevMessageId })

    const req = createBaseRequest(query, onAuthCallback)
    req.additionalAuthParams = {
      ...req.additionalAuthParams,
      event_id: eventId,
      prev_message_id: prevMessageId,
    }

    if (knowledgeSource) {
      const knowledgeSourceInput = ToBackendKeys(knowledgeSource)
      req.additionalRequestParams = {
        ...req.additionalRequestParams,
        knowledge_sources: [knowledgeSourceInput],
      }
      if (isVaultKnowledgeSource(knowledgeSource)) {
        req.additionalRequestParams = {
          ...req.additionalRequestParams,
          vault_project_id: knowledgeSource.folderId,
        }
      }
    } else {
      const fileIds = await getDocumentFileIds()
      if (fileIds.length > 0) {
        req.additionalRequestParams = {
          ...req.additionalRequestParams,
          knowledge_sources: [{ type: 'files', file_ids: fileIds }],
        }
      }
    }

    preserveState(query, currentMessageId)
    socket.initSocketAndSendQuery(req)
  }

  const sendCancelRequest = () => {
    isCancelled.current = true
    queryAnalytics.recordQueryCancel({
      num_documents: documents.length + documentsUploading.length,
    })
    socket.sendCancelRequest()
  }

  return {
    initChat,
    createChatReply,
    sendCancelRequest,
  }
}
