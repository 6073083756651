import * as Sentry from '@sentry/browser'

import {
  GenerateTitleRequest,
  GenerateTitleRequestToJSON,
} from 'openapi/models/GenerateTitleRequest'
import Services from 'services'
import { HistoryItem } from 'types/history'

import {
  AssistantMessage,
  ForkedAssistantEvent,
} from 'components/assistant-v2/types'

export const deleteMessage = (eventId: string, message: AssistantMessage) => {
  return Services.Backend.Patch(`assistant/${eventId}/current_message`, {
    currentMessageId: message.prevMessageId,
  })
}

export const processFiles = (eventId: string, fileIds: string[]) => {
  return Services.Backend.Post(`event/process_files/${eventId}`, { fileIds })
}

export const fetchRecentAssistantQueries = async () => {
  const requestPath = `assistant/history`
  return await Services.Backend.Get<{ events: HistoryItem[] }>(requestPath, {
    throwOnError: true,
  }).catch((e) => {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return { events: [] }
  })
}

export const fetchRecentSharedAssistantQueries = async (params: {
  pageSize: number
  offset: number
}) => {
  const requestPath = `assistant/shared/history?page_size=${params.pageSize}&offset=${params.offset}`

  return await Services.Backend.Get<HistoryItem[]>(requestPath, {
    throwOnError: true,
  }).catch((e) => {
    Sentry.captureException(e)
    Services.HoneyComb.RecordError(e)
    return []
  })
}

export const generateTitle = async (request: GenerateTitleRequest) => {
  const requestPath = 'assistant/generate-title'

  return await Services.Backend.Post<string>(
    requestPath,
    GenerateTitleRequestToJSON(request)
  )
}

export const setCaption = async (eventId: string, caption: string) => {
  return Services.Backend.Post(`assistant/${eventId}/set-caption`, { caption })
}

export const createOwnCopy = async (eventId: string): Promise<HistoryItem> => {
  return Services.Backend.Post(`assistant/${eventId}/create-own-copy`, {})
}

export const forkEventDocuments = async (
  eventId: string
): Promise<ForkedAssistantEvent> => {
  return Services.Backend.Post(
    `assistant/${eventId}/new-event-same-documents`,
    {}
  )
}
