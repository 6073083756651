import React from 'react'

import _ from 'lodash'
import { Instance } from 'pspdfkit'

import { useGeneralStore } from 'stores/general-store'

import { getSourceClicked } from 'utils/source'
import { Source } from 'utils/task'

import { useAuthUser } from 'components/common/auth-context'
import Response from 'components/common/response/response'
import { ScrollArea } from 'components/ui/scroll-area'

import { ASSISTANT_WITHOUT_OPEN_ENDED_HELP } from './assistant-constants'
import AssistantSources from './assistant-sources'
import { useAssistantStore } from './assistant-store'
import {
  getHelpPanelText,
  isDoneStreaming,
  isSourcesEmpty,
} from './assistant-utils'
import { scrollToSourceHighlight } from './pspdfkit-helpers'

interface AssistantResponsePanelProps {
  onCancel: () => void
  pspdfInstanceRef: React.RefObject<Instance>
}

const AssistantResponsePanel = ({
  onCancel,
  pspdfInstanceRef,
}: AssistantResponsePanelProps): JSX.Element => {
  const userInfo = useAuthUser()

  const {
    response,
    headerText,
    isLoading,
    sources,
    annotations,
    uploadedFiles,
    activeDocument,
    queryId,
    feedback,
    internetBrowsing,
    setActiveDocument,
    setSelectedSource,
    setFeedback,
  } = useAssistantStore()

  const onSourceClick = async (source: Source): Promise<void> => {
    if (internetBrowsing) {
      if (_.isNil(source.documentUrl)) {
        return
      }
      window.open(source.documentUrl, '_blank')
      return
    }

    if (!userInfo.isDocumentUser) {
      return
    }

    const group = source.id
    if (_.isNil(group)) {
      return
    }
    setSelectedSource(source)

    const doc = _.first(
      uploadedFiles?.filter((file) => file.name == source.documentName)
    )
    if (_.isNil(doc)) {
      return
    }

    if (activeDocument?.name !== doc.name) {
      setActiveDocument(doc)
    } else {
      await scrollToSourceHighlight(source, pspdfInstanceRef.current)
    }
  }

  const getHrvyInfoMetadata = (identifier: string) => {
    if (_.isNil(sources)) {
      return
    }

    const source = getSourceClicked(identifier, annotations)
    if (!source) {
      return
    }

    return { onClick: () => void onSourceClick(source as Source) }
  }

  const showFeedback = isDoneStreaming(response, isLoading) && !feedback?.closed
  const showSources = !isSourcesEmpty(sources)
  const assistantHelpText = _.isNil(userInfo)
    ? ASSISTANT_WITHOUT_OPEN_ENDED_HELP
    : getHelpPanelText(userInfo)

  const sidebarWidth = useGeneralStore((s) => s.sidebarWidth)
  const assistantResponsePanelPercent = useAssistantStore(
    (s) => s.assistantResponsePanelPercent
  )
  const answerContainerRef = React.useRef<HTMLDivElement>(null)
  const containerHorizontalPadding = answerContainerRef.current
    ? parseInt(
        window.getComputedStyle(answerContainerRef.current).paddingLeft
      ) +
      parseInt(window.getComputedStyle(answerContainerRef.current).paddingRight)
    : 0
  // Calculate width for markdown if it is in assistant response panel:
  // 1. 100vw - sidebarWidth = width of the main content area.
  // 2. Multiply the width above by the percentage of the assistant response panel.
  // 3. Subtract the padding on the left and right side of the container.
  // Otherwise, set the width to 100%.
  const answerWidth = `calc((100vw - ${sidebarWidth}px) * ${
    assistantResponsePanelPercent / 100
  } - ${containerHorizontalPadding}px)`

  return (
    <div
      id="assistant-response"
      className="h-full w-full"
      data-testid="assistant--response-panel"
    >
      <ScrollArea className="h-full" overflowHintSide="both">
        <Response
          className="border-none"
          markdown={response}
          headerText={headerText}
          emptyStateText={assistantHelpText}
          handleCancel={onCancel}
          isLoading={isLoading}
          getHrvyInfoMetadata={getHrvyInfoMetadata}
          queryId={String(queryId)}
          handleSaveFeedback={showFeedback ? setFeedback : undefined}
          hideSpinner
          footerChildren={
            showSources && (
              <AssistantSources
                sources={sources}
                onSourceClick={onSourceClick}
              />
            )
          }
          answerWidth={answerWidth}
          answerContainerRef={answerContainerRef}
        />
      </ScrollArea>
    </div>
  )
}

export default AssistantResponsePanel
