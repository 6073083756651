import CryptoJS from 'crypto-js'
import { IBaseParagraphStyleOptions } from 'docx'
import { Packer } from 'docx'
import { saveAs } from 'file-saver'
import _, { isEmpty } from 'lodash'
import { remark } from 'remark'
import { remarkExtendedTable } from 'remark-extended-table'
import remarkGfm from 'remark-gfm'
import remarkHtml from 'remark-html'
import remarkParse from 'remark-parse'
import showdown from 'showdown'

import Services from 'services'
import { Maybe } from 'types'

import { WordSection, htmlToDocx, sectionsToDocx } from './docx'
import { createExportFilename } from './export'
import { SentryEventType, sentryEvent } from './sentry'
import { removeControlCharacters } from './string'
import { AnnotationById, Source, TaskType } from './task'
import { backendFormat } from './utils'

const modelMarkdownToHtmlBreakLinks = (
  converter: showdown.Converter,
  text: string
): string => {
  // ignores links, since the model is unlikely to give useful links, so any text in markdown link format
  // is probably a false positive
  const matches = text.match(/\[.+?\] ?\(.+?\)/gm)
  if (matches === null || isEmpty(matches)) {
    return converter.makeHtml(text)
  }

  // insert an underscore to disrupt parsing, e.g. "[google](google.com)" -> "[google]_(google.com)"
  const modifiedMatches: string[] = []
  for (let i = 0; i < matches.length; i++) {
    const match = matches[i]
    const modifiedMatch = match.replace(/\]( ?)\(/, ']$1\\(')
    text = text.replace(match, modifiedMatch)
    modifiedMatches.push(modifiedMatch)
  }
  text = remapCitations(text)
  text = converter.makeHtml(text)

  // remove the underscore by replacing the new text with the old text
  for (let i = 0; i < modifiedMatches.length; i++) {
    text = text.replace(modifiedMatches[i], matches[i])
  }

  return text
}

const modelMarkdownToHtmlPreserveLinks = (
  converter: showdown.Converter,
  text: string
): string => {
  const listConverter = new showdown.Converter()
  // extract lists in tables and replace with hash
  const lists: Record<string, string> = {}
  for (const match of Array.from(
    text.matchAll(/(?<=\| )(- (?:[^|\n]|\n)+?) \|/gm)
  )) {
    const mdList = match[1]
    const hash = CryptoJS.SHA256(mdList).toString(CryptoJS.enc.Hex)
    lists[hash] = mdList
  }
  for (const [hash, mdList] of Object.entries(lists)) {
    text = text.replace(mdList, hash)
  }
  text = converter.makeHtml(text)

  // convert lists to html separately
  for (const [hash, mdList] of Object.entries(lists)) {
    // insert them back into the string where the hashes are
    text = text.replace(
      hash,
      listConverter.makeHtml(mdList.replace(/<br>/g, '\n'))
    )
  }

  // open links in a new tab/window by adding `target="_blank"`
  text = text.replace(
    /<a\s+(?!.*target=)[^>]*href="([^"]*)"[^>]*>/g,
    // eslint-disable-next-line prefer-smart-quotes/prefer
    '<a target="_blank" href="$1">'
  )

  return text
}

function remapCitations(text: string): string {
  return text.replace(
    /<span data-hrvy-id="[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}">(\d+)<\/span>/g,
    '[$1]'
  )
}

export function modelMarkdownToHtmlRemark(text: string): string {
  return remark()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkExtendedTable)
    .use(remarkHtml)
    .processSync(text)
    .toString()
}

function modelMarkdownToHtml(text: string, useLinks: boolean): string {
  const converter = new showdown.Converter({
    simpleLineBreaks: true,
    tables: true,
  })
  return useLinks
    ? modelMarkdownToHtmlPreserveLinks(converter, text)
    : modelMarkdownToHtmlBreakLinks(converter, text)
}

/**
 * Extracts just the answer part of the model's response, ignoring status/context sections like "Filings Reviewed" and "References".
 */
function extractAnswerOnly(
  responseHtml: string,
  taskType?: Maybe<TaskType>
): string {
  let answerHtml = responseHtml

  // remove all inline citations and deduplicate leftover space
  const spanRegex = /<span data-hrvy-id="([0-9a-fA-F-]+)">(\d+)<\/span>/g
  answerHtml = responseHtml.replace(spanRegex, '')

  const matchCitationLink = /<a target="_blank" href="#\d+">(\[\d+\])<\/a>/g
  answerHtml = answerHtml.replace(matchCitationLink, '$1')

  if (taskType && TaskType.SEC_EDGAR_QA === taskType) {
    // eslint-disable-next-line
    if (answerHtml.search(/<h3[^>]*>Analysis<\/h3>/g)) {
      const match = answerHtml.match(/<h3[^>]*>Analysis<\/h3>(.+)/s)
      if (match !== null) {
        answerHtml = match[1]
      }
    }
  }
  answerHtml = removeInlineCitations(answerHtml as string)

  return answerHtml
}

/**
 * Removes the inline citations from a text string (either HTML or markdown) and returns the text string
 * with the citations removed.
 * @param text The text string to remove the citations from.
 * @returns The text string with the citations removed.
 */
export function removeInlineCitations(text: string): string {
  if (_.isNil(text) || _.isEmpty(text) || !_.isString(text)) {
    return text
  }
  const matchCitation = /\[\d+\](\s\[\d+\])*/g
  return text.replace(matchCitation, '')
}

export const logExport = async (
  exportType: string,
  taskType: TaskType,
  eventId: string
): Promise<void> => {
  Services.HoneyComb.Record({
    metric: 'ui.export_response',
    export_type: exportType,
    task_type: taskType,
    event_id: eventId,
  })
  sentryEvent(SentryEventType.USER_ACTION, 'export_response', {
    export_type: exportType,
    task_type: taskType,
    event_id: eventId,
  })
}

export const exportWord = async function (params: {
  content: string
  sources?: Maybe<Source[]>
  taskType: TaskType
  includeAnnotation: boolean
  queryId: string
  titleText?: string
}): Promise<void> {
  const { content, sources, taskType, includeAnnotation, queryId, titleText } =
    params
  const responseHtml = modelMarkdownToHtml(content, true)
  const answerHtml = includeAnnotation
    ? responseHtml
    : extractAnswerOnly(responseHtml, taskType)
  const title = titleText ?? _.startCase(_.lowerCase(taskType))

  const doc = await htmlToDocx({
    html: `<body>${answerHtml}</body>`,
    injectTitle: title,
    sources: sources && sources.length > 0 ? sources : null,
  })
  const blob = await Packer.toBlob(doc)
  saveAs(blob, createExportFilename(taskType))

  void logExport('docx', taskType, queryId)
}

type ExportWordWithSectionsParams = {
  title: string
  includeAnnotation: boolean
  sections: WordSection[]
  queryId: string
  taskType: TaskType
  filePrefixOverride?: string
  useRemark?: boolean
  addTitleToSections?: boolean
  defaultStyleOverrides?: Record<string, IBaseParagraphStyleOptions>
}

export const exportWordWithSections = async function (
  params: ExportWordWithSectionsParams
): Promise<void> {
  const {
    title,
    sections,
    queryId,
    includeAnnotation,
    taskType,
    filePrefixOverride,
    useRemark,
    addTitleToSections = true,
    defaultStyleOverrides,
  } = params

  const normalizedSections = sections.map((section) => {
    const { type } = section
    if (type === 'markdown') {
      if (useRemark) {
        section.content = modelMarkdownToHtmlRemark(section.content as string)
      } else {
        section.content = modelMarkdownToHtml(section.content as string, true)
      }
      section.type = 'html'
    }
    if (!includeAnnotation && section.type != 'sources') {
      section.content = extractAnswerOnly(section.content as string, taskType)
    }

    // Remove control characters from the content
    if (typeof section.content === 'string') {
      section.content = removeControlCharacters(section.content)
    } else {
      section.content = section.content.map((source: Source) => {
        const documentName = removeControlCharacters(source.documentName ?? '')
        const text = removeControlCharacters(source.text)
        return {
          ...source,
          documentName,
          text,
        }
      })
    }
    return section
  })

  const doc = await sectionsToDocx({
    title,
    sections: normalizedSections,
    addTitleToSections,
    defaultStyleOverrides,
  })
  const blob = await Packer.toBlob(doc)
  const dateTime = backendFormat(new Date())
  const filename = `${filePrefixOverride || taskType}_${dateTime}.docx`
  saveAs(blob, filename)

  void logExport('docx', taskType, queryId)
}

export const replaceSpanWithLink = (
  response: string,
  annotations: AnnotationById
) => {
  const spanRegex = /<span data-hrvy-id="([0-9a-fA-F-]+)">(\d+)<\/span>/g
  return response.replace(spanRegex, (match, id, citation) => {
    const annotation = annotations[id] as Source | undefined
    if (annotation) {
      return `<a target="_blank" href="${annotation.documentUrl}">[${citation}]</a>`
    }
    return match
  })
}

export const replaceSpanWithCitation = (response: string) => {
  const spanRegex = /<span data-hrvy-id="([0-9a-fA-F-]+)">(\d+)<\/span>/g
  return response.replace(spanRegex, (_, id, citation) => {
    return `<span data-hrvy-id="${id}">[${citation}]</span>`
  })
}

export const exportWordWithQuery = async function (params: {
  query: string
  response: string
  taskType: TaskType
  includeAnnotation: boolean
  queryId: string
  files?: string[]
  sources?: Source[]
  titleText?: string
  additionalSections?: { content: string; type: 'markdown' | 'html' }[]
}): Promise<void> {
  const {
    query,
    response,
    sources,
    taskType,
    includeAnnotation,
    queryId,
    titleText,
    additionalSections,
    files,
  } = params
  const title = titleText ?? _.startCase(_.lowerCase(taskType))
  const cleanedResponse = remapCitations(response)
  await exportWordWithSections({
    title,
    taskType,
    includeAnnotation,
    queryId: String(queryId),
    sections: [
      ...(files
        ? [
            { content: `##Files\n\n${files.join('\n\n')}`, type: 'markdown' },
            { content: `<br/>`, type: 'html' },
          ]
        : []),
      { content: `##Query\n\n${query}`, type: 'markdown' },
      { content: `<br/>`, type: 'html' },
      { content: `##Response\n\n${cleanedResponse}`, type: 'markdown' },
      ...(sources ? [{ content: sources, type: 'sources' }] : []),
      ...(additionalSections ?? []),
    ] as {
      content: string | Source[]
      type: 'sources' | 'html' | 'markdown'
    }[],
  })
}
