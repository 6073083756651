import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { upperCase } from 'lodash'

import { Source } from 'openapi/models/Source'
import { Maybe } from 'types'

import { bytesToReadable, getFileNameExtension } from 'utils/file-utils'

import Citation from 'components/ui/citation'

const DotSeparator = () => <span className="text-xs "> ・ </span>

interface Props {
  name: Maybe<string>
  size: Maybe<number>
  sources?: Source[]
  onSourceClick?: (source: Source) => void
  sourceIdParam?: string
}
const PdfDocumentHeader = ({
  name,
  size,
  sources,
  onSourceClick,
  sourceIdParam,
}: Props) => {
  const [params] = useSearchParams()
  const sourceId = params.get(sourceIdParam ?? '')

  return (
    <div className="not-prose">
      <p className="break-words font-semibold">{name ?? 'Loading…'}</p>
      <div className="flex flex-wrap gap-0.5 text-xs text-muted">
        <span className="text-xs">
          {upperCase(getFileNameExtension(name)) || 'File'}
        </span>
        {size && (
          <>
            <DotSeparator />
            <span className="text-xs">{bytesToReadable(size)}</span>
          </>
        )}
        {sources && sources.length > 0 && (
          <>
            <DotSeparator />
            {sources.map((source) => (
              <Citation
                key={source.footnote}
                onClick={
                  onSourceClick ? () => onSourceClick(source) : undefined
                }
                isSelected={source.id === sourceId}
                className="mr-0.5"
              >
                {source.footnote}
              </Citation>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default PdfDocumentHeader
