import React from 'react'

import { AppHeader } from 'components/common/app-header'

import SettingsLayout from './settings-layout'

interface SettingsErrorProps {
  description: string
}

const SettingsError = ({ description }: SettingsErrorProps) => {
  return (
    <>
      <AppHeader title="Settings" subtitle="Error" breadcrumbs={<div />} />
      <SettingsLayout>
        <div className="flex h-1/2 translate-y-1/2 flex-col items-center space-y-4">
          <div className="text-center">
            <h1 className="text-xl">Error</h1>
          </div>
          <div className="text-center">
            <p className="text-muted">{description}</p>
          </div>
          <div className="flex space-x-2"></div>
        </div>
      </SettingsLayout>
    </>
  )
}
export default SettingsError
