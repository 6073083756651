/* tslint:disable */
/* eslint-disable */
/**
 * Harvey Web App - User Permissions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DefaultBasePermission = {
    VIEWHELP_CENTER: 'view:help_center',
    CREATECOMPLETION: 'create:completion',
    CREATEDOCUMENT_QA: 'create:document_qa',
    CREATEADVANCED_DOC_QA: 'create:advanced_doc_qa',
    CREATEMULTI_DOC_QA: 'create:multi_doc_qa',
    USEASSISTANT_V2: 'use:assistant_v2',
    CREATEASSISTANT_DRAFT: 'create:assistant_draft',
    READHISTORY: 'read:history',
    CREATEFEEDBACK: 'create:feedback',
    CREATEPRIVATE_LIBRARY_PROMPTS: 'create:private_library_prompts',
    USELIBRARY_EXAMPLES: 'use:library_examples',
    CREATEUSER_LIBRARY_ITEMS: 'create:user_library_items',
    CREATETRANSLATION: 'create:translation',
    CREATEREDLINES: 'create:redlines',
    CREATESEC_EDGAR_QA: 'create:sec_edgar_qa'
} as const;
export type DefaultBasePermission = typeof DefaultBasePermission[keyof typeof DefaultBasePermission];


export function instanceOfDefaultBasePermission(value: any): boolean {
    for (const key in DefaultBasePermission) {
        if (Object.prototype.hasOwnProperty.call(DefaultBasePermission, key)) {
            if (DefaultBasePermission[key as keyof typeof DefaultBasePermission] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DefaultBasePermissionFromJSON(json: any): DefaultBasePermission {
    return DefaultBasePermissionFromJSONTyped(json, false);
}

export function DefaultBasePermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefaultBasePermission {
    return json as DefaultBasePermission;
}

export function DefaultBasePermissionToJSON(value?: DefaultBasePermission | null): any {
    return value as any;
}

