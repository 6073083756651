import { useMemo } from 'react'

import { useShallow } from 'zustand/react/shallow'

import useHarveySocket from 'utils/use-harvey-socket'

import * as config from 'components/workflows/workflow/discovery/config'
import { useDiligenceStore } from 'components/workflows/workflow/discovery/diligence-store'

export const useRunFollowUpQuestion = () => {
  // Actions for running the report
  const [
    setter,
    runFollowUpQuestion,
    currentQuery,
    sections,
    runFollowUpCompletedCallback,
  ] = useDiligenceStore(
    useShallow((s) => [
      s.setter,
      s.runFollowUpQuestion,
      s.currentFollowUpInput,
      s.sections,
      s.runFollowUpCompletedCallback,
    ])
  )

  const { initSocketAndSendQuery } = useHarveySocket({
    path: config.ROUTE_FOLLOWUPS,
    setter,
    endCallback: runFollowUpCompletedCallback,
    closeOnUnmount: false, // answer will continue streaming if the user navigates away and comes back
  })

  const reportText = useMemo(() => {
    return sections
      .map((section) =>
        section.tasks
          .map((task) => task.title + '\n' + task.answer?.response)
          .join('\n')
      )
      .join('\n\n')
  }, [sections])

  const runFollowUp = () => {
    runFollowUpQuestion(currentQuery, reportText, initSocketAndSendQuery)
  }

  return runFollowUp
}
