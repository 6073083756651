// TODO: History table is not a great example of how we should use react hooks.
// There are too many useEffect usages that can be refactored out.
// While we don't have bandwidth to do this refactor, we should make sure this is
// not an example that people follow.
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table'
import { format, startOfWeek } from 'date-fns'
import _ from 'lodash'
import { useShallow } from 'zustand/react/shallow'

import type { Event } from 'models/event'
import { cleanUpHistory, HistoryTypeEnum } from 'models/helpers/history-helper'
import { useHistoryPollingQuery } from 'models/queries/use-history-polling-query'
import type { Workspace } from 'models/workspace'
import { AuditLogType } from 'openapi/models/AuditLogType'
import Services from 'services'

import { usePostAuditLog } from 'utils/audit-log'
import { readableFormat, TodayOption } from 'utils/date-utils'
import { getRouteForEvent } from 'utils/routing'
import { getDocumentCount, useAllTaskLabelLookup } from 'utils/task-definitions'

import { useClientMattersStore } from 'components/client-matters/client-matters-store'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import { useAuthUser } from 'components/common/auth-context'
import { useEventFilterStore } from 'components/filter/filter-store'
import { ClientMatterRecord } from 'components/filter/types/client-matter-record'
import { useGroupingStore } from 'components/grouping/grouping-store'
import { HistoryGrouping } from 'components/grouping/history-grouping-def'
import { Collapsible } from 'components/ui/collapsible'
import { DataTable } from 'components/ui/data-table/data-table'

import {
  HistoryClientMatterCell,
  HistoryFavoriteCell,
  HistoryMenuCell,
  HistoryQueryCell,
  HistoryResponseCell,
  HistorySelectCell,
  HistorySelectHeader,
  HistorySourceCell,
  HistoryTaskTypeCell,
  HistoryTimeCell,
  HistoryTitleCell,
  HistoryUserNameCell,
} from './history-cells'
import HistoryDeleteDialog from './history-delete-dialog'
import HistoryEditRemoveClientMatterDialog from './history-edit-remove-client-matter-dialog'
import {
  useGetEventDeleted,
  useHistoryMetadataStore,
} from './history-metadata-store'
import { useHistoryStore } from './history-store'
import { useWorkspaceHistoryMetadataStore } from './workspace-history-metadata-store'

export const HISTORY_BATCH_SIZE = 50
const TableDefaultColumnSize = 120

const HistoryTable: React.FC<{
  type: HistoryTypeEnum
  workspace: Workspace
}> = ({ type, workspace }) => {
  const userInfo = useAuthUser()
  const taskLabelLookup = useAllTaskLabelLookup(userInfo)
  const {
    historyClientMatters: workspaceHistoryClientMatters,
    getClientMatterName: getWorkspaceClientMatterName,
    getRouteWithClientMatter: getWorkspaceRouteWithClientMatter,
  } = useWorkspaceHistoryMetadataStore()
  const {
    favoriteFolder,
    historyClientMatters: userHistoryClientMatters,
    getClientMatterName: getUserClientMatterName,
    getRouteWithClientMatter: getUserRouteWithClientMatter,
  } = useHistoryMetadataStore()
  const getEventDeleted = useGetEventDeleted()

  const historyClientMatters = useMemo(
    () =>
      type === HistoryTypeEnum.WORKSPACE
        ? workspaceHistoryClientMatters[workspace.slug ?? ''] ?? []
        : userHistoryClientMatters,
    [
      type,
      workspaceHistoryClientMatters,
      workspace.slug,
      userHistoryClientMatters,
    ]
  )
  const hasHistoryClientMatters = useMemo(
    () => historyClientMatters.length > 0,
    [historyClientMatters]
  )
  const getClientMatterName = useCallback(
    (event: ClientMatterRecord) => {
      if (type === HistoryTypeEnum.WORKSPACE) {
        return getWorkspaceClientMatterName(workspace.slug ?? '', event)
      } else {
        return getUserClientMatterName(event)
      }
    },
    [getWorkspaceClientMatterName, getUserClientMatterName, workspace, type]
  )
  const getRouteWithClientMatter = useCallback(
    (event: ClientMatterRecord, path: string) => {
      if (type === HistoryTypeEnum.WORKSPACE) {
        return getWorkspaceRouteWithClientMatter(
          workspace.slug ?? '',
          event,
          path
        )
      } else {
        return getUserRouteWithClientMatter(event, path)
      }
    },
    [
      getWorkspaceRouteWithClientMatter,
      getUserRouteWithClientMatter,
      workspace,
      type,
    ]
  )

  const {
    isLoading,
    dataSource,
    setIsLoading,
    setIsHistoryPending,
    setIsLoadingMore,
    setEarliestLoadedTime,
    setLatestLoadedTime,
    updateLastUpdatedTime,
    setDataSource,
    setFetchHistoryUpdate,
  } = useHistoryStore((s) => ({
    isLoading: s.isLoading,
    earliestLoadedTime: s.earliestLoadedTime,
    latestLoadedTime: s.latestLoadedTime,
    dataSource: s.dataSource,
    setIsLoading: s.setIsLoading,
    setIsHistoryPending: s.setIsHistoryPending,
    setIsLoadingMore: s.setIsLoadingMore,
    setEarliestLoadedTime: s.setEarliestLoadedTime,
    setLatestLoadedTime: s.setLatestLoadedTime,
    updateLastUpdatedTime: s.updateLastUpdatedTime,
    setDataSource: s.setDataSource,
    setFetchHistoryUpdate: s.setFetchHistoryUpdate,
  }))

  const { selectedEventIds, setSelectedEventIds } = useHistoryStore((s) => ({
    selectedEventIds: s.selectedEventIds,
    setSelectedEventIds: s.setSelectedEventIds,
  }))

  const oldestDate = useEventFilterStore
    .getState()
    .getEarliestDateFromDateRangeFilter()

  const {
    data: historyData,
    isPending: isHistoryPending,
    fetchOnePoll,
    isFetchingOlder,
    isFetchingNewer,
  } = useHistoryPollingQuery({
    fetchHistoryArgs: {
      currentPage: 1,
      pageSize: HISTORY_BATCH_SIZE,
      workspaceSlug: workspace.slug,
      historyType: type,
    },
    oldestDate: oldestDate ?? new Date(0), // Returns January 1, 1970, 00:00:00 UTC
    queryOptions: {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      select: (result) => {
        return { ...result, pages: result.pages.map(cleanUpHistory) }
      },
    },
    retentionPolicyInSeconds: workspace.retentionPeriod,
    updateLastUpdatedTime,
  })

  const { trackEvent } = useAnalytics()

  useEffect(
    () => setIsHistoryPending(isHistoryPending),
    [setIsHistoryPending, isHistoryPending]
  )
  useEffect(() => {
    setFetchHistoryUpdate(fetchOnePoll)
  }, [setFetchHistoryUpdate, fetchOnePoll])

  // The debounce utility is to manage the state updates for isLoadingMore and isLoading.
  // Rapid toggling between true and false states can lead to undesirable layout flickering in the UI.
  const debouncedSetIsLoadingMore = useMemo(
    () => _.debounce(setIsLoadingMore, 250),
    [setIsLoadingMore]
  )
  useEffect(() => {
    if (isFetchingOlder) {
      debouncedSetIsLoadingMore.cancel() // Cancel any pending debounced calls
      setIsLoadingMore(true)
    } else {
      debouncedSetIsLoadingMore(false)
    }
    return () => debouncedSetIsLoadingMore.cancel()
  }, [isFetchingOlder, debouncedSetIsLoadingMore, setIsLoadingMore])

  const debouncedSetIsLoading = useMemo(
    () => _.debounce(setIsLoading, 250),
    [setIsLoading]
  )
  useEffect(() => {
    if (isFetchingOlder || isFetchingNewer || isHistoryPending) {
      debouncedSetIsLoading.cancel() // Cancel any pending debounced calls
      setIsLoading(true)
    } else {
      debouncedSetIsLoading(false)
    }
    return () => debouncedSetIsLoading.cancel()
  }, [
    isFetchingOlder,
    isFetchingNewer,
    setIsLoading,
    isHistoryPending,
    debouncedSetIsLoading,
  ])

  // Calculate the earliest and latest loaded times for the status message based on the loaded history data.
  useEffect(() => {
    if (dataSource.length === 0) {
      // If there is no history data, then there is no earliest or latest loaded time.
      return
    }

    // Assuming dataSource is sorted by 'created' in descending order,
    // the last record is the earliest, and the first record is the latest.
    const earliestLoadedDate = new Date(
      dataSource[dataSource.length - 1].created
    )
    const latestLoadedDate = new Date(dataSource[0].created)

    setEarliestLoadedTime(
      readableFormat(earliestLoadedDate, TodayOption.showTime)
    )
    setLatestLoadedTime(readableFormat(latestLoadedDate, TodayOption.showTime))
  }, [dataSource, setEarliestLoadedTime, setLatestLoadedTime])

  const [
    selectedEventForEditClientMatter,
    setSelectedEventForEditClientMatter,
  ] = useState<Event | null>(null)
  const [
    selectedEventForRemoveClientMatter,
    setSelectedEventForRemoveClientMatter,
  ] = useState<Event | null>(null)
  const [editRemoveClientMatterModalOpen, setEditRemoveClientMatterModalOpen] =
    useState(false)
  useEffect(() => {
    if (!editRemoveClientMatterModalOpen) {
      setSelectedEventForEditClientMatter(null)
      setSelectedEventForRemoveClientMatter(null)
    }
  }, [
    editRemoveClientMatterModalOpen,
    setSelectedEventForEditClientMatter,
    setSelectedEventForRemoveClientMatter,
  ])
  const onEditClientMatter = useMemo(
    () =>
      type === HistoryTypeEnum.USER && userInfo.isClientMattersReadUser
        ? (event: Event) => {
            setSelectedEventForEditClientMatter(event)
            setEditRemoveClientMatterModalOpen(true)
            Services.HoneyComb.Record({
              metric: `ui.history_edit_client_matter_dialog_opened`,
              event_id: event.id,
            })
            trackEvent('History Edit Client Matter Dialog Opened', {
              event_id: event.id,
            })
          }
        : undefined,
    [type, userInfo.isClientMattersReadUser, trackEvent]
  )
  const onRemoveClientMatter = useMemo(
    () =>
      type === HistoryTypeEnum.USER && userInfo.isClientMattersReadUser
        ? (event: Event) => {
            setSelectedEventForRemoveClientMatter(event)
            setEditRemoveClientMatterModalOpen(true)
            Services.HoneyComb.Record({
              metric: `ui.history_remove_client_matter_dialog_opened`,
              event_id: event.id,
            })
            trackEvent('History Remove Client Matter Dialog Opened', {
              event_id: event.id,
            })
          }
        : undefined,
    [
      type,
      userInfo.isClientMattersReadUser,
      setSelectedEventForRemoveClientMatter,
      setEditRemoveClientMatterModalOpen,
      trackEvent,
    ]
  )

  const [shouldCmLockQueries] = useClientMattersStore(
    useShallow((state) => [state.shouldCmLockQueries])
  )

  const [selectedEventForDelete, setSelectedEventForDelete] =
    useState<Event | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  useEffect(() => {
    if (!deleteModalOpen) {
      setSelectedEventForDelete(null)
    }
  }, [deleteModalOpen, setSelectedEventForDelete])
  const onDeleteEvent = useMemo(() => {
    if (
      type === HistoryTypeEnum.WORKSPACE &&
      userInfo.IsDeleteWorkspaceHistoryUser
    ) {
      return (event: Event) => {
        setSelectedEventForDelete(event)
        setDeleteModalOpen(true)
        Services.HoneyComb.Record({
          metric: 'ui.workspace_history_delete_event_dialog_opened',
          event_id: event.id,
          event_user_id: event.userId,
          type: type,
          workspace_slug: workspace.slug,
        })
        trackEvent('Workspace History Delete Event Dialog Opened', {
          event_id: event.id,
          type: type,
        })
      }
    } else if (
      type === HistoryTypeEnum.USER &&
      userInfo.isDeleteUserHistoryUser
    ) {
      return (event: Event) => {
        setSelectedEventForDelete(event)
        setDeleteModalOpen(true)
        Services.HoneyComb.Record({
          metric: 'ui.user_history_delete_event_dialog_opened',
          event_id: event.id,
          event_user_id: event.userId,
          type: type,
          workspace_slug: workspace.slug,
        })
        trackEvent('User History Delete Event Dialog Opened', {
          event_id: event.id,
          type: type,
        })
      }
    }
    return undefined
  }, [
    type,
    userInfo.IsDeleteWorkspaceHistoryUser,
    userInfo.isDeleteUserHistoryUser,
    workspace.slug,
    trackEvent,
  ])

  const filterFunctions = useEventFilterStore((s) => s.filterFunctions)
  useEffect(() => {
    const newFilteredEvents = historyData
      .filter((record: Event) => !getEventDeleted(record))
      .filter((record: Event) =>
        Object.values(filterFunctions).every((filterFunc) => filterFunc(record))
      )
    setDataSource(newFilteredEvents)
  }, [historyData, getEventDeleted, filterFunctions, setDataSource])

  const { postAuditLog } = usePostAuditLog()

  const onRowClickAuditLog = useCallback(
    async (row: Row<Event>) => {
      if (type === HistoryTypeEnum.WORKSPACE) {
        await postAuditLog(
          AuditLogType.ADMINCLIENT_VIEW_WORKSPACE_HISTORY_ITEM,
          workspace.id,
          {
            event_id: row.original.id,
          }
        )
      } else if (type === HistoryTypeEnum.USER) {
        await postAuditLog(
          AuditLogType.USERCLIENT_VIEW_HISTORY_ITEM,
          workspace.id,
          {
            event_id: row.original.id,
          }
        )
      }
    },
    [postAuditLog, workspace, type]
  )

  const canBulkDeleteHistory =
    type === HistoryTypeEnum.WORKSPACE &&
    userInfo.IsDeleteWorkspaceHistoryBulkUser

  const grouping = useGroupingStore((s) => s.grouping)
  const columns = useMemo<Array<ColumnDef<Event>>>(() => {
    const columns: Array<ColumnDef<Event>> = []
    if (canBulkDeleteHistory) {
      columns.splice(0, 0, {
        id: 'select',
        header: ({ table }) => <HistorySelectHeader table={table} />,
        cell: ({ row }) => <HistorySelectCell row={row} />,
        accessorKey: 'id',
        size: 40,
      })
    }

    if (
      type === HistoryTypeEnum.WORKSPACE &&
      !_.isNil(workspace) &&
      !workspace.userAnonymization &&
      columns.every((c) => c.header !== 'User')
    ) {
      columns.push({
        header: 'User',
        id: 'userId',
        accessorKey: 'userId',
        cell: ({ row }) => <HistoryUserNameCell row={row} />,
      })
    }

    columns.push({
      header: 'Created',
      id: 'created',
      accessorKey: 'created',
      cell: ({ row }) => <HistoryTimeCell row={row} />,
      enableResizing: false,
      size: 104,
      maxSize: 104,
    })

    if (favoriteFolder && type !== HistoryTypeEnum.WORKSPACE) {
      columns.splice(0, 0, {
        header: '',
        id: 'folderId',
        accessorKey: 'folderId',
        cell: ({ row }) => (
          <div className="flex w-full justify-center">
            <HistoryFavoriteCell row={row} />
          </div>
        ),
        size: 64,
        maxSize: 64,
      })
    }

    if (userInfo.IsAssistantV2User) {
      columns.push({
        header: 'Title',
        id: 'caption',
        accessorKey: 'caption',
        cell: ({ row }) => <HistoryTitleCell row={row} />,
        size: 480,
      })
    } else {
      columns.push({
        header: 'Query',
        id: 'query',
        accessorKey: 'query',
        cell: ({ row }) => <HistoryQueryCell row={row} />,
        size: 320,
      })

      columns.push({
        header: 'Response',
        id: 'response',
        accessorKey: 'response',
        cell: ({ row }) => <HistoryResponseCell row={row} />,
        size: 320,
      })
    }

    columns.push({
      header: 'Type',
      id: 'kind',
      accessorKey: 'kind',
      cell: ({ row }) => <HistoryTaskTypeCell row={row} userInfo={userInfo} />,
      size: 124,
    })

    columns.push({
      header: 'Source',
      id: 'source',
      accessorKey: 'source',
      cell: ({ row }) => <HistorySourceCell row={row} userInfo={userInfo} />,
      size: 232,
    })

    if (userInfo.isClientMattersReadUser && hasHistoryClientMatters) {
      columns.push({
        header: 'CM#',
        id: 'clientMatterId',
        accessorKey: 'clientMatterId',
        cell: ({ row }) => (
          <HistoryClientMatterCell
            row={row}
            getClientMatterName={getClientMatterName}
          />
        ),
      })
    }

    columns.push({
      header: '',
      id: 'id',
      accessorKey: 'id',
      cell: ({ row }) => (
        <HistoryMenuCell
          row={row}
          userInfo={userInfo!}
          getClientMatterName={getClientMatterName}
          onDeleteEvent={onDeleteEvent}
          onEditClientMatter={onEditClientMatter}
          onRemoveClientMatter={
            shouldCmLockQueries ? undefined : onRemoveClientMatter
          }
          workspaceId={workspace.id}
          onOpenEventAuditLog={onRowClickAuditLog}
        />
      ),
      size: 64,
    })

    return columns
  }, [
    canBulkDeleteHistory,
    type,
    workspace,
    favoriteFolder,
    userInfo,
    hasHistoryClientMatters,
    shouldCmLockQueries,
    getClientMatterName,
    onDeleteEvent,
    onEditClientMatter,
    onRemoveClientMatter,
    onRowClickAuditLog,
  ])

  const groupingRows = useMemo(() => {
    switch (grouping) {
      case undefined:
        return []
      case HistoryGrouping.KIND: {
        const groupedEvents = _.groupBy(
          dataSource,
          (event) => taskLabelLookup[event.kind]
        )
        return Object.keys(groupedEvents).map((key) => {
          return {
            id: key,
            name: key,
            events: groupedEvents[key],
          }
        })
      }
      case HistoryGrouping.DAY: {
        const groupedEvents = _.groupBy(dataSource, (event) => {
          return readableFormat(new Date(event.created), TodayOption.showLabel)
        })
        return Object.keys(groupedEvents).map((key) => {
          return {
            id: key,
            name: key,
            events: groupedEvents[key],
          }
        })
      }
      case HistoryGrouping.WEEK: {
        const groupedEvents = _.groupBy(dataSource, (event) => {
          const date = new Date(event.created)
          const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 })
          return `Week of ${readableFormat(
            startOfWeekDate,
            TodayOption.showDate
          )}`
        })
        return Object.keys(groupedEvents).map((key) => {
          return {
            id: key,
            name: key,
            events: groupedEvents[key],
          }
        })
      }
      case HistoryGrouping.MONTH: {
        const groupedEvents = _.groupBy(dataSource, (event) => {
          return format(new Date(event.created), 'MMM yyyy')
        })
        return Object.keys(groupedEvents).map((key) => {
          return {
            id: key,
            name: key,
            events: groupedEvents[key],
          }
        })
      }
      case HistoryGrouping.DOCUMENT_NUMBER: {
        const groupedEvents = _.groupBy(dataSource, (event) => {
          const documentCount = getDocumentCount(event)
          if (documentCount === 0) {
            return 'No document'
          } else if (documentCount === 1) {
            return '1 document'
          } else if (2 <= documentCount && documentCount <= 5) {
            return '2-5 documents'
          } else {
            return '6+ documents'
          }
        })
        return (
          Object.entries(groupedEvents)
            .map(([key, events]) => ({
              id: key,
              name: key,
              events: events,
            }))
            // Sort by the number of documents in ascending order
            .sort(
              (a, b) =>
                (a.events[0].documents?.length ?? 0) -
                (b.events[0].documents?.length ?? 0)
            )
        )
      }
      case HistoryGrouping.CLIENT_MATTER: {
        const groupedEvents = _.groupBy(dataSource, (event) => {
          return getClientMatterName(event) || 'No client matter'
        })
        return (
          Object.entries(groupedEvents)
            .map(([key, events]) => ({
              id: key,
              name: key,
              events: events,
            }))
            // Make sure the "No client matter" group is always at the end
            .sort(
              (a, b) =>
                (a.events[0].clientMatterId ? 0 : 1) -
                (b.events[0].clientMatterId ? 0 : 1)
            )
        )
      }
      default:
        return []
    }
  }, [grouping, dataSource, taskLabelLookup, getClientMatterName])

  const rowSelection = useMemo(() => {
    return Object.fromEntries(
      selectedEventIds
        .map((eventId) => {
          const rowIndex = dataSource.findIndex((r) => r.id === eventId)
          if (rowIndex === -1) return [undefined, false]
          return [rowIndex, true]
        })
        .filter(([index]) => index !== undefined)
    )
  }, [dataSource, selectedEventIds])

  const onRowSelectionChange = (
    updaterOrValue:
      | Record<string, boolean>
      | ((old: Record<string, boolean>) => Record<string, boolean>)
  ) => {
    let newRowSelection = updaterOrValue
    if (updaterOrValue instanceof Function) {
      newRowSelection = updaterOrValue(rowSelection)
    }
    const newSelectedEventIds = Object.entries(newRowSelection)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([index, isSelected]) => isSelected)
      .map(([index]) => dataSource[parseInt(index)].id)
      .filter(Boolean) as number[]
    setSelectedEventIds(newSelectedEventIds)
  }

  const table = useReactTable<Event>({
    data: dataSource,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    defaultColumn: {
      size: TableDefaultColumnSize,
    },
    onRowSelectionChange: onRowSelectionChange,
    state: {
      rowSelection,
    },
  })

  const onGroupingCollapse = (collapsed: boolean, groupName: string) => {
    Services.HoneyComb.Record({
      metric: 'ui.history_grouped_table_toggled',
      group_name: groupName,
      collapsed,
      type: type,
      workspace_slug: workspace.slug,
    })
    trackEvent(`History Grouped Table Toggled`, {
      group_name: groupName,
      collapsed,
      type: type,
    })
  }

  const hrefForRow = useCallback(
    (row: Row<Event>) =>
      getRouteWithClientMatter(
        row.original,
        getRouteForEvent(row.original, userInfo!)
      ),
    [userInfo, getRouteWithClientMatter]
  )
  const analyticsMetadataForRow = useCallback(
    (row: Row<Event>) => {
      return {
        event_id: row.original.id,
        type: type,
        workspace_slug: workspace.slug,
      }
    },
    [type, workspace.slug]
  )

  const trackHistoryRowClick = (row: Row<Event>, inNewTab: boolean) =>
    trackEvent(
      'History Item Loaded',
      {
        event_id: row.original.id,
        event_kind: row.original.kind,
        history_type: type,
        in_new_tab: inNewTab,
      },
      /* suppressPageView */ true
    )

  const dataTables =
    _.isNil(grouping) || _.isEmpty(dataSource) ? (
      <DataTable
        table={table}
        hrefForRow={hrefForRow}
        trackRowEvent={trackHistoryRowClick}
        isLoading={isLoading}
        tableFixed
        useVirtual={dataSource.length > 15}
        virtualEstimateSize={65}
        analyticsName="history"
        analyticsMetadataForRow={analyticsMetadataForRow}
        onRowClickAuditLog={onRowClickAuditLog}
        marginTop={48}
      />
    ) : (
      <>
        {groupingRows.map((rows) => (
          <div key={rows.id}>
            <Collapsible
              title={
                <div className="flex h-10 items-center">
                  <p>{rows.name}</p>
                </div>
              }
              onToggle={(collapsed) => {
                onGroupingCollapse(collapsed, rows.name)
              }}
              variant={null}
            >
              <GroupedTable
                group={rows}
                columns={columns}
                hrefForRow={hrefForRow}
                trackRowEvent={trackHistoryRowClick}
                analyticsMetadataForRow={analyticsMetadataForRow}
                onRowClickAuditLog={onRowClickAuditLog}
              />
            </Collapsible>
          </div>
        ))}
      </>
    )

  const selectedEventForEditOrRemoveClientMatter = useMemo(
    () =>
      selectedEventForEditClientMatter ?? selectedEventForRemoveClientMatter,
    [selectedEventForEditClientMatter, selectedEventForRemoveClientMatter]
  )

  return (
    <div className="flex flex-col gap-2">
      {dataTables}
      {selectedEventForEditOrRemoveClientMatter && (
        <HistoryEditRemoveClientMatterDialog
          type={selectedEventForEditClientMatter ? 'edit' : 'remove'}
          modalOpen={editRemoveClientMatterModalOpen}
          setModalOpen={setEditRemoveClientMatterModalOpen}
          event={selectedEventForEditOrRemoveClientMatter}
          getClientMatterName={getClientMatterName}
          taskLabelLookup={taskLabelLookup}
          showClearCmOption={!shouldCmLockQueries}
        />
      )}
      {selectedEventForDelete && (
        <HistoryDeleteDialog
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          event={selectedEventForDelete}
          taskLabelLookup={taskLabelLookup}
          isWorkspaceDelete={type === HistoryTypeEnum.WORKSPACE}
        />
      )}
    </div>
  )
}

interface GroupedTableProps {
  group: {
    id: string
    name: string
    events: Event[]
  }
  columns: ColumnDef<Event>[]
  onRowClick?: (row: Row<Event>) => void
  hrefForRow: (row: Row<Event>) => string
  trackRowEvent?: (row: Row<Event>, inNewTab: boolean) => void
  analyticsMetadataForRow: (row: Row<Event>) => Record<string, unknown>
  onRowClickAuditLog: (row: Row<Event>) => Promise<void>
}

const GroupedTable: React.FC<GroupedTableProps> = ({
  group,
  columns,
  onRowClick,
  hrefForRow,
  trackRowEvent,
  analyticsMetadataForRow,
  onRowClickAuditLog,
}) => {
  const { selectedEventIds, setSelectedEventIds } = useHistoryStore((s) => ({
    selectedEventIds: s.selectedEventIds,
    setSelectedEventIds: s.setSelectedEventIds,
  }))

  const rowSelection = useMemo(() => {
    return Object.fromEntries(
      selectedEventIds
        .map((eventId) => {
          const rowIndex = group.events.findIndex((r) => r.id === eventId)
          if (rowIndex === -1) return [undefined, false]
          return [rowIndex, true]
        })
        .filter(([index]) => index !== undefined)
    )
  }, [group, selectedEventIds])

  const tableInstance = useReactTable<Event>({
    data: group.events,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    defaultColumn: {
      size: TableDefaultColumnSize,
    },
    onRowSelectionChange: (
      updaterOrValue:
        | Record<string, boolean>
        | ((old: Record<string, boolean>) => Record<string, boolean>)
    ) => {
      let newRowSelection = updaterOrValue
      if (updaterOrValue instanceof Function) {
        newRowSelection = updaterOrValue(rowSelection)
      }
      const newSelectedEventIds = Object.entries(newRowSelection)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([index, isSelected]) => isSelected)
        .map(([index]) => group.events[parseInt(index)].id)
        .filter(Boolean) as number[]
      setSelectedEventIds(newSelectedEventIds)
    },
    state: {
      rowSelection,
    },
  })

  return (
    <DataTable
      table={tableInstance}
      onRowClick={onRowClick}
      hrefForRow={hrefForRow}
      trackRowEvent={trackRowEvent}
      tableFixed
      useVirtual={group.events.length > 15}
      virtualEstimateSize={65}
      analyticsName="history"
      analyticsMetadataForRow={analyticsMetadataForRow}
      onRowClickAuditLog={onRowClickAuditLog}
      marginTop={48}
    />
  )
}

export default HistoryTable
