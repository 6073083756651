import React, { useMemo, ChangeEvent, useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useClickAway, useMount, useUnmount } from 'react-use'

import _ from 'lodash'
import { InfoIcon } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { TagScope } from 'openapi/models/TagScope'

import { useNavigateWithQueryParams } from 'hooks/use-navigate-with-query-params'
import { TaskType } from 'utils/task'
import { displayWarningMessage } from 'utils/toast'
import {
  EMPTY_QUERY_HELP_TEXT,
  IS_LOADING_HELP_TEXT,
} from 'utils/tooltip-texts'
import { cn } from 'utils/utils'

import { BaseAppPath } from 'components/base-app-path'
import { useAnalytics } from 'components/common/analytics/analytics-context'
import AskHarveyButton from 'components/common/ask-harvey-button'
import { useAuthUser } from 'components/common/auth-context'
import Icon from 'components/ui/icon/icon'
import { Textarea } from 'components/ui/text-area'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import { DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING } from 'components/vault/utils/use-document-classification-store'
import {
  GenerateN1ResponseProps,
  GenerateNNResponseProps,
  GenerateQuestionsProps,
  LOADING_REVIEW_QUERY_LIMIT_HELP_TEXT,
  MIN_QUERY_LENGTH,
  QueryQuestions,
  REVIEW_QUERY_LIMIT_HELP_TEXT,
} from 'components/vault/utils/vault'
import {
  projectsPath,
  queriesPath,
  REMOVE_PARAMS,
  QueryQuestionsResponseData,
  DocumentClassificationAnalyticsData,
} from 'components/vault/utils/vault'
import { FetchVaultReviewQueryUsage } from 'components/vault/utils/vault-fetcher'
import {
  computeQueryDisabledReason,
  getInProgressQuery,
  getQuestionsLimit,
  getReadyToQueryFileIds,
} from 'components/vault/utils/vault-helpers'
import { QUERY_TYPES, useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'
import {
  getQueryUnitStringForRemaining,
  useVaultUsageStore,
} from 'components/vault/utils/vault-usage-store'

import Close from './close'
import Overlay from './overlay'
import QueryTypeSelect from './query-type-select'
import Questions from './questions'

const maxQueryLength = 4000

const VaultQueryBox = ({
  generateQuestions,
  generateNNResponse,
  generateN1Response,
  shouldHideWhenUnfocused,
  disableQueryTypeSelect,
  className,
}: {
  generateQuestions: (
    props: GenerateQuestionsProps
  ) => Promise<QueryQuestionsResponseData>
  generateNNResponse?: (props: GenerateNNResponseProps) => Promise<void>
  generateN1Response: (props: GenerateN1ResponseProps) => Promise<void>
  shouldHideWhenUnfocused?: boolean
  disableQueryTypeSelect?: boolean
  className?: string
}) => {
  const navigate = useNavigateWithQueryParams()
  const { projectId } = useParams()

  const userInfo = useAuthUser()
  const { trackEvent } = useAnalytics()
  const queryIdToState = useVaultStore(useShallow((s) => s.queryIdToState))
  const queryIdToReviewState = useVaultStore(
    useShallow((s) => s.queryIdToReviewState)
  )
  // '' is the pending query ID for the current query box
  const isLoading = queryIdToState['']?.isLoading || false
  const queryId = useVaultStore((state) => state.queryId)
  const currentProjectMetadata = useVaultStore(
    (state) => state.currentProjectMetadata
  )
  const folderIdToVaultFolder = useVaultStore(
    useShallow((s) => s.folderIdToVaultFolder)
  )
  const sourceQuery = queryIdToState[queryId]?.query
  const existingQuestions = queryIdToReviewState[queryId!]?.questions
  const existingFollowUpQueries =
    queryIdToReviewState[queryId!]?.followUpQueries
  const existingQuestionsLimit = queryIdToReviewState[queryId!]?.questionsLimit
  const existingColumnOrder = queryIdToReviewState[queryId!]?.columnOrder
  const existingFilesLimit = queryIdToReviewState[queryId!]?.filesLimit
  const pendingQuery = useVaultStore((state) => state.pendingQuery)
  const pendingQueryFileIds = useVaultStore(
    (state) => state.pendingQueryFileIds
  )
  const isTextAreaFocused = useVaultStore((state) => state.isTextAreaFocused)
  const queryType = useVaultStore((state) => state.queryType)
  const questions = useVaultStore((state) => state.questions)
  const maxQuestionCharacterLength = useVaultStore(
    (state) => state.maxQuestionCharacterLength
  )
  const minQuestionCharacterLength = useVaultStore(
    (state) => state.minQuestionCharacterLength
  )
  const isQuestionsOpen = useVaultStore((state) => state.isQuestionsOpen)
  const isWorkflowRepsWarranties = useVaultStore(
    (state) => state.isWorkflowRepsWarranties
  )
  const exampleProjectIds = useVaultStore(
    useShallow((state) => state.exampleProjectIds)
  )

  const setPendingQuery = useVaultStore((state) => state.setPendingQuery)
  const setIsTextAreaFocused = useVaultStore(
    (state) => state.setIsTextAreaFocused
  )
  const setQueryType = useVaultStore((state) => state.setQueryType)
  const setQuestions = useVaultStore((state) => state.setQuestions)
  const setSelectedQuestions = useVaultStore(
    (state) => state.setSelectedQuestions
  )
  const setMaxQuestionCharacterLength = useVaultStore(
    (state) => state.setMaxQuestionCharacterLength
  )
  const setMinQuestionCharacterLength = useVaultStore(
    (state) => state.setMinQuestionCharacterLength
  )
  const setIsQuestionsOpen = useVaultStore((state) => state.setIsQuestionsOpen)
  const setIsWorkflowRepsWarranties = useVaultStore(
    (state) => state.setIsWorkflowRepsWarranties
  )

  const setTask = useVaultStore((state) => state.setTask)
  const setReviewTask = useVaultStore((state) => state.setReviewTask)
  const clearPendingTask = useVaultStore((state) => state.clearPendingTask)
  const markInProgressTaskAsFromHistory = useVaultStore(
    (state) => state.markInProgressTaskAsFromHistory
  )
  const markHistoryTaskAsFromStreaming = useVaultStore(
    (state) => state.markHistoryTaskAsFromStreaming
  )

  const reviewQuestionsPerQueryLimit = useVaultUsageStore(
    (state) => state.reviewQuestionsPerQueryLimit
  )
  const reviewFilesPerQueryLimit = useVaultUsageStore(
    (state) => state.reviewFilesPerQueryLimit
  )
  const reviewQueryCapExplanation = useVaultUsageStore(
    (state) => state.reviewQueryCapExplanation
  )
  const reviewQueryCapExplanationMore = useVaultUsageStore(
    (state) => state.reviewQueryCapExplanationMore
  )
  const reviewQueryUnit = useVaultUsageStore((state) => state.reviewQueryUnit)
  const reviewQueryDenominator = useVaultUsageStore(
    (state) => state.reviewQueryDenominator
  )
  const reviewQueryUsage = useVaultUsageStore((state) => state.reviewQueryUsage)
  const reviewQueryLimit = useVaultUsageStore((state) => state.reviewQueryLimit)
  const reviewQueryRemaining = reviewQueryLimit
    ? Math.max(reviewQueryLimit - reviewQueryUsage, 0)
    : null
  const reviewQueryRemainingDisplayString = useVaultUsageStore(
    (state) => state.reviewQueryRemainingDisplayString
  )
  const setReviewQueryUsage = useVaultUsageStore(
    (state) => state.setReviewQueryUsage
  )
  const setReviewQueryLimit = useVaultUsageStore(
    (state) => state.setReviewQueryLimit
  )
  const setReviewQueryLimitUnitLevel = useVaultUsageStore(
    (state) => state.setReviewQueryLimitUnitLevel
  )
  const setReviewQueryLimitTimeFrame = useVaultUsageStore(
    (state) => state.setReviewQueryLimitTimeFrame
  )
  const setReviewQueryLimitLevel = useVaultUsageStore(
    (state) => state.setReviewQueryLimitLevel
  )
  const questionsLimit = getQuestionsLimit(
    existingQuestionsLimit,
    reviewQuestionsPerQueryLimit
  )
  const filesLimit = existingFilesLimit ?? reviewFilesPerQueryLimit

  const [questionDescriptionLabel, setQuestionDescriptionLabel] =
    useState<string>('')
  const [isQueryTypeSelectPopoverOpen, setIsQueryTypeSelectPopoverOpen] =
    useState(false)
  const [isFetchingQuestions, setIsFetchingQuestions] = useState(false)
  const [isFetchingReviewQueryLimit, setIsFetchingReviewQueryLimit] =
    useState(false)

  const isNNQuery = queryType === QUERY_TYPES.NN

  const isExampleProject = useMemo(() => {
    return projectId && exampleProjectIds.has(projectId)
  }, [projectId, exampleProjectIds])

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId,
  })

  const readyToQueryFileIds = useMemo(() => {
    return getReadyToQueryFileIds(
      currentProjectMetadata,
      pendingQueryFileIds,
      folderIdToVaultFolder
    )
  }, [currentProjectMetadata, pendingQueryFileIds, folderIdToVaultFolder])

  const documentClassificationAnalyticsData = useMemo(() => {
    const documentClassificationTags = readyToQueryFileIds.flatMap((fileId) => {
      const file = currentProjectMetadata.descendantFiles?.find(
        (file) => file.id === fileId
      )
      return (
        file?.tags.filter(
          (tag) => tag.scope === TagScope.DOCUMENT_CLASSIFICATION
        ) ?? []
      )
    })
    const documentClassificationData = documentClassificationTags.reduce(
      (acc, tag) => {
        const existingEntry = acc.find(
          (entry) => entry.typeDocumentClassification === tag.name
        )
        const tagKey =
          tag.name as keyof typeof DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING
        const groupingDocumentClassification =
          DOCUMENT_CLASSIFICATION_TAG_PARENT_MAPPING[tagKey]
        if (existingEntry) {
          existingEntry.numDocuments++
        } else {
          acc.push({
            numDocuments: 1,
            typeDocumentClassification: tag.name,
            groupingDocumentClassification: groupingDocumentClassification,
          })
        }
        return acc
      },
      [] as DocumentClassificationAnalyticsData[]
    )
    return documentClassificationData
  }, [readyToQueryFileIds, currentProjectMetadata])

  const queryDisabledReason = computeQueryDisabledReason(
    currentProjectMetadata,
    pendingQueryFileIds
  )

  const queryDisabled = queryDisabledReason && queryDisabledReason.length > 0
  const queryLength = pendingQuery.trim().length
  const expandingTextAreaDisabled = isLoading
  const textAreaDisabled = expandingTextAreaDisabled || isQuestionsOpen
  const isButtonDisabled =
    isExampleProject ||
    !doesCurrentUserHaveEditPermission ||
    queryDisabled ||
    textAreaDisabled ||
    queryLength < MIN_QUERY_LENGTH ||
    isFetchingReviewQueryLimit ||
    (queryType === QUERY_TYPES.NN &&
      reviewQueryLimit !== null &&
      reviewQueryUsage >= reviewQueryLimit) ||
    (!!reviewFilesPerQueryLimit &&
      readyToQueryFileIds.length > reviewFilesPerQueryLimit)

  const queryPlaceholder = 'Ask Harvey about the files in your project…'

  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null)
  const cardRef = useRef<HTMLButtonElement>(null)

  // NOTE showGenerateQuestionsButton and showAskHarveyButton should never be true at the same time
  // the ask harvey buttons below use the same textareaRef for cmd+enter assuming there's only one button mounted at a time
  const showGenerateQuestionsButton =
    userInfo.IsVaultReviewUser &&
    isNNQuery &&
    questions.length === 0 &&
    !isFetchingQuestions &&
    !isQuestionsOpen
  const showAskHarveyButton = !isNNQuery
  const shouldGuardSubmission = queryDisabled || !projectId

  const getButtonTooltip = () => {
    if (isExampleProject) {
      return 'Cannot run queries on an example project'
    } else if (!doesCurrentUserHaveEditPermission) {
      return 'You do not have permission to run queries on this project'
    } else if (queryDisabled) {
      return queryDisabledReason
    } else if (isLoading) {
      return IS_LOADING_HELP_TEXT
    } else if (isFetchingReviewQueryLimit) {
      return LOADING_REVIEW_QUERY_LIMIT_HELP_TEXT
    } else if (
      queryType === QUERY_TYPES.NN &&
      reviewQueryLimit !== null &&
      reviewQueryUsage >= reviewQueryLimit
    ) {
      return REVIEW_QUERY_LIMIT_HELP_TEXT
    } else if (
      !!reviewFilesPerQueryLimit &&
      readyToQueryFileIds.length > reviewFilesPerQueryLimit
    ) {
      return `Cannot query more than ${reviewFilesPerQueryLimit} files`
    } else if (queryLength === 0) {
      return EMPTY_QUERY_HELP_TEXT
    } else {
      return ''
    }
  }

  const getFooterText = () => {
    if (queryDisabled) {
      return queryDisabledReason
    } else if (!pendingQueryFileIds) {
      return `All ${pluralizeFiles(readyToQueryFileIds.length)} selected`
    } else {
      return `${pluralizeFiles(readyToQueryFileIds.length)} selected`
    }
  }

  const handleQueryChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > maxQueryLength) {
      displayWarningMessage(
        `Query length cannot exceed ${maxQueryLength} characters`
      )
    } else {
      setPendingQuery(e.target.value)
    }
  }

  const handleGenerateQuestions = async () => {
    if (shouldGuardSubmission) return
    const folderId = projectId

    setIsQuestionsOpen(true)
    setIsFetchingQuestions(true)
    const response = await generateQuestions({ query: pendingQuery, folderId })
    setQuestionDescriptionLabel(response.response)
    setIsFetchingQuestions(false)
    setQuestions(response.questions)
    // Only select up to the new questions limit
    setSelectedQuestions(
      response.questions.slice(
        0,
        questionsLimit - (existingQuestions?.length ?? 0)
      ),
      response.questions
    )
    setMaxQuestionCharacterLength(response.maxQuestionCharacterLength)
    setMinQuestionCharacterLength(response.minQuestionCharacterLength)
  }

  const handleGenerateN1Response = async () => {
    const folderId = projectId ?? ''

    if (_.isEmpty(readyToQueryFileIds)) {
      displayWarningMessage('No files are ready to query')
      return
    }

    markInProgressTaskAsFromHistory(TaskType.VAULT)

    await generateN1Response({
      query: pendingQuery,
      folderId,
      fileIds: readyToQueryFileIds,
      sourceQueryId: queryId,
      documentClassificationAnalyticsData: documentClassificationAnalyticsData,
      onAuthCallback: (queryId) => {
        handleClose()
        clearPendingTask()
        setTask({
          isFromHistory: false,
          queryId: queryId,
          query: pendingQuery.trim(),
          isLoading: true,
          headerText: 'Processing',
          taskType: TaskType.VAULT,
          vaultFolderId: projectId,
          numFiles: readyToQueryFileIds.length,
          fileIds: readyToQueryFileIds,
          startedAt: new Date(),
        })
        const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}${queryId}`
        navigate(newPath, {}, REMOVE_PARAMS)
      },
    })
  }

  const existingQuestionsLength = existingQuestions?.length ?? 0
  const addExtraColumns = queryId && existingQuestionsLength > 0

  const handleGenerateNNResponse = async (
    selectedQuestions: QueryQuestions[]
  ) => {
    if (!generateNNResponse) return
    // Backend doesn't care if we preserve ids, so we map new ones using idx to ensure uniqueness
    const selectedQuestionsWithNewIds = selectedQuestions.map(
      (question, idx) => ({
        ...question,
        id: String(idx + 1 + existingQuestionsLength),
      })
    )

    if (shouldGuardSubmission) return
    const folderId = projectId

    if (_.isEmpty(selectedQuestionsWithNewIds)) {
      displayWarningMessage('Please select at least one question')
      return
    }

    if (_.isEmpty(readyToQueryFileIds)) {
      displayWarningMessage('No files are ready to query')
      return
    }

    // Instead of markInProgressTaskAsFromHistory(TaskType.VAULT_REVIEW)
    // We currently only support one review query at a time
    const inProgressQuery = getInProgressQuery(
      queryIdToState,
      TaskType.VAULT_REVIEW
    )
    if (inProgressQuery) {
      displayWarningMessage(
        'There is already a review query in progress, please wait for it to finish before making a new one'
      )
      return
    }
    if (addExtraColumns) {
      markHistoryTaskAsFromStreaming(queryId)
    }

    const unselectedQuestions = questions
      .filter(
        (question) => !selectedQuestions.map((q) => q.id).includes(question.id)
      )
      .map((question, idx) => ({
        ...question,
        id: String(
          idx + 1 + selectedQuestions.length + existingQuestionsLength
        ),
      }))

    await generateNNResponse({
      requestType: addExtraColumns ? 'extra_columns' : 'new',
      queryId: addExtraColumns ? queryId : undefined,
      query: pendingQuery,
      folderId,
      fileIds: readyToQueryFileIds,
      documentClassificationAnalyticsData: documentClassificationAnalyticsData,
      questions: selectedQuestionsWithNewIds,
      questionsNotAnswered: unselectedQuestions,
      existingQuestions: existingQuestions,
      questionsLimit: questionsLimit,
      filesLimit: filesLimit,
      maxQuestionCharacterLength: maxQuestionCharacterLength,
      minQuestionCharacterLength: minQuestionCharacterLength,
      dryRun: false,
      isWorkflowRepsWarranties,
      existingColumnOrder: existingColumnOrder,
      clientMatterId: currentProjectMetadata.clientMatterId,
      onAuthCallback: (queryId) => {
        handleClose()
        clearPendingTask()
        setReviewTask({
          isFromHistory: false,
          queryId: queryId,
          // For extra_columns, we don't want to update query, use original query instead
          query: addExtraColumns ? sourceQuery : pendingQuery.trim(),
          isLoading: true,
          headerText: 'Processing',
          taskType: TaskType.VAULT_REVIEW,
          vaultFolderId: projectId,
          numFiles: readyToQueryFileIds.length,
          fileIds: readyToQueryFileIds,
          startedAt: new Date(),
          followUpQueries: addExtraColumns
            ? [
                ...(existingFollowUpQueries ?? []),
                {
                  query: pendingQuery.trim(),
                  questionIds: selectedQuestionsWithNewIds.map((q) => q.id),
                },
              ]
            : [],
          questions: existingQuestions
            ? [...existingQuestions, ...selectedQuestionsWithNewIds]
            : selectedQuestionsWithNewIds,
          questionsNotAnswered: unselectedQuestions,
          dryRun: false,
          isWorkflowRepsWarranties: isWorkflowRepsWarranties,
          creatorUserEmail: userInfo.id,
        })
        if (!addExtraColumns) {
          const newPath = `${BaseAppPath.Vault}${projectsPath}${projectId}${queriesPath}${queryId}`
          navigate(newPath, {}, REMOVE_PARAMS)
        }
      },
    })
  }

  const handleEditQuery = () => {
    trackEvent('Vault Query Edited')
    setIsQuestionsOpen(false)
    setQuestions([])
    setSelectedQuestions([], [])
    setQuestionDescriptionLabel('')
    setTimeout(() => textareaRef.current?.focus(), 0)
  }

  const handleClose = () => {
    if (isFetchingQuestions) {
      displayWarningMessage('Please wait while we generate questions for you.')
      return
    }

    if (queryIdToState[queryId]?.isLoading) {
      displayWarningMessage('Please wait while we process your query.')
      return
    }

    setIsQuestionsOpen(false)
    setIsWorkflowRepsWarranties(false)
    setIsTextAreaFocused(false)
    setQuestions([])
    setSelectedQuestions([], [])
    setQuestionDescriptionLabel('')
  }

  const handleSetQueryType = (queryType: QUERY_TYPES) => {
    if (queryType === QUERY_TYPES.NN && questions.length > 0) {
      setIsQuestionsOpen(true)
    }

    if (queryType === QUERY_TYPES.N1) {
      setIsQuestionsOpen(false)
    }

    setQueryType(queryType)
  }

  const handleInputClick = () => {
    setIsTextAreaFocused(true)
  }

  useEffect(() => {
    if (isTextAreaFocused) {
      setTimeout(() => {
        if (!textareaRef.current) return
        textareaRef.current.focus()
        // Set the cursor at the end of the text
        textareaRef.current.setSelectionRange(
          textareaRef.current.value.length,
          textareaRef.current.value.length
        )
      }, 0)
    } else {
      textareaRef.current?.blur()
    }
  }, [isTextAreaFocused, textareaRef])

  useClickAway(cardRef, () => {
    if (isQueryTypeSelectPopoverOpen) return
    handleClose()
  })

  useMount(async () => {
    setPendingQuery(sourceQuery ?? '')
    if (userInfo.IsVaultReviewUser) {
      setIsFetchingReviewQueryLimit(true)
      // Fetch review query usage whenever we are displaying them to make sure we have the latest data
      const response = await FetchVaultReviewQueryUsage()
      setReviewQueryUsage(response.usage)
      setReviewQueryLimit(response.limit)
      setReviewQueryLimitUnitLevel(response.unitLevel)
      setReviewQueryLimitTimeFrame(response.timeFrame)
      setReviewQueryLimitLevel(response.level)
      setIsFetchingReviewQueryLimit(false)
    }
  })

  useEffect(() => {
    setPendingQuery(sourceQuery ?? '')
  }, [sourceQuery, setPendingQuery])

  useUnmount(() => {
    setPendingQuery('')
    clearPendingTask()
  })

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleEscKey)

    return () => {
      document.removeEventListener('keydown', handleEscKey)
    }
  })

  const shouldShowQueryUsage =
    isTextAreaFocused && reviewQueryLimit !== null && !isExampleProject

  if (shouldHideWhenUnfocused && !isTextAreaFocused) {
    return null
  }

  return (
    <>
      <Overlay />

      <div
        className={cn(
          'z-50',
          {
            'absolute left-0 w-full': !isTextAreaFocused,
          },
          className
        )}
      >
        <div className="container mx-auto">
          <div
            className={cn('h-fit w-full', {
              'z-50': isTextAreaFocused,
              'z-0': !isTextAreaFocused,
            })}
          >
            <button
              className={cn(
                'group w-full cursor-text rounded-lg border bg-primary shadow-sm transition',
                {
                  'border-ring shadow-lg ring-0': isTextAreaFocused,
                }
              )}
              disabled={expandingTextAreaDisabled}
              onClick={handleInputClick}
              ref={cardRef}
            >
              <div
                className={cn('relative flex w-full items-center px-0.5 py-3', {
                  'pb-6': !isQuestionsOpen && isTextAreaFocused,
                })}
              >
                {isTextAreaFocused ? (
                  <Textarea
                    ref={textareaRef}
                    className={cn(
                      'focus-visible:ring-none text-ellipses mb-16 h-40 resize-none rounded-lg border-none pr-8 focus-visible:ring-transparent min-desktop-size:mb-8 min-desktop-size:h-32',
                      {
                        'mb-0 h-24': isQuestionsOpen,
                      }
                    )}
                    placeholder={queryPlaceholder}
                    rows={1}
                    onFocus={() => setIsTextAreaFocused(true)}
                    onBlur={() =>
                      // eslint-disable-next-line
                      isTextAreaFocused && textareaRef.current?.focus()
                    }
                    value={pendingQuery}
                    disabled={textAreaDisabled}
                    onChange={handleQueryChange}
                    data-testid="vault-text-input"
                  />
                ) : (
                  <div className="py-2">
                    <p
                      className={cn('mr-32 line-clamp-1 pl-3 text-left', {
                        'text-muted': pendingQuery === '',
                      })}
                    >
                      {pendingQuery || queryPlaceholder}
                    </p>
                  </div>
                )}

                <Close onClose={handleClose} />

                {/* Bottom Actions */}
                <div
                  className={cn(
                    'absolute flex w-full items-end justify-between gap-2 px-4',
                    {
                      'justify-end': !isTextAreaFocused,
                      'bottom-4': isTextAreaFocused,
                    }
                  )}
                >
                  {isTextAreaFocused && !isQuestionsOpen && (
                    <div className="flex flex-col-reverse items-start gap-2 sm:flex-row sm:items-center sm:gap-4">
                      <QueryTypeSelect
                        isQueryTypeSelectPopoverOpen={
                          isQueryTypeSelectPopoverOpen
                        }
                        setIsQueryTypeSelectPopoverOpen={
                          setIsQueryTypeSelectPopoverOpen
                        }
                        queryType={queryType}
                        setQueryType={handleSetQueryType}
                        disabled={disableQueryTypeSelect}
                      />
                      <p className="text-muted">{getFooterText()}</p>
                    </div>
                  )}

                  {showGenerateQuestionsButton && (
                    <div className="flex flex-col items-center gap-2 sm:flex-row sm:gap-4">
                      {shouldShowQueryUsage && (
                        <div className="flex items-center gap-1">
                          {/* Long Text */}
                          <p className="hidden text-muted md:block">
                            {reviewQueryRemaining &&
                            reviewQueryUsage < reviewQueryLimit
                              ? `${reviewQueryRemainingDisplayString} ${getQueryUnitStringForRemaining(
                                  reviewQueryRemaining,
                                  reviewQueryUnit,
                                  reviewQueryDenominator
                                )} remaining`
                              : `No ${getQueryUnitStringForRemaining(
                                  0
                                )} remaining`}
                          </p>

                          {/* Short Text */}
                          <p className="block truncate text-muted md:hidden">
                            {reviewQueryRemainingDisplayString} remaining
                          </p>

                          <Tooltip delayDuration={200}>
                            <TooltipTrigger>
                              <Icon icon={InfoIcon} className="text-muted" />
                            </TooltipTrigger>
                            <TooltipContent className="max-w-sm text-start">
                              {[
                                reviewQueryCapExplanation,
                                reviewQueryCapExplanationMore,
                              ].join(' ')}
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      )}

                      <div>
                        <AskHarveyButton
                          handleSubmit={() => handleGenerateQuestions()}
                          disabled={isButtonDisabled}
                          tooltip={getButtonTooltip()}
                          tooltipAlign="end"
                          inputRef={textareaRef}
                          // adding extra columns should not require a client matter selection
                          // because it uses the same event as the original query
                          shouldEnforceClientMatterSelection={
                            !addExtraColumns &&
                            !userInfo.IsVaultProjectClientMatterUser
                          }
                        />
                      </div>
                    </div>
                  )}

                  {showAskHarveyButton && (
                    <div>
                      <AskHarveyButton
                        handleSubmit={() => handleGenerateN1Response()}
                        disabled={isButtonDisabled}
                        tooltip={getButtonTooltip()}
                        inputRef={textareaRef}
                      />
                    </div>
                  )}
                </div>
              </div>
              <Questions
                isQuestionsOpen={isQuestionsOpen}
                questions={questions}
                setQuestions={setQuestions}
                onCreateTable={handleGenerateNNResponse}
                questionsLimit={questionsLimit}
                existingQuestions={existingQuestions}
                isFetchingQuestions={isFetchingQuestions}
                onEditQuery={handleEditQuery}
                descriptionLabel={questionDescriptionLabel}
                maxQuestionCharacterLength={maxQuestionCharacterLength}
                minQuestionCharacterLength={minQuestionCharacterLength}
                filesCount={readyToQueryFileIds.length}
                filesLimit={filesLimit}
                reviewQueryLimit={reviewQueryLimit}
                reviewQueryUsage={reviewQueryUsage}
                disabled={isWorkflowRepsWarranties}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default VaultQueryBox
