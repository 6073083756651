import React, { useCallback, useMemo, memo } from 'react'

import {
  ColDef,
  RowHeightParams,
  GridReadyEvent,
  HeaderClassParams,
  RowGroupOpenedEvent,
  SortChangedEvent,
  FilterChangedEvent,
  ColumnMovedEvent,
} from 'ag-grid-community'
import { useShallow } from 'zustand/react/shallow'

import { VaultFile } from 'openapi/models/VaultFile'

import { displayErrorMessage } from 'utils/toast'

import { DataGrid } from 'components/ui/data-grid/data-grid-v2'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'
import {
  reorderColumnIds,
  createGridColumnDefs,
  addFilesToGrid,
  getDisplayedRows,
  GridTransactionAction,
} from 'components/vault/query-detail/data-grid-helpers'
import { EXCLUDED_COLIDS_FROM_RESIZE_FILTER } from 'components/vault/query-detail/vault-query-detail'
import useVaultQueryDetailStore, {
  ReviewHistoryItem,
} from 'components/vault/query-detail/vault-query-detail-store'
import { useVaultDataGridFilterStore } from 'components/vault/utils/vault-data-grid-filters-store'
import { ReorderVaultReviewQueryColumns } from 'components/vault/utils/vault-fetcher'
import { useVaultStore } from 'components/vault/utils/vault-store'

import RowNumberCell from './cells/row-number-cell'
import DocumentCell from './cells/vault-document-cell'
import VaultGroupRowRenderer from './cells/vault-group-row-renderer'
import VaultHeaderCell from './cells/vault-header-cell'
import NoRowsOverlay from './no-rows-overlay'

interface VaultDataGridProps {
  projectId: string
  queryId: string
  isQueryLoading: boolean
  isExampleProject: boolean
  hasPendingColumns: boolean
  doesCurrentUserHaveEditPermission: boolean
  onGridReady: (e: GridReadyEvent) => void
  onGridDestroyed: () => void
  computeHeaderClass: (params: HeaderClassParams) => string
  onRowGroupOpened: (e: RowGroupOpenedEvent) => void
  onSortChanged: (e: SortChangedEvent) => void
  onFilterChanged: (e: FilterChangedEvent) => void
  onColumnMoved: (e: ColumnMovedEvent) => void
}

const VaultDataGrid = memo(
  ({
    projectId,
    queryId,
    isQueryLoading,
    isExampleProject,
    hasPendingColumns,
    doesCurrentUserHaveEditPermission,
    onGridReady,
    onGridDestroyed,
    computeHeaderClass,
    onRowGroupOpened,
    onSortChanged,
    onFilterChanged,
    onColumnMoved,
  }: VaultDataGridProps) => {
    const questionColumnType = {
      useValueFormatterForExport: false,
      cellRenderer: DocumentCell,
      pinned: false,
      lockPinned: true,
      // do not allow moving question columns for example projects or when the query is still loading
      suppressMovable:
        isExampleProject ||
        !doesCurrentUserHaveEditPermission ||
        isQueryLoading ||
        hasPendingColumns,
      lockPosition: false,
    }
    return (
      <DataGrid
        gridOptions={{
          // allowing reactive components
          reactiveCustomComponents: true,
          // allowing multiple row selection
          rowSelection: 'multiple',
          suppressRowClickSelection: true,
          // setting up the grid options to allow for row grouping
          // we set groupDefaultExpanded to -1 so that all of the groups will be expanded by default
          animateRows: false,
          groupDefaultExpanded: -1,
          groupDisplayType: 'groupRows',
          groupRowRenderer: VaultGroupRowRenderer,
          getRowHeight: (e: RowHeightParams) => (e.node.group ? 48 : null),
          // we need this to prevent the grid from scrolling to the top when new data is loaded
          // new data is loaded when we stream responses and update rowData
          // or when we toggle short/long responses
          // https://stackoverflow.com/questions/55723337/ag-grid-how-to-scroll-to-last-known-position
          suppressScrollOnNewData: true,
          suppressColumnMoveAnimation: true,
          suppressCellFocus: true,
          suppressHeaderFocus: true,
          suppressDragLeaveHidesColumns: true,
          suppressNoRowsOverlay: true,
          // default header height
          headerHeight: 0,
          rowData: [],
          columnDefs: [],
        }}
        defaultColDef={{
          headerComponent: VaultHeaderCell,
          suppressMovable: true,
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            maxNumConditions: Number.MAX_SAFE_INTEGER,
          },
          lockPosition: true,
          resizable: !isQueryLoading,
          headerClass: computeHeaderClass,
          cellRendererParams: {
            projectId,
            queryId,
          },
          headerComponentParams: {
            isExampleProject,
            hasPendingColumns,
            doesCurrentUserHaveEditPermission,
          },
        }}
        columnTypes={{
          hidden: {
            hide: true,
            lockVisible: true,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true,
          },
          number: {
            useValueFormatterForExport: false,
            cellRenderer: RowNumberCell,
            sortable: false,
          },
          document: {
            useValueFormatterForExport: false,
            cellRenderer: DocumentCell,
          },
          text: questionColumnType,
          date: questionColumnType,
          gutter: {
            headerComponent: () => (
              <div className="h-full w-full bg-secondary" />
            ),
            useValueFormatterForExport: false,
            rowSpan: (params) => {
              return params.node &&
                params.node.parent &&
                params.node.parent.allChildrenCount
                ? params.node.parent.allChildrenCount - params.node.childIndex
                : 1
            },
            cellRenderer: () => <div className="h-full w-full bg-secondary" />,
            resizable: false,
            lockPosition: 'right',
          },
        }}
        onGridReady={onGridReady}
        onGridDestroyed={onGridDestroyed}
        noRowsOverlayComponent={NoRowsOverlay}
        onRowGroupOpened={onRowGroupOpened}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        onColumnMoved={onColumnMoved}
      />
    )
  }
)

const VaultDataGridWrapper = () => {
  const [setDisplayedRows, setAgGridHeaderHeight, bulkRemoveSelectedRows] =
    useVaultDataGridFilterStore(
      useShallow((state) => [
        state.setDisplayedRows,
        state.setAgGridHeaderHeight,
        state.bulkRemoveSelectedRows,
      ])
    )

  const [
    currentProject,
    currentProjectMetadata,
    exampleProjectIds,
    fileIdToVaultFile,
    folderIdToVaultFolder,
  ] = useVaultStore(
    useShallow((state) => [
      state.currentProject,
      state.currentProjectMetadata,
      state.exampleProjectIds,
      state.fileIdToVaultFile,
      state.folderIdToVaultFolder,
    ])
  )

  const [
    queryId,
    isQueryLoading,
    initialHistoryItem,
    initialPendingQueryFileIds,
    initialPendingQueryQuestions,
    pendingQueryQuestions,
    setGridApi,
    setHasOnGridReadyExecuted,
    setPendingQueryQuestions,
    setPendingQueryFileIds,
  ] = useVaultQueryDetailStore(
    useShallow((state) => [
      state.queryId,
      state.isQueryLoading,
      state.initialHistoryItem,
      state.initialPendingQueryFileIds,
      state.initialPendingQueryQuestions,
      state.pendingQueryQuestions,
      state.setGridApi,
      state.setHasOnGridReadyExecuted,
      state.setPendingQueryQuestions,
      state.setPendingQueryFileIds,
    ])
  )

  const projectId = currentProject?.id ?? ''
  const hasPendingColumns = pendingQueryQuestions
    ? pendingQueryQuestions.length > 0
    : false

  const isExampleProject = useMemo(
    () => (currentProject ? exampleProjectIds.has(currentProject.id) : false),
    [currentProject, exampleProjectIds]
  )
  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: projectId,
  })

  const onGridDestroyed = useCallback(() => {
    setPendingQueryQuestions(null)
    setPendingQueryFileIds(null)
    setGridApi(null)
  }, [setGridApi, setPendingQueryQuestions, setPendingQueryFileIds])

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      const api = e.api
      setGridApi(api)
      const projectFolders = [
        currentProjectMetadata,
        ...(currentProjectMetadata.descendantFolders ?? []),
      ]
      const shouldGroupRows = projectFolders.length > 1

      const reviewEvent = initialHistoryItem as ReviewHistoryItem
      // 1. create the column definitions
      createGridColumnDefs({
        gridApi: api,
        shouldGroupRows,
        eventId: Number(reviewEvent?.eventId),
        historyColumns: reviewEvent?.columns ?? [],
        pendingQueryQuestions: initialPendingQueryQuestions ?? [],
      })

      // 2. create the necessary row data
      const historyFilesToAdd =
        reviewEvent?.fileIds?.map(
          (fileId: string) => fileIdToVaultFile[fileId]
        ) ?? []
      const processedFileIds = reviewEvent?.processedFileIds ?? []
      const pendingFilesToAdd =
        initialPendingQueryFileIds?.map(
          (fileId: string) => fileIdToVaultFile[fileId]
        ) ?? []

      if (historyFilesToAdd.length > 0) {
        addFilesToGrid({
          gridApi: api,
          setAgGridHeaderHeight: setAgGridHeaderHeight,
          files: [...historyFilesToAdd, ...pendingFilesToAdd].filter(
            Boolean
          ) as VaultFile[],
          folderIdToVaultFolder,
          isLoading: isQueryLoading,
          gridAction: GridTransactionAction.ADD,
          isDryRun: reviewEvent.dryRun ?? false,
          fileIdToSources: reviewEvent.fileIdToSources,
          fileIdToAnswers: reviewEvent.answers,
          fileIdToErrors: reviewEvent.errors,
          suppressedFileIdsSet: new Set(reviewEvent.suppressedFileIds),
          processedFileIdsSet: new Set(processedFileIds),
          questions: reviewEvent.questions,
        })
      }

      if (pendingFilesToAdd.length > 0) {
        addFilesToGrid({
          gridApi: api,
          setAgGridHeaderHeight: setAgGridHeaderHeight,
          files: pendingFilesToAdd.filter(Boolean) as VaultFile[],
          folderIdToVaultFolder,
          isLoading: isQueryLoading,
          gridAction: GridTransactionAction.ADD,
        })
      }

      // 3. We want to remove the suppressNoRowsOverlay so it will display if there are no rows
      api.setGridOption('suppressNoRowsOverlay', false)
      if (historyFilesToAdd.length === 0 && pendingFilesToAdd.length === 0) {
        api.showNoRowsOverlay()
      } else {
        setDisplayedRows(getDisplayedRows(api))
      }
      setHasOnGridReadyExecuted(true)
    },
    [
      setGridApi,
      setHasOnGridReadyExecuted,
      setAgGridHeaderHeight,
      setDisplayedRows,
      isQueryLoading,
      currentProjectMetadata,
      initialPendingQueryFileIds,
      initialPendingQueryQuestions,
      initialHistoryItem,
      fileIdToVaultFile,
      folderIdToVaultFolder,
    ]
  )

  const computeHeaderClass = useCallback(
    (params: HeaderClassParams) => {
      const colId = params.column?.getColId() ?? ''
      if (EXCLUDED_COLIDS_FROM_RESIZE_FILTER.includes(colId)) {
        return ''
      }
      return isQueryLoading ? 'border-r' : ''
    },
    [isQueryLoading]
  )

  const onRowGroupOpened = useCallback((e: RowGroupOpenedEvent) => {
    e.api.refreshCells()
  }, [])

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      e.api.refreshCells()
      setDisplayedRows(getDisplayedRows(e.api))
    },
    [setDisplayedRows]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const numVisibleRows = e.api.getDisplayedRowCount()
      if (numVisibleRows === 0) {
        e.api.showNoRowsOverlay()
      } else {
        e.api.hideOverlay()
        e.api.refreshCells()
      }
      setDisplayedRows(getDisplayedRows(e.api))

      // after the filter is applied we need to update the selected rows
      // we do this by getting the selected rows and then updating the selected rows
      // this is necessary because the selected rows are not updated automatically
      // when the filter is applied
      const selectedNodes = e.api.getSelectedNodes()
      const nodesToUnselect: string[] = []
      selectedNodes.forEach((node) => {
        if (!node.displayed && node.id) {
          nodesToUnselect.push(node.id)
          node.setSelected(false)
        }
      })
      bulkRemoveSelectedRows(nodesToUnselect)
    },
    [setDisplayedRows, bulkRemoveSelectedRows]
  )

  const onColumnMoved = useCallback(
    async (e: ColumnMovedEvent) => {
      const isNewQuery = queryId === 'new'
      const movedColumnId = e.columns?.[0].getColId()
      const toIndex = e.toIndex
      if (
        e.finished &&
        e.source === 'uiColumnMoved' &&
        !isNewQuery &&
        movedColumnId &&
        toIndex
      ) {
        const gridApi = e.api

        const allGridColumns = gridApi.getAllGridColumns()
        const updatedQuestionIdOrder = allGridColumns
          .map((col) => col.getColDef())
          // only question columns are moveable
          .filter((col) => !col.suppressMovable)
          .map((col) => col.field)
          .filter((field): field is string => field !== undefined)
        // console.log('toIndex', toIndex)
        // console.log('updatedQuestionIdOrder', updatedQuestionIdOrder)
        try {
          await ReorderVaultReviewQueryColumns(queryId, updatedQuestionIdOrder)
        } catch (error) {
          displayErrorMessage('Error moving column, please try again.')

          // revert the column order
          const updatedColumnIdOrder = allGridColumns
            .map((col) => col.getColDef())
            .map((col) => col.field)
            .filter((field): field is string => field !== undefined)
          const originalColumnIdOrder = reorderColumnIds(
            updatedColumnIdOrder,
            toIndex,
            movedColumnId
          )
          const columnDefs = gridApi.getColumnDefs() ?? []

          const sortedColumnDefs = columnDefs.sort((a, b) => {
            const aField = (a as ColDef).field
            const bField = (b as ColDef).field
            const aIndex = originalColumnIdOrder.indexOf(aField as string)
            const bIndex = originalColumnIdOrder.indexOf(bField as string)
            if (aIndex === -1 && bIndex === -1) return 0
            if (aIndex === -1) return 1
            if (bIndex === -1) return -1
            return aIndex - bIndex
          })
          gridApi.setGridOption('columnDefs', sortedColumnDefs)
        }
      }
    },
    [queryId]
  )

  return (
    <VaultDataGrid
      projectId={projectId}
      queryId={queryId}
      isQueryLoading={isQueryLoading}
      isExampleProject={isExampleProject}
      hasPendingColumns={hasPendingColumns}
      doesCurrentUserHaveEditPermission={doesCurrentUserHaveEditPermission}
      onGridReady={onGridReady}
      onGridDestroyed={onGridDestroyed}
      computeHeaderClass={computeHeaderClass}
      onRowGroupOpened={onRowGroupOpened}
      onSortChanged={onSortChanged}
      onFilterChanged={onFilterChanged}
      onColumnMoved={onColumnMoved}
    />
  )
}

VaultDataGrid.displayName = 'VaultDataGrid'
VaultDataGridWrapper.displayName = 'VaultDataGridWrapper'
export default VaultDataGridWrapper
