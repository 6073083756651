import React, { useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Plus } from 'lucide-react'

import { getMergedQueryPath } from 'hooks/use-navigate-with-query-params'
import { NAV_STATE_PARAM } from 'utils/routing'

import { useAssistantAnalytics } from 'components/assistant-v2/hooks/use-assistant-analytics'
import { useAssistantStore } from 'components/assistant-v2/stores/assistant-store'
import {
  FORK_EVENT_STATE_KEY,
  REMOVE_PARAMS,
} from 'components/assistant-v2/utils/assistant-helpers'
import {
  isResearchKnowledgeSource,
  isVaultKnowledgeSource,
} from 'components/assistant-v2/utils/assistant-knowledge-sources'
import { StateValues } from 'components/common/use-navigation-query-state'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import Link from 'components/ui/link/link'
import useSharingPermissions from 'components/vault/hooks/use-sharing-permissions'

const AssistantCreateThreadDropdown = ({
  disabled,
}: {
  disabled?: boolean
}) => {
  const location = useLocation()
  const { mode } = useParams()
  const trackEvent = useAssistantAnalytics()
  const eventId = useAssistantStore((s) => s.eventId)
  const documents = useAssistantStore((s) => s.documents)
  const getCurrentThread = useAssistantStore((s) => s.getCurrentThreadMessages)
  const knowledgeSource = useAssistantStore((s) => s.knowledgeSource)

  const isVaultSource = isVaultKnowledgeSource(knowledgeSource)
  const hasDocuments = !!documents.length || isVaultSource
  const hasFilters = isResearchKnowledgeSource(knowledgeSource)

  const { doesCurrentUserHaveEditPermission } = useSharingPermissions({
    projectId: isVaultSource ? knowledgeSource.folderId : undefined,
  })

  const getLinkWithMode = useCallback(
    (reuse: boolean) => {
      const locState: StateValues = { mode }
      if (reuse) {
        locState.query = getCurrentThread()[0]?.query
        if (hasDocuments && eventId) locState[FORK_EVENT_STATE_KEY] = eventId
        if (knowledgeSource) {
          if (
            isVaultKnowledgeSource(knowledgeSource) &&
            knowledgeSource.query
          ) {
            locState.query = knowledgeSource.query
          }
          // NOTE: If user actually has 1000 Vault files in source, this might not capture them all
          // https://stackoverflow.com/questions/812925/what-is-the-maximum-possible-length-of-a-query-string
          locState.knowledge = knowledgeSource
        }
      }

      const params = new URLSearchParams(location.search)
      params.append(NAV_STATE_PARAM, JSON.stringify(locState))
      return getMergedQueryPath(
        '/assistant',
        { search: params.toString() },
        REMOVE_PARAMS
      )
    },
    [
      location.search,
      mode,
      getCurrentThread,
      eventId,
      hasDocuments,
      knowledgeSource,
    ]
  )

  const trackFork = (reuse: boolean) => {
    trackEvent('Thread Forked', {
      reuse: reuse,
      with_documents: !!documents.length,
      with_vault: !!isVaultSource,
    })
  }

  const canReuse = !isVaultSource || doesCurrentUserHaveEditPermission

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={disabled} asChild>
        <Button variant="outline">
          <Icon icon={Plus} />
          <p className="ml-2 hidden sm:block">New thread</p>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem asChild>
          <Link
            className="flex items-center text-sm"
            onClick={() => trackFork(false)}
            to={getLinkWithMode(false)}
          >
            Empty
          </Link>
        </DropdownMenuItem>
        {canReuse && (
          <DropdownMenuItem asChild>
            <Link
              className="flex items-center text-sm"
              onClick={() => trackFork(true)}
              to={getLinkWithMode(true)}
            >
              Reuse{' '}
              {hasDocuments ? 'documents' : hasFilters ? 'filters' : 'query'}
            </Link>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default AssistantCreateThreadDropdown
